<!-- @format -->

<ng-container *ngIf="offer">
	<div class="box d-flex ai-start jc-start rounded-sm position-r p-3">
		<div class="close cursor-p" (click)="onClose(offer)">
			<app-svg-icon
				class="d-block"
				appIcon="cross--white"
				appWidth="10"
				appHeight="10"
				appViewBox="0 0 14 14"
			></app-svg-icon>
		</div>
		<div class="d-flex ai-start jc-start mr-3">
			<app-svg-icon
				class="d-block"
				[appIcon]="isSigned ? 'status-await' : 'status-success'"
				appWidth="40"
				appHeight="40"
				appViewBox="0 0 24 25"
			></app-svg-icon>
		</div>
		<div class="d-block">
			<div class="d-flex ai-center jc-start my-1">
				<span class=" h4 text-bold mr-5">
					{{ messageMap?.title }}
				</span>
				<time class=" h7">
					{{ offer.created_at | dayjs: "format":"HH:mm / DD.MM.YYYY" }}
				</time>
			</div>
			<p class=" line-height-3 mb-5">
				{{ messageMap?.message }}
			</p>
			<div class="control d-flex ai-center jc-start gap-4">
				<button
					class="link-to-detail btn btn-style-green btn-inline rounded-md line-height-5 px-1"
					(click)="transfer.emit(offer.book)"
				>
					<app-svg-icon
						class="mr-1"
						appIcon="contract-black"
						appWidth="19"
						appHeight="20"
						appViewBox="0 0 19 20"
					></app-svg-icon>
					Показать предложение
				</button>
				<div *ngIf="showBook" class="d-flex ai-center jc-start">
					<span class=" mr-1">Книга:</span>
					<a
						class="link-to-book d-flex ai-center jc-start"
						[routerLink]="['/office/books', offer.book.id]"
					>
						<app-svg-icon
							class="d-block rounded-sm overflow-h mr-1"
							appIcon="book-cover"
							appWidth="23"
							appHeight="32"
							appViewBox="0 0 116 160"
						></app-svg-icon>
						<span class="">{{ offer.book.title }}</span>
					</a>
				</div>
			</div>
		</div>
	</div>
</ng-container>
