import {
  SimpleChanges,
  OnChanges,
  OnDestroy,
  Directive,
  ElementRef,
  Input,
  NgZone, Inject,
} from '@angular/core';
import { gsap } from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { animationsPresets } from "./ng-gx-text-animate.lib";
import { filter, switchMap } from "rxjs/operators";
import { of, Subscription } from "rxjs";
import { PlatformService } from "../../../services";

@Directive({
  selector: '[ngGxTextAnimate]'
})
export class ngGxTextAnimateDirective implements OnChanges, OnDestroy {
  @Input('serial') public serial: any;
  @Input('animate') public animateMode: any = 'nodes';
  @Input('animation') public animationVariant: string | null = null;
  @Input('immediateRender') public immediateRender: boolean = true;
  @Input('delay') public delay: number = 0;

  windowResize$: Subscription | undefined;

  constructor(
    protected ngZone: NgZone,
    protected el: ElementRef<HTMLElement>,
    protected platformService: PlatformService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.serial) {
      if (changes.serial.firstChange) {  // change before initialization
        this.ngZone.runOutsideAngular(() => {
          this.animationInit();
        });
      }
      else {
        this.ngZone.runOutsideAngular(() => {
          this.animationDestroy();
          this.animationInit();
          this.afterAnimationInit();
        });
      }
    }
  }

  ngAfterViewInit() {
    this.windowResize$ = this.platformService.getResize()
      .pipe(
        switchMap(() => of(this.platformService.getWindow())),
      )
      .subscribe((window: Window) => {
        this.ngZone.runOutsideAngular(() => {
          const scrollTriggers = ScrollTrigger.getAll();
          scrollTriggers.slice(0).forEach(function (t) {
            return t.vars.id === 'ngGxTextAnimate' && t.refresh();
          });
        });
      });

    this.ngZone.runOutsideAngular(() => {
      this.afterAnimationInit();
    });
  }

  ngOnDestroy() {
    this.windowResize$?.unsubscribe();
    this.ngZone.runOutsideAngular(() => {
      this.animationDestroy();
    });
  }

  animationInit(): void {
  }

  animationDestroy(): void
  {
    const scrollTriggers = ScrollTrigger.getAll();
    scrollTriggers.slice(0).forEach(function (t) {
      return t.vars.id === 'ngGxTextAnimate' && t.kill();
    });
  }

  afterAnimationInit(): void {
    if (this.immediateRender) {
      gsap.set(this.el.nativeElement, { opacity: 0 });
    }

    if ('splitChars' ===  this.animateMode) {
      // TODO
      gsap.to(this.el.nativeElement, { opacity: 1, duration: 0});
    }
    else if ('words' ===  this.animateMode) {
      nodeTraverse(this.el.nativeElement, textSplitAndMarkup);
      const words = this.el.nativeElement.getElementsByClassName('gx-word');
      if (this.immediateRender) {
        gsap.to(words, { opacity: 0, duration: 0});
        gsap.to(this.el.nativeElement, { opacity: 1, duration: 0});
      }
      animationsPresets[(this.animationVariant ?? 'magicWords') + 'Animation'](words, this.immediateRender);
    }
    else if ('lines' ===  this.animateMode) {
      nodeTraverse(this.el.nativeElement, textSplitAndMarkup);
      const lines = this.el.nativeElement.getElementsByClassName('gx-line');
      if (this.immediateRender) {
        gsap.to(lines, { opacity: 0, duration: 0});
        gsap.to(this.el.nativeElement, { opacity: 1, duration: 0});
      }
      animationsPresets[(this.animationVariant ?? 'elastic') + 'Animation'](lines, this.immediateRender);
    }
    else if ('*' ===  this.animateMode) {
      const collection = this.el.nativeElement.querySelectorAll('*');
      if (this.immediateRender) {
        gsap.to(collection, { opacity: 0, duration: 0});
        gsap.to(this.el.nativeElement, { opacity: 1, duration: 0});
      }
      animationsPresets[(this.animationVariant ?? 'elastic') + 'Animation'](collection, this.immediateRender);
    }
    else if ('nodes' ===  this.animateMode) {
      const children = this.el.nativeElement.children?.length ? Array.prototype.slice.call(this.el.nativeElement.children) : this.el.nativeElement;
      if (this.immediateRender) {
        gsap.to(children, { opacity: 0, duration: 0});
        gsap.to(this.el.nativeElement, { opacity: 1, duration: 0});
      }
      animationsPresets[(this.animationVariant ?? 'elastic') + 'Animation'](children, this.immediateRender);
    }
    setTimeout(() => ScrollTrigger.refresh(), 500);
  }
}

const textSplitAndMarkup = (node: Node): void => {
  if (node.nodeType === Node.TEXT_NODE) {
    const parentNode = node!.parentNode;
    const lines = node.textContent?.split(/[\n]/);
    const newNode = document.createElement('span');
    newNode.classList.add('gx-node');
    lines?.forEach(line => {
      const newLine = document.createElement('span');
      newLine.classList.add('gx-line');
      const words = line.split(/([^- _]+[- _])/gu).filter(value => value !== '');
      words?.forEach(word => {
        const newWord = document.createElement('span');
        newWord.classList.add('gx-word');
        newWord.appendChild(document.createTextNode(safeNbsp(word)));
        newLine.appendChild(newWord);
      })
      newNode.appendChild(newLine);
    })
    // if (!words?.length ?? false) return;
    parentNode?.replaceChild(newNode, node);
  }
};

function nodeTraverse(el: HTMLElement, cb: (node: Node) => void): void {
  return allDescendants(el, cb);
}

function allDescendants (node: Node, cb: (node: Node) => void): void {
  for (var i = 0; i < node.childNodes.length; i++) {
    var child = node.childNodes[i];
    allDescendants(child, cb);
    cb(child);
  }
}

const safeNbsp = (text: string) => text.replace(/(^ | $)/s, '\u00A0');
