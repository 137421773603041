/** @format */

import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  AttachmentService,
  ContractService, DkpService,
  FileService, SnackbarService,
  User,
  UserAttachment,
  UserContract, UserDkp,
  UserService
} from '../../../core';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-office-settings-resume',
  templateUrl: './resume.component.html',
  styleUrls: ['./resume.component.scss']
})
export class OfficeSettingsResumeComponent implements OnInit, OnDestroy {
  route$!: Subscription;
  user$: Observable<User> | undefined;

  contractList: UserContract[] = [];
  attachmentList: UserAttachment[] = [];

  dkpList: UserDkp[] = [];

  constructor(
    private userService: UserService,
    private route: ActivatedRoute,
    private contractService: ContractService,
    private dkpService: DkpService,
    private fileService: FileService,
    private attachmentService: AttachmentService,
    private snackbarService: SnackbarService
  ) {}

  ngOnInit(): void {
    this.user$ = this.userService.currentUser;

    this.route$ = this.route.data.pipe(map((data: any) => data.data)).subscribe({
      next: (data: [UserContract[], UserDkp[], UserAttachment[]]) => {
        const [contractList, dkpList, attachmentList] = data;
        this.contractList = contractList;
        this.attachmentList = attachmentList;
        this.dkpList = dkpList;
      },
      error: (error: any) => console.error(error)
    });
  }

  ngOnDestroy(): void {
    [this.route$].forEach($ => $?.unsubscribe());
  }

  onDownloadContract(contract: UserContract): void {
    this.contractService.getContract(contract.id).subscribe({
      next: (fileBinary: any) => this.fileService.getDownloadFile(fileBinary, contract.file_name),
      error: (error: any) => console.error(error)
    });
  }

  onDownloadAttachment(attachment: UserAttachment): void {
    this.attachmentService.getAttachment(attachment.id).subscribe({
      next: (fileBinary: any) => this.fileService.getDownloadFile(fileBinary, attachment.file_name),
      error: (error: any) => console.error(error)
    });
  }

  onDownloadDkp(contract: UserDkp): void {
    this.dkpService.getContract(contract.id).subscribe({
      next: (fileBinary: any) => this.fileService.getDownloadFile(fileBinary, contract.file_name),
      error: (error: any) => console.error(error)
    });
  }

  onSignDkp(contract: UserDkp): void {
    this.dkpService.signContract(contract.id).subscribe({
      next: (data: any) => {
        this.snackbarService.success(
          'Копия договора отправлена вложением на адрес электронной почты указанный вами при регистрации.' +
          ' Если вы готовы подписать договор, перейдите по ссылке в письме.'
        )
      },
      error: (error: any) => console.error(error)
    });
  }

  onDeleteDkp(contract: UserDkp): void {
    this.dkpService.deleteContract(contract.id).subscribe({
      next: (data: any) => {
        const iof = this.dkpList.indexOf(contract);
        if (iof !== -1) this.dkpList.splice(iof, 1);
        this.snackbarService.success(
          `Черновик договора "${contract.file_name}" удален!`
        )
      },
      error: (error: any) => console.error(error)
    });
  }
}
