/** @format */

import { Inject, Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { PlatformService } from './platform.service';

@Injectable({
  providedIn: 'root'
})
export class FileService {
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private apiService: ApiService,
    private platformService: PlatformService
  ) {}

  getMIMEType(fileName: string): string {
    const extension = fileName.split('.').pop();

    /**
     * MIME types list
     *
     * https://ru.wikipedia.org/wiki/%D0%A1%D0%BF%D0%B8%D1%81%D0%BE%D0%BA_MIME-%D1%82%D0%B8%D0%BF%D0%BE%D0%B2#image
     */

    switch (extension) {
      case 'jpeg':
      case 'jpg':
        return 'image/jpeg';
      case 'png':
        return 'image/png';
      case 'pdf':
        return 'application/pdf';
      case 'doc':
      case 'docx':
        return 'application/msword';
      case 'mp3':
        return 'audio/mpeg';
      case 'wave':
        return 'audio/vnd.wave';
      default:
        return 'text/plain';
    }
  }

  getDownloadFile(fileBlob: Blob, fileName: string): void {
    if (this.platformService.isBrowser()) {
      const window: any = this.platformService.getWindow();
      const blob = new Blob([fileBlob], { type: this.getMIMEType(fileName) });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');

      link.href = url;
      link.setAttribute('download', fileName);

      this.document.body.appendChild(link);
      link.click();
      this.document.body.removeChild(link);
    }
  }

  postUpload(body: any): Observable<any> {
    return this.apiService.post('/v1/users/uploadFiles', body).pipe(map((data: any) => data.data));
  }

  getUpload(id: any): Observable<any> {
    return this.apiService.get('/v1/users/readFile/' + id, undefined, { responseType: 'blob' });
  }

  deleteUpload(id: any): Observable<any> {
    return this.apiService.post('/v1/users/deleteFile/' + id, {}).pipe(map((data: any) => data.data));
  }

  bulkDeleteUploads(ids: string[]): Observable<any> {
    return this.apiService.post('/v1/users/deleteFiles', {files: ids}).pipe(map((data: any) => data.data));
  }
}
