<!-- @format -->

<section class="box d-flex ai-center jc-center">
	<main class="main d-flex fd-column ai-center jc-center py-7">
		<img class="d-block mb-5" src="assets/images/error/error-image.svg" alt="Ошибка" />
		<div class="d-flex fd-column text-center mb-5">
			<h2 class="d-block border rounded-xl text-regular mb-1 p-2">
				{{ message }}
			</h2>
			<h4 class="d-block text-regular text-color-log-print_main-red">
				Код ошибки {{ statusCode }}
			</h4>
		</div>
		<p class="h4 m-0">Ой, кажется, что-то пошло не так!</p>
		<a
				[routerLink]="['/']"
				class="btn btn-style-1 btn-inline rounded-md p-1 my-3 w-1/2"
		>
			Продолжить сначала
		</a>
	</main>
</section>
