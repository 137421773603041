/**
 *
 * Window code snippet is based on
 * https://juristr.com/blog/2016/09/ng2-get-window-ref/
 *
 * @format
 */

import { Inject, Injectable, PLATFORM_ID, Renderer2, RendererFactory2 } from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { EMPTY, fromEvent, Observable } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

const getWindow = (): Window => window;

@Injectable()
export class PlatformService {
  renderer2: Renderer2;

  constructor(
    @Inject(PLATFORM_ID) private platformId: string,
    @Inject(DOCUMENT) private document: Document,
    private rendererFactory2: RendererFactory2
  ) {
    this.renderer2 = rendererFactory2.createRenderer(null, null);
  }

  isBrowser(): boolean {
    return isPlatformBrowser(this.platformId);
  }

  getWindow(): Window {
    return getWindow();
  }

  getResize(debounce: number = 200): Observable<Event> {
    if (this.isBrowser()) {
      const window = this.getWindow();

      return fromEvent(window, 'resize').pipe(debounceTime(debounce));
    }

    return EMPTY;
  }

  setScrollToggle(toggle: boolean): void {
    if (this.isBrowser()) {
      const window = this.getWindow();
      const className = 'overlay';

      if (this.document.body.clientHeight > window.innerHeight) {
        this.renderer2[toggle ? 'addClass' : 'removeClass'](this.document.body, className);
      } else {
        this.renderer2.removeClass(this.document.body, className);
      }
    }
  }
}
