/** @format */

import { Component, OnInit } from '@angular/core';
import { ChildrenOutletContexts, Router } from '@angular/router';
import { nice1, none } from "./auth-animations";
import { transition, trigger } from "@angular/animations";

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
  animations: [
    trigger('routeAnimations', [
      // transition('void <=> loginPage', none),
      // transition('void <=> registrationPage', none),
      transition('* <=> *', nice1),
    ])
  ]
})
export class AuthComponent implements OnInit {
  constructor(
    private contexts: ChildrenOutletContexts,
    public router: Router
  ) {}

  ngOnInit(): void {}

  getRouteAnimationData() {
    return this.contexts.getContext('primary')?.route?.snapshot?.data?.['animation'];
  }
}
