/** @format */

import { Component, OnInit } from '@angular/core';
import { transition, trigger } from "@angular/animations";
import { scaleIn } from "../animations";

@Component({
  selector: 'app-right-bar-advertising-3',
  // templateUrl: './right-bar-advertising-3.component.html',
  // styleUrls: ['../advertising-1/right-bar-advertising-1.component.scss']
  template: '',
  animations: [
    trigger('showIn', [
      transition(':enter', scaleIn)
    ])
  ]
})
export class RightBarAdvertising3Component implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
