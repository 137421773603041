<!-- @format -->

<div class="modal box grow flex">
  <div class="grow inner d-flex fd-column position-r bg-color-neutral_white rounded-md p-6">
	<div class="close d-flex ai-center jc-center cursor-p p-1" (click)="closed.emit()">
		<app-svg-icon
			[appIcon]="'cross--yellow'"
			[appWidth]="14"
			[appHeight]="14"
			[appViewBox]="'0 0 14 14'"
		></app-svg-icon>
	</div>
	<div class="content pb-3 mb-1 overflow-h">
		<div class="custom-scrollbar h-full pr-1 mr-2">
			<h3 class="mb-1">Условия сервиса Rugram</h3>
			<p>
				Настоящее пользовательское соглашение (далее – «Соглашение») разработано Обществом
				с ограниченной ответственностью «Руграм» и определяет порядок использования сайта
				www.rugram.me лицами, желающими воспользоваться услугами (работами), предлагаемыми
				ООО «Руграм». В соответствии со статьей 435 и пунктом 2 статьи 437 Гражданского
				кодекса Российской Федерации Соглашение является публичной офертой, адресованной
				неограниченному кругу лиц.
			</p>
			<h6 class="mb-1">1. ТЕРМИНЫ И ОПРЕДЕЛЕНИЯ</h6>
			<p>
				<strong>Исполнитель</strong>
				– Общество с ограниченной ответственностью «Руграм» (ИНН 9709073816, ОГРН
				1217700383086 адрес: РФ, 109147, г. Москва, ул. Большая Андроньевская, д.23,
				помещ. 1, 5 этаж, ком. кабинет 32), оказывающее услуги (выполняющее работы) в
				соответствии с условиями настоящего Соглашения.
			</p>
			<p>
				<strong>Сайт</strong>
				— принадлежащий Исполнителю и администрируемый им сайт в сети Интернет по адресу
				www.rugram.me предназначенный для заказа услуг (работ), оказываемых (выполняемых)
				Исполнителем в соответствии с настоящим Соглашением.
			</p>
			<p>
				<strong>Пользователь</strong>
				– прошедшее процедуру регистрации на Сайте физическое лицо, использующее Сайт в
				целях ознакомления и выбора оказываемых Исполнителем услуг (выполняемых работ) и
				их заказа.
			</p>
			<p>
				<strong>Личный кабинет</strong>
				– персонализированный раздел Сайта ограниченного доступа, предназначенный для
				взаимодействия между Исполнителем и Пользователем, в том числе для заказа услуг
				(работ), получения доступа к информации о ходе выполнения заказов Пользователя,
				статистической и иной относящейся к Пользователю и его заказам информации.
			</p>
			<p>
				<strong>Регистрация</strong>
				— процедура внесения лицом, желающим воспользоваться услугами (работами)
				Исполнителя, персональных данных в специальную электронную форму на Сайте в целях
				создания Личного кабинета и получения регистрационных данных (логина и пароля).
			</p>
			<p>
				<strong>Авторизация</strong>
				— идентификация зарегистрированного Пользователя на Сайте в целях предоставления
				доступа к Личному кабинету, совершаемая посредством ввода в поле авторизации
				регистрационных данных (логина и пароля).
			</p>
			<p>
				<strong>Заказ</strong>
				— задание Пользователя на оказание конкретных услуг (выполнение работ),
				оформленное с использованием функциональных возможностей Сайта в Личном кабинете.
			</p>
			<p>
				<strong>Подписка</strong>
				– предоставляемая Пользователю за обусловленную плату возможность делать Заказы в
				определённом объёме в течение установленного периода времени вне зависимости от
				того, было ли затребовано Пользователем соответствующее исполнение от Исполнителя.
			</p>
			<p>
				<strong>Договор</strong>
				— регулирующее порядок выполнения Заказа соглашение между Исполнителем и
				Пользователем, заключаемое в порядке, установленном настоящим Соглашением.
			</p>
			<p>
				<strong>Персональные данные</strong>
				— любая относящаяся к Пользователю информация, добровольно и осознанно
				предоставляемая Пользователем при Регистрации на Сайте и оформлении Заказа, и
				необходимая для выполнения Исполнителем своих обязательств перед Пользователем.
			</p>
			<h6 class="mb-1">2. ОБЩИЕ ПОЛОЖЕНИЯ</h6>
			<p>
				2.1. Исполнитель обязуется по заданию Пользователя оказывать следующие услуги
				(выполнять работы) в отношении литературного произведения (далее – «Произведение»)
				Пользователя:
			</p>
			<p>
				2.1.1. Допечатная подготовка – корректура, редактура текста, присвоение пакета
				классификаторов, необходимых для указания в выходных данных Произведения (ISBN,
				УДК, ББК), пре-пресс подготовка (подготовка файлов для распознавания печатными
				машинами в типографии).
			</p>
			<p>
				2.1.2. Дизайн обложки – проектирование внешнего вида обложки книги с целью
				формирования её эстетических и функциональных качеств.
			</p>
			<p>
				2.1.3. Вёрстка - компоновка страниц оригинал-макета Произведения текстовыми,
				графическими и иными элементами.
			</p>
			<p>
				2.1.4. Печать – изготовление экземпляров (тиража) Произведения на бумажном
				носителе.
			</p>
			<p>
				2.1.5. Озвучка – озвучение текста Произведения с записью соответствующей
				информации в файл цифрового аудиоформата.
			</p>
			<p>
				2.1.6. Маркетинг – совокупность действий, направленных на продвижение Произведения
				на рынке книжной продукции в целях реализации его экземпляров.
			</p>
			<p>
				2.1.7. Печать по требованию (Print on Demand) - совокупность действий Исполнителя,
				связанных с адаптацией и размещением Произведения в электронном каталоге
				Исполнителя «Print on Demand» (POD-каталог) в целях реализации экземпляров
				Произведения в печатном или электронном виде.
			</p>
			<p>
				2.1.8. Дополнительные услуги (работы), оказываемые (выполняемые) Исполнителем по
				согласованию с Пользователем.
			</p>
			<p>
				2.2. Помимо оказания услуг (выполнения работ) Исполнитель обязуется совершать по
				поручению Пользователя от своего имени, но за счёт Пользователя сделки в отношении
				экземпляров Произведения в порядке и на условиях, предусмотренных Соглашением и
				Договором.
			</p>
			<p>
				2.3. Настоящее Соглашение определяет общие условия отношений Сторон, возникающих в
				связи с использованием Сайта и выполнением Сторонами взаимных обязательств.
				Порядок оказания Исполнителем услуг (выполнения работ) и/или совершения сделок
				регулируется также правилами оказания конкретных услуг (выполнения работ),
				являющимися приложениями к настоящему Соглашению и его неотъемлемой частью (далее
				– «Правила»). Делая Заказ, Пользователь подтверждает, что ознакомлен и принимает
				полностью условия настоящего Соглашения, Правил и политики конфиденциальности,
				применяемой Исполнителем в отношениях с Пользователями (далее – «Политика
				конфиденциальности»).
			</p>
			<p>
				2.4. Порядок и условия выполнения Заказа Пользователя, включая перечень, объём,
				стоимость, сроки и иные условия оказания услуг (выполнения работ) и/или совершения
				сделок по поручению Пользователя, определяются Договором, заключаемым в порядке,
				установленном Соглашением.
			</p>
			<p>
				2.5. Пользователь выражает намерение воспользоваться услугами (работами) и
				заключить Договор путём заполнения формы заказа в Личном кабинете и загрузки на
				Сайт файла, содержащего рукопись Произведения, в требуемом формате. Подтверждение
				Заказа в Личном кабинете признаётся акцептом Пользователем Договора. Стороны
				признают соблюдённой письменную форму сделки, совершаемой в установленном
				настоящим Соглашением порядке с помощью программных средств и функциональных
				возможностей Сайта. При этом требование о наличии подписи считается выполненным в
				любом случае, когда программные средства Сайта позволяют достоверно определить
				лицо, выразившее волю на совершение юридически значимых действий. Применительно к
				Пользователю требование о наличии подписи признаётся выполненным при совершении
				Пользователем действий, влекущих правовые последствия, в Личном кабинете с
				использованием своих регистрационных данных.
			</p>
			<p>
				2.6. Пользователь вправе делать Заказы как на одну (любую) услугу (работу)
				Исполнителя, так и на любое их сочетание и объём, а также воспользоваться услугами
				(работами) на условиях Подписки.
			</p>
			<p>
				2.7. Стороны признают юридическую силу документов, связанных с исполнением
				взаимных обязательств, переданных с использованием Личного кабинета, по
				электронной почте или с использованием иных средств связи, которые позволяют
				достоверно определить, что документ исходит от Стороны-отправителя. Надлежащая
				электронная переписка между Сторонами в рамках исполнения взаимных обязательств
				имеет доказательственную силу и может использоваться в качестве письменного
				доказательства при разрешении возникающих споров. Под надлежащей электронной
				перепиской подразумевается обмен сообщениями с помощью функциональных возможностей
				Сайта, в том числе с использованием Личного кабинета Пользователя.
			</p>
			<h6 class="mb-1">3. ПОРЯДОК ОФОРМЛЕНИЕ ЗАКАЗА</h6>
			<p>
				3.1. Совершение Заказа доступно для зарегистрированного на Сайте Пользователя
				после прохождения им процедуры Авторизации.
			</p>
			<p>
				3.2. В процессе регистрации на Сайте Пользователь предоставляет информацию,
				включая Персональные данные, в соответствии с запросами Исполнителя в форме
				регистрации. После прохождения процедуры регистрации Пользователю предоставляются
				регистрационные данные (логин и пароль), необходимые для последующей Авторизации
				на Сайте и получения доступа к Личному кабинету. Юридически значимые действия,
				совершённые на Сайте с использованием регистрационных данных, признаются
				совершёнными Пользователем лично. Пользователь несёт ответственность за
				сохранность регистрационных данных, а также за любые действия, связанные с их
				использованием. Пользователь обязуется обеспечивать конфиденциальность
				регистрационных данных, не передавать эти данные третьим лицам или получать их от
				третьих лиц, а также обязуется незамедлительно уведомить Исполнителя о любом
				случае несанкционированного доступа к регистрационным данным.
			</p>
			<p>
				3.3. Пользователь соглашается предоставить достоверную и полную информацию по
				вопросам, предлагаемым при Регистрации и создании Личного кабинета, а также
				необходимую для оформления Заказа и выполнения обязательств Исполнителем.
				Исполнитель не несёт ответственности за ненадлежащее исполнение обязательств по
				причине предоставления Покупателем недостоверных и/или недействительных сведений.
			</p>
			<p>
				3.4. Оформление Заказа осуществляется Пользователем путём заполнения специальной
				формы в Личном кабинете в соответствии с функциональными возможностями Сайта.
				Пользователь несёт ответственность за достоверность предоставленной при оформлении
				Заказа информации.
			</p>
			<p>
				3.5. Конечная стоимость услуг (работ) по конкретному Заказу определяется
				Исполнителем исходя из условий Заказа на основании установленных Исполнителем
				расценок на услуги (работы) и принимается Пользователем путём подтверждения Заказа
				в соответствующем поле формы Заказа. Порядок оплаты по Договору определяется
				Правилами.
			</p>
			<p>
				3.6. После получения Заказа Исполнитель предоставляет Пользователю подтверждение
				заключения Договора с указанием номера Заказа.
			</p>
			<p>
				3.7. Аннуляция Заказа производится по инициативе Пользователя либо Исполнителя в
				случаях, предусмотренных настоящим Соглашением, Правилами или законодательством.
				Исполнитель аннулирует Заказ в случае существенного изменения обстоятельств, из
				которых Стороны исходили при оформлении Заказа, сделавшего исполнение Заказа
				невозможным.
			</p>
			<p>
				3.8. Исполнитель оставляет за собой право удалить учётную запись Пользователя в
				случае, если Пользователь не использует регистрационные данные в течение
				длительного промежутка времени. Продолжительность соответствующего периода
				определяется по усмотрению Исполнителя.
			</p>
			<p>
				3.9. Пользователь вправе воспользоваться услугами (работами) на условиях Подписки.
				Предлагаемые Исполнителем правила Подписки доводятся до сведения Пользователей на
				Сайте и могут быть приняты путём выбора соответствующего пункта меню в Личном
				кабинете. Оформление Заказов Пользователем, принявшим правила Подписки, и их
				выполнение Исполнителем производятся в порядке, установленном Соглашением и
				Правилами, с учётом условий Подписки. Пользователь, принявший правила Подписки,
				производит оплату вне зависимости от того, было ли затребовано им соответствующее
				исполнение от Исполнителя в течение установленного периода Подписки.
			</p>
			<h6 class="mb-1">4. ПРАВА И ОБЯЗАННОСТИ СТОРОН</h6>
			<p>
				4.1. Исполнитель обязуется оказывать услуги (выполнять работы), совершать сделки
				по поручению Пользователя, исполнять иные обязательства в соответствии с условиями
				Договора, Правилами и требованиями законодательства.
			</p>
			<p>
				4.2. Исполнитель вправе запрашивать у Пользователя информацию, включая
				Персональные данные, необходимые для исполнения обязательств перед Пользователем.
			</p>
			<p>
				4.3. Исполнитель вправе привлекать третьих лиц по своему усмотрению для оказания
				услуг (выполнения работ), неся ответственность за действия таких лиц перед
				Пользователем, как за свои собственные.
			</p>
			<p>
				4.4. Исполнитель вправе в одностороннем порядке вносить изменения в текст
				настоящего Соглашения и Правил. Изменения вступают в силу и становятся
				обязательными для Сторон с момента размещения новой редакции Соглашения и/или
				Правил на Сайте. Условия оказания услуг (выполнения работ), выполнения поручений
				Пользователя, согласованные Сторонами до момента внесения изменений в Соглашение
				и/или Правила в соответствии с настоящим пунктом, остаются неизменными.
			</p>
			<p>
				4.5. Пользователь обязуется оплачивать услуги (работы) на условиях и в порядке,
				установленных Правилами. В случае если условиями соответствующих Правил
				предусмотрена предварительная оплата услуг (работ), Исполнитель вправе не
				приступать к их оказанию (выполнению) до момента надлежащего выполнения
				Пользователем обязательства по оплате.
			</p>
			<p>
				4.6. Пользователь вправе отказаться от исполнения любого Договора (выполнения
				Заказа), заключённого в соответствии с настоящим Соглашением, при условии оплаты
				Исполнителю фактически понесенных им расходов, уведомив о своем намерении
				Исполнителя не менее чем за 14 (четырнадцать) дней до даты прекращения
				соответствующего Договора.
			</p>
			<h6 class="mb-1">5. ОТВЕТСТВЕННОСТЬ СТОРОН</h6>
			<p>
				5.1. Стороны несут ответственность за ненадлежащее исполнение обязательств в
				порядке и пределах, установленных законодательством.
			</p>
			<p>
				5.2. Исполнитель не несёт ответственности за содержание и/или оформление
				Произведения, включая его текст, иллюстрации и/или обложку (за исключением случая,
				когда подбор обложки для Произведения осуществлялся Исполнителем).
			</p>
			<p>
				5.3. Исполнитель не несёт ответственности за возможный ущерб, причинённый
				Пользователю в результате несанкционированного использования третьими лицами его
				регистрационных данных и получения доступа к Личному кабинету в результате
				нарушения Пользователем условий и обязательств, установленных пунктом 3.2.
				Соглашения.
			</p>
			<p>
				5.4. Ответственность за размещение предупреждения о наличии в Произведении
				нецензурной брани согласно требованиям статьи 4 Федерального закона от
				05.05.2014 г. № 101-ФЗ «О внесении изменений в Федеральный закон
				«О государственном языке Российской Федерации» и отдельные законодательные акты
				Российской Федерации в связи с совершенствованием правового регулирования в сфере
				использования русского языка», а также исполнение иных обязательных требований
				применимого законодательства к содержанию и/или оформлению Произведения несёт
				Пользователь.
			</p>
			<p>
				5.5. Стороны освобождаются от ответственности за ненадлежащее исполнение
				обязательств, если это явилось следствием обстоятельств непреодолимой силы,
				возникших после заключения Договора в результате обстоятельств чрезвычайного
				характера, которые Стороны не могли предвидеть или предотвратить. При наступлении
				указанных обстоятельств каждая Сторона должна без промедления принять все разумные
				меры для извещения другой Стороны об их наступлении. Извещение должно содержать
				данные о характере обстоятельств, а также ссылку на официальные документы,
				удостоверяющие наступление обстоятельств непреодолимой силы и по возможности
				дающие оценку их влияния на возможность исполнения Стороной своих обязательств по
				Договору.
			</p>
			<p>
				5.6. В случае наступления обстоятельств, указанных в пункте 5.5. Соглашения, срок
				выполнения Стороной обязательств продлевается соразмерно времени, в течение
				которого действуют эти обстоятельства и их последствия. Если обстоятельства
				непреодолимой силы и их последствия продолжают действовать более двух месяцев,
				Стороны проводят дополнительные переговоры для выявления приемлемых альтернативных
				способов исполнения обязательств.
			</p>
			<h6 class="mb-1">6. ПРОЧИЕ УСЛОВИЯ</h6>
			<p>
				6.1. В рамках исполнения обязательств Пользователь не передает, а Исполнитель не
				приобретает никаких исключительных прав на Произведение, являющееся предметом
				Договора за исключением случаев, прямо оговорённых в Правилах. Исполнитель
				обладает правом использования Произведения в объёме и пределах, необходимых в
				целях выполнения обязательств перед Пользователем.
			</p>
			<p>6.2. Пользователь заверяет и гарантирует, что:</p>
			<ul class="my-0 mt-3 pl-0">
				<li class="d-flex mb-3 ai-center">
					- имеет соответствующие права в полном объёме на Произведение согласно
					применимому законодательству;
				</li>
				<li class="d-flex mb-3 ai-center">
					- Произведение, а также иллюстративный материал (в том числе иллюстрации,
					используемые в оформлении обложки Произведения, либо готовая обложка,
					предоставленная Пользователем) не содержит в себе авторского материала, права на
					который не принадлежат Пользователю;
				</li>
				<li class="d-flex mb-3 ai-center">
					- использование Произведения и/или иллюстративного материала в соответствии с
					положениями Договора не влечёт за собой нарушения каких-либо прав и законных
					интересов третьих лиц.
				</li>
			</ul>
			<p>
				6.3. В случае получения Исполнителем каких-либо требований, претензий и/или исков
				в отношении Произведения от третьих лиц Пользователь обязуется предоставлять
				Исполнителю все имеющиеся у него документы, подтверждающие права Пользователя на
				такое Произведение, в течение 3 (трёх) рабочих дней с момента получения запроса от
				Исполнителя. Пользователь гарантирует, что любые подобные требования, претензии
				и/или иски со стороны третьих лиц будут разрешены Пользователем самостоятельно от
				своего имени и за свой счёт. В случае возникновения у Исполнителя убытков,
				вызванных неисполнением или ненадлежащим исполнением Пользователем установленных
				настоящим Соглашением обязанностей и гарантий в отношении принадлежности
				исключительных прав, Пользователь обязуется возместить Исполнителю документально
				подтвержденный реальный ущерб, включая, но, не ограничиваясь, ущерб, возникший в
				связи с нарушением принадлежащих третьим лицам прав, включая судебные расходы, и
				ущерб репутации Исполнителя.
			</p>
			<p>
				6.4. Пользователь гарантирует, что содержание Произведения соответствует
				требованиям российского законодательства, нормам международного права и несёт всю
				ответственность перед государственными органами и третьими лицами в случае
				нарушения таких требований. В целях реализации указанной гарантии Пользователь
				обязуется не включать в Произведение любую информацию и материалы следующего
				характера:
			</p>
			<ul class="my-0 mt-3 pl-0">
				<li class="d-flex mb-3 ai-center">
					- пропагандирующие и/или способствующие разжиганию расовой, религиозной,
					этнической ненависти или вражды, пропагандирующие фашизм или идеологию
					превосходства по признакам расы, национальности, пола, возраста, физической или
					психической неполноценности, статуса, религиозных предпочтений, сексуальной
					ориентации или гендерной идентичности;
				</li>
				<li class="d-flex mb-3 ai-center">
					- пропагандирующие преступную деятельность или содержащие советы, инструкции или
					руководства по совершению преступных действий;
				</li>
				<li class="d-flex mb-3 ai-center">- экстремистского характера;</li>
				<li class="d-flex mb-3 ai-center">
					- содержащие описание средств и способов суицида, любое подстрекательство к его
					совершению;
				</li>
				<li class="d-flex mb-3 ai-center">
					- нарушающие права и законные интересы несовершеннолетних лиц, пропагандирующие
					педофилию, сексуальное насилие или сексуальное принуждение;
				</li>
				<li class="d-flex mb-3 ai-center">
					- пропагандирующие нетрадиционные сексуальные отношения, направленные на
					формирование искаженного представления о социальной равноценности традиционных и
					нетрадиционных сексуальных отношений, либо на формирование интереса к таким
					отношениям (в случае если Произведение предназначено для распространения среди
					несовершеннолетних).
				</li>
			</ul>
			<p>
				6.5. В случае выявления Исполнителем в тексте Произведения какой-либо информации
				или материалов, указанных в пункте 6.4. Соглашения Исполнитель вправе в
				одностороннем порядке отказаться от исполнения Договора; в этом случае сумма,
				внесенная Пользователем в счёт оплаты услуг (работ) по Договору, Пользователю не
				возвращается.
			</p>
			<p>
				6.6. Совершая заказ, Пользователь предоставляет Исполнителю согласие на обработку
				Персональных данных. Согласие даётся с целью исполнения обязательств по Договору,
				а также использования персональных данных для информирования Пользователя о
				маркетинговых и рекламных мероприятиях Исполнителя. Порядок обработки Исполнителем
				Персональных данных установлен Политикой конфиденциальности.
			</p>
			<p>
				6.7. Споры и разногласия между Сторонами, которые могут возникнуть в процессе
				исполнения Сторонами обязательств, будут разрешаться путём переговоров. При
				невозможности урегулирования споров и разногласий путём переговоров, такие споры и
				разногласия подлежат разрешению в судебном порядке в соответствии с
				законодательством Российской Федерации.
			</p>
			<h6 class="mb-1">7. КОНФИДЕНЦИАЛЬНОСТЬ</h6>
			<p>
				7.1. Любая информация, полученная Сторонами друг от друга, будет рассматриваться
				как конфиденциальная. Стороны обязуются не разглашать такую информацию третьим
				лицам без прямого письменного разрешения другой Стороны в течение срока исполнения
				обязательств и после их прекращения, кроме случаев, когда это требуется в
				соответствии с законодательством.
			</p>
			<p>
				7.2. Информация, являющаяся конфиденциальной, может быть предоставлена третьим
				лицам только с разрешения обладателя такой информации и/или по мотивированному
				требованию органа государственной власти или органа местного самоуправления в
				порядке, установленном законодательством. Сторона вправе раскрыть конфиденциальную
				информацию третьим лицам без согласия её правообладателя только в целях
				надлежащего исполнения своих обязательств.
			</p>
		</div>
	</div>
	<div class="d-flex ai-center jc-between">
		<button
			class="btn btn-style-2 btn-inline rounded-md sm px-3"
			(click)="onSubmitted(false)"
		>
			Не принимаю условия
		</button>
		<button
			class="btn btn-style-1 btn-inline rounded-md sm px-3"
			data-cy="agreement"
			(click)="onSubmitted(true)"
		>
			Соглашаюсь с условиями
		</button>
	</div>
</div>
</div>
