/** @format */

import { Component } from '@angular/core';

@Component({
  selector: 'app-office-settings-templates',
  templateUrl: './templates.component.html'
})
export class OfficeSettingsTemplatesComponent {
  templateList: { templateName: string; fileName: string }[] = [
    {
      templateName: 'Шаблоны для юридических лиц',
      fileName: 'yurlico.zip'
    },
    {
      templateName: 'Шаблоны для физических лиц',
      fileName: 'fizlico.zip'
    },
    {
      templateName: 'Шаблоны для ИП',
      fileName: 'ip.zip'
    },
    {
      templateName: 'Шаблоны для самозанятых',
      fileName: 'samozanyatyj.zip'
    }
  ];

  constructor() {}
}
