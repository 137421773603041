<!-- @format -->

<section class="box d-flex fd-column jc-start ai-stretch">
	<div class="p-5 bg-color-neutral_white rounded-sm bordered" *ngIf="templateList.length">
		<h1 class="h3 text-bold w-full mb-5">Шаблоны документов</h1>
		<ul class="h5 m-0 pl-0">
			<a
				*ngFor="let template of templateList"
				class="group d-block mb-3"
				[href]="'/assets/download/' + template.fileName"
				download
			>
				<li
					class="d-flex ai-center jc-between bg-color-neutral_light-gray-2 rounded-sm p-2 pl-3"
				>
					<p class="label mb-0 mr-2 text-color-neutral_dark">
						{{ template.templateName }}
					</p>
					<app-svg-icon
						class="cursor-p group-hover:bg-gray-200 rounded-full p-1"
						appIcon="download-gray"
						appWidth="20"
						appHeight="20"
						appViewBox="0 0 16 16"
					></app-svg-icon>
				</li>
			</a>
		</ul>
	</div>
</section>
