/** @format */

import { NgModule } from '@angular/core';
import { LandingComponent } from './landing.component';
import { LandingRoutingModule } from './landing-routing.module';
import { SharedModule } from '../shared';

@NgModule({
  imports: [SharedModule, LandingRoutingModule],
  declarations: [LandingComponent],
  providers: []
})
export class LandingModule {}
