/** @format */

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ServiceReceipt, ServiceReceiptRow, ServicesMap, getServiceMap } from '../../../../core';

@Component({
  selector: 'app-services-receipt',
  templateUrl: './services-receipt.component.html',
  styleUrls: ['./services-receipt.component.scss']
})
export class ServicesReceiptComponent implements OnInit, OnDestroy {
  @Input()
  set appServicesReceipt(servicesReceipt: ServiceReceipt) {
    this.servicesMap = getServiceMap(servicesReceipt.service_key);
    this.servicesReceipt = servicesReceipt;

    if (this.servicesReceipt.receipt_row) {
      this.servicesReceiptTotal = this.servicesReceipt.receipt_row
        .map((serviceReceiptRow: ServiceReceiptRow) => serviceReceiptRow.sum)
        .filter((sum: number | undefined) => sum)
        .reduce((sum: number, a: any) => sum + Number(a), 0);
    }
  }

  servicesMap: ServicesMap | undefined;

  servicesReceipt: ServiceReceipt | undefined;
  servicesReceiptTotal = 0;

  constructor() {}

  ngOnInit(): void {}

  ngOnDestroy(): void {}
}
