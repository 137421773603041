/** @format */

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-left-bar-advertising-litcovers',
  templateUrl: './left-bar-advertising-litcovers.component.html',
  styleUrls: ['./left-bar-advertising-litcovers.component.scss']
})
export class LeftBarAdvertisingLitcoversComponent implements OnInit {
  @Input()

  href = 'https://litcovers.com/ru/users/register?code=ZDdXgyIYl5';
  target = '_blank';

  constructor() {}

  ngOnInit(): void {}
}
