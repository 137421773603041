/** @format */

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { APP_CONFIG, UA_INFO, Configuration} from './app/core';
import { UAParser } from 'ua-parser-js';

fetch('./assets/configurations/config.json')
  .then((response: Response) => response.json())
  .then((response: any) => {
    const configuration: Configuration = JSON.parse(atob(response.configuration));

    if (configuration.production) {
      enableProdMode();
    }

    const appConfigProvider: any = {
      provide: APP_CONFIG,
      useValue: configuration
    };

    const ua = new UAParser(window.navigator.userAgent).getResult();
    const uaProvider: any = {
      provide: UA_INFO,
      useValue: {
        ua,
        uaVersion: ua.browser.name + '/' + (ua.browser.version ? ua.browser.version.replace(/(?:\.0)+/, '') : '?')
          + ' (' + ua.engine.name + '/' + (ua.engine.version ? ua.engine.version.replace(/(?:\.0)+/, '') : '?') + ')'
          + (ua.device.type ? ' / ' + ua.device.type : '')
          + (ua.os.version ? ' / ' + ua.os.name + ' ' + ua.os.version : '')
      }
    };

    platformBrowserDynamic([appConfigProvider, uaProvider])
      .bootstrapModule(AppModule)
      .catch(err => console.error(err));
  });
