/** @format */

import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import {HelperService, HelpService, UA_INFO, UAInfo, User, UserService} from '../../../core';
import { gsap } from "gsap";

interface HelpForm {
  email: FormControl<string | null>;
  message: FormControl<string | null>;
}

@Component({
  selector: 'app-office-help-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class OfficeHelpFormComponent implements OnInit, OnDestroy, AfterViewInit {
  // helpForm: FormGroup;

  userSubscription$: Subscription | undefined;

  // isSubmitted = false;
  // isCompleted = false;

  constructor(
    @Inject(UA_INFO) public ua: UAInfo,
    // private formBuilder: FormBuilder,
    // private helperService: HelperService,
    private el:ElementRef,
    private userService: UserService,
    private helpService: HelpService
  ) {
    // this.helpForm = this.formBuilder.group<HelpForm>({
    //   email: this.formBuilder.control('', [Validators.required, Validators.email]),
    //   message: this.formBuilder.control('', [Validators.required])
    // });
  }

  ngOnInit(): void {
    this.userSubscription$ = this.userService.currentUser.subscribe({
      next: (user: User) => {
        // this.helpForm.patchValue({
        //   email: user.email
        // });
      },
      error: (error: any) => console.error(error)
    });
  }

  ngAfterViewInit() {
    const children = this.el.nativeElement.querySelectorAll('*');
    componentInitAnimation(children, true);
  }

  ngOnDestroy(): void {
    this.userSubscription$?.unsubscribe();
  }

  // onSubmitForm(): void {
  //   if (this.helperService.getFormValidation(this.helpForm)) {
  //     this.isSubmitted = true;
  //
  //     this.userService.postHelp(this.helpForm.value).subscribe({
  //       next: () => (this.isCompleted = true),
  //       error: () => (this.isSubmitted = false)
  //     });
  //   }
  // }

  onShowModalHelp(): void {
    this.helpService.setShowHelpModal({ show: true, title: 'Есть вопрос?' });
  }
}

const componentInitAnimation = (targets: string | object | null, immediateRender?: boolean) => gsap
  .fromTo(targets, {
    opacity: 0,
    x: () => {
      return gsap.utils.random(-25, 25);
    },
    y: () => {
      return gsap.utils.random(-25, 25);
    },
  }, {
    duration: .3,
    opacity: 1,
    x: 0,
    y: 0,
    stagger: {
      each: .015,
    },
    immediateRender,
  });
