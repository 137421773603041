<!-- @format -->

<section class="box">
	<app-left-bar class="left-bar"></app-left-bar>
	<app-header-bar class="header-bar"></app-header-bar>
	<div class="view w-full">
		<app-snackbar></app-snackbar>
		<main class="outlet mx-auto" [@routeAnimations]="getRouteAnimationData()">
			<router-outlet></router-outlet>
		</main>
	</div>
</section>
