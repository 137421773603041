/** @format */

import { Component, Input, OnInit } from '@angular/core';
import { BalanceTransaction, Transaction } from '../../../../core';

@Component({
  selector: 'app-list-balance, [appListBalance]',
  templateUrl: './list-balance.component.html',
  styleUrls: ['./list-balance.component.scss']
})
export class ListBalanceComponent implements OnInit {
  @Input()
  set appBalance(balance: Transaction) {
    this.balance = balance;
  }

  balance!: Transaction;

  constructor() {}

  ngOnInit(): void {}
}
