/** @format */

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthComponent } from './auth.component';
import { AuthLoginComponent } from './login/auth-login.component';
import { AuthConfirmComponent } from './confirm/auth-confirm.component';
import { AuthResetComponent } from './reset/auth-reset.component';
import { AuthRegistrationComponent } from './registration/auth-registration.component';
import { AuthSmsComponent } from './sms/auth-sms.component';
import { AuthImportComponent } from './import/auth-import.component';

const routes: Routes = [
  {
    path: '',
    component: AuthComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'login'
      },
      {
        path: 'login',
        component: AuthLoginComponent,
        data: { animation: 'loginPage' }
      },
      {
        path: 'registration',
        component: AuthRegistrationComponent,
        data: { animation: 'registrationPage' }
      },
      {
        path: 'sms',
        component: AuthSmsComponent,
        data: { animation: 'smsPage' }
      },
      {
        path: 'confirm',
        component: AuthConfirmComponent,
        data: { animation: 'confirmPage' }
      },
      {
        path: 'reset',
        component: AuthResetComponent,
        data: { animation: 'resetPage' }
      },
      {
        path: 'import',
        component: AuthImportComponent,
        data: { animation: 'importPage' }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule {}
