/** @format */

import { Component, Input, OnInit } from '@angular/core';
import { Book, User, UserService } from '../../../../core';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-contract-sign',
  templateUrl: './contract-sign.component.html',
  styleUrls: ['./contract-sign.component.scss']
})
export class ContractSignComponent implements OnInit {
  @Input()
  set appBook(book: Book) {
    this.book = book;
  }

  user$: Observable<User> | undefined;

  book!: Book;

  constructor(private userService: UserService) {}

  ngOnInit(): void {
    this.user$ = this.userService.currentUser;
  }
}
