/** @format */

import { Injectable } from '@angular/core';
import { ApiService, Book } from 'src/app/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AudiobookOrder, KeysAccessMessageMap } from '..';

@Injectable({
  providedIn: 'root'
})
export class AudiobookService {
  constructor(private apiService: ApiService) {}

  getBooks(): Observable<Book[]> {
    return this.apiService.get('/v1/sp/audio_distribution/books').pipe(
      map((data: any) => data.data),
      map(data => {
        interface BooksData {
          book: Book;
          flag: KeysAccessMessageMap;
          availability: boolean;
        }

        return data.map((booksData: BooksData) => {
          return {
            ...booksData,
            ...booksData.book
          };
        });
      })
    );
  }

  postInfo(body: any): Observable<AudiobookOrder> {
    return this.apiService
      .post('/v1/sp/audio_distribution/info', body)
      .pipe(map((data: any) => data.data));
  }

  postUpload(orderId: number, body: any): Observable<AudiobookOrder> {
    return this.apiService
      .post(`/v1/sp/audio_distribution/${orderId}/file`, body)
      .pipe(map((data: any) => data.data));
  }

  postComplete(orderId: number, body: any): Observable<AudiobookOrder> {
    return this.apiService
      .post(`/v1/sp/audio_distribution/${orderId}/complete`, body)
      .pipe(map((data: any) => data.data));
  }

  postDeleteFile(orderId: number): Observable<{ message: string }> {
    return this.apiService
      .post(`/v1/sp/audio_distribution/${orderId}/deleteFile`, {})
      .pipe(map((data: any) => data.data));
  }
}
