/** @format */

import { Component, HostListener, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Breadcrumb, HeaderService } from '../../../core';
import { Event } from "@angular/router";

@Component({
  selector: 'app-header-bar',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  breadcrumbs$: Observable<Breadcrumb[]> | undefined;

  // TODO: add service that will set this value
  hasNotifications = false;

  viewport!: string;
  zoom!: number;

  constructor(private headerService: HeaderService) {}

  ngOnInit(): void {
    this.breadcrumbs$ = this.headerService.currentBreadcrumbs;
    this.updateViewportInfo();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.updateViewportInfo();
  }

  updateViewportInfo(): void {
    this.viewport = getComputedStyle(document.documentElement).getPropertyValue('--viewport') ?? '?';
    this.zoom = parseFloat(getComputedStyle(document.documentElement).getPropertyValue('--zoom')) ?? 1.0;
  }
}
