/** @format */

import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { HelperService, SnackbarService, UserService } from '../../core';

interface ResetForm {
  email: FormControl<string | null>;
}

@Component({
  selector: 'app-auth-reset',
  templateUrl: './auth-reset.component.html',
  styleUrls: ['./auth-reset.component.scss']
})
export class AuthResetComponent {
  resetForm: FormGroup;

  isSubmitting = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private formBuilder: FormBuilder,
    private snackbarService: SnackbarService,
    private helperService: HelperService
  ) {
    this.resetForm = this.formBuilder.group<ResetForm>({
      email: this.formBuilder.control('', [Validators.required, Validators.email])
    });
  }

  onSubmitForm(): void {
    if (this.helperService.getFormValidation(this.resetForm)) {
      this.isSubmitting = true;

      this.userService
        .postForgot(this.resetForm.value)
        .pipe(map((response: any) => response.message))
        .subscribe({
          next: (message: string) => {
            this.snackbarService.success(message, 5000);

            this.resetForm.reset();
            this.isSubmitting = false;
          },
          error: () => (this.isSubmitting = false)
        });
    }
  }
}
