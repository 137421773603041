/** @format */

import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { CookieService } from './cookie.service';

@Injectable()
export class WelcomeGuard implements CanActivate {
  constructor(private router: Router, private cookieService: CookieService) {}

  canActivate(): Observable<boolean> {
    return of(this.cookieService.getItem('rugramWelcomeWizardUser')).pipe(
      switchMap((cookieWelcomeWizardUser: any) => {
        if (!cookieWelcomeWizardUser) {
          this.router.navigate(['/auth/registration']).then(() => console.log('Route changed'));
        }

        return of(!!cookieWelcomeWizardUser);
      })
    );
  }
}
