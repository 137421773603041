import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const magicWordsAnimation = (targets: string | object | null, immediateRender?: boolean) => gsap
  .fromTo(targets, {
    opacity: 0,
    x: () => {
      return gsap.utils.random(-100, 100);
    },
    y: () => {
      return gsap.utils.random(-100, 100);
    },
    rotation: () => {
      return gsap.utils.random(-25, 25);
    },
  }, {
    duration: .5,
    opacity: 1,
    x: 0,
    y: 0,
    rotation: 0,
    stagger: .03,
    immediateRender,
  });

const waterPourAnimation = (targets: string | object | null, immediateRender?: boolean) => gsap
  .fromTo(targets, {
    opacity: 0,
    x: 50,
    y: 50,
    rotation: () => {
      return gsap.utils.random(0, 30);
    },
  }, {
    duration: .5,
    opacity: 1,
    x: 0,
    y: 0,
    rotation: 0,
    ease: "ease.out(1.7)",
    stagger: .03,
    immediateRender,
  });

const elasticAnimation = (targets: string | object | null, immediateRender?: boolean) => gsap
  .fromTo(targets, {
    opacity: 0,
    x: () => {
      return gsap.utils.random(-100, 100);
    },
    y: () => {
      return gsap.utils.random(-100, 100);
    },
    rotation: () => {
      return gsap.utils.random(-15, 15);
    },
  }, {
    duration: 1.2,
    opacity: 1,
    x: 0,
    y: 0,
    rotation: 0,
    // ease: "bounce.out",
    ease: "elastic.out",
    stagger: .1,
    immediateRender,
    delay: () => {
      return gsap.utils.random(0.01, .5)
    },
  });

const scrollElasticAnimation = (collection: any, immediateRender?: boolean) => {
  // const viewport = getViewport();
  ScrollTrigger.batch(collection, {
    // start: 'top ' + viewport.clientHeight * viewport.zoom,
    // end: 'bottom ' + viewport.clientHeight * viewport.zoom,
    start: () => { const viewport = getViewport(); return 'top ' + viewport.clientHeight * viewport.zoom; },
    end: () => { const viewport = getViewport(); return 'bottom ' + viewport.clientHeight * viewport.zoom; },
    // preventOverlaps: true,
    onEnter: batch => gsap.fromTo(batch, {
      opacity: 0,
      x: () => {
        return gsap.utils.random(-50, 50);
      },
      y: () => {
        return gsap.utils.random(-50, 50);
      },
      rotation: () => {
        return gsap.utils.random(-10, 10);
      },
    }, {
      opacity: 1,
      x: 0,
      y: 0,
      rotation: 0,
      ease: "elastic.out",
      duration: 1,
      stagger: {
        each: .05,
      },
      immediateRender,
    }),
  }).forEach(t => {t.vars.id = 'ngGxTextAnimate'});
};

const scrollMagicAnimation = (collection: any, immediateRender?: boolean) => {
  // const viewport = getViewport();
  ScrollTrigger.batch(collection, {
    // start: 'top ' + viewport.clientHeight * viewport.zoom,
    // end: 'bottom ' + viewport.clientHeight * viewport.zoom,
    start: () => { const viewport = getViewport(); return 'top ' + viewport.clientHeight * viewport.zoom; },
    end: () => { const viewport = getViewport(); return 'bottom ' + viewport.clientHeight * viewport.zoom; },
    preventOverlaps: true,
    onEnter: batch => gsap.timeline().from(batch, {
      autoAlpha: 0,
      x: () => {
        return gsap.utils.random(-100, 100);
      },
      y: () => {
        return gsap.utils.random(-100, 100);
      },
      rotation: () => {
        return gsap.utils.random(-25, 25);
      },
    })
      .to(batch, {
        autoAlpha: 1,
      x: 0,
      y: 0,
      rotation: 0,
      stagger: {
        each: .05,
        from: 'random',
      },
      // ease: "power1.inOut",
      immediateRender,
    })
  }).forEach(t => t.vars.id = 'ngGxTextAnimate');
};

const scrollDocAnimation = (collection: any, immediateRender?: boolean) => {
  // const viewport = getViewport();
  ScrollTrigger.batch(collection, {
    // start: 'top ' + viewport.clientHeight * viewport.zoom,
    // end: 'bottom ' + viewport.clientHeight * viewport.zoom,
    start: () => { const viewport = getViewport(); return 'top ' + viewport.clientHeight * viewport.zoom; },
    end: () => { const viewport = getViewport(); return 'bottom ' + viewport.clientHeight * viewport.zoom; },
    interval: 0.1,
    batchMax: 100,
    preventOverlaps: true,
    onEnter: batch => gsap.fromTo(batch, {
        autoAlpha: 0,
        y: () => {
          return gsap.utils.random(-50, 0);
        },
      }, {
        autoAlpha: 1,
        y: 0,
        ease: "elastic.out",
        stagger: {
          each: 0.05
        },
        immediateRender,
      })
  }).forEach(t => t.vars.id = 'ngGxTextAnimate');
};

const scrollSlideInAnimation = (collection: any, immediateRender?: boolean) => {
  // const viewport = getViewport();
  ScrollTrigger.batch(collection, {
    // start: 'top ' + viewport.clientHeight * viewport.zoom,
    // end: 'bottom ' + viewport.clientHeight * viewport.zoom,
    start: () => { const viewport = getViewport(); return 'top ' + viewport.clientHeight * viewport.zoom; },
    end: () => { const viewport = getViewport(); return 'bottom ' + viewport.clientHeight * viewport.zoom; },
    preventOverlaps: true,
    onEnter: batch => gsap.fromTo(batch, {
      autoAlpha: 0,
      x: () => {
        return gsap.utils.random(0, 25);
      },
    }, {
      autoAlpha: 1,
      x: 0,
      // duration: .3,
      stagger: {
        each: .05,
        from: 'random',
      },
      immediateRender,
    })
  }).forEach(t => t.vars.id = 'ngGxTextAnimate');
};

export const animationsPresets: Record<string, (targets: string | object | null, immediateRender?: boolean) => void> = {
  magicWordsAnimation,
  waterPourAnimation,
  elasticAnimation,
  scrollElasticAnimation,
  scrollMagicAnimation,
  scrollSlideInAnimation,
  scrollDocAnimation,
};

const getViewport = () => {
  const clientWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
  const clientHeight = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
  var screenWidth = window.screen.width;
  var screenHeight = window.screen.height;
  const dpr = parseFloat(window.devicePixelRatio.toFixed(2));
  const zoom = 1 / parseFloat(getComputedStyle(document.documentElement).getPropertyValue('--zoom')) ?? 1.0;
  return {clientWidth, clientHeight, screenWidth, screenHeight, dpr, zoom};
}
