/** @format */

import { animate, animateChild, group, query, sequence, state, style, transition, trigger } from '@angular/animations';

export const slideInAnimation = [
  transition('* <=> servicesPage', [
    style({ position: 'relative' }),
    query(':enter, :leave', [
      style({
        position: 'absolute',
        // top: 0,
        left: 0,
        width: '100%'
      })
    ], { optional: true }),
    query(':enter', [
      style({ left: '100%' })
    ], { optional: true }),
    query(':leave', animateChild(), { optional: true }),
    group([
      query(':leave', [
        animate('200ms ease-out', style({ left: '100%', opacity: 0 }))
      ], { optional: true }),
      query(':enter', [
        animate('300ms ease-out', style({ left: '0%' }))
      ], { optional: true }),
      query('@*', animateChild(), { optional: true })
    ]),
  ]),
  transition('* <=> *', [
    style({ position: 'relative' }),
    query(':enter, :leave', [
      style({
        position: 'absolute',
        // top: 0,
        left: 0,
        width: '100%'
      })
    ], { optional: true }),
    query(':enter', [
      style({ left: '-100%' })
    ], { optional: true }),
    query(':leave', animateChild(), { optional: true }),
    group([
      query(':leave', [
        animate('300ms ease-out', style({ opacity: 0 }))
      ], { optional: true }),
      query(':enter', [
        animate('300ms ease-out', style({ left: '0%' }))
      ], { optional: true }),
      query('@*', animateChild(), { optional: true })
    ]),
  ])
];

export const fadeAnimation = [
  transition('* <=> *', [
    style({ position: 'relative' }),
    query(':enter, :leave', [
      style({
        position: 'absolute',
        left: 0,
        width: '100%',
        opacity: 0,
        // transform: 'scale(0.95) translateY(-5%)',
        transform: 'scale(0.95)',
      }),
    ]),
    group([
      query(':enter', [
        // animate('200ms ease-out', style({ opacity: 1, transform: 'scale(1) translateY(0)' }))
        animate('200ms ease-out', style({ opacity: 1, transform: 'scale(1)' }))
      ], { optional: true }),
      query('@*', animateChild(), { optional: true }),
    ])
  ]),
];
