/** @format */

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-modal-payment-status',
  templateUrl: 'modal-payment-status.component.html',
  styleUrls: ['modal-payment-status.component.scss']
})
export class ModalPaymentStatusComponent implements OnInit, OnDestroy {
  @Input()
  set appMessage(message: string | undefined) {
    this.message = message;
  }

  @Output() closed = new EventEmitter<any>();

  message: string | undefined;

  constructor() {}

  ngOnInit(): void {}

  ngOnDestroy(): void {}
}
