<!-- @format -->

<section class="box d-flex fd-column ai-center jc-start">
  <app-snackbar></app-snackbar>
  <img class="d-block my-7" src="assets/logo.png" alt="RUGRAM" />
  <div class="content bg-color-neutral_white rounded-md bordered m-0 p-7">
    <h1 class="d-flex jc-center h2 text-bold mb-3">
      <span *ngIf="!grantedSubject">Добро пожаловать!</span>
      <span *ngIf="grantedSubject">Ваше персональное меню</span>
    </h1>
    <ul class="list-btn d-flex fd-column ai-center p-0" @.disabled>
      <li *ngIf="!grantedSubject" class="d-flex my-1">
        <a
          class="btn btn-style-2 btn-outline rounded-md lg d-flex ai-center jc-center p-1"
          [href]="landingOrigin" target="_blank"
        >
          Об издательстве RUGRAM
        </a>
      </li>
      <li *ngIf="!grantedSubject" class="d-flex my-1">
        <a
          class="btn btn-style-2 btn-outline rounded-md lg d-flex ai-center jc-center p-1 !bg-sky-50 hover:!bg-sky-100"
          href="https://dzen.ru/k_slovu_rugram" target="_blank"
        >
          Наш Дзен
        </a>
      </li>
      <li *ngIf="'welcomeWizard' === grantedSubject" class="d-flex my-1">
        <a
            class="btn btn-style-1 btn-inline rounded-md lg d-flex ai-center jc-center p-1"
            [routerLink]="'/welcome'"
        >
          Мои рукописи
        </a>
      </li>
      <li *ngIf="'welcomeWizard' === grantedSubject" class="d-flex my-1">
        <a
            class="btn btn-style-2 btn-inline rounded-md lg d-flex ai-center jc-center p-1"
            [routerLink]="'/about-office'"
        >
          Мой кабинет
        </a>
      </li>
      <li *ngIf="'office' === grantedSubject" class="d-flex my-1">
        <a
            class="btn btn-style-1 btn-inline rounded-md lg d-flex ai-center jc-center p-1"
            [routerLink]="'/office'"
        >
          Мой кабинет
        </a>
      </li>
      <li *ngIf="!grantedSubject" class="d-flex my-1">
        <a
            class="btn btn-style-1 btn-inline rounded-md lg d-flex ai-center jc-center p-1"
            [routerLink]="'/welcome'"
        >
          Хочу пользоваться платформой
        </a>
      </li>
      <li *ngIf="!grantedSubject" class="d-flex my-1">
        <a
          class="btn btn-style-1 btn-inline rounded-md lg d-flex ai-center jc-center p-1"
          [routerLink]="'/office'"
        >
          У меня уже есть учетная запись
        </a>
      </li>
      <li *ngIf="'welcomeWizard' === grantedSubject" class="d-flex my-1">
        <a
            class="btn btn-style-info btn-inline rounded-md lg d-flex ai-center jc-center p-1"
            (click)="onShowModalHelp()"
        >
          Связаться с менеджером
        </a>
      </li>
      <li *ngIf="grantedSubject" class="d-flex my-1">
        <a
            class="btn btn-style-5 btn-inline rounded-md lg d-flex ai-center jc-center p-1"
            [routerLink]="null"
            (click)="onLogout()"
        >
          Завершение сеанса
        </a>
      </li>
    </ul>
    <div class="absolute bottom-0 left-0 right-0">
      <div class="flex flex-row justify-between items-center gap-x-1 p-1">
        <div class="ua-version overflow-clip m-[2px]"
          title="Сведения об используемом интернет-браузере">
          <div class="whitespace-nowrap text-ellipsis">{{ ua.uaVersion }}</div>
        </div>
        <div class="app-version ml-auto text-nowrap overflow-ellipsis">
          <button class="px-1 rounded-sm border border-none hover:border-solid"
            (click)="appReload()"
            title="Версия приложения &quot;ЛК автора RUGRAM&quot;. Нажмите для обновления"
          >
            <span>&#8635;</span> вер. {{ appVersion }}
          </button>
        </div>
      </div>
    </div>
  </div>
</section>
