/** @format */

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { startWith } from 'rxjs/operators';

@Component({
  selector: 'app-list-pagination',
  templateUrl: 'list-pagination.component.html',
  styleUrls: ['list-pagination.component.scss']
})
export class ListPaginationComponent implements OnInit, OnDestroy {
  @Input()
  set appLimit(limit: number) {
    this.limit = limit;

    this.calculate.next();
  }

  @Input()
  set appPage(page: number) {
    this.page = page;

    this.calculate.next();
  }

  @Input()
  set appTotal(total: number) {
    this.total = total;
  }

  @Output() selectLimit = new EventEmitter<number>();
  @Output() selectPage = new EventEmitter<number | string>();

  calculate$: Subscription | undefined;
  calculate = new Subject<void>();

  limitList = [5, 10, 25, 50];
  limit = 10;

  pageList: (number | string)[] = [];
  page = 1;

  total = 0;

  constructor() {}

  ngOnInit(): void {
    /**
     * Typical pagination implementation
     * https://gist.github.com/kottenator/9d936eb3e4e3c3e02598#gistcomment-2102917
     */

    this.calculate$ = this.calculate.pipe(startWith(1)).subscribe(() => {
      const total = Math.ceil(this.total / this.limit);
      const page = this.page;

      const delta = 2;
      const range = [];
      const rangeWithDots = [];

      let l;

      range.push(1);

      for (let i = page - delta; i <= page + delta; i++) {
        if (i < total && i > 1) {
          range.push(i);
        }
      }

      range.push(total);

      for (const i of range) {
        if (l) {
          if (i - l === 2) {
            rangeWithDots.push(l + 1);
          } else {
            if (i - l !== 1) {
              rangeWithDots.push('...');
            }
          }
        }

        rangeWithDots.push(i);

        l = i;
      }

      this.pageList = rangeWithDots;
    });
  }

  ngOnDestroy(): void {
    [this.calculate$].forEach($ => $?.unsubscribe());
  }
}
