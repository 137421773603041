/** @format */

import {Component, OnDestroy, OnInit} from '@angular/core';
import { Subscription } from 'rxjs';
import {
  HelpService,
  ModalHelpModel,
} from '../../../../core';

@Component({
  selector: 'app-modal-help',
  templateUrl: 'modal-help.component.html'
})
export class ModalHelpComponent implements OnInit, OnDestroy {
  showHelpModal$!: Subscription;
  showHelpModal = false;
  titleHelpModal!: string;

  constructor(
    private helpService: HelpService,
  ) {}

  ngOnInit(): void {
    this.showHelpModal$ = this.helpService.showModalHelp.subscribe(
      (stateModalHelp: ModalHelpModel) => {
        this.showHelpModal = stateModalHelp.show;

        this.titleHelpModal = stateModalHelp?.title || 'Остались вопросы?';
      }
    );
  }

  ngOnDestroy(): void {
    [
      this.showHelpModal$
    ].forEach($ => $?.unsubscribe());
  }

  onClose(): void {
    this.helpService.setShowHelpModal({ show: false });
  }
}
