<!-- @format -->

<div class="box">
	<ul
		class="map d-flex ai-center jc-between position-r m-0 p-0"
		[style.--state-width]="(100 / (map.length - 1)) * (mapProgress + 1) + '%'"
	>
		<li
			class="state d-flex ai-center jc-center position-r"
			*ngFor="let state of map; let i = index"
			[ngClass]="state"
		>
			<app-svg-icon
				class="d-block"
				*ngIf="state === 'done'"
				[appIcon]="'service-map-state'"
				appWidth="22"
				appHeight="22"
				appViewBox="0 0 22 22"
			></app-svg-icon>
		</li>
	</ul>
</div>
