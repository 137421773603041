<!-- @format -->

<ng-container *ngIf="!!upload" [ngSwitch]="uploadExtension">
	<!-- prettier-ignore -->
	<div *ngSwitchCase="'image'" class="box p-2">
		<div class="image rounded-md d-flex jc-start ai-center">
			<div class="d-flex position-r">
				<img class="image-cover rounded-sm" [src]="upload.fileUrl" [alt]="upload.fileName">
				<ng-container *ngTemplateOutlet="close"></ng-container>
			</div>
			<span class="image-name block h6 text-color-log-print_dark-gray ml-2">{{upload.fileName}}</span>
		</div>
	</div>
	<!-- prettier-ignore -->
	<div *ngSwitchCase="'file'" class="box p-2 ">
		<div class="file rounded-md d-flex jc-between ai-center">
			<div class="d-flex ai-center jc-start">
				<app-svg-icon
					class="fill-color-log-print_success"
					appIcon="document"
					appWidth="26"
					appHeight="32"
					appViewBox="0 0 26 32"
				></app-svg-icon>
				<p class="file-name mb-0 ml-2 h5 text-color-log-print_dark-gray">
					{{ upload.fileName }}
				</p>
			</div>
			<ng-container *ngTemplateOutlet="close"></ng-container>
		</div>
	</div>
	<div *ngSwitchDefault class="box p-2">
		<div class="file rounded-md d-flex jc-between ai-center">
			<div class="d-flex ai-center jc-start">
				<app-svg-icon
					class="fill-color-log-print_success"
					appIcon="document"
					appWidth="26"
					appHeight="32"
					appViewBox="0 0 26 32"
				></app-svg-icon>
				<p class="file-name overflow-h mb-0 ml-2 h5 text-color-log-print_dark-gray">
					Undefined file type
				</p>
			</div>
			<ng-container *ngTemplateOutlet="close"></ng-container>
		</div>
	</div>
	<ng-template #close>
		<app-svg-icon
			(click)="remove.emit()"
			(touchstart)="hover.value = !hover.value"
			(mouseenter)="hover.value = true"
			(mouseleave)="hover.value = false"
			appSquare="24"
			appViewBox="0 0 32 32"
			[appIcon]="hover.value ? 'plus-round-red-hover' : 'plus-round-red'"
			class="remove d-block cursor-p fill-color-log-print_main-red"
			*ngIf="{value: false} as hover"
		></app-svg-icon>
	</ng-template>
</ng-container>
