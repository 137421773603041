/** @format */

import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { HelperService, PlatformService, SnackbarService, User, UserService } from '../../../core';

interface EditForm {
  name: FormControl<string | null>;
  surname: FormControl<string | null>;
  email: FormControl<string | null>;
  phone: FormControl<string | null>;
}

@Component({
  selector: 'app-office-settings-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class OfficeSettingsEditComponent implements OnInit, AfterViewInit, OnDestroy {
  userSubscription$: Subscription | undefined;

  editForm: FormGroup;

  isSubmitted = false;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private userService: UserService,
    private helperService: HelperService,
    private snackbarService: SnackbarService,
    private elementRef: ElementRef,
    private platformService: PlatformService,
  ) {
    this.editForm = this.formBuilder.group<EditForm>({
      name: this.formBuilder.control(null, [Validators.required]),
      surname: this.formBuilder.control(null, [Validators.required]),
      email: this.formBuilder.control(null, [Validators.required, Validators.email]),
      phone: this.formBuilder.control(null)
    });
  }

  ngOnInit(): void {
    this.userSubscription$ = this.userService.currentUser.subscribe({
      next: (user: User) => {
        this.editForm.patchValue({
          name: user.name,
          surname: user.surname,
          email: user.email,
          phone: (user.phone && user.phone[0] !== '+' ? '+' : '') + user.phone,
        });
      },
      error: (error: any) => console.error(error)
    });
  }

  ngAfterViewInit(): void {
  }

  ngOnDestroy(): void {
    [
      this.userSubscription$,
    ].forEach($ => $?.unsubscribe());
  }

  onSubmit(): void | undefined {
    if (this.helperService.getFormValidation(this.editForm)) {
      this.isSubmitted = true;

      const { phone, ...body } = this.editForm.value;

      this.userService.postProfile(body).subscribe({
        next: () => {
          this.router
            .navigate(['/office/settings'])
            .then(() => this.snackbarService.success('Информация успешно изменена'));
        },
        error: () => (this.isSubmitted = false)
      });
    }
  }
}
