/** @format */

import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { User } from '../models';
import { UserService } from './user.service';

@Injectable()
export class ContractGuard implements CanActivate {
  constructor(private router: Router, private userService: UserService) {}

  canActivate(): Observable<boolean> {
    return this.userService.currentUser.pipe(
      switchMap((user: User) => {
        if (!!user.contract) {
          this.router.navigate(['/office']).then(() => console.log('Route changed'));
        }

        return of(!user.contract);
      })
    );
  }
}
