/** @format */

import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, ReplaySubject, throwError } from 'rxjs';
import { catchError, distinctUntilChanged, map, tap } from 'rxjs/operators';
import { ApiService } from './api.service';
import { NotationService } from './notation.service';
import { LocalStorageService } from './local-storage.service';
import { User, UserContract, UserDkp } from '../models';
import { CookieService } from './cookie.service';

@Injectable()
export class UserService {
  public currentUser;
  public isAuthenticated;
  public currentWelcomeWizardUser;

  private currentUserSubject = new BehaviorSubject<User>({} as User);
  private isAuthenticatedSubject = new ReplaySubject<boolean>(1);
  private currentWelcomeWizardUserSubject = new BehaviorSubject<object>({} as object);

  constructor(
    private apiService: ApiService,
    private localStorageService: LocalStorageService,
    private cookieService: CookieService,
    private notationService: NotationService
  ) {
    this.currentUser = this.currentUserSubject.asObservable().pipe(distinctUntilChanged());
    this.isAuthenticated = this.isAuthenticatedSubject.asObservable();
    this.currentWelcomeWizardUser = this.currentWelcomeWizardUserSubject.asObservable().pipe(distinctUntilChanged());
  }

  getAuthorization(): void {
    const rugramOfficeUser = this.localStorageService.getItem('rugramOfficeUser');
    const rugramWelcomeWizardUser = this.notationService.tryJson(
      this.cookieService.getItem('rugramWelcomeWizardUser'));

    // console.error('init rugramOfficeUser', rugramOfficeUser);
    if (rugramOfficeUser) {
      this.apiService
        .get('/v1/users/profile')
        .pipe(
          map((data: any) => data.data),
          catchError(error => {
            this.resetAuthorization();

            return throwError(() => new Error('getAuthorization error'));
          })
        )
        .subscribe({
          next: (user: User) => this.setAuthorization(user),
          error: (error: any) => console.error(error)
        });
    }
    else {
      this.resetAuthorization();
    }

    // console.error('init rugramWelcomeWizardUser', rugramWelcomeWizardUser);
    if (typeof rugramWelcomeWizardUser === 'object') {
      this.setWelcomeWizardUser(rugramWelcomeWizardUser);
    }
    else {
      this.resetWelcomeWizardUser();
    }
  }

  setWelcomeWizardUser(rugramWelcomeWizardUser: object): void {
    // console.error('set rugramWelcomeWizardUser', rugramWelcomeWizardUser);
    this.currentWelcomeWizardUserSubject.next(rugramWelcomeWizardUser);
  }
  resetWelcomeWizardUser(): void {
    // console.error('reset rugramWelcomeWizardUser');
    this.currentWelcomeWizardUserSubject.next({});
    this.cookieService.removeItem('rugramWelcomeWizardUser');
  }

  setAuthorization(user: User): void {
    // console.error('set rugramOfficeUser', user);
    // this.localStorageService.setItem('rugramOfficeUser', user.token as string);
    this.currentUserSubject.next(user);
    this.isAuthenticatedSubject.next(true);
  }
  resetAuthorization(): void {
    // console.error('reset rugramOfficeUser');
    this.localStorageService.removeItem('rugramOfficeUser');
    this.currentUserSubject.next({} as User);
    this.isAuthenticatedSubject.next(false);
  }

  getLogout(body: any): any {
    return this.apiService.post('/v1/users/logout', body).pipe(
      tap(() => {
        this.resetAuthorization();
        this.resetWelcomeWizardUser();
      }),
      catchError(() => {
        this.resetAuthorization();
        this.resetWelcomeWizardUser();

        return throwError(() => new Error('getLogout error'));
      })
    );
  }

  getContracts(params?: any): Observable<UserContract[]> {
    return this.apiService.get('/v1/users/contracts', params).pipe(map((data: any) => data.data));
  }

  getDkps(params?: any): Observable<UserDkp[]> {
    return this.apiService.get('/v1/users/dkps', params).pipe(map((data: any) => data.data));
  }

  postLogin(body: object): Observable<User> {
    return this.apiService.post('/login', body).pipe(
      map((data: any) => data.data),
      tap((user: User) => {
        if (user.cabinetAvailable) {
          this.cookieService.removeItem('rugramWelcomeWizardUser');

          // prettier-ignore
          this.localStorageService.setItem('rugramOfficeUser', user.token as string);
          this.setAuthorization(user);
        } else {
          this.localStorageService.removeItem('rugramOfficeUser');

          // prettier-ignore
          this.cookieService.setItem('rugramWelcomeWizardUser', JSON.stringify(user), {
            ['max-age']: 2592000
          });
          this.setWelcomeWizardUser(user);
        }
      })
    );
  }

  postRegistration(body: object): Observable<any> {
    return this.apiService.post('/v1/users/registration', body).pipe(map((data: any) => data.data));
  }

  postSMS(body: object): Observable<any> {
    return this.apiService.post('/v1/verify/phone/try', body).pipe(
      map((data: any) => data.data),
    );
  }

  postVerification(body: object): Observable<User> {
    return this.apiService.post('/v1/verify/phone', body).pipe(
      map((data: any) => data.data),
      tap((user: User) => {
        this.localStorageService.removeItem('rugramOfficeUser');

        // prettier-ignore
        this.cookieService.setItem('rugramWelcomeWizardUser', JSON.stringify(user), {
          ['max-age']: 2592000
        });
      })
    );
  }

  postHelp(body: any): Observable<any> {
    return this.apiService.post('/v1/feedback/create', body).pipe(map((data: any) => data.data));
  }

  postAvatar(body: any): Observable<any> {
    return this.apiService.post('/v1/users/changeAvatar', body).pipe(
      map((data: any) => data.data),
      tap((user: User) => this.setAuthorization(user))
    );
  }

  /**
   * Change common info of user profile such as name, surname, email and so on
   * @param body - user object
   */
  postProfile(body: any): Observable<User> {
    return this.apiService.post('/v1/users/changeProfile', body).pipe(
      map((data: any) => data.data),
      tap((user: User) => this.setAuthorization(user))
    );
  }

  /**
   * Change password of current user, in the and return boolean which mean was the operation successful
   * @param body - object with current password and password that user want to set
   */
  postPassword(body: any): Observable<User> {
    return this.apiService.post('/v1/users/changePass', body).pipe(
      map((data: any) => data.data),
      tap((user: User) => {
        this.setAuthorization(user);

        // prettier-ignore
        this.localStorageService.setItem('rugramOfficeUser', user.token as string);
      })
    );
  }

  /**
   * @param body - object with token, password and confirm password that user want to set
   */
  postReset(body: any): Observable<User> {
    return this.apiService.post('/v1/users/password-reset', body).pipe(
      map((data: any) => data.data),
      tap((user: User) => {
        if (user.cabinetAvailable) {
          this.cookieService.removeItem('rugramWelcomeWizardUser');

          // prettier-ignore
          this.localStorageService.setItem('rugramOfficeUser', user.token as string);
          this.setAuthorization(user);
        } else {
          this.localStorageService.removeItem('rugramOfficeUser');

          // prettier-ignore
          this.cookieService.setItem('rugramWelcomeWizardUser', JSON.stringify(user), {
            ['max-age']: 2592000
          });
          this.setWelcomeWizardUser(user);
        }
      })
    );
  }

  /**
   * @param body - object with the current email address to which the user wants to receive instructions
   */
  postForgot(body: any): Observable<any> {
    return this.apiService
      .post('/v1/users/password-forgot', body)
      .pipe(map((data: any) => data.data));
  }

  postResetImportedAuthor(body: any): Observable<User> {
    return this.apiService
      .post('/v1/users/set-imported-author-password', body)
      .pipe(map((data: any) => data.data));
  }
}
