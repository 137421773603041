/** @format */

import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  Dkp,
  DkpField,
  DkpService,
  FileService,
  HelperService,
  SnackbarService,
  User,
  UserService
} from '../../../core';
import { filter, first, map, tap } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

interface DkpForm {
  contractId: FormControl<string | null>;
}

interface DkpFilledContract {
  id: number;
  name: string;
  file_name: string;
  created_at: string;
  updated_at: string;
  signed_at: string;
  expired_at: string;
}

interface DkpFilled {
  contract: DkpFilledContract;
  link: string;
}

@Component({
  selector: 'app-office-services-dkp',
  templateUrl: './dkp.component.html',
  styleUrls: ['./dkp.component.scss']
})
export class OfficeServiceDkpComponent implements OnInit, OnDestroy {
  routeData$!: Subscription;

  user$: Observable<User> | undefined;

  progressMap = ['initial', 'initial', 'initial', 'initial'];
  progressState = 'specification';
  progressHistory: any = {};

  contractList: Dkp[] = [];
  contractSelected: Dkp | undefined;
  contractFilled!: DkpFilled;

  contractForm: FormGroup;
  contractFormIsSubmitting = false;

  specificationForm!: FormGroup;
  specificationFormIsSubmitting = false;

  applicationForm!: FormGroup;
  applicationFormIsSubmitting = false;

  contractResendIsSubmitting = false;

  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private helperService: HelperService,
    private dkpService: DkpService,
    private userService: UserService,
    private fileService: FileService,
    private snackbarService: SnackbarService
  ) {
    this.contractForm = this.formBuilder.group<DkpForm>({
      contractId: this.formBuilder.control('', [Validators.required])
    });
  }

  ngOnInit(): void {
    this.routeData$ = this.route.data.pipe(map((data: any) => data.data)).subscribe({
      next: (contractList: Dkp[]) => (this.contractList = contractList),
      error: (error: any) => console.error(error)
    });
    this.user$ = this.userService.currentUser;
    this.contractSelected = this.contractList.find((contract: Dkp) => {
      return contract.id === this.contractForm.value.contractId;
    });
    this.stepSpecification();
  }

  ngOnDestroy(): void {
    [this.routeData$].forEach($ => $?.unsubscribe());
  }

  universalFieldMask(fieldName: string) {
    switch (fieldName) {
      case 'snils':
        return '000-000-000 00';
    }
    return '';
  }

  setProgressHistory(state: string): void {
    this.progressHistory = {
      ...this.progressHistory,
      [state]: (() => {
        switch (state) {
          case 'specification': {
            return {
              specificationForm: this.specificationForm.value
            };
          }
          case 'contract': {
            return {
              contractForm: this.contractForm.value
            };
          }
          case 'application': {
            return {
              applicationForm: this.applicationForm.value
            };
          }
          default: {
            return {};
          }
        }
      })()
    };
  }

  stepSpecification(): void {
    /**
     * Dynamically create application form
     */
    this.specificationForm = this.formBuilder.group({
      items: this.formBuilder.array([])
    });

    const items = (<FormArray>this.specificationForm.get("items"));

    /**
     * Set value if has previous fill
     */
    if ('specification' in this.progressHistory) {
      const rowsCount = this.progressHistory.specification.specificationForm.items.length;
      for (let i=0; i < rowsCount; i++) {
        items.push(this.buildSpecificationFormItemGroup());
      }
      this.specificationForm.patchValue(this.progressHistory.specification.specificationForm);
    }
    else {
      items.push(this.buildSpecificationFormItemGroup());
    }

    this.progressMap = ['active', 'initial', 'initial', 'initial'];
    this.progressState = 'specification';
  }

  onSubmitSpecificationForm(): void {
    if (!this.helperService.getFormValidation(this.specificationForm)) return;

    this.setProgressHistory('specification');

    this.stepContract();
  }

  stepContract(): void {
    this.progressMap = ['done', 'active', 'initial', 'initial'];
    this.progressState = 'contract';
  }

  onSubmitContractForm(): void {
    if (!this.helperService.getFormValidation(this.contractForm)) return;

    this.setProgressHistory('contract');

    this.contractSelected = this.contractList.find((contract: Dkp) => {
      return contract.id === this.contractForm.value.contractId;
    });

    this.stepApplication();
  }

  stepApplication(): void {
    /**
     * Dynamically create application form
     */
    this.applicationForm = this.formBuilder.group({});
    this.contractSelected?.fields.forEach((contractField: DkpField) => {
      this.applicationForm.addControl(
        contractField.key,
        new FormControl(contractField.value, Validators.required)
      );
    });

    /**
     * Set value if has previous fill
     */
    if ('application' in this.progressHistory) {
      this.applicationForm.patchValue(this.progressHistory.application.applicationForm);
    }

    this.progressMap = ['done', 'done', 'active', 'initial'];
    this.progressState = 'application';
  }

  onSubmitApplicationForm(): void {
    if (!this.helperService.getFormValidation(this.applicationForm)) return;

    this.setProgressHistory('application');

    this.stepDone();

  }

  stepDone(): void {
    this.dkpService.postContract({
      contract_id: this.contractSelected?.id,
      specification: this.specificationForm.value,
      fields: this.applicationForm.value
    })
      /**
       * Use getAuthorization to fill user contract in app memory
       */
      .pipe(tap(() => this.userService.getAuthorization()))
      .subscribe({
        next: (contractFilled: DkpFilled) => {
          this.contractFilled = contractFilled;

          this.progressMap = ['done', 'done', 'done', 'done'];
          this.progressState = 'done';
        },
        error: (error: any) => {
          console.error(error)
          this.progressState = 'error';
        }
      });

    this.progressMap = ['done', 'done', 'done', 'active'];
    this.progressState = 'submit';
  }

  onReturnToInitial(from: string): void {
    this.setProgressHistory(from);
    this.stepSpecification();
  }

  onDownloadContract(): void {
    const {id, file_name} = this.contractFilled.contract;

    this.dkpService.getContract(id).subscribe({
      next: (fileBinary: any) => this.fileService.getDownloadFile(fileBinary, file_name),
      error: (error: any) => console.error(error)
    });
  }

  // onContractResend(): void {
  //   this.userService.currentUser
  //     .pipe(
  //       filter((user: User) => !!user.contract.id),
  //       first()
  //     )
  //     .subscribe({
  //       next: (user: User) => {
  //         this.contractResendIsSubmitting = true;
  //
  //         this.dkpService.postContractResend(user.contract.id).subscribe({
  //           next: () => {
  //             this.contractResendIsSubmitting = false;
  //
  //             this.snackbarService.success('Мы отправили письмо вам на почту', 4000);
  //           },
  //           error: () => (this.contractResendIsSubmitting = false)
  //         });
  //       },
  //       error: (error: any) => console.error(error)
  //     });
  // }



  getSpecificationFormControls() {
    return (this.specificationForm.get('items') as FormArray).controls;
  }

  buildSpecificationFormItemGroup(): FormGroup {
    return this.formBuilder.group({
      name: ["", Validators.required],
      price: [null, Validators.required],
      quantity: [1, Validators.required],
    });
  }

  onSpecificationFormItemAdd(): void {
    (<FormArray>this.specificationForm.get("items")).push(
      this.buildSpecificationFormItemGroup()
    );
  }

  onSpecificationFormItemRemove(index: number): void {
    const companies = this.specificationForm.get('items') as FormArray;
    companies?.removeAt(index);
  }
}
