/** @format */

import { Component } from '@angular/core';
import {HelpService} from "../../../core";

@Component({
  selector: 'app-office-help-sale-in-stores-form',
  templateUrl: './sale-in-stores.component.html',
  styleUrls: ['./sale-in-stores.component.scss']
})
export class OfficeHelpSaleInStoresComponent {
  constructor(
    private helpService: HelpService
  ) {}

  onShowModalHelp(): void {
    this.helpService.setShowHelpModal({ show: true, title: 'Есть вопрос?' });
  }
}
