/** @format */

import { Injectable } from '@angular/core';
import { forkJoin, Observable, throwError } from 'rxjs';
import {
  BooksService,
  NotificationList,
  NotificationsService,
  OfferList,
  OffersService
} from '../../core';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class OfficeNotificationsResolver {
  constructor(
    private notificationsService: NotificationsService,
    private booksService: BooksService,
    private offerService: OffersService,
    private router: Router
  ) {}

  resolve(): Observable<[NotificationList, OfferList]> {
    return forkJoin([
      this.notificationsService.getAll({
        limit: 10,
        page: 1
      }),
      this.offerService.getAll({
        limit: 10,
        page: 1
      })
    ]).pipe(
      catchError((error: any) => {
        this.router
          .navigateByUrl('/error/' + error.status)
          .then(() => console.debug('Route changed'));

        return throwError(() => new Error('OfficeNotificationsResolver error'));
      })
    );
  }
}
