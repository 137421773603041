/** @format */

import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { NotificationList } from '../models';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  constructor(private apiService: ApiService) {}

  getAll(params?: any): Observable<NotificationList> {
    return this.apiService.get('/v1/notifications', params).pipe(map((data: any) => data.data));
  }

  postById(id: string): Observable<NotificationList> {
    return this.apiService.post('/v1/notifications/' + id, {}).pipe(map((data: any) => data.data));
  }
}
