/** @format */

import { Injectable } from '@angular/core';
import { ApiService } from '../../../../core';
import { Observable } from 'rxjs';
import { DeliveryPickup, DeliveryYandex } from '../models';
import { map, pluck } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DeliveryService {
  constructor(private apiService: ApiService) {}

  getDeliveryYandex(body: any): Observable<DeliveryYandex[]> {
    return this.apiService.get('/v1/yandex/delivery-services', body)
      .pipe(map((data: any) => data.data));
  }

  getDeliveryServices(params?: any): Observable<any[]> {
    return this.apiService.get('/v1/delivery-services', params)
      .pipe(pluck('data'));
  }

  getDeliveryPickup(): Observable<DeliveryPickup[]> {
    return this.apiService.get('/v1/pickupAddresses').pipe(map((data: any) => data.data));
  }
}
