/** @format */

import { Component, OnInit } from '@angular/core';
import { ChildrenOutletContexts } from "@angular/router";
import { fadeAnimation, slideInAnimation } from "./office-animations";
import { trigger } from "@angular/animations";

@Component({
  selector: 'app-office',
  templateUrl: './office.component.html',
  styleUrls: ['./office.component.scss'],
  animations: [
    trigger('routeAnimations', fadeAnimation)
  ]
})
export class OfficeComponent implements OnInit {
  constructor(private contexts: ChildrenOutletContexts) {}

  ngOnInit(): void {}

  getRouteAnimationData() {
    return this.contexts.getContext('primary')?.route?.snapshot?.data?.['animation'];
  }
}
