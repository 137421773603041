/** @format */

import { Component, Input, OnInit } from '@angular/core';
import { transition, trigger } from "@angular/animations";
import { scaleIn } from "../animations";

@Component({
  selector: 'app-right-bar-advertising-litcovers',
  templateUrl: './right-bar-advertising-litcovers.component.html',
  styleUrls: ['./right-bar-advertising-litcovers.component.scss'],
  animations: [
    trigger('showIn', [
      transition(':enter', scaleIn)
    ])
  ]
})
export class RightBarAdvertisingLitcoversComponent implements OnInit {
  @Input()

  href = 'https://litcovers.com/ru/users/register?code=ZDdXgyIYl5';
  target = '_blank';

  constructor() {}

  ngOnInit(): void {}
}
