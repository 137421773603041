/** @format */

import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-services-terms',
  templateUrl: './services-terms.component.html',
  styleUrls: ['./services-terms.component.scss']
})
export class ServicesTermsComponent implements OnInit, OnDestroy {
  @Output() closed = new EventEmitter<any>();

  @Output() submitted = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {}

  ngOnDestroy(): void {}

  onSubmitted(toggle: boolean): void {
    this.closed.emit();

    this.submitted.emit(toggle);
  }
}
