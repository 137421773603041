<!-- @format -->

<div class="snackbar d-flex fd-column ai-center">
	<div
		class="snack d-flex ai-center jc-between rounded-sm p-3 mt-2"
		[ngClass]="[snack.class]"
		*ngFor="let snack of snacks"
	>
		<span class="d-block h7 line-height-2 text-color-log-print_text mr-1"
    >{{ snack.message }}</span>
		<app-svg-icon
			class="close d-block cursor-p p-1"
			[appIcon]="'cross--dark'"
			[appWidth]="8"
			[appHeight]="8"
			[appViewBox]="'0 0 16 16'"
			(click)="onClose(snack)"
		></app-svg-icon>
	</div>
</div>
