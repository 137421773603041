<!-- @format -->

<section class="box d-flex ai-start bg-color-neutral_white rounded-md bordered p-7">
	<div class="d-flex ai-center mr-3">
		<app-svg-icon
			appIcon="bg-green-check"
			appWidth="50"
			appHeight="51"
			appViewBox="0 0 50 51"
		></app-svg-icon>
	</div>
	<div class="d-flex fd-column">
		<h1 class="py-h mb-3">Готово!</h1>
		<p class="mb-0 text-color-log-print_text line-height-2 h4">
			Спасибо, что решили доверить нам издание своей книги! Ваша заявка ушла на модерацию,
			как только мы примем решение об условиях сотрудничества, вам на почту придет уведомление.
		</p>
		<div class="d-flex fd-row jc-between ai-center mt-5">
			<button
					(click)="this.resume.emit()"
					class="btn btn-style-1 btn-inline lg text-semibold rounded-md px-9"
			>
				<span>Продолжить</span>
			</button>
		</div>
	</div>
</section>
