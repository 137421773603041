/** @format */

import { Component, Input } from '@angular/core';
import { AccessMessageMap } from '../../core';
import { Book } from '../../../../core';

@Component({
  selector: 'app-services-access-message',
  templateUrl: './services-access-message.component.html',
  styleUrls: ['./services-access-message.component.scss']
})
export class ServicesAccessMessageComponent {
  @Input()
  set appBook(book: Book) {
    this.book = book;
  }

  @Input()
  set appMessageMap(messageMap: AccessMessageMap) {
    this.messageMap = messageMap;
  }

  book: Book | undefined;

  messageMap: AccessMessageMap | undefined;
}
