<!-- @format -->

<div class="dropdown position-r d-flex">
	<form class="w-full" [formGroup]="inputForm">
		<fieldset
			class="input i-end d-flex ai-center w-full"
			[class.invalid]="address.invalid && address.touched"
			[ngClass]="classList"
			*ngIf="inputForm.get('address') as address"
			[disabled]="isSubmitted"
		>
			<input
				class=""
				formControlName="address"
				data-cy="address"
				[placeholder]="placeholder || ''"
				(focus)="toggleDropdown = true"
				(blur)="onBlur($event)"
				(keyup)="onKeyup($event)"
				#inputElement
			/>
			<span
				*ngIf="inputForm.get('address')?.errors?.notFound"
				class="w-full mt-1 text-color-log-print_main-red h7"
				data-cy="notFound"
			>
				Адрес не найден, укажите корректные данные
			</span>
			<span
				*ngIf="inputForm.get('address')?.errors?.notSelected"
				class="w-full mt-1 text-color-log-print_main-red h7"
			>
				Выберите адрес из списка
			</span>
		</fieldset>
		<app-loader
			class="d-flex"
			[class.my-3]="isSubmitted"
			[appIsLoading]="isSubmitted"
		></app-loader>
	</form>
	<div
		class="dropdown-inner w-full"
    data-lenis-prevent="true"
		[ngClass]="classList"
		*ngIf="toggleDropdown && data.length > 0"
		[@fadeAnimation]="{value: toggleDropdown}"
	>
		<ul class="rounded-sm cursor-p bg-color-neutral_white p-0 m-0">
			<li
				class="c-pointer text-color-log-print_dark-gray"
				[attr.data-cy]="'address-' + i"
				[class.hover]="toggleHovered === i"
				*ngFor="let li of data; let i = index"
				(click)="onSelect(li)"
			>
				{{ li.dropdown() }}
			</li>
		</ul>
	</div>
</div>
