/** @format */

import { Component, OnInit } from '@angular/core';
import { HelperService, SnackbarService, UserService } from '../../../core';
import { FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';

interface PasswordForm {
  password: FormControl<string | null>;
  new_password: FormControl<string | null>;
  new_password_confirmation: FormControl<string | null>;
}

@Component({
  selector: 'app-office-settings-password',
  templateUrl: './password.component.html',
  styleUrls: ['../edit/edit.component.scss']
})
export class OfficeSettingsPasswordComponent implements OnInit {
  passwordForm: FormGroup;

  isSubmitted = false;

  constructor(
    private userService: UserService,
    private formBuilder: FormBuilder,
    private helperService: HelperService,
    private router: Router,
    private snackbarService: SnackbarService
  ) {
    this.passwordForm = this.formBuilder.group<PasswordForm>(
      {
        password: this.formBuilder.control('', [Validators.required]),
        new_password: this.formBuilder.control('', [Validators.required, Validators.minLength(6)]),
        new_password_confirmation: this.formBuilder.control('', [
          Validators.required,
          Validators.minLength(6)
        ])
      },
      { validators: [this.getConfirmPassword('new_password', 'new_password_confirmation')] }
    );
  }

  ngOnInit(): void {}

  getConfirmPassword(left: string, right: string): ValidatorFn {
    return formGroup => {
      if (formGroup.get(left)?.value !== formGroup.get(right)?.value) {
        return { match: false };
      }

      return null;
    };
  }

  onSubmit(): void {
    if (this.helperService.getFormValidation(this.passwordForm)) {
      this.isSubmitted = true;

      this.userService.postPassword(this.passwordForm.value).subscribe({
        next: () => {
          this.router
            .navigate(['/office/settings'])
            .then(() => this.snackbarService.success('Пароль успешно изменен'));
        },
        error: () => (this.isSubmitted = false)
      });
    }
  }
}
