/** @format */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxMaskModule } from 'ngx-mask';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import {
  AppDropzoneDirective,
  AppInputTrimWhitespaceDirective,
  AppRandomBgDirective,
  AppSwipeDirective,
  IntlTelInputDirective
} from './directives';
import {
  ButtonHelpComponent,
  ModalHelpComponent,
} from './components';
import {
  CardBookComponent,
  CardBookUploadedComponent,
  CarouselPostsComponent,
  CollapseComponent,
  ContractInitialComponent,
  ContractSignComponent,
  DeliveryAutocompleteComponent,
  DropdownInputComponent,
  HeaderComponent,
  HttpLoaderComponent,
  LeftBarComponent,
  LeftBarAdvertisingLitcoversComponent,
  ListNotificationComponent,
  ListPaginationComponent,
  LoaderComponent,
  ModalComponent,
  ModalManagerComponent,
  OfferInitialComponent,
  OfferSignComponent,
  OfferSignFinalComponent,
  RightBarAdvertising1Component,
  RightBarAdvertising2Component,
  RightBarAdvertising3Component,
  RightBarAdvertisingLitcoversComponent,
  RightBarSocialComponent,
  RightBarDocTemplatesComponent,
  SnackbarComponent,
  SvgIconComponent,
  TooltipComponent
} from './layout';
import {
  DayjsPipe,
  PluralPipe,
  ReplacePipe,
  SanitizerPipe,
  TransformPipe,
  ReversePipe,
  PhoneInternationalFormatPipe
} from './pipes';
import { ngGxTextAnimateModule } from '../core/modules/ng-gx-text-animate/src';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    NgxMaskModule.forRoot({
      allowNegativeNumbers: true
    }),
    ngGxTextAnimateModule
  ],
  declarations: [
    AppDropzoneDirective,
    AppInputTrimWhitespaceDirective,
    AppRandomBgDirective,
    AppSwipeDirective,
    IntlTelInputDirective,
    ButtonHelpComponent,
    ModalHelpComponent,
    CardBookComponent,
    CardBookUploadedComponent,
    CarouselPostsComponent,
    CollapseComponent,
    ContractInitialComponent,
    ContractSignComponent,
    DeliveryAutocompleteComponent,
    DropdownInputComponent,
    HeaderComponent,
    HttpLoaderComponent,
    LeftBarComponent,
    LeftBarAdvertisingLitcoversComponent,
    ListNotificationComponent,
    ListPaginationComponent,
    LoaderComponent,
    ModalComponent,
    ModalManagerComponent,
    OfferInitialComponent,
    OfferSignComponent,
    OfferSignFinalComponent,
    RightBarAdvertising1Component,
    RightBarAdvertising2Component,
    RightBarAdvertising3Component,
    RightBarAdvertisingLitcoversComponent,
    RightBarSocialComponent,
    RightBarDocTemplatesComponent,
    SnackbarComponent,
    SvgIconComponent,
    TooltipComponent,
    DayjsPipe,
    PluralPipe,
    ReplacePipe,
    SanitizerPipe,
    TransformPipe,
    ReversePipe,
    PhoneInternationalFormatPipe
  ],
  exports: [
    CommonModule,
    NgxMaskModule,
    HttpClientModule,
    ReactiveFormsModule,
    RouterModule,
    AppDropzoneDirective,
    AppInputTrimWhitespaceDirective,
    AppRandomBgDirective,
    AppSwipeDirective,
    IntlTelInputDirective,
    ButtonHelpComponent,
    ModalHelpComponent,
    CardBookComponent,
    CardBookUploadedComponent,
    CarouselPostsComponent,
    CollapseComponent,
    ContractInitialComponent,
    ContractSignComponent,
    DeliveryAutocompleteComponent,
    DropdownInputComponent,
    HeaderComponent,
    HttpLoaderComponent,
    LeftBarComponent,
    LeftBarAdvertisingLitcoversComponent,
    ListNotificationComponent,
    ListPaginationComponent,
    LoaderComponent,
    ModalComponent,
    ModalManagerComponent,
    OfferInitialComponent,
    OfferSignComponent,
    OfferSignFinalComponent,
    RightBarAdvertising1Component,
    RightBarAdvertising2Component,
    RightBarAdvertising3Component,
    RightBarAdvertisingLitcoversComponent,
    RightBarSocialComponent,
    RightBarDocTemplatesComponent,
    SnackbarComponent,
    SvgIconComponent,
    TooltipComponent,
    DayjsPipe,
    PluralPipe,
    ReplacePipe,
    SanitizerPipe,
    TransformPipe,
    ReversePipe,
    PhoneInternationalFormatPipe,
    ngGxTextAnimateModule,
  ]
})
export class SharedModule {}
