/** @format */

import { Injectable } from '@angular/core';
import { CookieService, LocalStorageService, PlatformService } from '../../../core';
import {StepResume, StepUploadForm, Wizard} from '../models/wizard.model';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import {skip} from "rxjs/operators";
import {FormControl} from "@angular/forms";

@Injectable()
export class WizardService {
  public wizard$ = new BehaviorSubject<Wizard>({} as Wizard);
  public wizardSubscription$: Subscription | undefined;

  public localStorageName = 'rugramWelcomeWizard';

  public mimeTypes: string[] = [
    'application/pdf',
    // 'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    // 'application/vnd.oasis.opendocument.text',
    // 'application/rtf',
    '.docx',
    // '.odt',
    // '.doc',
    'image/jpe',
    'image/jpg',
    'image/jpeg',
    'image/png',
    'image/svg+xml',
    'image/tiff',
    'audio/mpeg',
    'text/plain'
  ];

  constructor(
    private platformService: PlatformService,
    private cookieService: CookieService,
    private localStorageService: LocalStorageService
  ) {}

  onInitWizard(): void {
    // prettier-ignore
    const cookieWelcomeWizardUser = this.cookieService.getItem('rugramWelcomeWizardUser');
    const user = JSON.parse(cookieWelcomeWizardUser);

    if (!/\d/i.test(this.localStorageName)) {
      this.localStorageName = 'rugramWelcomeWizard' + user.id;
    } else {
      const id = this.localStorageName.match(/(\d+)/)?.pop();

      if (Number(id) !== Number(user.id)) {
        this.localStorageService.removeItem(this.localStorageName);

        this.localStorageName = 'rugramWelcomeWizard' + user.id;
      }
    }

    const localStorage = this.localStorageService.getItem(this.localStorageName);
    const wizardNext: Wizard = localStorage ? JSON.parse(localStorage) : {
      resume: [] as StepResume[],
    };
    this.wizard$.next(wizardNext);

    this.wizardSubscription$ = this.wizard$.pipe(skip(1)).subscribe({
      next: (wizard: Wizard) => {
        this.localStorageService.setItem(this.localStorageName, JSON.stringify(wizard));
      },
      error: (error: any) => console.error(error)
    });
  }

  onDestroyWizard(): void {
    this.wizardSubscription$?.unsubscribe();
  }

  onSetWizard(formName: string, formPayload: any = {}): Observable<any> {
    const wizard = this.wizard$.getValue();
    const wizardNext = {
      [formName]: formPayload
    };

    switch (formName) {
      case 'uploadForm':
      case 'editForm':
      case 'index':
      case 'recover':
        this.wizard$.next({
          ...wizard,
          ...wizardNext
        });

        break;
      case 'resume':
        // @ts-ignore
        this.wizard$.next(wizardNext);

        break;
    }

    return of(this.wizard$.getValue());
  }

  onDeleteWizard(): void {
    // this.wizard$.next({} as Wizard);
    // this.localStorageService.removeItem(this.localStorageName);
  }

  removeUpload(fileId: string): Observable<any> {
    const wizard: Wizard = this.wizard$.getValue();

    if (wizard.hasOwnProperty('uploadForm')) {
      if (wizard.uploadForm.hasOwnProperty(fileId)) {
        // @ts-ignore
        delete wizard.uploadForm[fileId];

        this.wizard$.next(wizard);

        this.localStorageService.setItem(this.localStorageName, JSON.stringify(wizard));
      }
    }

    return of(wizard);
  }
}
