<!-- @format -->

<section class="box d-flex jc-between ai-start">
	<div class="view">
		<router-outlet></router-outlet>
	</div>
	<div class="advertising d-flex fd-column">
    <!--<app-right-bar-social class="d-block"></app-right-bar-social>-->
		<app-right-bar-advertising-1 [showDescription]="false" class="d-block"></app-right-bar-advertising-1>
		<app-right-bar-advertising-2 class="d-block"></app-right-bar-advertising-2>
		<app-right-bar-advertising-3 class="d-block"></app-right-bar-advertising-3>
    <div class="empty-card"></div>
	</div>
</section>
