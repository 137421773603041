import { Pipe, PipeTransform } from '@angular/core';
import { IntlTelInputService } from '../../core';

@Pipe({
  name: 'phoneInternational'
})
export class PhoneInternationalFormatPipe implements PipeTransform {
  constructor(
    private phoneUtilsService: IntlTelInputService
  ) { }

  transform(value?: string, ...args: any[]): unknown {
    const phoneNumber = this.phoneUtilsService.getPhoneNumber(value ?? '', args[0]) ?? this.phoneUtilsService.getPhoneNumber('+' + value ?? '', args[0]);
    return (phoneNumber?.country ? phoneNumber?.formatInternational() : null) ?? value;
  }
}
