<!-- @format -->

<section class="box xflex xjc-between xai-start">
	<!--main-->
	<div class="content flex flex-col gap-4">
		<div class="bg-color-neutral_white bordered rounded-sm">
			<!--progress-->
			<div class="p-3">
				<h3 class="mb-1">Печать для себя</h3>
				<app-services-progress
					class="d-block my-3 mx-1"
					[appMap]="progressMap"
				></app-services-progress>
				<ng-container [ngSwitch]="progressState">
					<ng-container *ngSwitchCase="'initial'">
						<div class="text-color-log-print_dark-gray mx-1">Шаг 1: Выбор книги</div>
					</ng-container>
					<ng-container *ngSwitchCase="'quantity'">
						<div class="text-color-log-print_dark-gray mx-1">Шаг 2: Тираж</div>
					</ng-container>
					<ng-container *ngSwitchCase="'delivery'">
						<div class="text-color-log-print_dark-gray mx-1">Шаг 3: Способ получения</div>
					</ng-container>
					<ng-container *ngSwitchCase="'pay'">
						<div class="d-flex jc-end text-color-log-print_dark-gray mx-1">
							Оплата заказа №{{ calculateBook.id }} от {{ calculateBook.updated_at | dayjs: "format":"D MMMM, YYYY" }}
						</div>
					</ng-container>
				</ng-container>
			</div>
			<!--form-->
			<ng-container [ngSwitch]="progressState">
					<ng-container *ngSwitchDefault>
						<app-loader
							[appIsLoading]="!progressState"
						></app-loader>
					</ng-container>
					<!--progressState: initial-->
					<ng-container *ngSwitchCase="'initial'">
						<!--note-->
						<div class="px-3">
							<div class="w-full flex items-center bg-color-neutral_light-gray-2 rounded-sm p-2">
								<app-svg-icon
									class="d-block mx-2"
									appIcon="active-bell"
									appWidth="32"
									appHeight="32"
									appViewBox="0 0 24 24"
								></app-svg-icon>
								<div class="leading-4">
									<div class="h7 text-light">
										К выбору доступны уже прошедшие допечатную подготовку книги,
										за исключением книг, заказ печати которых уже выполняется в настоящий момент
										(информацию по заказам смотрите в уведомлениях).
									</div>
								</div>
							</div>
						</div>
						<div class="px-3" *ngIf="booksSelected">
							<app-services-access-message
								[appBook]="booksSelected"
								[appMessageMap]="printForSelfAccessMessage"
							></app-services-access-message>
						</div>
						<div class="p-3">
							<form [formGroup]="bookForm">
								<fieldset>
									<ul class="book-list radio-list grid grid-cols-2 gap-2">
										<li class="d-flex select-none" *ngFor="let book of books" [ngClass]="{ 'opacity-25 pointer-events-none': !book.availability }">
											<fieldset
												class="w-full position-r rounded-md pr-1"
												*ngIf="{
													isActive: book.id === bookForm.get('book_id')?.value
												} as state"
												[class.active]="state.isActive"
											>
												<input
													[id]="'bookId-' + book.id"
													formControlName="book_id"
													type="radio"
													[value]="book.id"
													hidden
												/>
												<label
													appCardBook
													[appBook]="book"
													[for]="'bookId-' + book.id"
													class="cursor-p"
												></label>
												<app-svg-icon
													class="d-block bg-color-neutral_white active-icon"
													*ngIf="state.isActive"
													appIcon="success-round-green"
													appWidth="23"
													appHeight="23"
													appViewBox="0 0 23 24"
												></app-svg-icon>
											</fieldset>
										</li>
									</ul>
								</fieldset>
							</form>
						</div>
					</ng-container>
					<!--progressState: quantity-->
					<ng-container *ngSwitchCase="'quantity'">
						<div class="p-3">
							<div class="grid grid-cols-2 gap-3">
							<div class="">
								<div class="text-color-log-print_dark-gray mb-3 px-3">Книга</div>
								<div
									appCardBook
									[appBook]="booksSelected"
									class="border-4 border-dashed border-[#ffb63d] rounded-xl px-1"
								></div>
								<app-services-access-message
									*ngIf="booksSelected"
									[appBook]="booksSelected"
									[appMessageMap]="printForSelfAccessMessage"
								></app-services-access-message>
							</div>
							<ng-container *ngIf="bookDetail">
								<!-- Параметры печати -->
								<div class="flex flex-col justify-between bg-color-neutral_white px-3">
									<div class="text-color-log-print_dark-gray mb-3">Параметры печати</div>
									<ul class="flex flex-col gap-1 my-auto">
										<li class="d-flex ai-center mr-5">
											<app-svg-icon
												class="icon d-flex jc-start mr-1"
												[appIcon]="'book-format'"
												[appWidth]="19"
												[appHeight]="24"
												[appViewBox]="'0 0 19 24'"
											></app-svg-icon>
											<div class="text-color-log-print_text">
												Формат:
												<span class="text-bold">{{ bookDetail.book_format }}</span>
											</div>
										</li>
										<li class="d-flex ai-center mr-5">
											<app-svg-icon
												class="icon d-flex jc-start mr-1"
												[appIcon]="'book-print'"
												[appWidth]="24"
												[appHeight]="24"
												[appViewBox]="'0 0 24 24'"
											></app-svg-icon>
											<div class="text-color-log-print_text">
												Печать:
												<span class="text-bold">{{ bookDetail.print_type }}</span>&nbsp;
												<span class="text-bold">{{ bookDetail.page_count }}</span>
												{{ this.getPagePlural(bookDetail.page_count || 0) }}
											</div>
										</li>
										<li class="d-flex ai-center">
											<app-svg-icon
												class="icon d-flex jc-start mr-1"
												[appIcon]="'book-cover-art'"
												[appWidth]="24"
												[appHeight]="24"
												[appViewBox]="'0 0 24 24'"
											></app-svg-icon>
											<div class="text-color-log-print_text">
												Обложка:
												<span class="text-bold">{{ bookDetail.cover_type }}</span>
											</div>
										</li>
										<li class="d-flex ai-center">
											<app-svg-icon
												class="icon d-flex jc-start mr-1"
												appIcon="marketing-priority-display"
												[appWidth]="24"
												[appHeight]="24"
												[appViewBox]="'0 0 42 36'"
											></app-svg-icon>
											<div class="text-color-log-print_text">
												Вес изделия:
												~<span class="text-bold">{{ bookDetail.unit_weight }} гр</span>
											</div>
										</li>
									</ul>
								</div>
								<!-- Тираж -->
								<div class="bg-color-neutral_white px-3">
									<div class="text-color-log-print_dark-gray mb-3">Тираж</div>
									<form [formGroup]="printForm" autocomplete="off" class="d-block w-full mb-3">
										<fieldset [disabled]="printFormIsSubmitted">
											<label class="d-block text-color-neutral_gray-1 h7 mb-1" for="book_count">
												Количество экземпляров:
											</label>
											<div class="input d-flex fd-row ai-center">
												<input
													id="book_count"
													type="number" min="1"
													(change)="printForm.controls.book_count.markAsTouched()"
													mask="separator.0"
													separatorLimit="100"
													[validation]="false"
													formControlName="book_count"
												/>
												<div class="text-color-neutral_dark-2 text-nowrap h8 ml-1">
													От 1 экз. до 35 кг.
												</div>
											</div>
											<ng-container *ngIf="printForm.controls.book_count.hasError('min')">
												<div class="hint d-flex ai-center jc-start py-1 pl-3 text-caption-1 text-gray-600">
													<span class="mr-3">
														<app-svg-icon
															class="d-block"
															appIcon="attention-red"
															appWidth="32"
															appHeight="32"
															appViewBox="0 0 16 16"
														></app-svg-icon>
													</span>
													<span>
														От {{ printForm.controls.book_count.errors?.min?.min }} экземпляров
													</span>
												</div>
											</ng-container>
											<ng-container *ngIf="printForm.controls.book_count.hasError('max')">
												<div class="hint d-flex ai-center jc-start py-1 pl-3 text-caption-1 text-gray-600">
													<span class="mr-3">
														<app-svg-icon
															class="d-block"
															appIcon="attention-red"
															appWidth="32"
															appHeight="32"
															appViewBox="0 0 16 16"
														></app-svg-icon>
													</span>
													<span>
														До {{ printForm.controls.book_count.errors?.max?.max / 1000 }} кг.
														(до {{ Math.floor(printForm.controls.book_count.errors?.max?.max / this.bookDetail.unit_weight) }} экз.)
													</span>
												</div>
											</ng-container>
										</fieldset>
									</form>
								</div>
								<!--Стоимость тиража-->
								<div class="flex flex-col gap-2 bg-gradient-to-tl from-gray-50 to-indigo-100 rounded-sm p-3">
									<div class="w-full grid grid-cols-2 gap-1">
										<div>Стоимость печати экз.</div>
										<div class="justify-self-end">{{bookDetail.one_book_prices}}&nbsp;&#x20bd;</div>
										<div>Тираж</div>
										<div class="justify-self-end" *ngIf="printForm.controls.book_count.valid; else ellipsis">
											{{ printForm.controls.book_count.value | transform: getRoundDecimal | mask: "separator.0" }}&nbsp;экз.
										</div>
										<div>Стоимость тиража</div>
										<div class="justify-self-end" *ngIf="printForm.controls.book_count.valid; else ellipsis">
											{{ printForm.controls.book_count.value * bookDetail.one_book_prices | transform: getRoundDecimal | mask: "separator.0" }}&nbsp;&#x20bd;
										</div>
										<div class="col-span-2 border-t-2 border-indigo-400"></div>
										<div class="text-color-log-print_main-red">Скидка</div>
										<div class="justify-self-end text-color-log-print_main-red">{{bookEditionPercent * 100}}%</div>
										<div>Итого за печать</div>
										<div class="justify-self-end text-bold h4" *ngIf="printForm.controls.book_count.valid; else ellipsis">
											{{ (bookDetail.one_book_prices - (bookDetail.one_book_prices * bookEditionPercent)) * printForm.controls.book_count.value | transform: getRoundDecimal | mask: "separator.0"}}&nbsp;&#x20bd;
										</div>
									</div>
								</div>
							</ng-container>
						</div>
						</div>
					</ng-container>
					<!--progressState: delivery-->
					<ng-container *ngSwitchCase="'delivery'">
						<div class="w-full flex flex-col gap-3 p-3">
							<form [formGroup]="deliveryForm">
								<fieldset>
									<ul
										class="delivery-list radio-list grid grid-cols-2 gap-2"
									>
										<!--Курьером:0-->
										<li
											class="d-flex"
											*ngIf="{value: deliveryForm.get('deliveryId')?.value === 0} as active"
										>
											<fieldset
												class="position-r rounded-md w-full"
												[class.active]="active.value"
											>
												<input
													id="delivery-0"
													formControlName="deliveryId"
													type="radio"
													[value]="0"
													hidden
												/>
												<label
													class="d-flex jc-between ai-center cursor-p px-3 py-5"
													for="delivery-0"
												>
													<div class="d-flex ai-center">
														<app-svg-icon
															class="icon d-flex jc-start mr-1"
															[appIcon]="'delivery-courier'"
															[appWidth]="42"
															[appHeight]="30"
															[appViewBox]="'0 0 42 30'"
														></app-svg-icon>
														<span class="h6">Курьерской службой</span>
													</div>
												</label>
												<app-svg-icon
													class="d-block bg-color-neutral_white active-icon"
													*ngIf="active.value"
													appIcon="success-round-green"
													appWidth="23"
													appHeight="23"
													appViewBox="0 0 23 24"
												></app-svg-icon>
											</fieldset>
										</li>
										<!--Самовывоз:1-->
										<li
											class="d-flex"
											*ngIf="{value: deliveryForm.get('deliveryId')?.value === 1} as active"
										>
											<fieldset
												class="position-r rounded-md w-full"
												[class.active]="active.value"
											>
												<input
													id="delivery-1"
													formControlName="deliveryId"
													type="radio"
													[value]="1"
													hidden
												/>
												<label
													class="d-flex jc-between ai-center cursor-p px-3 py-5"
													for="delivery-1"
												>
													<div class="d-flex ai-center">
														<app-svg-icon
															class="icon d-flex jc-start mr-1"
															[appIcon]="'delivery-pickup'"
															[appWidth]="33"
															[appHeight]="32"
															[appViewBox]="'0 0 33 32'"
														></app-svg-icon>
														<span class="h6">Самовывоз</span>
													</div>
													<span class="text-bold h4">бесплатно</span>
												</label>
												<app-svg-icon
													class="d-block bg-color-neutral_white active-icon"
													*ngIf="active.value"
													appIcon="success-round-green"
													appWidth="23"
													appHeight="23"
													appViewBox="0 0 23 24"
												></app-svg-icon>
											</fieldset>
										</li>
									</ul>
								</fieldset>
							</form>
							<ng-container [ngSwitch]="deliveryForm.get('deliveryId')?.value">
								<!--deliveryId: 0-->
								<div class="w-full flex flex-col gap-3" *ngSwitchCase="0">
									<!--note-->
									<div>
										<div class="w-full flex items-center bg-color-neutral_light-gray-2 rounded-sm p-2">
											<app-svg-icon
												class="d-block mx-2"
												appIcon="active-bell"
												appWidth="32"
												appHeight="32"
												appViewBox="0 0 24 24"
											></app-svg-icon>
											<div class="leading-4">
												<div class="h7 text-light">
													Доставка курьерскими службами осуществляется по России.
												</div>
											</div>
										</div>
									</div>
									<form [formGroup]="courierForm" autocomplete="off">
										<fieldset
											[disabled]="pickupFormIsSubmitting"
											*ngIf="{
												hasFocus: deliveryPhoneInputElement?.nativeElement === documentActiveElement,
												hasError: !!courierForm.controls.phone.errors && courierForm.controls.phone.touched,
												disabled: pickupFormIsSubmitting
											} as stateFieldPhone"
										>
											<fieldset
												class="delivery-field-address input d-block mb-3"
												[disabled]="isSubmittedDelivery"
											>
												<label class="d-block text-color-neutral_gray-1 h7 mb-1" for="address">
													Полный адрес доставки - город, улица, дом, корпус, строение, квартира/офис
												</label>
												<app-delivery-autocomplete
													id="address"
													appPlaceholder="Пример: г Санкт-Петербург, г Пушкин, Академический пр-кт, д 2, кв 11"
													[appAddress]="courierForm.controls.address.value"
													(selected)="courierForm.controls.address.patchValue($event)"
												></app-delivery-autocomplete>
											</fieldset>
											<app-loader
												class="delivery-loader d-flex"
												[class.mb-3]="isSubmittedDelivery"
												[appIsLoading]="isSubmittedDelivery"
											></app-loader>
											<ng-container *ngIf="deliveryServiceList !== null">
												<ul
												class="delivery-list radio-list grid grid-cols-2 gap-2 mb-3"
												*ngIf="deliveryServiceList.length && courierForm.get('delivery_tariff_id') as tariffId; else deliveryYandexEmpty"
											>
												<li
													class="d-flex"
													*ngFor="let delivery of deliveryServiceList; let i = index"
												>
													<fieldset
														class="position-r rounded-md w-full"
														*ngIf="{value: tariffId.value === delivery.tariffId} as active"
														[class.active]="active.value"
													>
														<input
															[id]="'deliveryId-' + i"
															type="radio"
															[attr.data-cy]="'deliveryId-' + i"
															formControlName="delivery_tariff_id"
															[value]="delivery.tariffId"
															hidden
														/>
														<label
															[for]="'deliveryId-' + i"
															class="delivery-item w-full cursor-p p-1"
														>
															<img
																class="icon"
																[src]="delivery.service_logo"
																[alt]="delivery.service_name"
															/>
															<span class="price text-bold h6 text-right">
																{{ delivery.cost.deliveryForCustomer | mask: "separator.2" }}
																<span class="text-weight-600 h5">&#x20bd;</span>
															</span>
															<span class="info">
																{{ delivery.service_name }}
															</span>
														</label>
														<app-svg-icon
															class="d-block bg-color-neutral_white active-icon"
															*ngIf="active.value"
															appIcon="success-round-green"
															appWidth="23"
															appHeight="23"
															appViewBox="0 0 23 24"
														></app-svg-icon>
													</fieldset>
												</li>
											</ul>
											</ng-container>
											<ng-template #deliveryYandexEmpty>
												<div
													*ngIf="!isSubmittedDelivery && courierForm.get('address')?.valid"
													class="delivery-empty d-flex rounded-md p-3 mb-3"
												>
													<app-svg-icon
														class="d-block mr-3"
														appIcon="emoji-sad"
														appWidth="40"
														appHeight="40"
														appViewBox="0 0 40 40"
													></app-svg-icon>
													<div class="d-flex fd-column">
														<span class="d-block text-semibold h5 mb-h">
															Не найдено транспортных компаний
														</span>
														<p class="d-block m-0">Попробуйте указать другой адрес.</p>
													</div>
												</div>
											</ng-template>
											<fieldset class="input d-block mb-3">
												<label class="d-block text-color-neutral_gray-1 h7 mb-1" for="phone">
													<span *ngIf="!stateFieldPhone.hasFocus">
															Контактный номер мобильного телефона получателя в регионе Россия
														</span>
													<span *ngIf="stateFieldPhone.hasFocus">
															Контактный номер мобильного телефона получателя в регионе {{ internationalPhoneCountryName }} в формате {{ examplePhoneNumber }}
														</span>
												</label>
												<div class="w-full" data-lenis-prevent="true">
													<input
														#deliveryPhoneInputElement
														[intlTelInput]="{ initialCountry: 'ru', onlyCountries: ['ru'], allowDropdown: false }"
														id="phone"
														formControlName="phone"
														(countryChange)="examplePhoneNumber = $event.examplePhoneNumber"
														(focus)="stateFieldPhone.hasFocus = true"
														(blur)="stateFieldPhone.hasFocus = false"
													/>
												</div>
											</fieldset>
											<fieldset class="textarea d-block">
												<label for="comment" class="d-block text-color-neutral_gray-1 h7 mb-1">
													Комментарий для курьера
												</label>
												<textarea id="comment" formControlName="comment" rows="3"></textarea>
											</fieldset>
										</fieldset>
									</form>
								</div>
								<!--deliveryId: 1-->
								<div *ngSwitchCase="1">
									<form [formGroup]="pickupForm">
										<fieldset [disabled]="pickupFormIsSubmitting">
											<ul
												class="radio-list d-flex fw-wrap p-0 m-0"
												*ngIf="pickupForm.get('pickup_address_id') as pickup_address_id"
											>
												<li
													class="d-flex w-full"
													*ngFor="let pickup of deliveryPickup; let i = index"
												>
													<fieldset
														class="position-r rounded-md w-full"
														*ngIf="{value: pickup_address_id.value === pickup.id} as active"
														[class.active]="active.value"
													>
														<input
															[id]="'pickupId-' + i"
															type="radio"
															formControlName="pickup_address_id"
															[attr.data-cy]="'pickup-' + i"
															[value]="pickup.id"
															hidden
														/>
														<label
															[for]="'pickupId-' + i"
															class="d-flex fd-column cursor-p p-3"
														>
															<span class="mb-2">{{ pickup.address }}</span>
															<span class="text-bold h4">{{ pickup.schedule }}</span>
														</label>
														<app-svg-icon
															class="d-block bg-color-neutral_white active-icon"
															*ngIf="active.value"
															appIcon="success-round-green"
															appWidth="23"
															appHeight="23"
															appViewBox="0 0 23 24"
														></app-svg-icon>
													</fieldset>
												</li>
											</ul>
										</fieldset>
									</form>
								</div>
							</ng-container>
						</div>
					</ng-container>
					<!--progressState: pay-->
					<ng-container *ngSwitchCase="'pay'">
						<div class="px-3 pb-3">
							<div
								appCardBook
								[appBook]="calculateBook.book"
								class="border-4 border-dashed border-[#ffb63d] rounded-xl px-1 mb-3"
							></div>
							<!--<ul class="flex flex-wrap gap-x-2 p-0 mb-2">
								<li class="d-flex ai-start jc-start">
									<span class="text-color-log-print_dark-gray mr-1">Формат:</span>
									<span class="text-color-neutral_gray-1">
										{{ calculateBook.print.data.bookFormat.title }}
									</span>
								</li>
								<li class="d-flex ai-start jc-start">
									<span class="text-color-log-print_dark-gray mr-1">Блок:</span>
									<span class="text-color-neutral_gray-1">
										{{ calculateBook.print.data.printType }},&nbsp;
									</span>
									<span class="text-color-neutral_gray-1">
										{{ calculateBook.print.data.edition.page_count }}
										{{ this.getPagePlural(calculateBook.print.data.edition.page_count || 0) }}
									</span>
								</li>
								<li class="d-flex ai-start jc-start">
									<span class="text-color-log-print_dark-gray mr-1">Обложка:</span>
									<span class="text-color-neutral_gray-1">
										{{ calculateBook.print.data.coverType.title }}
									</span>
								</li>
							</ul>-->
							<div class="d-flex ai-center jc-between mb-2">
								<div class="d-flex ai-start jc-start mr-3">
									<span class="text-color-log-print_dark-gray mr-1"><b>{{ calculateBook.print.title }}</b>:</span>
									<span class="text-color-neutral_gray-3">
										{{ calculateBook.print.description }}
									</span>
								</div>
								<div class="text-nowrap text-bold h4">
									{{ calculateBook.print.price | mask: "separator.0" }}
									<span class="text-weight-600 h5">&#x20bd;</span>
								</div>
							</div>
							<div class="d-flex ai-center jc-between mb-2">
								<div class="d-flex ai-start jc-start mr-3">
									<span class="text-color-log-print_dark-gray mr-1"><b>{{ calculateBook.delivery.title }}</b>:</span>
									<span class="text-color-neutral_gray-3">
										{{ calculateBook.delivery.description }}
									</span>
								</div>
								<div class="text-nowrap text-bold h4">
									{{ calculateBook.delivery.price | mask: "separator.0" }}
									<span class="text-weight-600 h5">&#x20bd;</span>
								</div>
							</div>
							<hr class="pay-hr w-full mb-3" />
							<div class="d-flex ai-center jc-between">
								<span class="text-bold h4">Всего к оплате:</span>
								<span class="text-bold h4">
									{{ calculateBook.totalPrice | mask: "separator.0" }}
									<span class="text-weight-600 h5">&#x20bd;</span>
								</span>
							</div>

							<app-services-access-message
								*ngIf="booksSelected"
								[appBook]="booksSelected"
								[appMessageMap]="printForSelfAccessMessage"
							></app-services-access-message>
							<div
								class="w-full flex items-center justify-between mt-3"
								*ngIf="!booksSelected?.flag"
							>
								<form [formGroup]="termsForm">
									<fieldset class="checkbox">
										<input
											id="terms"
											type="checkbox"
											formControlName="agreement"
											data-cy="agreement"
										/>
										<label (click)="modalTermsToggle = true" class="ml-2">
											<span class="text-color-neutral_gray-3">
												Я соглашаюсь с
												<span class="text-color-log-print_main-violet">
													условиями сервиса Rugram
												</span>
											</span>
										</label>
									</fieldset>
								</form>
								<!--Оплатить-->
								<div>
									<button
										class="btn btn-style-1 btn-inline rounded-md lg d-flex ai-center px-3"
										data-cy="nextStage"
										[disabled]="termsForm.invalid || termsFormIsSubmitting"
										(click)="onSubmitPayStep()"
									>
										<app-svg-icon
											class="mr-1"
											appIcon="balance-wallet-black"
											appWidth="24"
											appHeight="25"
											appViewBox="0 0 24 25"
										></app-svg-icon>
										<span>Оплатить</span>
									</button>
								</div>
							</div>
						</div>
					</ng-container>
				</ng-container>
		</div>
		<!--note-->
		<div
			class="pay-banks bg-color-neutral_white rounded-sm bordered p-3"
			*ngIf="progressState === 'pay' && !booksSelected?.flag"
		>
			<div class="d-flex fd-column jc-start ai-start">
				<p class="text-color-neutral_gray-2 h7">
					Оплата происходит через сервис по приёму платежей ЮKassa.
					На странице ЮKassa будут представлены доступные Вам способы оплаты.
					В ЮKassa у платежа достаточное, но ограниченное время на оплату.
					Необходимо подтвердить оплату до окончания этого срока.
				</p>
				<span
					class="d-block text-color-log-print_main-violet h7 cursor-p"
					(click)="modalTermsPaymentToggle = true"
				>
					Подробнее
				</span>
			</div>
		</div>
		<!--кнопки-->
		<div [ngSwitch]="progressState">
			<ng-container *ngSwitchCase="'initial'">
			</ng-container>
			<ng-container *ngSwitchCase="'quantity'">
				<div class="w-full flex items-center justify-between px-1">
					<!--Назад-->
					<button
						class="btn btn-style-2 btn-back btn-inline rounded-md lg d-flex ai-center px-3"
						[disabled]="printFormIsSubmitted"
						(click)="onResetToInitial()"
					>
						<app-svg-icon
							class="mr-1"
							appIcon="arrow-left-black"
							appWidth="15"
							appHeight="15"
							appViewBox="0 0 15 14"
						></app-svg-icon>
						<span>Назад</span>
					</button>
					<!--Продолжить-->
					<button
						class="btn btn-style-1 btn-inline rounded-md lg d-flex ai-center px-3"
						[disabled]="printForm.invalid || printFormIsSubmitted"
						(click)="onSubmitQuantityStep()"
						data-cy="nextStage"
					>
						<span>Продолжить</span>
						<app-svg-icon
							class="ml-1"
							appIcon="arrow-right-black"
							appWidth="15"
							appHeight="15"
							appViewBox="0 0 15 14"
						></app-svg-icon>
					</button>
				</div>
			</ng-container>
			<ng-container *ngSwitchCase="'delivery'">
				<div class="w-full flex items-center justify-between px-1"
						 *ngIf="deliveryForm.get('deliveryId') as deliveryId"
				>
					<!--Назад-->
					<button
						class="btn btn-style-2 btn-back btn-inline rounded-md lg d-flex ai-center px-3"
						[disabled]="pickupFormIsSubmitting"
						(click)="onReturnQuantityStep()"
					>
						<app-svg-icon
							class="mr-1"
							appIcon="arrow-left-black"
							appWidth="15"
							appHeight="15"
							appViewBox="0 0 15 14"
						></app-svg-icon>
						<span>Назад</span>
					</button>
					<!--Оформить заказ-->
					<button
						class="btn btn-style-1 btn-inline rounded-md lg d-flex ai-center px-3"
						[disabled]="
								deliveryId.invalid ||
								(deliveryId.value === 0 && courierForm.invalid) ||
								(deliveryId.value === 1 && pickupForm.invalid) ||
								pickupFormIsSubmitting
							"
						data-cy="nextStage"
						(click)="onSubmitDeliveryStep()"
					>
						<span>Оформить заказ</span>
						<app-svg-icon
							class="ml-1"
							appIcon="arrow-right-black"
							appWidth="15"
							appHeight="15"
							appViewBox="0 0 15 14"
						></app-svg-icon>
					</button>
				</div>
			</ng-container>
			<ng-container *ngSwitchCase="'pay'">
				<div class="w-full flex items-center justify-between px-1">
					<!--Назад-->
					<button
						class="btn btn-style-2 btn-back btn-inline rounded-md lg d-flex ai-center px-3"
						[class.visibility-h]="paymentsStatusIsActive"
						[disabled]="termsFormIsSubmitting"
						(click)="onReturnToDelivery()"
					>
						<app-svg-icon
							class="mr-1"
							appIcon="arrow-left-black"
							appWidth="15"
							appHeight="15"
							appViewBox="0 0 15 14"
						></app-svg-icon>
						<span>Назад</span>
					</button>
				</div>
			</ng-container>
		</div>
	</div>
	<!--?-->
	<div class="receipt"
		*ngIf="booksSelected?.availability || paymentsStatusIsActive"
	>
		<!--receipt-->
		<ng-container *ngIf="['quantity', 'delivery'].includes(progressState)">
			<app-services-receipt
				[appServicesReceipt]="servicesReceipt"
				class="d-block w-full"
			></app-services-receipt>
		</ng-container>
		<ng-container [ngSwitch]="progressState">
			<ng-container *ngSwitchCase="'initial'">
				<ng-container *ngIf="bookDetail">
					<!-- Параметры печати -->
					<div class="bg-color-neutral_white rounded-sm bordered p-3 mb-3">
						<div class="text-color-log-print_dark-gray mb-3">Параметры печати</div>
						<ul class="d-flex p-0 m-0">
							<li class="d-flex ai-center mr-5">
								<app-svg-icon
									class="icon d-flex jc-start mr-1"
									[appIcon]="'book-format'"
									[appWidth]="19"
									[appHeight]="24"
									[appViewBox]="'0 0 19 24'"
								></app-svg-icon>
								<div class="text-color-log-print_text">
									Формат:
									<span class="text-bold">{{ bookDetail.book_format }}</span>
								</div>
							</li>
							<li class="d-flex ai-center mr-5">
								<app-svg-icon
									class="icon d-flex jc-start mr-1"
									[appIcon]="'book-print'"
									[appWidth]="24"
									[appHeight]="24"
									[appViewBox]="'0 0 24 24'"
								></app-svg-icon>
								<div class="text-color-log-print_text">
									Печать:
									<span class="text-bold">{{ bookDetail.print_type }}</span>&nbsp;
									<span class="text-bold">{{ bookDetail.page_count }}</span>
									{{ this.getPagePlural(bookDetail.page_count || 0) }}
								</div>
							</li>
							<li class="d-flex ai-center">
								<app-svg-icon
									class="icon d-flex jc-start mr-1"
									[appIcon]="'book-cover-art'"
									[appWidth]="24"
									[appHeight]="24"
									[appViewBox]="'0 0 24 24'"
								></app-svg-icon>
								<div class="text-color-log-print_text">
									Обложка:
									<span class="text-bold">{{ bookDetail.cover_type }}</span>
								</div>
							</li>
						</ul>
					</div>
				</ng-container>
			</ng-container>
			<ng-container *ngSwitchCase="'delivery'">
			</ng-container>
			<ng-container *ngSwitchCase="'pay'">
			</ng-container>
		</ng-container>
	</div>
	<!--ads-->
	<div class="advertising d-flex fd-column">
		<!--<ng-container *ngIf="progressState === 'pay'">
			<app-right-bar-advertising-1 class="d-block"></app-right-bar-advertising-1>
			<app-right-bar-advertising-2 class="d-block"></app-right-bar-advertising-2>
			<app-right-bar-advertising-3 class="d-block"></app-right-bar-advertising-3>
		</ng-container>-->
	</div>
</section>
<app-modal *ngIf="modalTermsToggle" (closed)="modalTermsToggle = false">
	<app-services-terms
		(closed)="modalTermsToggle = false"
		(submitted)="termsForm.get('agreement')?.patchValue($event)"
	></app-services-terms>
</app-modal>
<app-modal *ngIf="modalTermsPaymentToggle" (closed)="modalTermsPaymentToggle = false">
	<app-services-terms-payment
		(closed)="modalTermsPaymentToggle = false"
	></app-services-terms-payment>
</app-modal>
<app-modal *ngIf="modalPaymentsStatusToggle" (closed)="modalPaymentsStatusToggle = false">
	<app-modal-payment-status
		[appMessage]="modalPaymentsMessage"
		(closed)="modalPaymentsStatusToggle = false"
	></app-modal-payment-status>
</app-modal>
<ng-template #ellipsis>
	<span class="justify-self-end">...</span>
</ng-template>
