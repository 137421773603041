<!-- @format -->

<div class="box w-full d-flex" @showIn>
	<a class="inner link-to-detail d-flex jc-between rounded-sm bg-color-log-print_light-blue bordered"
     [routerLink]="['/office/help/sale-in-stores']"
     (touchstart)="hover.value = !hover.value"
     (mouseenter)="hover.value = true"
     (mouseleave)="hover.value = false"
     *ngIf="{value: false} as hover"
  >
		<div class="h8 text-color-log-print_blue letter-spacing-1 mb-h">
			ПРОДАЖА В МАГАЗИНАХ
		</div>
		<div class="h6 text-bold line-height-1 my-h mb-1 transition-colors" [class.text-color-log-print_dark-gray]="hover.value">
			Что предлагает издательство RUGRAM авторам?
		</div>
		<p>Мы готовы инвестировать в вашу книгу и взять на себя заботы о публикации.</p>
		<p class="h7 mb-0" *ngIf="showDescription">
			<span class="text-light">Пришлите нам свою рукопись на рассмотрение и начните зарабатывать уже сейчас.</span>
		</p>
		<img
			class="d-block m-auto"
			src="/assets/images/market-sell.png"
			alt="Что предлагает Rugram авторам? Прежде всего - это продажа вашей книги в книжных магазинах"
		/>
		<div
			class="d-flex ai-center jc-between mt-1"
		>
			<p class="text-bold mb-0 transition-colors" [class.text-color-log-print_dark-gray]="hover.value">Узнать больше</p>
			<app-svg-icon
				[appIcon]="hover.value ? 'arrow-right-filled-hover' : 'arrow-right-filled'"
				appWidth="32"
				appHeight="32"
				appViewBox="0 0 32 32"
			></app-svg-icon>
		</div>
	</a>
</div>
