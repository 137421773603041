/** @format */

import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {Subscription, timer} from 'rxjs';
import {CookieService, HelperService, SnackbarService, UA_INFO, UAInfo, User, UserService} from '../../../../core';
import { filter, map } from 'rxjs/operators';

interface ManagerForm {
  email: FormControl<string | null>;
  message: FormControl<string | null>;
  ua: FormControl<string | null>;
}

@Component({
  selector: 'app-modal-manager',
  templateUrl: './modal-manager.component.html',
  styleUrls: ['./modal-manager.component.scss']
})
export class ModalManagerComponent implements OnInit, OnDestroy {
  @Output() closed = new EventEmitter<any>();

  managerForm: FormGroup;

  userSubscription$: Subscription | undefined;

  isSubmitted = false;
  isCompleted = false;

  constructor(
    @Inject(UA_INFO) public ua: UAInfo,
    private formBuilder: FormBuilder,
    private helperService: HelperService,
    private userService: UserService,
    private cookieService: CookieService,
    private snackbarService: SnackbarService,
  ) {
    this.managerForm = this.formBuilder.group<ManagerForm>({
      email: this.formBuilder.control('', [Validators.required, Validators.email]),
      message: this.formBuilder.control('', [Validators.required]),
      ua: this.formBuilder.control(ua.uaVersion)
    });
  }

  ngOnInit(): void {
    this.userSubscription$ = this.userService.currentUser
      .pipe(
        map((user: User) => {
          if ('email' in user) {
            return user.email;
          }

          // prettier-ignore
          const cookieWelcomeWizardUser = this.cookieService.getItem('rugramWelcomeWizardUser');

          if (cookieWelcomeWizardUser) {
            return JSON.parse(cookieWelcomeWizardUser).email;
          }

          return false;
        }),
        filter((email: string) => !!email)
      )
      .subscribe({
        next: (email: string) => this.managerForm.get('email')?.setValue(email),
        error: (error: any) => console.error(error)
      });
  }

  ngOnDestroy(): void {
    this.userSubscription$?.unsubscribe();
  }

  onSubmitForm(): void {
    if (this.helperService.getFormValidation(this.managerForm)) {
      this.isSubmitted = true;
      this.userService.postHelp(this.managerForm.value).subscribe({
        next: () => {
          this.snackbarService.success('Обращение отправлено');
          this.isCompleted = true;
          timer(4000).subscribe(() => this.closed.emit());
        },
        error: () => {
          this.isSubmitted = false;
        }
      });
    }
  }
}
