/** @format */

import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  Book,
  BookList,
  HelpService,
  Notification,
  NotificationList,
  NotificationsService,
  Offer,
  OfferDetailMap,
  OfferList,
  OffersService,
  Post,
  PostList,
  PusherService,
  User,
  UserService
} from '../../core';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { PresenceChannel } from 'pusher-js';

@Component({
  selector: 'app-office-home',
  templateUrl: './office-home.component.html',
  styleUrls: ['./office-home.component.scss']
})
export class OfficeHomeComponent implements OnInit, OnDestroy {
  routeData$!: Subscription;
  user$!: Subscription;

  pusherObservable$!: Subscription;
  pusherChannel$!: PresenceChannel;

  posts: Post[] = [];
  books: Book[] = [];

  notifications: Notification[] = [];
  notificationsTotal = 10;
  notificationsPaginationIsSubmitted = false;
  notificationsLimit = 5;
  notificationsPage = 1;

  isImported = false;

  hasContract = false;
  hasContractSigned = false;

  offers: Offer[] = [];
  offerDetailMap: OfferDetailMap | undefined;

  modalOfferToggle = false;

  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    private notificationsService: NotificationsService,
    private helpService: HelpService,
    private offersService: OffersService,
    private pusherService: PusherService
  ) {}

  ngOnInit(): void {
    this.routeData$ = this.route.data.pipe(map((data: any) => data.data)).subscribe({
      next: (routeData: [PostList, BookList, NotificationList, OfferList]) => {
        const [posts, books, notifications, offers] = routeData;

        this.posts = posts.items;
        this.offers = offers.items;
        this.books = books.items.filter((book: Book) => [0, 3].includes(book.status)).slice(0, 2);

        this.notifications = notifications.items;
        this.notificationsTotal = notifications.totalItems;
      },
      error: (error: any) => console.error(error)
    });

    this.user$ = this.userService.currentUser.subscribe({
      next: (user: User) => {
        this.isImported = !!user.is_imported;

        /** Disable any contract notifications for imported users https://kostylworks.atlassian.net/browse/RR-281 */

        if (!this.isImported) {
          this.hasContract = !!user.contract;

          if (this.hasContract) {
            this.hasContractSigned = !!user.contract.signed_at;
          }
        }
      },
      error: (error: any) => console.error(error)
    });

    this.pusherObservable$ = this.pusherService.pusherObservable$.subscribe({
      next: () => {
        this.pusherChannel$ = this.pusherService.pusherChannel.bind(
          this.pusherService.eventList['notification-created'],
          (notification: Notification) => {
            this.pusherService.getNotify(notification.description);

            this.notificationsLimit = 10;
            this.notificationsPage = 1;

            this.getNotifications();
          }
        );
      },
      error: (error: any) => console.error(error)
    });
  }

  ngOnDestroy(): void {
    [this.routeData$, this.user$, this.pusherObservable$].forEach($ => $?.unsubscribe());
    [this.pusherChannel$].forEach($ => $?.unbind());
  }

  onSelectNotificationsLimit(limit: number): void {
    this.notificationsLimit = limit;
    this.notificationsPage = 1;

    this.getNotifications();
  }

  onSelectNotificationsPage(page: number | string): void {
    this.notificationsPage = Number(page);

    this.getNotifications();
  }

  getNotifications(): void {
    const params: any = {
      limit: this.notificationsLimit,
      page: this.notificationsPage
    };

    this.notificationsPaginationIsSubmitted = true;

    this.notificationsService.getAll(params).subscribe({
      next: (notificationList: NotificationList) => {
        this.notifications = notificationList.items;
        this.notificationsTotal = notificationList.totalItems;

        if (!this.notifications.length && !this.notificationsTotal) {
          this.notificationsLimit = 10;
          this.notificationsPage = 1;
        }

        this.notificationsPaginationIsSubmitted = false;
      },
      error: () => (this.notificationsPaginationIsSubmitted = false)
    });
  }

  onOfferTransfer(book: Book): void {
    this.offersService.getByBookId(book.id).subscribe({
      next: (offerDetailMap: OfferDetailMap) => {
        this.offerDetailMap = offerDetailMap;

        this.modalOfferToggle = true;
      },
      error: (error: any) => console.error(error)
    });
  }

  onShowModalHelp(): void {
    this.helpService.setShowHelpModal({ show: true, title: 'Есть вопрос?' });
  }
}
