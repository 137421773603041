<!-- @format -->

<div class="w-full box d-flex" @showIn>
	<div class="inner d-flex flex-col jc-between rounded-sm bordered bg-color-neutral_white">
		<div class="h8 text-color-neutral_gray-2 letter-spacing-1 mb-h">УСЛУГИ</div>
		<a
			class="link-to-detail h6 text-bold xcursor-p line-height-1 my-h mb-1"
		>
      <!--[routerLink]="['/office/services']"-->
      <!--[queryParams]="{serviceKey: 'distribution'}"-->
			Подключите полный комплект маркетингового продвижения
		</a>
		<p class="d-flex h7 text-light">
			Продвигайте свою книгу в социальных сетях, через блогеров и авторские презентации
			в лучших книжных магазинах России или своего города.
		</p>
		<img
			class="d-block m-auto"
			src="/assets/images/book-alert.png"
			alt="Подключите полный комплект маркетингового продвижения"
		/>
		<p class="text-color-log-print_main-violet text-bold h7 mt-1">Скоро будет доступно</p>
		<!--<a
			class="mt-1 d-flex ai-center jc-between link-to-detail cursor-p"
			[routerLink]="['/office/services']"
			[queryParams]="{serviceKey: 'distribution'}"
			(touchstart)="hover.value = !hover.value"
			(mouseenter)="hover.value = true"
			(mouseleave)="hover.value = false"
			*ngIf="{value: false} as hover"
		>
			<div class="d-flex ai-center jc-start">
				<span class="text-bold mr-h">от {{ 2000 | mask: "separator" }}</span>
				<span class="d-block text-weight-600 h7">&#x20bd;</span>
			</div>
			<app-svg-icon
				[appIcon]="hover.value ? 'arrow-right-filled-hover' : 'arrow-right-filled'"
				appWidth="32"
				appHeight="32"
				appViewBox="0 0 32 32"
			></app-svg-icon>
		</a>-->
	</div>
</div>
