/** @format */

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Dkp, DkpService } from '../../../core';

@Injectable({
  providedIn: 'any'
})
export class OfficeServiceDkpResolverService {
  constructor(private dkpService: DkpService) {}

  resolve(): Observable<Dkp[]> {
    return this.dkpService.getAll();
  }
}
