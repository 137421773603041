/** @format */
import { Book } from './book.model';

export interface Notification {
  id: string;
  book?: Book;
  type: string;
  title: string;
  marker: string;
  service: string;
  description: string;
  content: NotificationContent;
  read_at: string;
  link: string;
  created_at: string;
  updated_at: string;
}

export interface NotificationList {
  items: Notification[];
  totalItems: number;
}

export interface NotificationContent {
  id: number;
  user_id: number;
  order_id?: number;
  audio_book_copyright_status: number;
  book_copyright_status: number;
  ebook_copyright_status: number;
  available_till: string;
  created_at: string;
  updated_at: string;
  amo_type?: string;
  book_id?: number;
  text?: string;
  copyright_object?: string;
}

export const NotificationsStatusMap = {
  ['success']: {
    label: 'Принято',
    icon: 'status-success',
    bgColor: 'bg-color-log-print_success'
  },
  ['waiting']: {
    label: 'Ожидает',
    icon: 'status-await',
    bgColor: 'bg-color-log-print_main-yellow'
  },
  ['error']: {
    label: 'Отклонено',
    icon: 'status-fail',
    bgColor: 'bg-color-log-print_main-red'
  }
};
