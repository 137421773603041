<!-- @format -->

<div class="modal box grow flex">
  <div class="grow inner d-flex fd-column position-r bg-color-neutral_white rounded-md p-6">
	<div class="close d-flex ai-center jc-center cursor-p p-1" (click)="closed.emit()">
		<app-svg-icon
			[appIcon]="'cross--yellow'"
			[appWidth]="14"
			[appHeight]="14"
			[appViewBox]="'0 0 14 14'"
		></app-svg-icon>
	</div>
	<div class="content pb-3 mb-1 overflow-h">
		<div class="custom-scrollbar h-full pr-1 mr-2">
			<h3 class="mb-1">Условия оплаты</h3>
			<p class="h6 mb-1">Текст обновляется...</p>
			<!--<p class="h6 mb-1">
				Для выбора оплаты товара с помощью банковской карты на соответствующей странице
				необходимо нажать кнопку “Оплатить”. Оплата происходит через ПАО СБЕРБАНК с
				использованием банковских карт следующих платёжных систем:.
			</p>-->
			<!--<img
				class="d-block w-full mb-1"
				src="assets/images/services/payment-logos-modal.svg"
				alt="Платёжные системы"
			/>-->
			<!--<p class="h6 mb-1">
				Для оплаты (ввода реквизитов Вашей карты) Вы будете перенаправлены на платёжный
				шлюз ПАО СБЕРБАНК. Соединение с платёжным шлюзом и передача информации
				осуществляется в защищённом режиме с использованием протокола шифрования SSL. В
				случае если Ваш банк поддерживает технологию безопасного проведения
				интернет-платежей Verified By Visa, MasterCard SecureCode, MIR Accept, J-Secure,
				для проведения платежа также может потребоваться ввод специального пароля.
			</p>-->
			<!--<p class="h6 mb-1">
				Настоящий сайт поддерживает 256-битное шифрование. Конфиденциальность сообщаемой
				персональной информации обеспечивается ПАО СБЕРБАНК. Введённая информация не будет
				предоставлена третьим лицам за исключением случаев, предусмотренных
				законодательством РФ. Проведение платежей по банковским картам осуществляется в
				строгом соответствии с требованиями платёжных систем МИР, Visa Int., MasterCard
				Europe Sprl, JCB.
			</p>-->
			<p class="h6 mb-1 text-semibold">Возврат средств</p>
			<p class="h6 mb-1">Текст обновляется...</p>
			<!--<p class="h6 mb-1">
				Возврат переведённых средств, производится на ваш банковский счёт в течение 5-30
				рабочих дней (срок зависит от банка, который выдал вашу банковскую карту)
			</p>-->
		</div>
	</div>
	<div class="d-flex ai-center jc-end">
		<button class="btn btn-style-1 btn-inline rounded-md sm px-3" (click)="closed.emit()">
			Хорошо, понятно
		</button>
	</div>
</div>
</div>
