/** @format */

import * as dayjs from 'dayjs';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import 'dayjs/locale/ru';

dayjs.extend(quarterOfYear);
dayjs.locale('ru');

export interface Dropdown {
  id: number;
  label: string;
  value?: string | number;
  dropdown: () => string;
}

export const getDropdown = (
  dropdown:
    | 'balanceDates'
    | 'balanceServices'
    | 'notificationSort'
    | 'notificationStatuses'
    | 'notificationServices'
    | 'statisticDates'
): Dropdown[] => dropdowns[dropdown].map(d => ({ ...d, dropdown: () => d.label }));

export const dropdowns = {
  balanceDates: [
    {
      id: 1,
      label: 'Сегодня',
      value: dayjs().format('YYYY-MM-DD')
    },
    {
      id: 2,
      label: 'Вчера',
      value: dayjs().subtract(1, 'day').format('YYYY-MM-DD')
    },
    {
      id: 3,
      label: 'За неделю',
      value: dayjs().subtract(7, 'day').format('YYYY-MM-DD')
    },
    {
      id: 4,
      label: 'За месяц',
      value: dayjs().subtract(1, 'month').format('YYYY-MM-DD')
    }
  ],
  balanceServices: [
    {
      id: 1,
      label: 'Печать для себя',
      value: 'print_for_yourself'
    },
    {
      id: 2,
      label: 'Маркетинговое продвижение',
      value: 'marketing'
    }
  ],
  notificationSort: [
    {
      id: 1,
      label: 'От новых к старым',
      value: 'desc'
    },
    {
      id: 2,
      label: 'От старых к новым',
      value: 'asc'
    }
  ],
  notificationStatuses: [
    {
      id: 1,
      label: 'Принято',
      value: 'success'
    },
    {
      id: 2,
      label: 'Ожидает',
      value: 'waiting'
    },
    {
      id: 3,
      label: 'Отклонено',
      value: 'error'
    }
  ],
  notificationServices: [
    {
      id: 1,
      label: 'Маркетинговое продвижение',
      value: 'marketing'
    },
    {
      id: 2,
      label: 'Продажа в магазинах',
      value: 'book_distribution'
    },
    {
      id: 3,
      label: 'Печать для себя',
      value: 'print_for_yourself'
    },
    {
      id: 4,
      label: 'Электронные книги',
      value: 'ebook'
    },
    {
      id: 5,
      label: 'Аудио книги',
      value: 'audio_book'
    },
    {
      id: 6,
      label: 'Модерация',
      value: 'moderation'
    },
    {
      id: 7,
      label: 'Контракты, офферы, приложения',
      value: 'docs'
    }
  ],
  statisticDates: [
    {
      id: 1,
      label: 'За предыдущий год',
      value: dayjs().subtract(1, 'year').startOf('year').format('YYYY-MM-DD') + ':' +
        dayjs().subtract(1, 'year').endOf('year').format('YYYY-MM-DD')
    },
    {
      id: 2,
      label: 'За текущий год',
      value: dayjs().startOf('year').format('YYYY-MM-DD')
    },
    {
      id: 3,
      label: 'За предыдущий квартал',
      value: dayjs().subtract(1, 'quarter').startOf('quarter').format('YYYY-MM-DD') + ':' +
        dayjs().subtract(1, 'quarter').endOf('quarter').format('YYYY-MM-DD')
    },
    {
      id: 4,
      label: 'За текущий квартал',
      value: dayjs().startOf('quarter').format('YYYY-MM-DD')
    },
    {
      id: 5,
      label: 'С предыдущего месяца',
      value: dayjs().subtract(1, 'month').startOf('month').format('YYYY-MM-DD')
    },
    {
      id: 6,
      label: 'С предыдущей недели',
      value: dayjs().subtract(7, 'days').startOf('week').format('YYYY-MM-DD')
    }
  ]
};
