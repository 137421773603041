/** @format */

import { Component, OnInit } from '@angular/core';
import { transition, trigger } from "@angular/animations";
import { scaleIn } from "../animations";

@Component({
  selector: 'app-right-bar-advertising-2',
  templateUrl: './right-bar-advertising-2.component.html',
  styleUrls: ['./right-bar-advertising-2.component.scss'],
  animations: [
    trigger('showIn', [
      transition(':enter', scaleIn)
    ])
  ]
})
export class RightBarAdvertising2Component implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
