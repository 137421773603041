/** @format */

import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { Snack } from '../models';

@Injectable()
export class SnackbarService {
  id = 0;
  subject = new Subject<Snack>();

  getObservable(): Observable<Snack> {
    return this.subject.asObservable();
  }

  info(message: string, timeout = 4000): void {
    this.subject.next({ id: this.id++, class: 'bg-color-log-print_cream text-prewrap', message, timeout });
  }

  success(message: string, timeout = 4000): void {
    this.subject.next({ id: this.id++, class: 'bg-color-log-print_success text-prewrap', message, timeout });
  }

  error(message: string, timeout = 8000): void {
    this.subject.next({ id: this.id++, class: 'bg-color-actions_error text-prewrap', message, timeout });
  }
}
