/** @format */

import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import {StepEditForm, StepResume, StepUploadForm, Wizard, WizardService} from '../../core';
import { Router } from '@angular/router';
import { first, map } from 'rxjs/operators';
import { concat, Subscription } from 'rxjs';
import {BooksService, HelpService, SnackbarService} from '../../../core';
import {FormControl, Validators} from "@angular/forms";
import { copy } from 'copy-anything';

@Component({
  selector: 'app-resume',
  templateUrl: './resume.component.html',
  styleUrls: ['./resume.component.scss']
})
export class ResumeComponent implements OnInit, OnDestroy {
  @Output() add = new EventEmitter<void>();
  @Output() remove = new EventEmitter<void>();
  @Output() edit = new EventEmitter<void>();
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() submit = new EventEmitter<void>();

  state?: string;

  resume: StepResume[] = [];

  wizardSubscription$?: Subscription;

  bookStatusMap = {
    0: {
      label: 'черновик',
      icon: 'status-await',
      class: 'text-color-log-print_main-yellow'
    },
    1: {
      label: 'на рассмотрении',
      icon: 'lock',
      class: 'text-color-neutral_gray-2'
    },
    2: {
      label: 'заявка отклонена',
      icon: 'status-fail',
      class: 'text-color-log-print_main-red'
    },
    3: {
      label: 'заявка одобрена',
      icon: 'status-success',
      class: 'text-color-log-print_success2'
    }
  };

  constructor(
    private router: Router,
    private wizardService: WizardService,
    private booksService: BooksService,
    private helpService: HelpService,
    private snackbarService: SnackbarService
  ) {}

  ngOnInit(): void {
    this.wizardSubscription$ = this.wizardService.wizard$.pipe(first()).subscribe({
      next: (wizard: Wizard) => {
        if ('uploadForm' in wizard && 'editForm' in wizard
            // && Object.keys(wizard.uploadForm).length && Object.keys(wizard.editForm).length
        ) {
          const add: any = {
            uploadForm: wizard.uploadForm as StepUploadForm,
            editForm: wizard.editForm as StepEditForm
          };

          const handleBookList = () => {
            if (wizard.resume) {
              const index = wizard.resume.findIndex((book: any) => {
                // method 1
                if (add.editForm.hasOwnProperty('id')) {
                  return book.editForm.id === add.editForm.id;
                }
                // method 2
                if (!Object.keys(add.uploadForm).length && !Object.keys(book.uploadForm).length) {
                  return true;
                }
                // method 3
                const previous: string[] = Object.keys(book.uploadForm);
                const next: string[] = Object.keys(add.uploadForm);
                return previous.some((key: string) => next.includes(key));
              });

              if (index !== -1) {
                const book = wizard.resume[index];

                wizard.resume[index] = {
                  uploadForm: {
                    ...add.uploadForm
                  },
                  editForm: {
                    ...book.editForm,  // fixme?
                    ...add.editForm
                  }
                };

                return wizard.resume;
              }

              // return wizard.resume.concat([add]);
              return [add].concat(wizard.resume);
            }

            return [add];
          };

          this.wizardService.onSetWizard('resume', handleBookList()).subscribe({
            next: (currentWizard: Wizard) => (this.resume = currentWizard.resume),
            error: (error: any) => console.error(error)
          });
        } else if ('resume' in wizard) {
          // @ts-ignore
          this.resume = wizard.resume;
        }
      },
      error: (error: any) => console.error(error)
    });
  }

  ngOnDestroy(): void {
    this.wizardSubscription$?.unsubscribe();
  }

  onEditDraft(step: StepResume): void {  // fixme onEditDraft
    const index = this.wizardService.wizard$.getValue().resume.indexOf(step);
    concat(
      this.wizardService.onSetWizard('uploadForm', copy(step.uploadForm)),
      this.wizardService.onSetWizard('editForm', copy(step.editForm)),
      this.wizardService.onSetWizard('index', index),
    )
      .pipe(first())
      .subscribe({
        next: () => this.edit.emit(),
        error: (error: any) => console.error(error)
      });
  }

  onRemoveDraft(step: StepResume): void {  // fixme onRemoveDraft
    const index = this.wizardService.wizard$.getValue().resume.indexOf(step);
    if (!step.editForm.id) {
      const { resume, ...wizard } = this.wizardService.wizard$.getValue();
      this.resume = resume.filter((book: any) => Object.keys(book.uploadForm).length && Object.keys(book.editForm).length);
      if (this.resume.length) {
        this.wizardService.wizard$.next({
          ...wizard,
          resume: this.resume
        });
      } else {
        this.wizardService.wizard$.next(wizard as Wizard);
        this.remove.emit();
      }
    }
    this.booksService
      .delete(Number(step.editForm.id))
      .pipe(map((response: any) => response.message))
      .subscribe({
        next: (message: string) => {
          this.snackbarService.success(message);

          const { resume, ...wizard } = this.wizardService.wizard$.getValue();

          this.resume = resume.filter((book: any) => {
            const a: string = JSON.stringify(Object.keys(book.uploadForm).join('-'));
            const b: string = JSON.stringify(Object.keys(step.uploadForm).join('-'));

            return a !== b;
          });

          if (this.resume.length) {
            this.wizardService.wizard$.next({
              ...wizard,
              resume: this.resume
            });
          } else {
            this.wizardService.wizard$.next(wizard as Wizard);

            this.remove.emit();
          }
        },
        error: (error: any) => console.error(error)
      });
  }

  onSubmitDraft(draft: StepResume): void {
    const wizard:Wizard = this.wizardService.wizard$.getValue();
    const draftIndex:number | undefined = wizard.resume.indexOf(draft);  // original draft index
    const bookId = draft.editForm.id;  // original draft id
    const body: any = { books: [bookId] };
    this.booksService.createDistribution(body)
      .subscribe({
        next: (message: Record<string, any[]>) => {
          if (!message?.failed || message.failed.includes(bookId)) {
            this.snackbarService.error('Не удалось выполнить операцию');
          }
          else {
            this.snackbarService.success(`Операция выполнена успешно`);
            // Сбрасываем признак "Черновик"
            wizard.resume[draftIndex].editForm.status = 1;
            this.wizardService.onSetWizard('resume', wizard.resume).subscribe({
              next: (currentWizard: Wizard) => (this.resume = currentWizard.resume),
              error: (error: any) => console.error(error)
            });
          }
        },
        error: (error: any) => {
          this.snackbarService.error(
            `Не удалось отправить произведение "${draft.editForm.name}" на оценку инвестиционного комитета. ` +
            'Пожалуйста, обратитесь к менеджеру.', 15000);
        }
      });
  }

  onBooklistRefresh(): void {
    this.booksService.getAll({ withFiles: true }).subscribe({
      next: (data: any) => {
        this.snackbarService.success(`Найдено произведений правообладателя: ${data.totalItems}.\nСтатусы обновлены.`);
        const wizard:Wizard = this.wizardService.wizard$.getValue();
        const list = [] as StepResume[];
        if (data.totalItems) data.items.forEach((item: any) => {
          //"is_imported": 0,
          const uploadForm = {} as Record<string, StepUploadForm>;
          if ('files' in item) {
            Object.keys(item.files).forEach((fileId: string) => {
              const file = item.files[fileId];
              if (file?.fileUrl) {
                const uploadItem = {
                  fileId: file.fileId,
                  file: file.file,
                  fileName: file.fileName,
                  fileExtension: file.fileExtension?.toLowerCase(),
                  fileUrl: file.fileUrl,
                  fileTerms: file.fileTerms === 1,
                } as StepUploadForm;
                uploadForm[uploadItem.fileId] = uploadItem;
              }
            });
          }
          const editForm = {
            status: item.status,
            id: item.id,
            pseudonym: item.pseudonym,
            name: item.title,
            annotation: item.annotation,
            genre: item.genre_id,
            sub_genres: item.sub_genres,
            ageRating: item.age_rating_id,
          } as StepEditForm;
          list.push({uploadForm, editForm} as StepResume);
        });
        this.wizardService.onSetWizard('resume', list).subscribe({
          next: (currentWizard: Wizard) => (this.resume = currentWizard.resume),
          error: (error: any) => console.error(error)
        });
      },
      error: (error: any) => {
        this.snackbarService.error('Ошибка');
      }
    });
  }

  onSubmit(): void {  // onBulkSubmitDraft
    const body: any = {
      books: this.resume.map((stepResume: StepResume) => stepResume.editForm.id)
    };

    this.booksService.createDistribution(body)
      .subscribe({
        next: (message: string) => {
          console.debug(message);
          // this.submit.emit();
        },
        error: (error: any) => console.error(error)
      });
  }

  onShowModalHelp(): void {
    this.helpService.setShowHelpModal({ show: true, title: 'Есть вопрос?' });
  }
}
