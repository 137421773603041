/** @format */

import { Injectable } from '@angular/core';
import { forkJoin, Observable, throwError } from 'rxjs';
import {
  BooksService,
  PostsService,
  NotificationsService,
  BookList,
  NotificationList,
  PostList,
  OffersService,
  OfferList
} from '../../core';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class HomeResolverService {
  constructor(
    private postService: PostsService,
    private bookService: BooksService,
    private notificationService: NotificationsService,
    private offerService: OffersService,
    private router: Router
  ) {}

  resolve(): Observable<[PostList, BookList, NotificationList, OfferList]> {
    return forkJoin([
      this.postService.getAll(),
      this.bookService.getAll(),
      this.notificationService.getAll({
        limit: 5,
        page: 1
      }),
      this.offerService.getAll({
        limit: 10,
        page: 1
      })
    ]).pipe(
      catchError((error: any) => {
        this.router
          .navigateByUrl('/error/' + error.status)
          .then(() => console.debug('Route changed'));

        return throwError(() => new Error('HomeResolverService error'));
      })
    );
  }
}
