/** @format */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpLoaderInterceptor, HttpTokenInterceptor } from './interceptors';
import {
  ApiService,
  AttachmentService,
  AuthGuard,
  AutocompleteService,
  BalanceService,
  BooksService,
  ContractGuard,
  ContractService,
  DkpService,
  CookieService,
  FileService,
  HeaderService,
  HelperService,
  HelpService,
  LocalStorageService,
  NotificationsService,
  OffersService,
  PlatformService,
  PostsService,
  PusherService,
  ReferenceService,
  SnackbarService,
  UserService,
  WelcomeGuard
} from './services';

@NgModule({
  imports: [CommonModule],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpLoaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpTokenInterceptor, multi: true },
    ApiService,
    AttachmentService,
    AuthGuard,
    AutocompleteService,
    BalanceService,
    BooksService,
    ContractService,
    DkpService,
    ContractGuard,
    CookieService,
    FileService,
    HeaderService,
    HelpService,
    HelperService,
    LocalStorageService,
    NotificationsService,
    OffersService,
    PlatformService,
    PostsService,
    PusherService,
    ReferenceService,
    SnackbarService,
    UserService,
    WelcomeGuard
  ],
  declarations: []
})
export class CoreModule {}
