/** @format */

import { AfterViewInit, Component, ElementRef, Inject, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { fromEvent, Observable, Subscription, timer } from 'rxjs';
import { delay, first, map, take, tap } from 'rxjs/operators';
import { HelperService, PlatformService, SnackbarService, User, UserService } from '../../core';
import { IntlTelInputService, intlTelInput, CountryData } from '../../core/services/intl-tel-input-utils.service';

@Component({
  selector: 'app-auth-sms',
  templateUrl: './auth-sms.component.html',
  styleUrls: ['./auth-sms.component.scss']
})
export class AuthSmsComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('phoneInternational') phoneInternational!: ElementRef;
  phoneCountryData?: CountryData;
  phoneExampleCountyNumber?: string;

  documentActiveElement: Element | null = null;

  phoneForm: FormGroup;
  phoneFormIsSubmitting = false;
  phoneFormIsDone = false;

  smsForm: FormGroup;
  smsFormIsSubmitting = false;
  smsFormIsDone = false;

  countdown$: Observable<number> | undefined;
  count = 2 * 60;

  constructor(
    @Inject(DOCUMENT)
    private document: Document,
    private title: Title,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private platformService: PlatformService,
    private elementRef: ElementRef,
    private snackbarService: SnackbarService,
    private helperService: HelperService,
    private formBuilder: FormBuilder,
    public phoneUtils: IntlTelInputService
  ) {
    this.phoneForm = this.formBuilder.group({
      phone: ['', [Validators.required]]
    });
    this.smsForm = this.formBuilder.group({
      method: ['flashCall'],
      sms: ['', [Validators.required]]
    });
  }

  ngOnInit(): void {
    this.title.setTitle('Подтверждение номера телефона');

    this.documentActiveElement = this.document.activeElement;
  }

  ngOnDestroy(): void {
    // [
    // ].forEach($ => $?.unsubscribe());
  }

  ngAfterViewInit(): void {
    // if (this.platformService.isBrowser()) {
    // }
  }

  onSubmitPhoneForm(): void {
    if (!this.helperService.getFormValidation(this.phoneForm)) {
      return;
    }

    // const countryData: CountryData = this.internationalPhoneInput.getSelectedCountryData();
    const countryData = this.phoneCountryData;
    const body: any = {
      ...this.phoneForm.value,
      country: countryData?.iso2,
      method: this.smsForm.controls.method.value,
      token: this.route.snapshot.queryParamMap.get('token')
    };
    body.phone = body.phone.replaceAll(/\D/g, '');
    this.phoneFormIsSubmitting = true;
    this.userService.postSMS(body)
      .subscribe({
        next: () => {
          this.phoneFormIsDone = true;

          this.snackbarService.success('Ожидайте входящего вызова...', 5000);

          this.countdown$ = timer(0, 1000).pipe(
            map(i => this.count - i),
            take(this.count + 1)
          );

          this.smsForm.patchValue({ sms: '' });

          this.phoneFormIsSubmitting = false;
        },
        error: () => (this.phoneFormIsSubmitting = false)
      });
  }

  onSubmitSMSForm(): void {
    if (!this.helperService.getFormValidation(this.phoneForm)) {
      return
    }

    // const countryData: CountryData = this.internationalPhoneInput.getSelectedCountryData();
    const countryData = this.phoneCountryData;
    const body: any = {
      ...this.phoneForm.value,
      country: countryData?.iso2,
      code: this.smsForm.controls.sms.value,
      method: this.smsForm.controls.method.value,
      token: this.route.snapshot.queryParamMap.get('token')
    };
    body.phone = body.phone.replaceAll(/\D/g, '');
    this.smsFormIsSubmitting = true;
    this.userService.postVerification(body)
      .pipe(
        tap(() => {
          this.smsFormIsDone = true;

          this.snackbarService.success('Ваш личный кабинет загружается...', 5000);
        }),
        delay(5000)
      )
      .subscribe({
        next: (user: User) => {
          // if (user.is_imported) {
          //   this.userService.setAuthorization(user);
          // }

          this.userService.setAuthorization(user);

          this.router
            .navigate([user.cabinetAvailable ? '/office' : '/welcome'])
            .then(() => this.snackbarService.success('Телефон подтвержден', 4000));
        },
        error: () => (this.smsFormIsSubmitting = false)
      });
  }
}
