/** @format */

import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { Post, PostList } from '../models';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PostsService {
  constructor(private apiService: ApiService) {}

  getAll(params?: any): Observable<PostList> {
    return this.apiService.get('/v1/posts', params).pipe(map((data: any) => data.data));
  }

  getById(id: string|number): Observable<Post> {
    return this.apiService.get(`/v1/posts/${id}`).pipe(map((data: any) => data.data));
  }
}
