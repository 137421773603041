/** @format */

import { Injectable } from '@angular/core';
import { FormGroup, ValidationErrors } from '@angular/forms';
import { SnackbarService } from './snackbar.service';
import { UserService } from './user.service';
import { User } from '../models';
import { switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import intlTelInput from 'intl-tel-input';

@Injectable()
export class HelperService {
  constructor(private snackbarService: SnackbarService, private userService: UserService) {}

  getPlural(list: string[], n: number): string {
    let index = Math.abs(n % 100);

    if (index >= 5 && index <= 20) {
      return list[2];
    }

    index %= 10;

    if (index === 1) {
      return list[0];
    }

    if (index >= 2 && index <= 4) {
      return list[1];
    }

    return list[2];
  }

  getRandomInt(max: number = 10, min: number = 0): number {
    return Math.floor(Math.random() * (Math.floor(max) - Math.ceil(min) + 1)) + Math.ceil(min);
  }

  getFormValidation(form: FormGroup): boolean {
    if (form.valid) {
      return true;
    }

    Object.keys(form.controls).forEach((control: string) => {
      // @ts-ignore
      return form.get(control).markAsTouched({ onlySelf: true });
    });

    return false;
  }

  getFileValidation(file: File, mimeTypes: string[], size: number = 10): boolean {
    const maxSize = 1000 * 1000 * size;

    const isValidMime = mimeTypes.includes(file.type);
    const isValidSize = file.size <= maxSize;

    if (!isValidMime || !isValidSize) {
      const errors: string[] = [];
      if (!isValidMime) {
        errors.push(`неподдерживаемый формат (${file.type})`);
      }
      if (!isValidSize) {
        errors.push(`превышен допустимый размер (${size} МБ)`);
      }
      this.snackbarService.error(`Файл "${file.name}":\n- ${errors.join('\n- ')}`, 15000);
    }

    return isValidMime && isValidSize;
  }

  getCustomValidation(customValidation: string, payload?: any): any {
    switch (customValidation) {
      case 'atLeastOne': {
        /**
         * Implement of
         * https://stackoverflow.com/questions/40321033/angular2-formbuilder-validators-require-at-least-one-field-in-a-group-to-be-fil
         */
        return (group: FormGroup): ValidationErrors | null => {
          const hasAtLeastOne =
            group &&
            group.controls &&
            Object.keys(group.controls).some(k => !payload(group.controls[k]));

          return hasAtLeastOne ? null : { atLeastOne: true };
        };
      }
      case 'isSame': {
        return (formGroup: FormGroup): ValidationErrors | null => {
          const { left, right }: { left: string; right: string } = payload;

          if (formGroup.get(left)?.value !== formGroup.get(right)?.value) {
            return { isSame: false };
          }

          return null;
        };
      }
      default: {
        return {};
      }
    }
  }

  getInternationalPhoneInputConfiguration(): Observable<intlTelInput.Options> {
    return this.userService.currentUser.pipe(
      switchMap((user: User) => {
        return of({
          // utilsScript: 'https://cdn.jsdelivr.net/npm/intl-tel-input@17.0.3/build/js/utils.js',
          initialCountry: user?.country_code || 'ru',
          preferredCountries: ['ru', 'az', 'am', 'by', 'kg', 'kz', 'md', 'tj', 'tm', 'uz', 'ua'],
          customContainer: 'relative',
          separateDialCode: false,
        });
      })
    );
  }
}
