/** @format */

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OfficeHelpComponent } from './office-help.component';
import { OfficeHelpFormComponent } from './form/form.component';
import { OfficeHelpSaleInStoresComponent } from './sale-in-stores/sale-in-stores.component';
import { OfficeHelpSocialComponent } from "./social/social.component";

const routes: Routes = [
  {
    path: '',
    component: OfficeHelpComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'form'
      },
      {
        path: 'form',
        component: OfficeHelpFormComponent,
        data: {
          breadcrumb: {
            label: 'Обратная связь',
            routerLink: '/office/help/form'
          },
          animation: 'officeHelpFormPage'
        }
      }
    ]
  },
  {
    path: 'sale-in-stores',
    component: OfficeHelpSaleInStoresComponent,
    data: {
      breadcrumb: {
        label: 'Что предлагает RUGRAM?',
        routerLink: '/office/help/sale-in-stores'
      },
      animation: 'officeHelpSaleInStoresPage'
    }
  },
  {
    path: 'social',
    component: OfficeHelpSocialComponent,
    data: {
      breadcrumb: {
        label: 'Мы в соцсетях',
        routerLink: '/office/help/social'
      },
      animation: 'OfficeHelpSocialPage'
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OfficeHelpRoutingModule {}
