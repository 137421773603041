<div class="box mt-3">
	<app-tooltip [appClassList]="['right']" class="sm">
		<div slot="toggle" class="d-flex jc-center">
			<a
				[href]="href"
				[target]="target"
			>
				<button class="btn-hover-radial"></button>
			</a>
		</div>
		<div slot="target">
			<span class="d-block">Попробуйте создать обложку для своей книги при помощи нейросети</span>
		</div>
	</app-tooltip>
	<app-tooltip [appClassList]="['bottom']" class="md">
		<div slot="toggle">
			<a
				[href]="href"
				[target]="target"
			>
				<button class="btn-hover gradient-1">Попробуйте</button>
			</a>
		</div>
		<div slot="target">
			<span class="d-block">создать обложку для своей книги при помощи нейросети</span>
		</div>
	</app-tooltip>
</div>
