<!-- @format -->

<section class="box d-flex jc-between ai-start">
	<div class="posts">
		<app-carousel-posts
			class="d-block mb-3"
			*ngIf="posts.length"
			[appPosts]="posts"
		></app-carousel-posts>
	</div>
	<div class="contracts">
		<ng-container *ngIf="!isImported">
			<app-contract-initial
				class="d-block mb-3"
				*ngIf="!hasContract"
			></app-contract-initial>
			<app-contract-sign
				class="d-block mb-3"
				*ngIf="hasContract && !hasContractSigned"
			></app-contract-sign>
		</ng-container>
	</div>
	<div class="offers">
		<ng-container *ngIf="!(!hasContract || (hasContract && !hasContractSigned))">
			<ul class="d-flex fd-column mx-0 mt-0 mb-3 p-0" *ngIf="offers.length">
				<li
					*ngFor="let offer of offers"
					class="d-flex"
					appOfferInitial
					[appOffer]="offer"
					(transfer)="onOfferTransfer($event)"
				></li>
			</ul>
		</ng-container>
	</div>
	<div class="books mb-3 p-3 bg-color-neutral_white rounded-sm bordered">
		<div class="d-flex jc-between ai-center">
			<h3 class="text-color-log-print_text text-bold m-0">Мои книги</h3>
			<a class="link-to-list d-flex ai-center" [routerLink]="'books'">
				Все книги
				<app-svg-icon
					class="d-block ml-h"
					appIcon="arrow-right-with-body--violet"
					appWidth="20"
					appHeight="14"
					appViewBox="0 0 16 8"
				></app-svg-icon>
			</a>
		</div>
		<ul class="book-list d-flex jc-between pl-0 mt-3">
			<li class="d-flex" appCardBook *ngFor="let book of books" [appBook]="book"></li>
			<li class="d-flex" appCardBook></li>
		</ul>
	</div>
	<div class="docs mb-3 p-3 bg-color-neutral_white rounded-sm bordered">
		<div class="d-flex jc-between ai-center">
			<h3 class="text-color-log-print_text text-bold m-0">Мои документы</h3>
			<a class="link-to-list d-flex ai-center" [routerLink]="'settings'">
				Все документы
				<app-svg-icon
						class="d-block ml-h"
						appIcon="arrow-right-with-body--violet"
						appWidth="20"
						appHeight="14"
						appViewBox="0 0 16 8"
				></app-svg-icon>
			</a>
		</div>
	</div>
	<div class="notifications mb-3 p-3 bg-color-neutral_white rounded-sm bordered">
		<div class="d-flex jc-between ai-center">
			<h3 class="text-color-log-print_text text-bold m-0">Мои уведомления</h3>
			<a class="link-to-list d-flex ai-center" [routerLink]="'notifications'">
				Все уведомления
				<app-svg-icon
					class="d-block ml-h"
					appIcon="arrow-right-with-body--violet"
					appWidth="20"
					appHeight="14"
					appViewBox="0 0 16 8"
				></app-svg-icon>
			</a>
		</div>
		<div class="d-flex fd-column mt-3"
			*ngIf="notifications.length; else notificationsEmpty"
			[class.pointer-n]="notificationsPaginationIsSubmitted"
		>
			<ul class="d-flex fd-column m-0 p-0" [class.opacity-h]="notificationsPaginationIsSubmitted">
				<li
					class="d-flex"
					*ngFor="let notification of notifications"
          appListNotification
					[appNotification]="notification"
					[appNotificationGrid]="'home'"
					(callManager)="onShowModalHelp()"
					(offerTransfer)="onOfferTransfer($event)"
				></li>
			</ul>
			<app-list-pagination
				class="notifications-pagination d-block mt-3"
				*ngIf="notificationsTotal > notificationsLimit"
				[appPage]="notificationsPage"
				[appLimit]="notificationsLimit"
				[appTotal]="notificationsTotal"
				(selectPage)="onSelectNotificationsPage($event)"
				(selectLimit)="onSelectNotificationsLimit($event)"
			></app-list-pagination>
		</div>
	</div>
	<div class="advertising d-flex fd-column">
		<!--<app-right-bar-social class="d-block"></app-right-bar-social>-->
		<app-right-bar-advertising-litcovers class="d-block"></app-right-bar-advertising-litcovers>
		<app-right-bar-advertising-1 class="d-block"></app-right-bar-advertising-1>
		<app-right-bar-advertising-2 class="d-block"></app-right-bar-advertising-2>
		<app-right-bar-advertising-3 class="d-block"></app-right-bar-advertising-3>
    <div class="empty-card"></div>
	</div>
	<app-modal *ngIf="modalOfferToggle" (closed)="modalOfferToggle = false">
		<app-offer-sign
			*ngIf="offerDetailMap"
			[appOfferMap]="offerDetailMap"
			(closed)="modalOfferToggle = false"
		></app-offer-sign>
	</app-modal>
	<ng-template #notificationsEmpty>
		<div class="notifications-empty rounded-md bordered p-3 mt-1">
			<app-svg-icon
				class="d-block mr-3"
				appIcon="emoji-sad"
				appWidth="40"
				appHeight="40"
				appViewBox="0 0 40 40"
			></app-svg-icon>
			<div class="d-flex fd-column">
				<span class="d-block text-semibold h5 mb-h">Не найдено уведомлений</span>
				<p class="d-block m-0">Они обязательно появятся через некоторое время.</p>
			</div>
		</div>
	</ng-template>
</section>
