<!-- @format -->

<section
	class="box d-flex fd-column ai-center jc-start"
	[class.success]="state === State.success"
>
	<app-snackbar></app-snackbar>
	<div class="content pt-7">
		<header class="d-flex fd-column ai-center jc-start mb-6">
			<a [routerLink]="'/'" class="mb-3">
				<img class="logo d-block" src="/assets/logo.png" alt="RUGRAM" />
			</a>
			<h3 class="text-bold text-center">
				Технологическое издательство нового типа.
			</h3>
			<p class="h3 text-center px-5 mb-0">
				Пришлите нам свою рукопись и откройте для себя все возможности платформы Rugram.
			</p>
		</header>
		<main class="d-block">
			<ng-container [ngSwitch]="true">
				<div class="loader rounded-md bordered py-9 px-7" *ngSwitchDefault>
					<app-loader class="d-inline-block" [appIsLoading]="true"></app-loader>
				</div>
				<app-form-book-draft *ngSwitchCase="state === State.editForm"
					(submit)="onUpdateWizard($event)"
				    (cancel)="onCancelWizard($event)"
				></app-form-book-draft>
				<app-resume *ngSwitchCase="state === State.resume"
					(add)="onStateChange(State.editForm)"
					(edit)="onStateChange(State.editForm)"
					(remove)="onStateChange(State.editForm)"
					(submit)="onStateChange(State.success)"
				></app-resume>
				<app-success *ngSwitchCase="state === State.success"
					(resume)="onStateChange(State.resume)"
				></app-success>
			</ng-container>
		</main>
	</div>
	<div class="welcome-footer">
    <ng-container [ngTemplateOutlet]="welcomeFooter"></ng-container>
  </div>
</section>
<ng-template #welcomeFooter>
  <ul class="mb-3">
    <li class="h5 text-d-underline">
      <a
        href="/assets/download/user_agreement.docx"
        class="text-bold text-color-log-print_main-violet"
        download
      >
        Пользовательское соглашение
      </a>
    </li>
    <li class="h5 text-d-underline">
      <a
        href="/assets/download/privacy_policy.docx"
        class="text-bold text-color-log-print_main-violet"
        download
      >
        Политика конфиденциальности
      </a>
    </li>
    <li class="h5 text-d-underline">
      <a
        href="/assets/download/cookie.docx"
        class="text-bold text-color-log-print_main-violet"
        download
      >
        Согласие cookie
      </a>
    </li>
  </ul>
  <a class="logo mb-1" href="/">
    <img class="d-block" src="/assets/logo.png" alt="RUGRAM" />
  </a>
  <p class="h5 mb-1">© RUGRAM. Все права защищены.</p>
  <p class="h5 text-center mb-1">
    РФ, 109147, г. Москва, ул. Большая Андроньевская, д.23,<br>
    помещ. 1, 5 этаж, ком. кабинет 32
  </p>
  <div class="contact d-flex jc-center fd-row mb-1">
    <span class="h5 text-center">ИНН: 9709073816</span>
    <span class="h5 text-center">Телефон: 8 (495) 259-62-06</span>
    <span class="h5 text-center">Генеральный директор: Левченко В.А.</span>
  </div>
  <a
    class="text-bold text-color-log-print_main-violet mb-1"
    href="mailto:info@rugram.me"
  >
    info@rugram.me
  </a>
  <div class="d-flex ai-center mt-2">
    <a class="d-block" href="https://dzen.ru/k_slovu_rugram" target="_blank">
      <app-svg-icon
        class="d-block"
        appIcon="service-moderation"
        appWidth="23"
        appHeight="22"
        appViewBox="0 0 19 18"
      ></app-svg-icon>
    </a>
    <a class="d-block ml-4" href="https://www.youtube.com/channel/UCaceSNtwjQh_N4gNzQlxdzw/" target="_blank">
      <app-svg-icon
        class="d-block"
        appIcon="service-audio-book"
        appWidth="21"
        appHeight="21"
        appViewBox="0 0 21 21"
      ></app-svg-icon>
    </a>
    <a class="d-block ml-4" href="https://vk.com/rugram_books" target="_blank">
      <app-svg-icon
        class="d-block"
        appIcon="vk"
        appWidth="33"
        appHeight="19"
        appViewBox="0 0 22 13"
      ></app-svg-icon>
    </a>
    <a class="d-block ml-4" href="https://t.me/rugram_me" target="_blank">
      <app-svg-icon
        class="d-block"
        appIcon="telegram"
        appWidth="22"
        appHeight="19"
        appViewBox="0 0 22 19"
      ></app-svg-icon>
    </a>
  </div>
</ng-template>
