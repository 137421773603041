/** @format */

import { environment } from '../../environments/environment';
import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription, combineLatest } from 'rxjs';
import {
  APP_CONFIG,
  Configuration,
  CookieService,
  HelpService,
  PlatformService,
  UA_INFO,
  UAInfo,
  UserService
} from '../core';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit, OnDestroy {
  public user$!: Subscription;
  public grantedSubject!: string;
  public landingOrigin!: string;
  public appVersion = environment.appVersion;

  constructor(
      @Inject(APP_CONFIG) private configuration: Configuration,
      @Inject(UA_INFO) public ua: UAInfo,
      private userService: UserService,
      private cookieService: CookieService,
      private router: Router,
      private platformService: PlatformService,
      private helpService: HelpService
  ) {
    this.landingOrigin = this.configuration.landingOrigin;
  }

  ngOnInit(): void {
    this.user$ = combineLatest([
      this.userService.currentUser,
      this.userService.currentWelcomeWizardUser
    ]).subscribe({
      next: ([rugramOfficeUser, rugramWelcomeWizardUser]) => {
        // console.error('rugramOfficeUser', rugramOfficeUser);
        // console.error('rugramWelcomeWizardUser', rugramWelcomeWizardUser);
        if (rugramOfficeUser && rugramOfficeUser.hasOwnProperty('id')) {
          this.grantedSubject = 'office';
        }
        else if (rugramWelcomeWizardUser && rugramWelcomeWizardUser.hasOwnProperty('id')) {
          this.grantedSubject = 'welcomeWizard';
        }
        else {
          this.grantedSubject = '';
        }
      },
      error: (error: any) => console.error(error)
    });
  }

  ngOnDestroy(): void {
    [this.user$].forEach($ => $?.unsubscribe());
  }

  onLogout(): void {
    const currentUrl = this.router.url;
    this.userService.getLogout({ flag: 'this' }).subscribe({
      next: () => this.router.navigate([currentUrl], {skipLocationChange: true}).then(() => console.debug('Route changed')),
      error: (error: any) => console.error(error)
    });
  }

  appReload(): boolean {
    this.platformService.getWindow().location.reload();
    return false;
  }

  onShowModalHelp(): void {
    this.helpService.setShowHelpModal({ show: true, title: 'Есть вопрос?' });
  }
}
