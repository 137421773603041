/** @format */

import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { BrowserModule } from '@angular/platform-browser';
import { AuthModule } from './auth/auth.module';
import { CoreModule } from './core';
import { ErrorModule } from './error/error.module';
import { LandingModule } from './landing/landing.module';
import { OfficeModule } from './office/office.module';
import { SharedModule } from './shared';
import { WelcomeModule } from './welcome2/welcome.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    AuthModule,
    CoreModule,
    CoreModule,
    ErrorModule,
    LandingModule,
    OfficeModule,
    SharedModule,
    WelcomeModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {}
