/** @format */

import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared';
import { OfficeServiceRoutingModule } from './office-service-routing.module';
import { OfficeServiceComponent } from './office-service.component';
import { OfficeServiceAudiobookComponent } from './audiobook/audiobook.component';
import { OfficeServiceContractComponent } from './contract/contract.component';
import { OfficeServiceDkpComponent } from "./dkp/dkp.component";
import { OfficeServiceDistributionComponent } from './distribution/distribution.component';
import { OfficeServiceEbookComponent } from './ebook/ebook.component';
import { OfficeServicePrintForSelfComponent } from './print-for-self/print-for-self.component';
import {
  ModalPaymentStatusComponent,
  ServicesCarouselComponent,
  ServicesProgressComponent,
  ServicesReceiptComponent,
  ServicesAccessMessageComponent,
  ServicesTermsComponent,
  ServicesTermsPaymentComponent
} from './shared';
import { DpDatePickerModule } from 'ng2-date-picker';
import {
  AudiobookService,
  DeliveryService,
  DistributionService,
  EbookService,
  PrintForSelfService
} from './core';

@NgModule({
  imports: [SharedModule, OfficeServiceRoutingModule, DpDatePickerModule],
  providers: [
    AudiobookService,
    DeliveryService,
    DistributionService,
    EbookService,
    PrintForSelfService
  ],
  declarations: [
    ModalPaymentStatusComponent,
    OfficeServiceComponent,
    OfficeServiceAudiobookComponent,
    OfficeServiceContractComponent,
    OfficeServiceDkpComponent,
    OfficeServiceDistributionComponent,
    OfficeServiceEbookComponent,
    OfficeServicePrintForSelfComponent,
    ServicesAccessMessageComponent,
    ServicesCarouselComponent,
    ServicesProgressComponent,
    ServicesReceiptComponent,
    ServicesAccessMessageComponent,
    ServicesTermsComponent,
    ServicesTermsPaymentComponent
  ]
})
export class OfficeServiceModule {}
