<!-- @format -->

<div class="box w-full">
	<a *ngIf="book; else addBook" class="inner d-flex ai-center"
     [routerLink]="['/office/books', book.id]"
  >
		<figure class="cover position-r m-0 p-1">
			<span *ngIf="book.notifications_count" class="d-flex ai-center jc-center h8">
				{{ book.notifications_count }}
			</span>
			<img
				class="d-block min-w-[61px] min-h-[84px] max-w-[61px] max-h-[84px] rounded-sm overflow-h"
				*ngIf="book.image; else bookPlaceholder"
				[src]="book.image"
				[alt]="book.title"
			/>
		</figure>
		<div class="ml-h">
			<div class="link-to-detail h6 line-clamp-3">
				{{ book.title }}
			</div>
			<div class="h7 text-color-neutral_gray-2 line-height-2">
				{{ book.genre }} {{ book.age_rating }}
			</div>
			<div class="h7 text-color-neutral_gray-2 line-height-2">
				Добавлена: {{ book.created_at | dayjs: "format":"DD.MM.YYYY" }}
			</div>
		</div>
	</a>
	<ng-template #bookPlaceholder>
		<app-svg-icon
			class="d-block rounded-sm overflow-h"
			appIcon="book-cover"
			appWidth="61"
			appHeight="84"
			appViewBox="0 0 116 160"
		></app-svg-icon>
	</ng-template>
	<ng-template #addBook>
		<a
			[routerLink]="'/office/books/create'"
			class="inner dashed d-flex ai-center rounded-md"
		>
			<div class="cover p-1">
				<app-svg-icon
					class="d-block rounded-sm overflow-h"
					appIcon="add-book-placeholder"
					appWidth="61"
					appHeight="84"
					appViewBox="0 0 61 84"
				></app-svg-icon>
			</div>
			<p class="text-color-neutral_dark h6 ml-1 mb-0">Создать книгу</p>
		</a>
	</ng-template>
</div>
