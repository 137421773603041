<!-- @format -->

<div class="box bg-color-neutral_white rounded-md bordered p-7">
	<ng-container [ngSwitch]="progressState">
		<ng-container *ngSwitchCase="'init'">
			<h1 class="mb-5">Установка пароля для учетной записи</h1>
			<form [formGroup]="confirmForm" (ngSubmit)="onSubmitConfirmForm()">
				<fieldset [disabled]="confirmIsSubmitting">
					<fieldset
						class="input i-end d-flex fd-row jc-between ai-center mb-1"
						*ngIf="{value: false} as isVisible"
					>
						<label class="d-block h5" for="password">Новый пароль:</label>
						<input
							id="password"
							[type]="isVisible.value ? 'text' : 'password'"
							formControlName="password"
						/>
						<app-svg-icon
							class="password"
							[appIcon]="isVisible.value ? 'eye-off' : 'eye'"
							[appWidth]="24"
							[appHeight]="24"
							[appViewBox]="'0 0 24 24'"
							(click)="isVisible.value = !isVisible.value"
						></app-svg-icon>
					</fieldset>
					<fieldset
						class="input i-end d-flex fd-row jc-between ai-center mb-1"
						*ngIf="{value: false} as isVisible"
					>
						<label class="d-block h5" for="password_confirmation">
							Повторите пароль:
						</label>
						<input
							id="password_confirmation"
							[type]="isVisible.value ? 'text' : 'password'"
							[class.ng-invalid]="confirmForm.errors?.match === false"
							formControlName="password_confirmation"
						/>
						<app-svg-icon
							class="password"
							[appIcon]="isVisible.value ? 'eye-off' : 'eye'"
							[appWidth]="24"
							[appHeight]="24"
							[appViewBox]="'0 0 24 24'"
							(click)="isVisible.value = !isVisible.value"
						></app-svg-icon>
					</fieldset>
					<a class="extra d-block h7 text-d-underline mb-5" [routerLink]="'/auth/login'">
						Вернуться
					</a>
					<button
						class="btn btn-style-1 btn-inline lg text-semibold w-1/2 rounded-md px-9"
						[disabled]="confirmForm.invalid || confirmIsSubmitting"
					>
						Задать пароль
					</button>
				</fieldset>
			</form>
		</ng-container>
		<ng-container *ngSwitchCase="'sms'">
			<h1 class="mb-5">Подтвердите номер телефона</h1>
			<div class="d-flex fd-column ai-center mb-5" *ngIf="phoneFormIsSending">
				<img
					class="call-img mb-1"
					src="assets/images/callsend_mobile.png"
					alt="Входящий звонок"
				/>
				<p class="h5">
					Сейчас на ваш номер поступит входящий звонок. Введите 4 последние цифры номера
					входящего звонка.
				</p>
			</div>
			<form [formGroup]="phoneForm">
				<fieldset [disabled]="phoneIsSubmitting">
					<fieldset
						class="input d-flex fd-row jc-between ai-center mb-1"
						[disabled]="countdown$ | async"
					>
						<label class="d-block h5" for="phone">Телефон</label>
						<input
							id="phone"
							formControlName="phone"
							[prefix]="'&nbsp;&nbsp;'"
							[mask]="'9{15}'"
						/>
					</fieldset>
				</fieldset>
			</form>
			<ng-container *ngIf="phoneFormIsSending">
				<form [formGroup]="smsForm">
					<fieldset [disabled]="phoneIsSubmitting">
						<fieldset
							class="input d-flex fd-row jc-between ai-center mb-1"
							[class.invalid]="sms.invalid && sms.touched"
							*ngIf="smsForm.get('sms') as sms"
						>
							<label class="d-block h5" for="sms">Цифры:</label>
							<input
								id="sms"
								[class.invalid]="sms.invalid && sms.touched"
								formControlName="sms"
								mask="0000"
							/>
						</fieldset>
					</fieldset>
				</form>
				<div class="sending mb-1">
					<span class="text-color-neutral_gray-2" *ngIf="countdown$ | async as countdown">
						Позвонить повторно через {{ countdown }}
						{{ countdown | plural: "секунду ":"секунды":"секунд" }}
					</span>
					<span
						class="text-color-log-print_main-violet cursor-p"
						*ngIf="!(countdown$ | async)"
						(click)="onSubmitPhoneForm()"
					>
						Позвонить повторно
					</span>
				</div>
			</ng-container>
			<div class="submit" [ngSwitch]="phoneFormIsSending">
				<button
					class="btn btn-style-1 btn-inline lg text-semibold rounded-md px-9 mt-3"
					[disabled]="phoneForm.invalid"
					*ngSwitchCase="false"
					(click)="onSubmitPhoneForm()"
					data-cy="sendSms"
				>
					Подтвердить
				</button>
				<button
					class="btn btn-style-1 btn-inline lg text-semibold rounded-md px-9 mt-3"
					[disabled]="smsForm.invalid"
					*ngSwitchCase="true"
					(click)="onSubmitSMSForm()"
					data-cy="codeConfirmation"
				>
					Подтвердить
				</button>
			</div>
		</ng-container>
	</ng-container>
</div>
