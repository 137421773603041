<!-- @format -->

<section class="box">
  <div class="section-container">
    <!--К слову-->
    <section class="section palette-white vertical-section">
      <div class="card">
        <figure class="flex justify-center">
          <img src="/assets/images/social/k_slovu.jpg" alt="( к слову )">
        </figure>
        <small class="tip">Прокрутите вниз, чтобы увидеть больше</small>
      </div>
    </section>
    <section class="section palette-white horizontal-section">
      <div class="panel">
        <div class="card">
          <div>
            Номинацию "Открытие года" заслуженно вручаем
            Telegram-каналу <strong>( к слову )</strong>
          </div>
        </div>
      </div>
      <div class="panel">
        <div class="card">
          «В начале было слово».
        </div>
      </div>
      <div class="panel">
        <div class="card">
          А далее были лучшие книги,
          выгодные цены,
          прогрессивный сервис
          и вдохновляющие читатели!
        </div>
      </div>
      <div class="panel">
        <div class="card">
          <div>
            Какие преимущества
            дает Telegram-канал <strong>( к слову )</strong>
            автору?
          </div>
        </div>
      </div>
      <div class="panel">
        <div class="card">
          Возможность привлечь новых читателей!
        </div>
      </div>
      <div class="panel">
        <div class="card">
          Возможность предложить книгу
          по самой доступной цене!
        </div>
      </div>
      <div class="panel">
        <div class="card">
          Возможность укрепить связь с читателями
          за счет подарков и товаров с символикой произведения
          или другими эксклюзивными предметами мерча!
        </div>
      </div>
      <div class="panel">
        <div class="card">
          <div class="grow w-full flex flex-col gap-4 justify-center items-center mt-8">
            Приглашаем к взаимовыгодному сотрудничеству!
            <button class="btn btn-style-1 btn-inline lg text-semibold rounded-md px-5"
              [routerLink]="['/office/help/form']"
            >Обратная связь</button>
          </div>
          <small class="tip">Прокрутите вниз, чтобы увидеть больше</small>
        </div>
      </div>
    </section>
    <!--Что скажете?-->
    <section class="section palette-lightsmoke vertical-section">
      <div class="card">
        <div class="grow w-full flex flex-col gap-4 justify-center items-center mt-8">
          <span>Telegram-канал</span>
          <span>( Что скажете? | RUGRAM )</span>
        </div>
        <small class="tip">Прокрутите вниз, чтобы увидеть больше</small>
      </div>
    </section>
    <section class="section palette-lightsmoke horizontal-section">
      <div class="panel">
        <div class="card">
          Уникальное место встречи читателя с автором и издателем
        </div>
      </div>
      <div class="panel">
        <div class="card">
          Обмениваемся мнениями!
        </div>
      </div>
      <div class="panel">
        <div class="card">
          Делимся личным!
        </div>
      </div>
      <div class="panel">
        <div class="card">
          По поводу и без!
        </div>
      </div>
      <div class="panel">
        <div class="card">
          <div class="grow w-full flex flex-col gap-4 justify-center items-center mt-8">
            <svg xmlns="http://www.w3.org/2000/svg" width="283" height="95">
              <use href="#tg-logo"></use>
            </svg>
            <div>Что скажете?</div>
            <a class="btn btn-style-1 btn-inline lg text-semibold rounded-md px-5"
               href="https://t.me/+TauSHqa3rR4wYTYy" target="_blank"
            >Присоединиться!</a>
          </div>
          <small class="tip">Прокрутите вниз, чтобы увидеть больше</small>
        </div>
      </div>
    </section>
    <!--Дзен-->
    <section class="section palette-lightblue vertical-section">
      <div class="card">
        <div class="grow w-full flex flex-col gap-4 justify-center items-center mt-8">
          ( Дзен )
        </div>
        <small class="tip">Прокрутите вниз, чтобы увидеть больше</small>
      </div>
    </section>
    <section class="section palette-lightblue horizontal-section">
      <div class="panel">
        <div class="card">
          Любимые авторы в малых жанрах и "острых" формах!
        </div>
      </div>
      <div class="panel">
        <div class="card">
          Для читателя: прекрасная возможность "зацепиться" за "своего" автора
        </div>
      </div>
      <div class="panel">
        <div class="card">
          Для автора: хорошая возможность нарастить базу подписчиков
        </div>
      </div>
      <div class="panel">
        <div class="card">
          <div class="grow w-full flex flex-col gap-4 justify-center items-center mt-8">
            <svg xmlns="http://www.w3.org/2000/svg" width="112" height="112">
              <use href="#zen-logo"></use>
            </svg>
            <div>Наш Дзен</div>
            <a class="btn btn-style-1 btn-inline lg text-semibold rounded-md px-5"
               href="https://dzen.ru/k_slovu_rugram?tab=articles" target="_blank"
            >Перейти</a>
          </div>
          <small class="tip">Прокрутите вниз, чтобы увидеть больше</small>
        </div>
      </div>
    </section>
    <!--Вконтакте-->
    <section class="section palette-beige vertical-section">
      <div class="card">
        <div class="grow w-full flex flex-col gap-4 justify-center items-center mt-8">
          ( Вконтакте )
        </div>
        <small class="tip">Прокрутите вниз, чтобы увидеть больше</small>
      </div>
    </section>
    <section class="section palette-beige horizontal-section">
      <div class="panel">
        <div class="card">
          Для тех, кто выбирает для ежедневного использования эту социальную сеть
        </div>
      </div>
      <div class="panel">
        <div class="card">
          Листайте нас под утренний кофе!
        </div>
      </div>
      <div class="panel">
        <div class="card">
          <div class="grow w-full flex flex-col gap-4 justify-center items-center mt-8">
            <svg xmlns="http://www.w3.org/2000/svg" width="96" height="96">
              <use href="#vk-logo"></use>
            </svg>
            <div>RUGRAM Вконтакте</div>
            <a class="btn btn-style-1 btn-inline lg text-semibold rounded-md px-5"
               href="https://vk.com/rugram_books" target="_blank"
            >Перейти</a>
          </div>
          <small class="tip">Прокрутите вниз, чтобы увидеть больше</small>
        </div>
      </div>
    </section>
    <!--Youtube-->
    <section class="section palette-antiquewhite vertical-section">
      <div class="card">
        <div class="grow w-full flex flex-col gap-4 justify-center items-center mt-8">
          ( Youtube )
        </div>
        <small class="tip">Прокрутите вниз, чтобы увидеть больше</small>
      </div>
    </section>
    <section class="section palette-antiquewhite horizontal-section">
      <div class="panel">
        <div class="card">
          Репортажи и интервью
        </div>
      </div>
      <div class="panel">
        <div class="card">
          Распаковка и обзоры книг
        </div>
      </div>
      <div class="panel">
        <div class="card">
          Буктрейлеры
        </div>
      </div>
      <div class="panel">
        <div class="card">
          ... созданные при помощи нейросетей!
        </div>
      </div>
      <div class="panel">
        <div class="card">
          <div class="grow w-full flex flex-col gap-4 justify-center items-center mt-8">
            <svg xmlns="http://www.w3.org/2000/svg" height="98" width="98">
              <use href="#yt-logo" fill="#683b00"></use>
            </svg>
            <div>RUGRAM в Youtube</div>
            <a class="btn btn-style-1 btn-inline lg text-semibold rounded-md px-5"
               href="https://www.youtube.com/@k_slovu_rugram/shorts" target="_blank"
            >Перейти</a>
          </div>
          <small class="tip">Прокрутите вниз, чтобы увидеть больше</small>
        </div>
      </div>
    </section>
    <!--Urbi et Orbi-->
    <section class="section palette-rugram vertical-section">
      <div class="card">
        <div class="grow w-full flex flex-col gap-4 justify-center items-center mt-8">
          ( Rugram_Urbi et Orbi )
        </div>
        <small class="tip">Прокрутите вниз, чтобы увидеть больше</small>
      </div>
    </section>
    <section class="section palette-rugram horizontal-section">
      <div class="panel">
        <div class="card">
          Не будем скромничать...
          И возможно, надо было с этого начинать...
        </div>
      </div>
      <div class="panel">
        <div class="card">
          Представляем самый заметный
          во всем Telegram книжный канал!
        </div>
      </div>
      <div class="panel">
        <div class="card">
          Официальный канал издательской платформы RUGRAM
        </div>
      </div>
      <div class="panel">
        <div class="card">
          Главные новости и адвент-календарь!
        </div>
      </div>
      <div class="panel">
        <div class="card">
          Книжные новинки!
        </div>
      </div>
      <div class="panel">
        <div class="card">
          Ежедневные рубрики с предзаказами!
        </div>
      </div>
      <div class="panel">
        <div class="card bg-[url(/assets/images/social/bg-boxes.gif)] bg-no-repeat bg-right-bottom bg-[size:35%]">
          Возможность приобрести книги и уникальный мерч в режиме online прямо в Telegram!
        </div>
      </div>
      <div class="panel">
        <div class="card">
          <div class="grow w-full flex flex-col gap-4 justify-center items-center mt-8">
            <svg xmlns="http://www.w3.org/2000/svg" width="283" height="95">
              <use href="#tg-logo"></use>
            </svg>
            <div>Urbi et Orbi</div>
            <a class="btn btn-style-1 btn-inline lg text-semibold rounded-md px-5"
               href="https://t.me/rugram_me" target="_blank"
            >Присоединиться!</a>
          </div>
          <small class="tip">Прокрутите вниз, чтобы увидеть больше</small>
        </div>
      </div>
    </section>
    <!--Спасибо-->
    <section class="section palette-mistyrose horizontal-section">
      <div class="panel">
        <div class="card">
          Хотим сказать главное...
        </div>
      </div>
      <div class="panel">
        <div class="card">
          Спасибо вам за поддержку, спасибо за многочисленные встречи и улыбки!
        </div>
      </div>
    </section>
    <section class="section palette-mistyrose horizontal-section">
      <div class="panel">
        <div class="card">
          За прошедшее время мы организовали 20 масштабных оффлайн-встреч!
        </div>
      </div>
      <div class="panel">
        <div class="card">
          С нетерпением ждем новой возможности увидеться с вами!
        </div>
      </div>
    </section>
    <section class="section palette-mistyrose horizontal-section">
      <div class="panel">
        <div class="card">
          Каждые 5 часов к издательской платформе RUGRAM присоединяется новый автор!
        </div>
      </div>
      <div class="panel">
        <div class="card">
          Вливайтесь, будем рады новым друзьям!
        </div>
      </div>
    </section>
  </div>
</section>

<svg xmlns="http://www.w3.org/2000/svg">
  <defs>
    <symbol id="tg-logo" viewBox="0 0 1131.53 379.304">
      <polygon fill="#D8D8D8" fill-opacity="0.5"
        points="72.008,0 274.113,140.173 274.113,301.804 390.796,221.102 601.682,367.302 1131.53,0.223"/>
      <polygon fill="#C4C4C3" fill-opacity="0.5"
        points="1131.53,0.223 274.113,140.173 274.113,301.804 390.796,221.102"/>
    </symbol>
    <symbol id="zen-logo" viewBox="0 0 28 28">
      <path fill="#2C3036" fill-opacity="0.1"
        d="M16.7 16.7c-2.2 2.27-2.36 5.1-2.55 11.3 5.78 0 9.77-.02 11.83-2.02 2-2.06 2.02-6.24 2.02-11.83-6.2.2-9.03.35-11.3 2.55M0 14.15c0 5.59.02 9.77 2.02 11.83 2.06 2 6.05 2.02 11.83 2.02-.2-6.2-.35-9.03-2.55-11.3-2.27-2.2-5.1-2.36-11.3-2.55M13.85 0C8.08 0 4.08.02 2.02 2.02.02 4.08 0 8.26 0 13.85c6.2-.2 9.03-.35 11.3-2.55 2.2-2.27 2.36-5.1 2.55-11.3m2.85 11.3C14.5 9.03 14.34 6.2 14.15 0c5.78 0 9.77.02 11.83 2.02 2 2.06 2.02 6.24 2.02 11.83-6.2-.2-9.03-.35-11.3-2.55">
      </path>
      <path fill="#fff"
        d="M28 14.15v-.3c-6.2-.2-9.03-.35-11.3-2.55-2.2-2.27-2.36-5.1-2.55-11.3h-.3c-.2 6.2-.35 9.03-2.55 11.3-2.27 2.2-5.1 2.36-11.3 2.55v.3c6.2.2 9.03.35 11.3 2.55 2.2 2.27 2.36 5.1 2.55 11.3h.3c.2-6.2.35-9.03 2.55-11.3 2.27-2.2 5.1-2.36 11.3-2.55">
      </path>
    </symbol>
    <symbol id="vk-logo" viewBox="0 0 48 48">
      <path fill="#0077FF" opacity="0.5"
        d="M0 23.04C0 12.1788 0 6.74826 3.37413 3.37413C6.74826 0 12.1788 0 23.04 0H24.96C35.8212 0 41.2517 0 44.6259 3.37413C48 6.74826 48 12.1788 48 23.04V24.96C48 35.8212 48 41.2517 44.6259 44.6259C41.2517 48 35.8212 48 24.96 48H23.04C12.1788 48 6.74826 48 3.37413 44.6259C0 41.2517 0 35.8212 0 24.96V23.04Z"/>
      <path fill="white"
        d="M25.54 34.5801C14.6 34.5801 8.3601 27.0801 8.1001 14.6001H13.5801C13.7601 23.7601 17.8 27.6401 21 28.4401V14.6001H26.1602V22.5001C29.3202 22.1601 32.6398 18.5601 33.7598 14.6001H38.9199C38.0599 19.4801 34.4599 23.0801 31.8999 24.5601C34.4599 25.7601 38.5601 28.9001 40.1201 34.5801H34.4399C33.2199 30.7801 30.1802 27.8401 26.1602 27.4401V34.5801H25.54Z"/>
    </symbol>
    <symbol id="yt-logo" viewBox="0 0 49 49">
      <path fill="#000000" fill-opacity="0.5"
        d="M39.256,6.5H9.744C4.371,6.5,0,10.885,0,16.274v16.451c0,5.39,4.371,9.774,9.744,9.774h29.512 c5.373,0,9.744-4.385,9.744-9.774V16.274C49,10.885,44.629,6.5,39.256,6.5z M47,32.726c0,4.287-3.474,7.774-7.744,7.774H9.744 C5.474,40.5,2,37.012,2,32.726V16.274C2,11.988,5.474,8.5,9.744,8.5h29.512c4.27,0,7.744,3.488,7.744,7.774V32.726z"></path>
      <path fill="#000000" fill-opacity="0.5"
        d="M33.36,24.138l-13.855-8.115c-0.308-0.18-0.691-0.183-1.002-0.005S18,16.527,18,16.886v16.229 c0,0.358,0.192,0.69,0.502,0.868c0.154,0.088,0.326,0.132,0.498,0.132c0.175,0,0.349-0.046,0.505-0.137l13.855-8.113 c0.306-0.179,0.495-0.508,0.495-0.863S33.667,24.317,33.36,24.138z M20,31.37V18.63l10.876,6.371L20,31.37z"></path>
    </symbol>
  </defs>
</svg>
