/** @format */

import { Pipe, PipeTransform } from '@angular/core';
import * as dayjs from 'dayjs';
import * as relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/ru';

dayjs.locale('ru');
dayjs.extend(relativeTime);

@Pipe({
  name: 'dayjs'
})
export class DayjsPipe implements PipeTransform {
  transform(value: string, type: string, options?: any): string {
    switch (type) {
      case 'from':
        return dayjs(value).from(dayjs());
      case 'fromNow':
        return dayjs(value).fromNow();
      case 'format':
        return dayjs(value).format(options);
      default:
        return value;
    }
  }
}
