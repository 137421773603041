/** @format */

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { User, UserService } from '../../../core';

@Component({
  selector: 'app-left-bar',
  templateUrl: './left-bar.component.html',
  styleUrls: ['./left-bar.component.scss']
})
export class LeftBarComponent implements OnInit, OnDestroy {
  user$: Observable<User> | undefined;

  constructor(private userService: UserService, private router: Router) {}

  ngOnInit(): void {
    this.user$ = this.userService.currentUser;
  }

  ngOnDestroy(): void {}

  onLogout(): void {
    this.userService.getLogout({ flag: 'this' }).subscribe({
      next: () => this.router.navigateByUrl('/').then(() => console.debug('Route changed')),
      error: (error: any) => console.error(error)
    });
  }
}
