/** @format */

import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { combineLatest, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { HeaderService, HelperService } from '../../../core';

@Component({
  selector: 'app-http-loader',
  templateUrl: './http-loader.component.html',
  styleUrls: ['./http-loader.component.scss']
})
export class HttpLoaderComponent implements OnInit, OnDestroy {
  loader$: Subscription | undefined;

  progress = 0;
  progressInterval: any;
  progressBusy: boolean | undefined;

  constructor(
    private router: Router,
    private headerService: HeaderService,
    private helperService: HelperService
  ) {}

  ngOnInit(): void {
    this.loader$ = combineLatest([
      // prettier-ignore
      this.router.events.pipe(filter(e => e instanceof NavigationStart || e instanceof NavigationEnd)),
      this.headerService.loading
    ]).subscribe({
      next: ([navigation, loading]) => {
        switch (true) {
          case !this.progressBusy && navigation instanceof NavigationStart:
          case !this.progressBusy && loading:
            this.onProgressStart();
            break;
          case navigation instanceof NavigationEnd:
          case !loading:
            this.onProgressEnd();
            break;
        }
      },
      error: (error: any) => console.error(error)
    });
  }

  ngOnDestroy(): void {
    this.loader$?.unsubscribe();
  }

  onProgressStart(): void {
    this.progressBusy = true;

    this.progressInterval = setInterval(() => {
      const next = this.progress + this.helperService.getRandomInt();

      if (this.progress < 100) {
        this.progress = next;
      }
    }, 100);
  }

  onProgressEnd(): void {
    clearInterval(this.progressInterval);

    this.progressBusy = false;
    this.progress = 0;
  }
}
