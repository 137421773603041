<!-- @format -->

<section class="box d-flex fd-column jc-start ai-stretch">
	<ng-container *ngIf="user$ | async as user">
		<div class="p-5 bg-color-neutral_white rounded-sm bordered">
			<h1 class="h3 text-bold w-full mb-5">{{ user.name }} {{ user.surname }}</h1>
			<ul class="h5 m-0 pl-0">
				<li class="d-flex ai-center jc-start line-height-5 mb-1">
					<p class="label mb-0 mr-2 text-color-neutral_gray-2">E-mail:</p>
					<p class="mb-0 text-color-log-print_text">{{ user.email || "—" }}</p>
				</li>
				<li class="d-flex ai-center jc-start line-height-5 mb-1">
					<p class="label mb-0 mr-2 text-color-neutral_gray-2">Телефон:</p>
					<p class="mb-0 text-color-log-print_text">
						{{ user.phone ?? '-' | phoneInternational }}
					</p>
				</li>
			</ul>
			<div class="buttons d-flex mt-4">
				<a routerLink="/office/settings/edit">
					<button class="btn btn-style-1 btn-inline rounded-md px-2 line-height-5">
						<app-svg-icon
							class="mr-h"
							appIcon="black-pen"
							appWidth="18"
							appHeight="18"
							appViewBox="0 0 18 18"
						></app-svg-icon>
						<span>Редактировать</span>
					</button>
				</a>
				<a routerLink="/office/settings/password">
					<button class="btn btn-style-2 btn-inline rounded-md px-2 line-height-5">
						<app-svg-icon
							class="mr-h"
							appIcon="lock"
							appWidth="17"
							appHeight="20"
							appViewBox="0 0 17 20"
						></app-svg-icon>
						<span>Сменить пароль</span>
					</button>
				</a>
			</div>
		</div>
		<div
			class="p-5 bg-color-neutral_white rounded-sm bordered mt-3"
		>
      <h1 class="h3 text-bold w-full mb-5">Мерчандайз</h1>
      <ul class="h5 m-0 pl-0">
        <li
          class="d-flex jc-start ai-center bg-color-neutral_light-gray-2 rounded-sm p-2 pl-3 mt-2"
          *ngIf="!dkpList.length"
        >
          <span class="h7 text-light">Тут будут отображаться договоры на мерч</span>
        </li>
        <li
          class="d-flex jc-start ai-center bg-color-neutral_light-gray-2 rounded-sm p-2 pl-3 mt-2"
          *ngFor="let contract of dkpList"
        >
          <div class="label flex flex-col leading-5 -mb-[.5em] mr-auto text-color-neutral_dark">
            {{ contract.file_name }}
            <div *ngIf="contract.signed_at; else contractIsNotSigned" class="flex items-center">
              <span class="h7 text-light text-gray-600">Подписан {{ contract.signed_at | dayjs: "format":"DD MMM YYYY" }} г.</span>
            </div>
          </div>
          <app-svg-icon
            *ngIf="!contract.signed_at"
            title="Подписать договор"
            class="cursor-p ml-2 hover:bg-amber-100 rounded-full p-1"
            appIcon="brush"
            appWidth="20"
            appHeight="20"
            appViewBox="0 0 18 18"
            (click)="onSignDkp(contract)"
          ></app-svg-icon>
          <app-svg-icon
            *ngIf="!contract.signed_at"
            title="Удалить договор"
            class="cursor-p ml-2 hover:bg-rose-200 rounded-full p-1"
            appIcon="basket-red"
            appWidth="20"
            appHeight="20"
            appViewBox="0 0 20 20"
            (click)="onDeleteDkp(contract)"
          ></app-svg-icon>
          <app-svg-icon
            title="Скачать"
            class="cursor-p ml-2 hover:bg-gray-200 rounded-full p-1"
            appIcon="download-gray"
            appWidth="20"
            appHeight="20"
            appViewBox="0 0 16 16"
            (click)="onDownloadDkp(contract)"
          ></app-svg-icon>
        </li>
      </ul>
      <button
        class="btn btn-style-1 btn-inline rounded-md mt-3 px-2 line-height-5"
        routerLink="/office/services/dkp"
      >
        Передать товары
      </button>
    </div>
		<div
			class="p-5 bg-color-neutral_white rounded-sm bordered mt-3"
		>
			<h1 class="h3 text-bold w-full mb-5">Лицензионные договоры</h1>
			<ul class="h5 m-0 pl-0">
        <li
          class="d-flex jc-start ai-center bg-color-neutral_light-gray-2 rounded-sm p-2 pl-3 mt-2"
          *ngIf="!contractList.length"
        >
          <span class="h7 text-light">Тут будут отображаться лицензионные договоры</span>
        </li>
				<li
					class="d-flex jc-start ai-center bg-color-neutral_light-gray-2 rounded-sm p-2 pl-3 mt-2"
					*ngFor="let contract of contractList"
				>
          <div class="label flex flex-col leading-5 -mb-[.5em] mr-auto text-color-neutral_dark">
            {{ contract.file_name }}
            <div *ngIf="contract.signed_at; else contractIsNotSigned" class="flex items-center">
              <span class="h7 text-light text-gray-600">Подписан {{ contract.signed_at | dayjs: "format":"DD MMM YYYY" }} г.</span>
            </div>
          </div>
					<app-svg-icon
            title="Скачать"
            class="cursor-p ml-2 hover:bg-gray-200 rounded-full p-1"
						appIcon="download-gray"
            appWidth="20"
            appHeight="20"
						appViewBox="0 0 16 16"
						(click)="onDownloadContract(contract)"
					></app-svg-icon>
				</li>
			</ul>
		</div>
		<div
			class="p-5 bg-color-neutral_white rounded-sm bordered mt-3"
		>
			<h1 class="h3 text-bold w-full mb-5">Приложения</h1>
			<ul class="h5 m-0 pl-0">
        <li
          class="d-flex jc-start ai-center bg-color-neutral_light-gray-2 rounded-sm p-2 pl-3 mt-2"
          *ngIf="!attachmentList.length"
        >
          <span class="h7 text-light">Тут будут отображаться приложения к договорам</span>
        </li>
				<!-- prettier-ignore -->
				<li
					class="d-flex jc-start ai-center bg-color-neutral_light-gray-2 rounded-sm p-2 pl-3 mt-2"
					*ngFor="let attachment of attachmentList"
				>
          <div class="label flex flex-col leading-5 -mb-[.5em] mr-auto text-color-neutral_dark">
            {{ attachment.file_name }}
            <div *ngIf="attachment.signed_at; else contractIsNotSigned" class="flex items-center">
              <span class="h7 text-light text-gray-600">Подписан {{ attachment.signed_at | dayjs: "format":"DD MMM YYYY" }} г.</span>
            </div>
          </div>
					<app-svg-icon
            title="Скачать"
            class="cursor-p ml-2 hover:bg-gray-200 rounded-full p-1"
						appIcon="download-gray"
						appWidth="20"
						appHeight="20"
						appViewBox="0 0 16 16"
						(click)="onDownloadAttachment(attachment)"
					></app-svg-icon>
				</li>
			</ul>
		</div>
		<div class="p-5 bg-color-neutral_white rounded-sm bordered mt-3">
			<h1 class="h3 text-bold w-full mb-5">Соглашения</h1>
			<ul class="h5 m-0 pl-0">
				<a class="group d-block mb-2" href="/assets/download/user_agreement.docx" download>
					<li
						class="d-flex ai-center jc-between bg-color-neutral_light-gray-2 rounded-sm p-2 pl-3"
					>
						<p class="label mb-0 mr-2 text-color-neutral_dark">
							Пользовательское соглашение
						</p>
						<app-svg-icon
              title="Скачать"
              class="cursor-p ml-2 group-hover:bg-gray-200 rounded-full p-1"
							appIcon="download-gray"
              appWidth="20"
              appHeight="20"
							appViewBox="0 0 16 16"
						></app-svg-icon>
					</li>
				</a>
				<a class="group d-block mb-2" href="/assets/download/privacy_policy.docx" download>
					<li
						class="d-flex ai-center jc-between bg-color-neutral_light-gray-2 rounded-sm p-2 pl-3"
					>
						<p class="label mb-0 mr-2 text-color-neutral_dark">
							Политика конфиденциальности
						</p>
						<app-svg-icon
              title="Скачать"
              class="cursor-p ml-2 group-hover:bg-gray-200 rounded-full p-1"
							appIcon="download-gray"
              appWidth="20"
              appHeight="20"
							appViewBox="0 0 16 16"
						></app-svg-icon>
					</li>
				</a>
				<a class="group d-block mb-2" href="/assets/download/cookie.docx" download>
					<li
						class="d-flex ai-center jc-between bg-color-neutral_light-gray-2 rounded-sm p-2 pl-3"
					>
						<p class="label mb-0 mr-2 text-color-neutral_dark">Согласие cookie</p>
						<app-svg-icon
              title="Скачать"
              class="cursor-p ml-2 group-hover:bg-gray-200 rounded-full p-1"
							appIcon="download-gray"
              appWidth="20"
              appHeight="20"
							appViewBox="0 0 16 16"
						></app-svg-icon>
					</li>
				</a>
			</ul>
		</div>
	</ng-container>
</section>
<ng-template #contractIsNotSigned class="flex items-center">
  <span class="h7 text-light text-amber-600">Ожидает подписания</span>
</ng-template>
