/** @format */

import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { HelperService, User, UserService } from '../../core';

interface ConfirmForm {
  password: FormControl<string | null>;
  password_confirmation: FormControl<string | null>;
}

@Component({
  selector: 'app-auth-confirm',
  templateUrl: './auth-confirm.component.html',
  styleUrls: ['./auth-confirm.component.scss']
})
export class AuthConfirmComponent implements OnInit, OnDestroy {
  routeQuery$!: Subscription;
  routeQueryParams!: Params;

  confirmForm: FormGroup;

  isSubmitting = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private formBuilder: FormBuilder,
    private helperService: HelperService
  ) {
    // prettier-ignore
    this.confirmForm = this.formBuilder.group<ConfirmForm>(
      {
        password: this.formBuilder.control('', [Validators.required, Validators.minLength(6)]),
        password_confirmation: this.formBuilder.control('', [
          Validators.required,
          Validators.minLength(6)
        ])
      },
      { validators: [this.getConfirmPassword('password', 'password_confirmation')] }
    );
  }

  ngOnInit(): void {
    this.routeQuery$ = this.route.queryParams.subscribe({
      next: (queryParams: Params) => {
        this.routeQueryParams = queryParams;

        if (!('token' in this.routeQueryParams)) {
          this.router.navigateByUrl('/auth/login').then(() => console.debug('Route changed'));
        }
      },
      error: (error: any) => console.error(error)
    });
  }

  ngOnDestroy(): void {
    this.routeQuery$?.unsubscribe();
  }

  getConfirmPassword(left: string, right: string): ValidatorFn {
    return formGroup => {
      if (formGroup.get(left)?.value !== formGroup.get(right)?.value) {
        return { match: false };
      }

      return null;
    };
  }

  onSubmitForm(): void {
    if (this.helperService.getFormValidation(this.confirmForm)) {
      this.isSubmitting = true;

      this.userService.postReset({
          ...this.confirmForm.value,
          token: this.routeQueryParams.token
        })
        .subscribe({
          next: (user: User) => this.router
            .navigate([user.cabinetAvailable ? '/office' : '/'])
            .then(() => console.debug('Route changed')),
          error: () => (this.isSubmitting = false)
        });
    }
  }
}
