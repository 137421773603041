/** @format */

import { Injectable } from '@angular/core';
import { AttachmentService, UserAttachment, UserContract, UserDkp, UserService } from '../../../core';
import { forkJoin, Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class OfficeSettingsResumeResolver {
  constructor(
    private router: Router,
    private userService: UserService,
    private attachmentService: AttachmentService
  ) {}

  resolve(): Observable<[UserContract[], UserDkp[], UserAttachment[]]> {
    return forkJoin([
      this.userService.getContracts(),
      this.userService.getDkps(),
      this.attachmentService.getAttachments()
    ]).pipe(
      catchError((error: any) => {
        this.router
          .navigateByUrl('/error/' + error.status)
          .then(() => console.debug('Router changed'));

        return throwError(() => new Error('OfficeSettingsResumeResolver error'));
      })
    );
  }
}
