<!-- @format -->

<div class="box bg-color-neutral_white rounded-md bordered p-7">
	<h1 class="mb-5">Сброс пароля</h1>
	<form [formGroup]="confirmForm" (ngSubmit)="onSubmitForm()">
		<fieldset [disabled]="isSubmitting">
			<fieldset
				class="input i-end d-flex fd-row jc-between ai-center mb-1"
				*ngIf="{value: false} as isVisible"
			>
				<label class="d-block h5" for="password">Новый пароль:</label>
				<input
					id="password"
					[type]="isVisible.value ? 'text' : 'password'"
					formControlName="password"
					placeholder="От 6 символов"
					autocomplete="new-password"
				/>
				<app-svg-icon
					class="password"
					[appIcon]="isVisible.value ? 'eye-off' : 'eye'"
					[appWidth]="24"
					[appHeight]="24"
					[appViewBox]="'0 0 24 24'"
					(click)="isVisible.value = !isVisible.value"
				></app-svg-icon>
			</fieldset>
			<fieldset
				class="input i-end d-flex fd-row jc-between ai-center mb-1"
				*ngIf="{value: false} as isVisible"
			>
				<label class="d-block h5" for="password_confirmation">Повторите пароль:</label>
				<input
					id="password_confirmation"
					[type]="isVisible.value ? 'text' : 'password'"
					[class.ng-invalid]="confirmForm.errors?.match === false"
					formControlName="password_confirmation"
					autocomplete="new-password"
				/>
				<app-svg-icon
					class="password"
					[appIcon]="isVisible.value ? 'eye-off' : 'eye'"
					[appWidth]="24"
					[appHeight]="24"
					[appViewBox]="'0 0 24 24'"
					(click)="isVisible.value = !isVisible.value"
				></app-svg-icon>
			</fieldset>
			<a class="extra d-block h7 text-d-underline mb-5" [routerLink]="'/auth/login'">
				Вернуться
			</a>
			<button
				class="btn btn-style-1 btn-inline lg text-semibold w-1/2 rounded-md px-9"
				[disabled]="confirmForm.invalid"
			>
				Подтвердить
			</button>
		</fieldset>
	</form>
</div>
