/** @format */

import { Component, Input, ElementRef } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
  animations: [
    trigger('enterLeaveTrigger', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('100ms', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        animate('100ms', style({ opacity: 0 }))
      ])
    ]),
  ],
})
export class TooltipComponent {
  @Input()
  set appClassList(classList: string[]) {
    this.classList = classList;
  }

  classList: string[] = ['default'];

  toggle = false;

  toggleHeight = '0';
  toggleWidth = '0';

  tooltipTop = '0';
  tooltipLeft = '0';

  constructor(private elementRef: ElementRef) {}

  onHover(toggle: boolean): void {
    const { top, left, height, width } = this.elementRef.nativeElement.getBoundingClientRect();

    this.toggleHeight = height + 'px';
    this.toggleWidth = width + 'px';

    this.tooltipTop = top + 'px';
    this.tooltipLeft = left + 'px';

    this.toggle = toggle;
  }
}
