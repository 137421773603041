<!-- @format -->

<div class="box d-flex ai-center jc-center h-full w-full">
	<svg
		class="d-block"
		[attr.width]="square || width"
		[attr.height]="square || height"
		[attr.viewBox]="viewBox"
	>
		<use [attr.xlink:href]="'assets/icons/sprite.svg#' + icon"></use>
	</svg>
</div>
