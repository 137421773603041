/** @format */

import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  NgZone,
  OnDestroy,
  ViewChild
} from '@angular/core';
import { PlatformService, Post } from '../../../../core';
import { filter, switchMap } from 'rxjs/operators';
import { of, Subscription } from 'rxjs';
import { gsap } from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { Draggable } from 'gsap/Draggable';

@Component({
  selector: 'app-carousel-posts',
  templateUrl: './carousel-posts.component.html',
  styleUrls: ['./carousel-posts.component.scss']
})
export class CarouselPostsComponent implements AfterViewInit, OnDestroy {
  @ViewChild('postListElement', { static: true }) postListElement!: ElementRef<HTMLElement>;

  @Input()
  set appPosts(posts: Post[]) {
    this.posts = posts;
  }

  windowResize$: Subscription | undefined;

  posts: Post[] = [];

  postWidth = 0;
  postMargin = 0;
  postCount = 0;
  postShift = 0;

  protected horizontalScroll!: ScrollTrigger;

  constructor(
    protected ngZone: NgZone,
    protected el: ElementRef,
    private cdRef: ChangeDetectorRef,
    private platformService: PlatformService
  ) {}

  ngAfterViewInit(): void {
    /**
     * Initial run
     */
    if (this.platformService.isBrowser()) {
      this.setPostWidth(this.platformService.getWindow());
    }

    /**
     * Responsive handler, run every resize after 1440
     */
    this.windowResize$ = this.platformService
      .getResize(10)
      .pipe(
        switchMap(() => of(this.platformService.getWindow())),
        filter((window: Window) => window.innerWidth < 1440)
      )
      .subscribe({
        next: (window: Window) => this.setPostWidth(window),
        error: (error: any) => console.error(error)
      });

    this.ngZone.runOutsideAngular(() => {
      this.initComponentAnimation();
    });
  }

  ngOnDestroy(): void {
    this.windowResize$?.unsubscribe();
    if (this.horizontalScroll) this.horizontalScroll.kill();
  }

  setPostWidth(window: Window): void {
    const postListElement = this.postListElement.nativeElement;
    const postElement = postListElement.querySelector('li');

    if (postElement) {
      this.postMargin = +getComputedStyle(postElement).marginRight.slice(0, -2);

      /**
       * Change postCount to 2 if window.innerWidth <= 768
       * Maybe need 769 because chrome glitches when resize?
       */
      this.postCount = window.innerWidth <= 769 ? 2 : 3;

      /**
       * calculate width for post using params
       * @param width - width of container that contain posts
       * @param postCount - number of posts that will be show at once in container
       * @param postMargin - right margin of each posts
       */
      const { width } = postListElement.getBoundingClientRect();

      this.postWidth = (width - this.postMargin * (this.postCount - 1)) / this.postCount;

      /**
       * RESET TRANSLATE
       */
      // this.postShift = 0;
      // this.postListElement.nativeElement.style.transform = 'translateX(0)';

      this.ngZone.runOutsideAngular(() => {
        if (this.horizontalScroll) this.horizontalScroll.refresh();
      });

      /**
       * Error: NG0100: ExpressionChangedAfterItHasBeenCheckedError:
       * Learn more about this error
       *
       * @link {https://angular.io/errors/NG0100}
       */
      this.cdRef.detectChanges();
    }
  }

  onSlide(direction: -1 | 1): void {
    if (this.isDoable(direction)) {
      const scroller = this.el.nativeElement.querySelector('.scroller');
      this.postShift += direction;
      gsap.to(scroller, {
        scrollTo: {
          x: (this.postWidth + this.postMargin) * this.postShift,
        }
      })
    }
  }

  onSwitch(item: number): void {
    const scroller = this.el.nativeElement.querySelector('.scroller');
    this.postShift = item;
    gsap.to(scroller, {
      scrollTo: {
        x: (this.postWidth + this.postMargin) * this.postShift,
      }
    })
  }

  onProgress(delta: number): void {
    let item = Math.floor(this.horizontalScroll.progress * this.posts.length);
    if (delta > 0) item--;
    if (item < 0) item = 0;
    this.onSwitch(item);
  }

  /**
   * See does user allowed to slide posts.
   * if he slide back and slider have no slides before current first slide, prevent it
   * if he slide forward and slider have no slides after current last slide, prevent it
   * @param direction - direction and number of slide movement
   */
  isDoable(direction: -1 | 1): boolean {
    return !(
      (direction === -1 && this.postShift === 0) ||
      (direction === 1 && this.postShift === this.posts.length - this.postCount)
    );
  }

  initComponentAnimation(): void {
    const self = this;

    const window: Window = this.platformService.getWindow();

    const scroller = this.el.nativeElement.querySelector('.scroller');
    const sections = this.el.nativeElement.querySelectorAll(".item");

    let maxWidth = 0;
    sections.forEach((section: HTMLElement) => {
      maxWidth += section.offsetWidth;
    });

    let scrollTween = gsap.timeline({duration: sections.length});
    sections.forEach((section: HTMLElement, i: number) => scrollTween
      .addLabel('item' + i, i)
      .to(section, {
          duration: 1,
      }, i));

    this.horizontalScroll = ScrollTrigger.create({
      scroller: scroller,
      animation: scrollTween,
      horizontal: true,
      scrub: true,
      start: 0,
      end: () => `+=${ScrollTrigger.maxScroll(scroller, true)}`,
      // snap: {
      //   snapTo: "labelsDirectional",
      //   duration: { min: .1, max: 1 },
      // },
      invalidateOnRefresh: true,
      refreshPriority: 1,
      // anticipatePin: 1,
    });
    setTimeout(() => this.horizontalScroll.refresh(), 200);

    let dragRatio = (maxWidth / (maxWidth - window.innerWidth));
    // if (dragRatio > 3) dragRatio = 3;
    dragRatio = 1;
    var drag = Draggable.create(this.el.nativeElement.querySelector('.drag-proxy'), {
      trigger: scroller,
      type: "x",
      // inertia: true,
      allowContextMenu: true,
      onPress() {
        this.startScroll = self.horizontalScroll.scroll();
      },
      onDrag() {
        self.horizontalScroll.scroll(this.startScroll - (this.x - this.startX) * dragRatio);
      },
      onThrowUpdate() {
        self.horizontalScroll.scroll(this.startScroll - (this.x - this.startX) * dragRatio);
      },
      onRelease() {
        self.ngZone.run(() => {
          self.onProgress(this.deltaX);
        });
      }
    })[0];
  }
}
