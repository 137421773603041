<!-- @format -->

<header class="w-full header -bg-color-neutral_light-gray-2 position-r">
	<div class="inner w-full d-flex jc-between ai-center">
		<div class="start d-flex mr-auto">
			<ul class="d-flex ai-center m-0 p-0">
				<li
					class="d-flex ai-center"
					*ngFor="let breadcrumb of breadcrumbs$ | async; let l = last"
				>
					<a
						class="breadcrumb overflow-h text-initial-uppercase cursor-p"
						[routerLink]="[breadcrumb.routerLink]"
					>
						{{ breadcrumb.label }}
					</a>
					<app-svg-icon
						class="fill-gray px-1"
						*ngIf="!l"
						[appIcon]="'triangle-right'"
						[appWidth]="6"
						[appHeight]="8"
						[appViewBox]="'0 0 6 8'"
					></app-svg-icon>
				</li>
			</ul>
		</div>
		<div class="end d-flex">
			<div class="position-r header-notifications d-flex ai-end visibility-h">
				<app-svg-icon
					*ngIf="hasNotifications; else noNotifications"
					class="cursor-p"
					[appIcon]="'active-bell'"
					[appWidth]="22"
					[appHeight]="25"
					[appViewBox]="'0 0 18 22'"
				></app-svg-icon>
				<ng-template #noNotifications>
					<app-svg-icon
						class="cursor-p"
						[appIcon]="'bell'"
						[appWidth]="16"
						[appHeight]="20"
						[appViewBox]="'0 0 16 20'"
					></app-svg-icon>
				</ng-template>
			</div>
		</div>
    <div class="text-slate-200 whitespace-nowrap">
      {{ viewport }} {{ zoom !== 1 ? zoom : '' }}
    </div>
	</div>
</header>
