/** @format */

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OfficeComponent } from './office.component';
import { AuthGuard } from '../core';

const routes: Routes = [
  {
    path: '',
    component: OfficeComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        data: {
          breadcrumb: {
            label: 'Мой кабинет',
            routerLink: '/office'
          },
          animation: 'homePage'
        },
        loadChildren: () => import('./home/office-home.module').then(m => m.OfficeHomeModule)
      },
      {
        path: 'balance',
        data: {
          breadcrumb: {
            label: 'Баланс',
            routerLink: '/office/balance'
          },
          animation: 'balancePage'
        },
        loadChildren: () =>
          import('./balance/office-balance.module').then(m => m.OfficeBalanceModule)
      },
      {
        path: 'books',
        data: {
          breadcrumb: {
            label: 'Мои книги',
            routerLink: '/office/books'
          },
          animation: 'booksPage'
        },
        loadChildren: () => import('./books/office-books.module').then(m => m.OfficeBooksModule)
      },
      {
        path: 'help',
        data: {
          breadcrumb: {
            label: 'Помощь',
            routerLink: '/office/help'
          },
          animation: 'helpPage'
        },
        loadChildren: () => import('./help/office-help.module').then(m => m.OfficeHelpModule)
      },
      {
        path: 'notifications',
        data: {
          breadcrumb: {
            label: 'Уведомления',
            routerLink: '/office/notifications'
          },
          animation: 'notificationsPage'
        },
        loadChildren: () =>
          import('./notifications/office-notifications.module').then(
            m => m.OfficeNotificationsModule
          )
      },
      {
        path: 'services',
        data: {
          breadcrumb: {
            label: 'Услуги',
            routerLink: '/office/services'
          },
          animation: 'servicesPage'
        },
        loadChildren: () =>
          import('./service/office-service.module').then(m => m.OfficeServiceModule)
      },
      {
        path: 'posts',
        data: {
          breadcrumb: {
            label: 'Публикации',
            routerLink: '/office/posts'
          },
          animation: 'postsPage'
        },
        loadChildren: () => import('./posts/office-posts.module').then(m => m.OfficePostsModule)
      },
      {
        path: 'settings',
        data: {
          breadcrumb: {
            label: 'Мой профиль',
            routerLink: '/office/settings'
          },
          animation: 'settingsPage'
        },
        loadChildren: () =>
          import('./settings/office-settings.module').then(m => m.OfficeSettingsModule)
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OfficeRoutingModule {}
