/** @format */

import {
  Component,
  EventEmitter,
  HostListener,
  Inject, Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2
} from '@angular/core';
import {fromEvent, Subscription, timer} from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { fadeAnimation } from '../../../app-animations';
import { filter} from 'rxjs/operators';
import { PlatformService } from '../../../core';

@Component({
  selector: 'app-modal',
  templateUrl: 'modal.component.html',
  styleUrls: ['modal.component.scss'],
  animations: [fadeAnimation]
})
export class ModalComponent implements OnInit, OnDestroy {
  @Input() closeOnClickOutside = true;
  @Input() timeout: number|null = null;
  @Output() closed = new EventEmitter<any>();

  click$: Subscription | undefined;

  @HostListener('document:keyup.esc')
  onEsc(keyboardEvent: KeyboardEvent): void {
    this.closed.emit();
  }

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private route: ActivatedRoute,
    private platformService: PlatformService,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    if (this.platformService.isBrowser()) {
      const window = this.platformService.getWindow();

      this.click$ = fromEvent(window, 'click')
        .pipe(filter((e: any) => this.closeOnClickOutside && e.target.id === 'modal'))
        .subscribe(() => this.closed.emit());

      if (this.timeout) {
        timer(this.timeout).subscribe(() => this.closed.emit());
      }
    }

    this.updateScroll(true);
  }

  ngOnDestroy(): void {
    this.click$?.unsubscribe();

    this.updateScroll();
  }

  updateScroll(update: boolean = false): void {
    if (this.platformService.isBrowser()) {
      const window = this.platformService.getWindow();

      const body = this.document.body;

      if (body.clientHeight > window.innerHeight) {
        this.renderer[update ? 'addClass' : 'removeClass'](body, 'modal-open');
      }
    }
  }
}
