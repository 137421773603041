<!-- @format -->

<section class="box d-flex fd-column p-0 m-0">
	<div class="">
		<div class="bg-color-neutral_white rounded-sm bordered p-3 mb-3">
			<ul class="p-0 m-0 d-flex fd-row">
				<li class="d-flex fd-column">
					<div class="d-flex ai-center jc-start mb-2">
						<span class="d-block line-height-1 mx-1 h8 text-color-neutral_gray-2 letter-spacing-1 text-uppercase">
							Маркетинг и PR
						</span>
					</div>
					<div class="d-flex ai-end jc-between w-full">
						<ul class="w-full grid grid-cols-1 xs:!grid-cols-2 sm:!grid-cols-3 gap-1 p-0 my-0">
							<li class="w-full flex flex-col gap-1 bg-color-neutral_light-gray-2 rounded-sm mb-h p-2">
								<a href="mailto:pr@rugram.me">pr@rugram.me</a>
								<span class="text-semibold">Идеи и вопросы по продвижению</span>
								<span class="h7 text-light">Если планируете
									любые маркетинговые активности или мероприятия по продвижению книги</span>
							</li>
							<li class="w-full flex flex-col gap-1 bg-color-neutral_light-gray-2 rounded-sm mb-h p-2">
								<a href="mailto:merch@rugram.me">merch@rugram.me</a>
								<span class="text-semibold">Распространение мерча</span>
								<span class="h7 text-light">Если хотите передать мерч
									на подарки своим читателям или на продажу</span>
                <div>
                  <button
                    class="btn btn-style-1 btn-inline rounded-md px-2 line-height-5"
                    routerLink="/office/services/dkp"
                  >
                    Передать товары
                  </button>
                </div>
							</li>
							<li class="w-full flex flex-col gap-1 bg-color-neutral_light-gray-2 rounded-sm mb-h p-2">
								<a href="mailto:support@rugram.me">support@rugram.me</a>
								<span class="text-semibold">Промокоды и акции</span>
								<span class="h7 text-light">Предлагайте свои книги по выгодным ценам</span>
							</li>
						</ul>
					</div>
				</li>
			</ul>
		</div>
		<div class="bg-color-neutral_white rounded-sm bordered p-3 mb-3">
			<ul class="p-0 m-0 d-flex fd-row">
				<li class="d-flex fd-column">
					<div class="d-flex ai-center jc-start mb-2">
						<span class="d-block line-height-1 mx-1 h8 text-color-neutral_gray-2 letter-spacing-1 text-uppercase">
							Подготовка и выпуск изданий
						</span>
					</div>
					<div class="d-flex ai-end jc-between w-full">
            <ul class="w-full grid grid-cols-1 xs:!grid-cols-2 sm:!grid-cols-3 gap-1 p-0 my-0">
							<li class="w-full flex flex-col gap-1 bg-color-neutral_light-gray-2 rounded-sm mb-h p-2">
								<a href="mailto:info@rugram.me">info@rugram.me</a>
								<span class="text-semibold">Вопросы по выпуску книг</span>
								<span class="h7 text-light">* (до начала работы с ведущим редактором)</span>
							</li>
							<li class="w-full flex flex-col gap-1 bg-color-neutral_light-gray-2 rounded-sm mb-h p-2">
								<a href="mailto:dostavka@rugram.me">dostavka@rugram.me</a>
								<span class="text-semibold">Заказ тиражей и авторских экземпляров</span>
								<span class="h7 text-light">Печать для себя также доступна в разделе Услуги</span>
							</li>
							<li class="w-full flex flex-col gap-1 bg-color-neutral_light-gray-2 rounded-sm mb-h p-2">
								<a href="mailto:report@rugram.me">report@rugram.me</a>
								<span class="text-semibold">Отчеты и оплаты</span>
							</li>
						</ul>
					</div>
					<div class="d-flex ai-start bg-color-log-print_yellow-25 rounded-sm p-2 mt-1">
						<app-svg-icon
								class="d-block mr-3"
								appIcon="attention-round-yellow"
								appWidth="40"
								appHeight="40"
								appViewBox="0 0 40 40"
						></app-svg-icon>
						<div class="h6 text-color-log-print_text line-height-3">
							Если вы уже начали работать с вашим ведущим редактором,
							именно ему вам необходимо адресовать вопросы касающиеся содержания,
							формы, а также сроков изготовления.
						</div>
					</div>

				</li>
			</ul>
		</div>
		<div class="bg-color-neutral_white rounded-sm bordered p-3 mb-3">
			<ul class="p-0 m-0 d-flex fd-row">
				<li class="d-flex fd-column">
					<div class="d-flex ai-center jc-start mb-2">
						<span class="d-block line-height-1 mx-1 h8 text-color-neutral_gray-2 letter-spacing-1 text-uppercase">
							Вопросы по работе личного кабинета
						</span>
					</div>
          <div class="d-flex fd-column ai-start jc-start p-1">
                <span class="d-block sale-in-stores-paragraph-1">
          				Напишите о проблеме, с которой вы столкнулись – наши специалисты рассмотрят ваше обращение в рабочее время.
                  Ответ с результатом обработки заявления придет на ваш контактный электронный почтовый адрес.
			          </span>
          </div>
          <div class="d-flex p-1">
            <button
              (click)="onShowModalHelp()"
              class="btn btn-style-1 btn-inline rounded-md d-flex ai-center"
              slot="content"
            >
              <app-svg-icon
                class="mr-1"
                appWidth="20"
                appHeight="19"
                appViewBox="0 0 20 19"
                appIcon="chat-black"
              ></app-svg-icon>
              <span>Задать вопрос</span>
            </button>
          </div>
				</li>
			</ul>
		</div>
    <div class="bg-color-neutral_white rounded-sm bordered p-3 mb-3">
      <ul class="p-0 m-0 d-flex fd-row">
        <li class="d-flex fd-column">
          <div class="d-flex ai-center jc-start mb-2">
						<span class="d-block line-height-1 mx-1 h8 text-color-neutral_gray-2 letter-spacing-1 text-uppercase">
							Мы в соцсетях
						</span>
          </div>
          <div class="d-flex ai-end jc-between w-full">
            <ul class="w-full grid grid-cols-1 xs:!grid-cols-2 sm:!grid-cols-3 gap-1 p-0 my-0">
              <li class="w-full">
                <a class="group relative w-full flex flex-col gap-1 bg-gradient-to-tl from-gray-50 to-violet-100 rounded-sm mb-h p-2"
                   href="https://t.me/+hiAKFu7hXrFlZTM6" target="_blank">
									<span class="absolute top-0 left-0" style="top: -5px;">✌</span>
                  <span class="text-semibold">RUGRAM info</span>
                  <span class="h7 text-light">Информационный канал для авторов Rugram!</span>
									t.me/+hiAKFu7hXrFlZTM6
                  <svg width="24" height="24" viewBox="0 0 24 24" class="absolute top-1 right-1 text-gray-500 group-hover:text-gray-600 fill-current">
                    <use xlink:href="/assets/icons/svg/target-blank.svg#target-blank"></use>
                  </svg>
                </a>
              </li>
              <li class="w-full">
                <a class="group relative w-full flex flex-col gap-1 bg-color-neutral_light-gray-2 rounded-sm mb-h p-2"
                   href="https://t.me/rugram_me" target="_blank">
                  <span class="text-semibold">Urbi et Orbi</span>
                  <span class="h7 text-light">Официальный канал издательской платформы RUGRAM</span>
                  t.me/rugram_me
                  <svg width="24" height="24" viewBox="0 0 24 24" class="absolute top-1 right-1 text-gray-500 group-hover:text-gray-600 fill-current">
                    <use xlink:href="/assets/icons/svg/target-blank.svg#target-blank"></use>
                  </svg>
                </a>
              </li>
              <li class="w-full">
                <a class="group relative w-full flex flex-col gap-1 bg-color-neutral_light-gray-2 rounded-sm mb-h p-2"
                   href="https://t.me/+TauSHqa3rR4wYTYy" target="_blank">
                  <span class="text-semibold">Что скажете?</span>
                  <span class="h7 text-light">Уникальное место встречи читателя с автором и издателем</span>
                  t.me/+TauSHqa3rR4wYTYy
                  <svg width="24" height="24" viewBox="0 0 24 24" class="absolute top-1 right-1 text-gray-500 group-hover:text-gray-600 fill-current">
                    <use xlink:href="/assets/icons/svg/target-blank.svg#target-blank"></use>
                  </svg>
                </a>
              </li>
              <li class="w-full">
                <a class="group relative w-full flex flex-col gap-1 bg-color-neutral_light-gray-2 rounded-sm mb-h p-2"
                   href="https://vk.com/rugram_books" target="_blank">
                  <span class="text-semibold">RUGRAM ВКонтакте</span>
                  <span class="h7 text-light">Листайте нас под утренний кофе!</span>
                  vk.com/rugram_books
                  <svg width="24" height="24" viewBox="0 0 24 24" class="absolute top-1 right-1 text-gray-500 group-hover:text-gray-600 fill-current">
                    <use xlink:href="/assets/icons/svg/target-blank.svg#target-blank"></use>
                  </svg>
                </a>
              </li>
              <li class="w-full">
                <a class="group relative w-full flex flex-col gap-1 bg-color-neutral_light-gray-2 rounded-sm mb-h p-2"
                   href="https://dzen.ru/k_slovu_rugram?tab=articles" target="_blank">
                  <span class="text-semibold">Наш Дзен</span>
                  <span class="h7 text-light">Любимые авторы в малых жанрах и "острых" формах!</span>
                  dzen.ru/k_slovu_rugram
                  <svg width="24" height="24" viewBox="0 0 24 24" class="absolute top-1 right-1 text-gray-500 group-hover:text-gray-600 fill-current">
                    <use xlink:href="/assets/icons/svg/target-blank.svg#target-blank"></use>
                  </svg>
                </a>
              </li>
              <li class="w-full">
                <a class="group relative w-full flex flex-col gap-1 bg-color-neutral_light-gray-2 rounded-sm mb-h p-2"
                   href="https://www.youtube.com/@k_slovu_rugram/shorts" target="_blank">
                  <span class="text-semibold">Мы на YouTube</span>
                  <span class="h7 text-light mr-4">Репортажи и интервью, распаковка и обзоры книг</span>
                  youtube.com/@k_slovu_rugram
                  <svg width="24" height="24" viewBox="0 0 24 24" class="absolute top-1 right-1 text-gray-500 group-hover:text-gray-600 fill-current">
                    <use xlink:href="/assets/icons/svg/target-blank.svg#target-blank"></use>
                  </svg>
                </a>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
	</div>
</section>
