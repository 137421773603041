<!-- @format -->

<section class="box d-flex jc-between ai-start">
	<!--	<div class="statistics">-->
	<!--		<div class="left d-flex rounded-sm bordered bg-color-log-print_light-blue p-3">-->
	<!--			<div class="d-flex ai-center jc-between w-full">-->
	<!--				<div class="d-flex fd-column">-->
	<!--					<span class="line-height-3 text-semibold h5 mb-1">Ваш ожидаемый доход:</span>-->
	<!--					<span class="h7">{{ balance.quarter }}</span>-->
	<!--				</div>-->
	<!--				<div class="d-flex ai-center jc-end">-->
	<!--					<span class="d-block h1 text-bold text-nowrap mr-h">-->
	<!--						{{-->
	<!--							balance.value-->
	<!--								? (balance.value | mask: "separator.2" | replace: "\\.":",")-->
	<!--								: "?"-->
	<!--						}}-->
	<!--					</span>-->
	<!--					<span class="d-block text-weight-600 h2">&#x20bd;</span>-->
	<!--				</div>-->
	<!--			</div>-->
	<!--		</div>-->
	<!--		<div class="right rounded-sm bordered bg-color-neutral_white p-3">-->
	<!--			<p *ngIf="!balance.value" class="mb-0">-->
	<!--				Точная сумма будет начислена в течение {{ balanceValuePlural }}.-->
	<!--			</p>-->
	<!--			<p *ngIf="balance.value" class="mb-0">-->
	<!--				В течении {{ balanceValuePlural }} эта сумма будет перечислена по указанным вами-->
	<!--				реквизитам.-->
	<!--			</p>-->
	<!--		</div>-->
	<!--	</div>-->
	<div class="content">
		<form [formGroup]="balanceForm" class="rounded-md bordered py-1 px-3">
			<fieldset class="d-flex ai-center jc-between w-full" [disabled]="isSubmitted">
				<span class="d-block">Показать:</span>
				<fieldset class="d-flex flex-1">
					<app-dropdown-input
						class="w-full"
						[appPlaceholder]="'Все события'"
						(selected)="onSetForm($event, 'event')"
						[appData]="balanceDropdownEvents"
						[appClassList]="['sm']"
					></app-dropdown-input>
				</fieldset>
				<fieldset class="d-flex flex-1">
					<app-dropdown-input
						class="w-full"
						[appPlaceholder]="'За все время'"
						(selected)="onSetForm($event, 'date')"
						[appData]="balanceDropdownDates"
						[appClassList]="['sm']"
					></app-dropdown-input>
				</fieldset>
			</fieldset>
		</form>
		<div
			class="d-flex fd-column"
			*ngIf="balanceList.length; else balanceEmpty"
			[class.pointer-n]="isSubmitted"
		>
			<ul
				class="d-flex fd-column bg-color-neutral_white rounded-md bordered px-3 mt-1 mb-3"
				[class.opacity-h]="isSubmitted"
			>
				<li
					class="d-flex"
					*ngFor="let balance of balanceList"
					appListBalance
					[appBalance]="balance"
				></li>
			</ul>
			<app-list-pagination
				*ngIf="balanceListTotal > limit"
				class="d-block"
				[appPage]="page"
				[appLimit]="limit"
				[appTotal]="balanceListTotal"
				(selectPage)="onSelectPage($event)"
				(selectLimit)="onSelectLimit($event)"
			></app-list-pagination>
		</div>
	</div>
	<div class="advertising d-flex fd-column">
		<app-right-bar-advertising-1 class="d-block"></app-right-bar-advertising-1>
		<app-right-bar-advertising-2 class="d-block"></app-right-bar-advertising-2>
    <app-right-bar-advertising-litcovers class="d-block"></app-right-bar-advertising-litcovers>
		<app-right-bar-advertising-3 class="d-block"></app-right-bar-advertising-3>
	</div>
</section>
<ng-template #balanceEmpty>
  <div class="d-flex ai-center bg-color-neutral_white rounded-md bordered p-3 mt-1 mb-3">
    <app-svg-icon
      class="d-block mr-3"
      appIcon="emoji-sad"
      appWidth="40"
      appHeight="40"
      appViewBox="0 0 40 40"
    ></app-svg-icon>
    <div class="d-flex fd-column">
      <span class="d-block text-semibold h5 mb-h">Не найдено транзакций</span>
      <p class="d-block m-0">Нет данных за выбранный период.</p>
    </div>
  </div>
</ng-template>
