/** @format */

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { StepUploadForm } from '../../../../welcome2/core';
import { FileService } from '../../../../core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-card-book-uploaded, [appCardBookUploaded]',
  templateUrl: './card-book-uploaded.component.html',
  styleUrls: ['./card-book-uploaded.component.scss']
})
export class CardBookUploadedComponent implements OnInit {
  @Input()
  set appUpload(upload: StepUploadForm) {
    this.upload = upload;

    // prettier-ignore
    // eslint-disable-next-line max-len
    this.uploadExtension = this.uploadFile.includes(this.upload.fileExtension) ? 'file' : this.uploadImage.includes(this.upload.fileExtension) ? 'image' : undefined;

    if (this.uploadExtension === 'image') {
      this.fileService.getUpload(this.upload.fileId).subscribe({
        next: (blob: string) => {
          // @ts-ignore
          this.upload.fileUrl = this.domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(blob));
        },
        error: (error: any) => console.error(error)
      });
    }
  }

  @Output() remove = new EventEmitter<void>();

  upload: StepUploadForm | undefined;
  uploadFile: string[] = ['pdf', 'doc', 'docx', 'odt', 'rtf', 'txt', 'mp3'];
  uploadImage: string[] = ['jpg', 'jpeg', 'png', 'svg', 'tiff'];
  uploadExtension: string | undefined;

  constructor(private fileService: FileService, private domSanitizer: DomSanitizer) {}

  ngOnInit(): void {}
}
