<!-- @format -->

<ng-container *ngIf="user$ | async as user">
<section class="box d-flex jc-between ai-start">
	<div class="contract bg-color-neutral_white rounded-sm bordered p-3">
		<h3 class="d-block mb-0">Заполните договор о взаимном сотрудничестве (лицензионный договор)</h3>
		<app-services-progress
			class="d-block my-3"
			[appMap]="progressMap"
		></app-services-progress>
		<ng-container [ngSwitch]="progressState">
			<ng-container *ngSwitchCase="'initial'">
				<div class="d-flex ai-start bg-color-log-print_yellow-25 rounded-sm p-3 my-3">
					<app-svg-icon
						class="d-block mr-3"
						appIcon="attention-round-yellow"
						appWidth="40"
						appHeight="40"
						appViewBox="0 0 40 40"
					></app-svg-icon>
					<div class="h6 text-color-log-print_text line-height-3">
						Обратите внимание, что
						<span class="text-weight-600">
              право использования литературных произведений передается на условиях неисключительной лицензии.
						</span>
            Мы никогда не делаем правообладателям "неприличных предложений"
            связанных с передачей исключительного права использовать произведение без их разрешения.
					</div>
				</div>
				<span class="d-block mb-3">Выберите тип договора:</span>
				<form [formGroup]="contractForm">
					<fieldset [disabled]="contractFormIsSubmitting">
						<ul
							class="contract-list d-flex jc-between pl-0 m-0"
							*ngIf="contractForm.get('contractId') as contractId"
						>
							<li
								class="d-flex w-full"
								*ngFor="let contract of contractList; let i = index"
							>
								<fieldset
									class="position-r rounded-md w-full"
									*ngIf="{value: contractId.value === contract.id} as active"
									[class.active]="active.value"
								>
									<input
										[id]="'contract-id-' + i"
										type="radio"
										formControlName="contractId"
										[value]="contract.id"
										hidden
									/>
									<label [for]="'contract-id-' + i">
										<span class="d-block cursor-p py-5 px-3">
											{{ i + 1 }}. {{ contract.name }}
										</span>
									</label>
									<app-svg-icon
										class="d-block active-icon"
										*ngIf="active.value"
										appIcon="success-round-green"
										appWidth="23"
										appHeight="24"
										appViewBox="0 0 23 24"
									></app-svg-icon>
								</fieldset>
							</li>
						</ul>
					</fieldset>
				</form>
			</ng-container>
			<ng-container *ngSwitchCase="'application'">
				<div class="d-flex ai-start bg-color-log-print_yellow-25 rounded-sm p-3 my-3">
					<app-svg-icon
						class="d-block mr-3"
						appIcon="attention-round-yellow"
						appWidth="40"
						appHeight="40"
						appViewBox="0 0 40 40"
					></app-svg-icon>
					<div class="h6 text-color-log-print_text line-height-3">
						<div>
							Пожалуйста, заполните <span class="text-weight-600">все поля формы</span>
							для договора <span class="text-weight-600">"{{ contractSelected?.name }}"</span>.
							При отсутствии данных поставьте прочерк.
						</div>
					</div>
				</div>
				<span class="d-block mb-3">Лицензиар:</span>
				<form [formGroup]="applicationForm">
					<fieldset
						[disabled]="applicationFormIsSubmitting"
						*ngIf="contractSelected && contractSelected.fields"
					>
						<fieldset
							class="input d-flex jc-start ai-start"
							[class.mb-1]="!l"
							*ngFor="let field of contractSelected.fields; let l = last"
							[disabled]="field.is_disabled"
						>
							<label class="d-block text-color-neutral_gray-1 h7 mb-1" [for]="field.key">
								{{ field.title }}:
							</label>
							<input
								[id]="field.key"
								[formControlName]="field.key"
								[mask]="universalFieldMask(field.key)"
								[dropSpecialCharacters]="false"
								appInputTrimWhitespace
							/>
						</fieldset>
					</fieldset>
				</form>
			</ng-container>
			<ng-container *ngSwitchCase="'done'">
				<div class="d-flex ai-start jc-start bg-color-neutral_light-gray-2 p-3">
					<div class="d-flex ai-start jc-start mr-3">
						<app-svg-icon
							class="d-block"
							appIcon="email-green"
							appWidth="40"
							appHeight="40"
							appViewBox="0 0 40 40"
						></app-svg-icon>
					</div>
					<div class="d-block">
						<span class="d-block h3 text-bold mb-1">
							Заполненный договор отправлен вложением на адрес электронной почты указанный при регистрации
						</span>
						<p class="line-height-3 mb-1">
              Договор вступит в действие после подписания.
              Ознакомьтесь с вложением и пройдите по ссылке в письме, чтобы подписать договор и завершить
              регистрацию.
						</p>
            <p class="line-height-3 mb-5">
              После подписания договора всё, что вы сделаете в личном кабинете, будет иметь юридическое значение
              и считаться подписанным простой электронной подписью.
              Действовать договор будет до тех пор, пока вы или мы не захотим его расторгнуть.
            </p>
						<button
							class="d-flex ai-center btn btn-style-1 btn-inline px-3 rounded-md mr-3 line-height-5"
							(click)="onContractResend()"
							[disabled]="contractResendIsSubmitting"
						>
							<app-svg-icon
								class="mr-1"
								appIcon="email-black"
								appWidth="20"
								appHeight="16"
								appViewBox="0 0 20 16"
							></app-svg-icon>
							<span>Выслать письмо еще раз</span>
						</button>
					</div>
				</div>
			</ng-container>
		</ng-container>
	</div>
	<ng-container [ngSwitch]="progressState">
		<div class="content" *ngSwitchCase="'initial'">
			<div class="d-flex ai-center jc-end">
				<button
					class="btn btn-style-1 btn-inline rounded-md lg d-flex ai-center px-3"
					[disabled]="contractForm.invalid"
					(click)="onSubmitContractForm()"
				>
					<span>Продолжить</span>
					<app-svg-icon
						class="ml-1"
						appIcon="arrow-right-black"
						appWidth="15"
						appHeight="15"
						appViewBox="0 0 15 14"
					></app-svg-icon>
				</button>
			</div>
		</div>
		<div class="content" *ngSwitchCase="'application'">
			<div class="d-flex ai-center jc-between">
				<button
					class="btn btn-style-2 btn-inline rounded-md lg d-flex ai-center px-3"
					(click)="onReturnToInitial()"
				>
					<app-svg-icon
						class="mr-1"
						appIcon="arrow-left-black"
						appWidth="15"
						appHeight="15"
						appViewBox="0 0 15 15"
					></app-svg-icon>
					<span>Назад</span>
				</button>
				<button
					class="btn btn-style-1 btn-inline rounded-md lg d-flex ai-center px-3"
					[disabled]="applicationForm.invalid"
					(click)="onSubmitApplicationForm()"
				>
					<app-svg-icon
						class="mr-1"
						appIcon="contract-black"
						appWidth="19"
						appHeight="20"
						appViewBox="0 0 19 20"
					></app-svg-icon>
					<span>Продолжить</span>
				</button>
			</div>
		</div>
		<div class="content" *ngSwitchCase="'done'">
			<div class="bg-color-neutral_white rounded-sm bordered p-3 mb-3">
				<div class="d-flex ai-center jc-between">
					<p class="line-height-3 mb-0">
						Вы можете скачать подготовленный для вас договор. Сформированные договоры также
						отображаются на странице вашего
						<a
							class="text-color-log-print_main-violet text-d-underline"
							[routerLink]="'/office/settings'"
						>профиля</a>.
					</p>
					<button
						class="btn btn-style-2 btn-inline rounded-md lg d-flex ai-center px-3 ml-2"
						(click)="onDownloadContract()"
					>
						<app-svg-icon
							class="mr-1"
							appIcon="download-black"
							appWidth="20"
							appHeight="21"
							appViewBox="0 0 20 21"
						></app-svg-icon>
						<span>Скачать договор</span>
					</button>
				</div>
			</div>
		</div>
	</ng-container>
	<div class="advertising d-flex fd-column">
		<div class="doc-templates mb-2" [ngClass]="templatesRla.isActive ? 'd-none' : 'd-block'">
			<div class="d-flex fd-column rounded-sm bg-color-log-print_yellow-25 bordered p-3">
				<div class="h4 text-bold line-height-1 mb-h">Шаблоны документов</div>
				<p class="h7">
					Перейдите в раздел, чтобы скачать шаблоны договоров, приложений и соглашений,
					используемых в сервисе Rugram.
				</p>
				<a
						[routerLink]="'/office/settings/templates'"
						[routerLinkActive]="['active']"
						#templatesRla="routerLinkActive"
						class="btn btn-style-1 btn-inline rounded-md d-flex ai-center jc-center"
				>
					<span>Перейти</span>
					<app-svg-icon
							class="ml-1"
							appIcon="arrow-right-black"
							appWidth="15"
							appHeight="15"
							appViewBox="0 0 15 15"
					></app-svg-icon>
				</a>
			</div>
		</div>
		<app-right-bar-advertising-1 class="d-block"></app-right-bar-advertising-1>
	</div>
</section>
</ng-container>
