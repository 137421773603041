<!-- @format -->

<div class="dropdown position-r d-flex">
	<form class="w-full" [formGroup]="inputForm">
		<fieldset
			class="input i-end d-flex ai-center w-full"
			[class.invalid]="label.invalid && label.touched"
			[ngClass]="classList"
			*ngIf="inputForm.get('label') as label"
		>
			<input
				class="cursor-p"
				formControlName="label"
				[placeholder]="placeholder"
				(keyup)="onKeyup($event)"
				#inputElement
				readonly
			/>
			<app-svg-icon
				[class.focus]="toggleDropdown"
				[@rotateAnimation]="{
					value: toggleDropdown,
					params: {to: 180 + 'deg'}
				}"
				class="d-block"
				[class.active]="toggleDropdown"
				appIcon="arrow-down"
				appWidth="24"
				appHeight="22"
				appViewBox="0 0 16 9"
			></app-svg-icon>
		</fieldset>
	</form>
	<div
		class="dropdown-inner w-full"
    data-lenis-prevent="true"
		[ngClass]="classList"
		*ngIf="toggleDropdown"
		[style.--toggle-height]="toggleHeight"
		[style.--toggle-width]="toggleWidth"
		[style.--tooltip-top]="dropdownTop"
		[style.--tooltip-left]="dropdownLeft"
		[@fadeAnimation]="{value: toggleDropdown}"
	>
		<ul class="rounded-sm cursor-p bg-color-neutral_white p-0 m-0">
			<li
				class="c-pointer text-color-neutral_gray-1"
				[class.hover]="toggleHovered === -1"
				(click)="onSelect(undefined)"
				*ngIf="inputForm.get('label')!.value !== ''"
			>
				{{ placeholder }}
			</li>
			<li
				class="c-pointer text-color-log-print_dark-gray"
				[class.hover]="toggleHovered === i"
				*ngFor="let li of data; let i = index"
				(click)="onSelect(li)"
			>
				{{ li.dropdown() }}
			</li>
		</ul>
	</div>
</div>
