/** @format */

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replace'
})
export class ReplacePipe implements PipeTransform {
  transform(value: string, a: string, b?: string): string {
    return value.replace(new RegExp(a, 'gmi'), b || '');
  }
}
