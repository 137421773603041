/** @format */

import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HelperService, User, UserService } from '../../core';

interface LoginForm {
  email: FormControl<string | null>;
  password: FormControl<string | null>;
}

@Component({
  selector: 'app-auth-login',
  templateUrl: './auth-login.component.html',
  styleUrls: ['./auth-login.component.scss']
})
export class AuthLoginComponent {
  isSubmitting = false;

  loginForm: FormGroup;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private helperService: HelperService,
    private formBuilder: FormBuilder
  ) {
    this.loginForm = this.formBuilder.group<LoginForm>({
      email: this.formBuilder.control('', [Validators.required, Validators.email]),
      password: this.formBuilder.control('', [Validators.required, Validators.minLength(6)])
    });
  }

  onSubmitForm(): void {
    if (this.helperService.getFormValidation(this.loginForm)) {
      this.getAuthentication(this.loginForm.value);
    }
  }

  getAuthentication(body: any): void {
    this.isSubmitting = true;

    this.userService.postLogin(body).subscribe({
      next: (user: User) => {
        this.router
          .navigate([user.cabinetAvailable ? '/office' : '/'])
          .then(() => console.debug('Route changed'));
      },
      error: () => (this.isSubmitting = false)
    });
  }
}
