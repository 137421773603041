/** @format */

import { Component, OnDestroy, OnInit } from '@angular/core';
import { ChildrenOutletContexts } from "@angular/router";

@Component({
  selector: 'app-office-help',
  templateUrl: './office-help.component.html',
  styleUrls: ['./office-help.component.scss'],
})
export class OfficeHelpComponent implements OnInit, OnDestroy {
  constructor(private contexts: ChildrenOutletContexts) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {}

  getRouteAnimationData() {
    return this.contexts.getContext('primary')?.route?.snapshot?.data?.['animation'];
  }
}
