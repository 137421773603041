/** @format */

import { Component, Output, EventEmitter, Input } from '@angular/core';
import { collapseAnimation } from '../../../app-animations';

@Component({
  selector: 'app-collapse',
  templateUrl: './collapse.component.html',
  animations: [collapseAnimation]
})
export class CollapseComponent {
  @Input()
  set appToggle(toggle: boolean) {
    this.toggle = toggle;
  }

  @Output() collapsed = new EventEmitter<boolean>();

  toggle: boolean | undefined;

  onClick(event: MouseEvent): void {
    // @ts-ignore
    if (!event.target.dataset.ignoreCollapse) {
      this.toggle = !this.toggle;

      this.collapsed.emit(this.toggle);
    }
  }
}
