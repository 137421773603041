/** @format */

import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HelperService, SnackbarService, UserService } from '../../core';

interface RegistrationForm {
  name: FormControl<string | null>;
  surname: FormControl<string | null>;
  email: FormControl<string | null>;
  password: FormControl<string | null>;
  password_confirmation: FormControl<string | null>;
}

@Component({
  selector: 'app-auth-registration',
  templateUrl: './auth-registration.component.html',
  styleUrls: ['./auth-registration.component.scss']
})
export class AuthRegistrationComponent {
  isSubmitting = false;

  registrationForm: FormGroup;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private helperService: HelperService,
    private formBuilder: FormBuilder,
    private snackbarService: SnackbarService
  ) {
    this.registrationForm = this.formBuilder.group<RegistrationForm>({
      name: this.formBuilder.control('', [Validators.required]),
      surname: this.formBuilder.control('', [Validators.required]),
      email: this.formBuilder.control('', [Validators.required, Validators.email]),
      password: this.formBuilder.control('', [Validators.required, Validators.minLength(6)]),
      password_confirmation: this.formBuilder.control('', [
        Validators.required,
        Validators.minLength(6)
      ])
    });
  }

  onSubmitForm(): void {
    if (this.helperService.getFormValidation(this.registrationForm)) {
      this.getAuthentication(this.registrationForm.value);
    }
  }

  getAuthentication(body: any): void {
    this.isSubmitting = true;

    this.userService.postRegistration(body).subscribe({
      next: (response: any) => {
        this.router
          .navigate(['/auth/sms'], {
            queryParams: {
              token: response.token
            }
          })
          .then(() =>
            this.snackbarService.success(
              'Для завершения создания профиля, чтобы мы могли защитить ваш аккаунт, предлагать персонализированную поддержку, и удовлетворять ваши заявки на вывод средств, нам необходим ваш действительный номер телефона',
              8000
            )
          );
      },
      error: () => (this.isSubmitting = false)
    });
  }
}
