/** @format */

import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared';
import { OfficeBalanceComponent } from './office-balance.component';
import { OfficeBalanceRoutingModule } from './office-balance-routing.module';
import { ListBalanceComponent } from './shared/list-balance/list-balance.component';

@NgModule({
  imports: [SharedModule, OfficeBalanceRoutingModule],
  declarations: [OfficeBalanceComponent, ListBalanceComponent]
})
export class OfficeBalanceModule {}
