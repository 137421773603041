/** @format */

import { animate, animateChild, group, query,style } from '@angular/animations';

export const flipInOut = [  // nice1
  style({ position: 'relative' }),
  query(':enter, :leave', [
    style({
      position: 'absolute',
      width: '100%',
      transform: 'scale(1) rotateY(0)',
      transformStyle: 'flat',
      backfaceVisibility: 'inherit',
    })
  ], { optional: true }),
  query(':enter', [
    style({ transform: 'scale(.95) rotateY(90deg)' })
  ], { optional: true }),
  query(':leave', animateChild(), { optional: true }),
  group([
    query(':leave', [
      animate('300ms ease-in', style({ transform: 'scale(.95) rotateY(-90deg)' }))
    ], { optional: true }),
    query(':enter', [
      animate('300ms 300ms cubic-bezier(0.35, 0, 0.25, 1)', style({ transform: 'scale(1) rotateY(0)' }))
    ], { optional: true }),
    query('@*', animateChild(), { optional: true })
  ]),
];

export const slideInOut = [
  style({ position: 'relative' }),
  query(':enter, :leave', [
    style({
      position: 'absolute',
      // top: 0,
      right: 0,
      width: '100%'
    })
  ], { optional: true }),
  query(':enter', [
    style({ right: '-10%' })
  ], { optional: true }),
  query(':leave', animateChild(), { optional: true }),
  group([
    query(':leave', [
      animate('300ms ease-out', style({ opacity: 0 }))
    ], { optional: true }),
    query(':enter', [
      animate('300ms ease-out', style({ right: '0%' }))
    ], { optional: true }),
    query('@*', animateChild(), { optional: true })
  ]),
];

export const fadeIn = [
  style({ position: 'relative' }),
  query(':enter, :leave', [
    style({
      position: 'absolute',
      left: 0,
      width: '100%',
      opacity: 0,
      transform: 'scale(0.95) xtranslateY(-5%)',
    }),
  ]),
  query(':enter', [
    animate('200ms ease-out', style({ opacity: 1, transform: 'scale(1) xtranslateY(0)' })),
  ], { optional: true })
];

export const none = [
  style({ opacity: 1 }),
  group([
    query(':leave', [
      style({ opacity: 1 })
    ], { optional: true }),
    query('@*', animateChild(), { optional: true })
  ]),
];
