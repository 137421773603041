<!-- @format -->

<div class="box w-full d-flex" @showIn>
	<div class="inner d-flex jc-between rounded-sm bg-color-log-print_yellow-25 bordered">
    <div class="h4 text-bold line-height-1 mb-h">Шаблоны документов</div>
    <p class="h7">
      Перейдите в раздел, чтобы скачать шаблоны договоров, приложений и соглашений,
      используемых в сервисе Rugram.
    </p>
    <a
      [routerLink]="'/office/settings/templates'"
      [routerLinkActive]="['active']"
      #templatesRla="routerLinkActive"
      class="btn btn-style-1 btn-inline rounded-md d-flex ai-center jc-center"
    >
      <span>Перейти</span>
      <app-svg-icon
        class="ml-1"
        appIcon="arrow-right-black"
        appWidth="15"
        appHeight="15"
        appViewBox="0 0 15 15"
      ></app-svg-icon>
    </a>
	</div>
</div>
