/** @format */

import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import {
  Balance,
  BalanceService,
  BalanceTransaction,
  Dropdown,
  getDropdown,
  HelperService,
  Transaction
} from '../../core';
import { map } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import * as dayjs from 'dayjs';
import isYesterday from 'dayjs/plugin/isYesterday';
import 'dayjs/locale/ru';

dayjs.extend(isYesterday);
dayjs.locale('ru');

interface BalanceForm {
  event: FormControl<string | null>;
  date: FormControl<string | null>;
}

@Component({
  selector: 'app-office-balance',
  templateUrl: './office-balance.component.html',
  styleUrls: ['./office-balance.component.scss']
})
export class OfficeBalanceComponent implements OnInit, OnDestroy {
  routeData$: Subscription | undefined;

  balanceForm: FormGroup;
  balanceForm$: Subscription | undefined;

  balance!: Balance;
  balanceList: Transaction[] = [];
  balanceListTotal = 10;
  balanceValuePlural: string | undefined;

  balanceDropdownEvents: Dropdown[] = getDropdown('balanceServices');
  balanceDropdownDates: Dropdown[] = getDropdown('balanceDates');

  isSubmitted = false;

  limit = 10;
  page = 1;

  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private balanceService: BalanceService,
    private helperService: HelperService
  ) {
    this.balanceForm = formBuilder.group<BalanceForm>({
      event: this.formBuilder.control('', [Validators.required]),
      date: this.formBuilder.control('', [Validators.required])
    });
  }

  ngOnInit(): void {
    this.routeData$ = this.route.data.pipe(map((data: any) => data.data)).subscribe({
      next: (balanceTransaction: BalanceTransaction) => {
        this.balanceList = balanceTransaction.transactions.items;
        this.balanceListTotal = balanceTransaction.transactions.totalItems;

        this.balance = balanceTransaction.balance;
        this.balanceValuePlural = this.getPlural(balanceTransaction);
      },
      error: (error: any) => console.error(error)
    });

    this.balanceForm$ = this.balanceForm.valueChanges.subscribe({
      next: () => this.getBalanceList(),
      error: (error: any) => console.error(error)
    });
  }

  ngOnDestroy(): void {
    [this.routeData$, this.balanceForm$].forEach($ => $?.unsubscribe());
  }

  onSetForm(dropdown: Dropdown, formControl: string): void {
    const control = this.balanceForm.get(formControl);

    if (dropdown) {
      control?.setValue(dropdown.value);
      this.page = 1;
    } else {
      control?.reset();
    }
  }

  onSelectLimit(limit: number): void {
    this.limit = limit;
    this.page = 1;

    this.getBalanceList();
  }

  onSelectPage(page: number | string): void {
    this.page = Number(page);

    this.getBalanceList();
  }

  getBalanceList(): void {
    let params: any = {
      page: this.page,
      limit: this.limit
    };

    if (this.balanceForm.get('date')?.valid) {
      const value: string = this.balanceForm.get('date')?.value;
      const isYesterday: boolean = dayjs(value).isYesterday();

      params = {
        ...params,
        date_start: value,
        date_end: isYesterday ? value : dayjs().format('YYYY-MM-DD')
      };
    }

    if (this.balanceForm.get('event')?.valid) {
      params = {
        ...params,
        service_key: this.balanceForm.get('event')?.value
      };
    }

    this.isSubmitted = true;

    this.balanceService.getAll(params).subscribe({
      next: (balanceTransaction: BalanceTransaction) => {
        this.balanceList = balanceTransaction.transactions.items;
        this.balanceListTotal = balanceTransaction.transactions.totalItems;

        this.balance = balanceTransaction.balance;
        this.balanceValuePlural = this.getPlural(balanceTransaction);

        this.isSubmitted = false;
      },
      error: () => (this.isSubmitted = false)
    });
  }

  getPlural(balanceTransaction: BalanceTransaction): string {
    let days: number = dayjs(balanceTransaction.balance.quarter_end).diff(dayjs(), 'day');

    if (balanceTransaction.balance.value) {
      days = dayjs(balanceTransaction.balance.quarter_end).add(2, 'month').diff(dayjs(), 'day');
    }

    return days + ' ' + this.helperService.getPlural(['день', 'дня', 'дней'], days);
  }
}
