<!-- @format -->

<div class="box bg-color-neutral_white rounded-md bordered">
	<div class="d-flex fd-row gap-1 jc-between ai-center mb-1">
		<h1 class="mb-5">Вход</h1>
		<a class="d-block h7 text-d-underline mb-2" [routerLink]="'/auth/registration'">
			Хочу зарегистрироваться
		</a>
	</div>
	<form [formGroup]="loginForm" (ngSubmit)="onSubmitForm()">
		<fieldset [disabled]="isSubmitting">
			<fieldset class="input d-flex fd-row jc-between ai-center mb-1">
				<label class="d-block h5" for="email">Ваш e-mail:</label>
				<input
					id="email"
					formControlName="email"
					autocomplete="email"
				/>
			</fieldset>
			<fieldset
				class="input i-end d-flex fd-row jc-between ai-center mb-1"
				*ngIf="{value: false} as isVisible"
			>
				<label class="d-block h5" for="password">Пароль:</label>
				<input
					id="password"
					[type]="isVisible.value ? 'text' : 'password'"
					formControlName="password"
					autocomplete="current-password"
				/>
				<app-svg-icon
					class="password"
					[appIcon]="isVisible.value ? 'eye-off' : 'eye'"
					[appWidth]="24"
					[appHeight]="24"
					[appViewBox]="'0 0 24 24'"
					(click)="isVisible.value = !isVisible.value"
				></app-svg-icon>
			</fieldset>
			<div class="d-flex gap-1 jc-between ai-center my-5">
				<a class="extra d-block h7 text-d-underline" [routerLink]="'/auth/reset'">
					Забыли пароль?
				</a>
				<a class="d-block h7 text-d-underline" [routerLink]="'/'">
					Вернуться
				</a>
			</div>
			<button
				class="extra btn btn-style-1 btn-inline lg text-semibold rounded-md px-9"
				[disabled]="loginForm.invalid"
				data-cy="login"
			>
				<app-svg-icon
					class="mr-h"
					appIcon="share-right"
					appWidth="20"
					appHeight="16"
					appViewBox="0 0 20 16"
				></app-svg-icon>
				Войти
			</button>
		</fieldset>
	</form>
</div>
