/** @format */

import { NgModule } from '@angular/core';
import { WelcomeComponent } from './welcome.component';
import { WelcomeRoutingModule } from './welcome-routing.module';
import { WizardService } from './core';
import { SharedModule } from '../shared';
import {
  // FormEditComponent,
  // FormUploadComponent,
  FormBookDraftComponent,
  ResumeComponent,
  SuccessComponent
} from './shared';
import { NgxFileDropModule } from "ngx-file-drop";

@NgModule({
  imports: [
    WelcomeRoutingModule,
    SharedModule,
    NgxFileDropModule
  ],
  declarations: [
    WelcomeComponent,
    // FormEditComponent,
    // FormUploadComponent,
    FormBookDraftComponent,
    ResumeComponent,
    SuccessComponent
  ],
  providers: [WizardService]
})
export class WelcomeModule {}
