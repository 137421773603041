/** @format */

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import {
  Notification,
  NotificationsStatusMap,
  NotificationsService,
  ServicesMap,
  getServiceMap,
  OffersService,
  SnackbarService,
  Book,
  getButtonsByNotificationType,
  User,
  UserService,
  ContractService
} from '../../../../core';
import { rotateAnimation } from '../../../../app-animations';
import { Subscription } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import * as dayjs from 'dayjs';
import 'dayjs/locale/ru';

dayjs.locale('ru');

type NotificationGrid = 'default' | 'book-detail' | 'home';

@Component({
  selector: 'app-list-notification, [appListNotification]',
  templateUrl: './list-notification.component.html',
  styleUrls: ['./list-notification.component.scss'],
  animations: [rotateAnimation]
})
export class ListNotificationComponent implements OnInit, OnDestroy {
  @Output() callManager = new EventEmitter<void>();
  @Output() offerTransfer = new EventEmitter<Book>();

  @Input()
  set appNotification(notification: Notification) {
    this.notification = notification;
    this.notificationServiceMap = getServiceMap(notification.service);

    this.serviceTypeButton = getButtonsByNotificationType(notification.type);
  }

  @Input()
  set appNotificationGrid(notificationGrid: NotificationGrid) {
    this.notificationGrid = notificationGrid;
  }

  user$!: Subscription;
  isImported = false;
  hasContract = false;
  hasContractSigned = false;

  notification!: Notification;
  notificationGrid: NotificationGrid = 'default';
  notificationServiceMap: ServicesMap | undefined;
  notificationStatusMap: any = NotificationsStatusMap;

  toggleCollapse = false;

  offerResendIsSubmit = false;
  contractResendIsSubmit = false;

  serviceTypeButton: string[] = [];
  serviceType: any = {
    ['print_for_yourself']: 'print-for-self',
    ['marketing']: 'distribution',
    ['audio_book']: 'audiobook',
    ['self_publish_audiobook']: 'audiobook',
    ['self_publish_ebook']: 'ebook'
  };

  constructor(
    private notificationsService: NotificationsService,
    private offersService: OffersService,
    private snackbarService: SnackbarService,
    private contractService: ContractService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.user$ = this.userService.currentUser.subscribe({
      next: (user: User) => {
        this.isImported = !!user.is_imported;
        /** Disable any contract notifications for imported users */
        if (!this.isImported) {
          this.hasContract = !!user.contract;
          if (this.hasContract) {
            this.hasContractSigned = !!user.contract.signed_at;
          }
        }
      },
      error: (error: any) => console.error(error)
    });
  }

  ngOnDestroy(): void {
    [this.user$].forEach($ => $?.unsubscribe());
  }

  onCollapse(toggleCollapse: boolean): void {
    this.toggleCollapse = toggleCollapse;

    if (!this.notification.read_at) {
      this.notificationsService.postById(this.notification.id).subscribe({
        next: () => (this.notification.read_at = dayjs().format('YYYY-MM-DD HH:mm:ss')),
        error: (error: any) => console.error(error)
      });
    }
  }

  onOfferResend(book: Book | undefined): void {
    if (book) {
      this.offerResendIsSubmit = true;

      this.offersService.postOfferResend(book.id).subscribe({
        next: () => {
          this.offerResendIsSubmit = false;

          this.snackbarService.success('Мы отправили письмо вам на почту', 4000);
        },
        error: () => (this.offerResendIsSubmit = false)
      });
    }
  }

  onContractResend(): void {
    this.userService.currentUser
      .pipe(
        filter((user: User) => !!user.contract.id),
        first()
      )
      .subscribe({
        next: (user: User) => {
          this.contractResendIsSubmit = true;

          this.contractService.postContractResend(user.contract.id).subscribe({
            next: () => {
              this.contractResendIsSubmit = false;

              this.snackbarService.success('Мы отправили письмо вам на почту', 4000);
            },
            error: () => (this.contractResendIsSubmit = false)
          });
        },
        error: (error: any) => console.error(error)
      });
  }
}
