/** @format */

import { Component, Input, OnInit } from '@angular/core';
import { Book, User, UserService } from '../../../../core';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-contract-initial',
  templateUrl: './contract-initial.component.html',
  styleUrls: ['./contract-initial.component.scss']
})
export class ContractInitialComponent implements OnInit {
  @Input()
  set appBook(book: Book) {
    this.book = book;
  }

  user$: Observable<User> | undefined;

  book!: Book;

  constructor(private userService: UserService) {}

  ngOnInit(): void {
    this.user$ = this.userService.currentUser;
  }
}
