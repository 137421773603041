/** @format */

import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Offer, OfferDetailMap, OfferList } from '../models';

@Injectable({
  providedIn: 'root'
})
export class OffersService {
  constructor(private apiService: ApiService) {}

  getAll(params?: any): Observable<OfferList> {
    return this.apiService.get('/v1/offers', params).pipe(map((data: any) => data.data));
  }

  getOfferByBookId(bookId: number): Observable<Offer> {
    return this.apiService.get(`/v1/offers/byBook/${bookId}`).pipe(map((data: any) => data.data));
  }

  getByBookId(bookId: number): Observable<OfferDetailMap> {
    return this.apiService.get(`/v1/offers/${bookId}`).pipe(map((data: any) => data.data));
  }

  postOfferSign(bookId: number, body: any): Observable<{ message: string }[]> {
    return this.apiService
      .post(`/v1/offers/${bookId}/sign`, body)
      .pipe(map((data: any) => data.data));
  }

  postOfferResend(bookId: number): Observable<{ message: string }[]> {
    return this.apiService
      .post(`/v1/offers/${bookId}/resendMail`, {})
      .pipe(map((data: any) => data.data));
  }

  postOfferHide(offerId: number): Observable<{ message: string }[]> {
    return this.apiService
      .post(`/v1/offers/${offerId}/hide`, {})
      .pipe(map((data: any) => data.data));
  }
}
