import { Injectable } from '@angular/core';
import { AbstractControl, ValidatorFn } from '@angular/forms';
import examples from 'libphonenumber-js/mobile/examples';
import {
  parsePhoneNumberFromString,
  parsePhoneNumberWithError,
  getExampleNumber,
  CountryCode,
  PhoneNumber,
  AsYouType,
  isValidPhoneNumber, parsePhoneNumber,
} from 'libphonenumber-js/mobile';
import intlTelInput, { CountryData } from 'intl-tel-input';

export { intlTelInput };
export { CountryData };

@Injectable({
  providedIn: 'root',
})
export class IntlTelInputService {

  getPhoneNumber(value: string, country: string): PhoneNumber | undefined {
    return parsePhoneNumberFromString(value, {
      defaultCountry: country?.toUpperCase() as CountryCode,
      extract: false
    });
  }

  parsePhoneNumber(value: string, country?: string): PhoneNumber | undefined {
    return parsePhoneNumberWithError(value, {
      defaultCountry: country?.toUpperCase() as CountryCode,
      extract: true
    });
  }

  /**
   * Transform a given phone number to international format
   */
  getInternational(value: string, country: string): string|undefined {
    return this.getPhoneNumber(value, country)?.formatInternational();
  }

  /**
   * Transform a given phone number to its national format
   */
  getNational(value: string, country: string): string|undefined {
    return this.getPhoneNumber(value, country)?.formatNational();
  }

  parsePhoneNumber2(value: string): PhoneNumber | undefined {
    return parsePhoneNumber(value, { defaultCountry: 'RU' });
  }

  /**
   * International format without inner spaces (PNF.E164)
   *
   * Instead of "+359 88 123" it will return "+35988123"
   */
  getPlain(value: string, country: string): string {
    const phoneNumber: PhoneNumber|undefined = parsePhoneNumberFromString(
      value,
      country?.toUpperCase() as CountryCode
    );

    if (phoneNumber) {
      return phoneNumber.number.toString();
    }

    return value ? value.replace(/ /g, '') : value;
  }

  getExampleNumber(phoneInput: intlTelInput.Plugin) {
    const countryData: CountryData = phoneInput.getSelectedCountryData();
    const phoneNumber: PhoneNumber|undefined = getExampleNumber(countryData?.iso2?.toUpperCase() as CountryCode, examples);
    return phoneNumber?.formatInternational();
  }
  getExampleInternationalNumberForCountry(country: string) {
    const phoneNumber = getExampleNumber(country?.toUpperCase() as CountryCode, examples);
    return phoneNumber ? phoneNumber.formatInternational() : null;
  }
  getRawExampleInternationalNumberForCountry(country: string) {
    return getExampleNumber(country?.toUpperCase() as CountryCode, examples);
  }

  /**
   * Is valid phone number?
   *
   * The method will return true when arguments relates to a
   * phone number from the selected country.
   */
  isValid(value: string, country: string): boolean {
    const phoneNumber: PhoneNumber|undefined = parsePhoneNumberFromString(
      value,
      country as CountryCode
    );

    if (!phoneNumber) {
      return false;
    }

    return phoneNumber.isValid();
  }

  /**
   * Reactive form validator
   *
   * Will set *phoneInvalid* property in related form control error's object.
   */
  isValidFormControl(country: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any }|null => {
      if (!control.value) {
        return null;
      }

      try {
        return !this.isValid(control.value, country)
          ? { phoneInvalid: true }
          : null;
      } catch (e) {
        return { phoneInvalid: true };
      }
    };
  }

  isValidPhoneFormControl2(telInput: intlTelInput.Plugin): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any }|null => {
      if (!control.value) { return null; }
      try {
        const countryData: CountryData = telInput.getSelectedCountryData();
        const phoneNumber = this.getPhoneNumber(control.value, countryData.iso2);
        if (phoneNumber?.country !== countryData.iso2?.toUpperCase()) { return { ANOTHER_COUNTRY: true }; };
        if (!isValidPhoneNumber(phoneNumber?.formatInternational(), phoneNumber?.country)) { return { invalid: true }; }
        return null;
      } catch (e: any) {
        if (e.name === 'ParseError') { return {[e.message]: true}; };
        return { invalid: true };
      }
    };
  }
}
