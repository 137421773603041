/** @format */

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OfficeServiceComponent } from './office-service.component';
import { OfficeServiceAudiobookComponent } from './audiobook/audiobook.component';
import { OfficeServiceAudiobookResolverService } from './audiobook/audiobook-resolver.service';
import { OfficeServiceContractComponent } from './contract/contract.component';
import { OfficeServiceDkpComponent } from "./dkp/dkp.component";
import { OfficeServiceContractResolverService } from './contract/contract-resolver.service';
import { OfficeServiceDkpResolverService } from './dkp/dkp-resolver.service';
import { OfficeServiceDistributionComponent } from './distribution/distribution.component';
import { OfficeServiceDistributionResolverService } from './distribution/distribution-resolver.service';
import { OfficeServiceEbookComponent } from './ebook/ebook.component';
import { OfficeServiceEbookResolverService } from './ebook/ebook-resolver.service';
import { OfficeServicePrintForSelfComponent } from './print-for-self/print-for-self.component';
import { OfficeServicePrintForSelfResolverService } from './print-for-self/print-for-self-resolver.service';
import { ContractGuard } from '../../core';
// TODO: hidden by https://kostylworks.atlassian.net/browse/RR-500
const routes: Routes = [
  {
    path: '',
    component: OfficeServiceComponent
  },
  /*{
    path: 'audiobook',
    component: OfficeServiceAudiobookComponent,
    data: {
      breadcrumb: {
        label: 'Аудиокнига',
        routerLink: '/office/services/audiobook'
      }
    },
    resolve: {
      data: OfficeServiceAudiobookResolverService
    }
  },*/
  {
    path: 'contract',
    component: OfficeServiceContractComponent,
    canActivate: [ContractGuard],
    data: {
      breadcrumb: {
        label: 'Подписание договора',
        routerLink: '/office/services/contract'
      }
    },
    resolve: {
      data: OfficeServiceContractResolverService
    }
  },
  {
    path: 'dkp',
    component: OfficeServiceDkpComponent,
    data: {
      breadcrumb: {
        label: 'Передача товаров для распространения',
        routerLink: '/office/services/dkp'
      }
    },
    resolve: {
      data: OfficeServiceDkpResolverService
    }
  },
  // {
  //   path: 'distribution',
  //   component: OfficeServiceDistributionComponent,
  //   data: {
  //     breadcrumb: {
  //       label: 'Маркетинговое продвижение',
  //       routerLink: '/office/services/distribution'
  //     }
  //   },
  //   resolve: {
  //     data: OfficeServiceDistributionResolverService
  //   }
  // },
  // {
  //   path: 'ebook',
  //   component: OfficeServiceEbookComponent,
  //   data: {
  //     breadcrumb: {
  //       label: 'Электронная книга',
  //       routerLink: '/office/services/ebook'
  //     }
  //   },
  //   resolve: {
  //     data: OfficeServiceEbookResolverService
  //   }
  // },
  {
    path: 'print-for-self',
    component: OfficeServicePrintForSelfComponent,
    data: {
      breadcrumb: {
        label: 'Печать для себя',
        routerLink: '/office/services/print-for-self'
      }
    },
    resolve: {
      data: OfficeServicePrintForSelfResolverService
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OfficeServiceRoutingModule {}
