/** @format */

import { Component, EventEmitter, Input, OnInit, OnDestroy, Output } from '@angular/core';
import { HelperService, OfferDetail, OfferDetailMap, OffersService } from '../../../../core';
import { Subscription } from 'rxjs';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { debounceTime, filter } from 'rxjs/operators';
import { Router } from '@angular/router';

interface OfferForm {
  [key: string]: FormControl<boolean | null>;
}

@Component({
  selector: 'app-offer-sign',
  templateUrl: './offer-sign.component.html',
  styleUrls: ['./offer-sign.component.scss']
})
export class OfferSignComponent implements OnInit, OnDestroy {
  @Input()
  set appOfferMap(offerMap: OfferDetailMap) {
    this.offerMap = offerMap;

    const offerDetail: OfferDetail = Object.values(this.offerMap)[0] as OfferDetail;

    this.bookId = Number(offerDetail.book.id);
    this.bookTitle = offerDetail.book.title;
    this.bookHasDistribution = this.offerMap.offer.book_copyright_status === 1;

    if (this.offerMap?.offer.book_copyright_status === 1) {
      // prettier-ignore
      this.offerForm.addControl('book_distribution', new FormControl(true, Validators.requiredTrue));
    }

    if (this.offerMap?.offer.ebook_copyright_status === 1) {
      this.offerForm.addControl('ebook_distribution', new FormControl(false));
    }

    if (this.offerMap?.offer.audio_book_copyright_status === 1) {
      this.offerForm.addControl('audio_distribution', new FormControl(false));
    }

    this.formHasControls = !!Object.keys(this.offerForm.controls).length;
  }

  @Output() closed = new EventEmitter<any>();

  offerMap: OfferDetailMap | undefined;

  offerForm: FormGroup;
  offerForm$: Subscription | undefined;

  offerIsSubmitted = false;

  bookId: number | undefined;
  bookTitle: string | undefined;
  bookHasDistribution = false;

  formHasControls = false;

  constructor(
    private router: Router,
    private helperService: HelperService,
    private formBuilder: FormBuilder,
    private offersService: OffersService
  ) {
    this.offerForm = this.formBuilder.group<OfferForm>(
      {},
      {
        validators: [this.helperService.getCustomValidation('atLeastOne', Validators.requiredTrue)]
      }
    );
  }

  ngOnInit(): void {
    this.offerForm$ = this.offerForm
      .get('book_distribution')
      ?.valueChanges.pipe(
        debounceTime(100),
        filter(() => this.offerForm.invalid)
      )
      .subscribe(() => this.offerForm.reset());
  }

  ngOnDestroy(): void {
    [this.offerForm$].forEach($ => $?.unsubscribe());
  }

  onSign(): void {
    if (this.helperService.getFormValidation(this.offerForm)) {
      this.offerIsSubmitted = true;

      const body = {
        book_copyright_status: +this.offerForm.get('book_distribution')?.value || 0,
        ebook_copyright_status: +this.offerForm.get('ebook_distribution')?.value || 0,
        audio_book_copyright_status: +this.offerForm.get('audio_distribution')?.value || 0
      };

      this.offersService.postOfferSign(this.bookId as number, body).subscribe(
        () => {
          this.offerIsSubmitted = false;

          this.closed.emit();

          this.router
            .navigate([`/office/books/${this.bookId}/services`], {
              queryParams: { status: 'signed' }
            })
            .then(() => console.log('Route changed'));
        },
        () => {
          this.offerIsSubmitted = false;

          this.closed.emit();
        }
      );
    }
  }
}
