/** @format */

import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  Book,
  Dropdown,
  getDropdown,
  HelpService,
  Notification,
  NotificationList,
  NotificationsService,
  Offer,
  OfferDetailMap,
  OfferList,
  OffersService,
  PusherService,
  User,
  UserService
} from '../../core';
import { Subscription } from 'rxjs';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { map } from 'rxjs/operators';
import { PresenceChannel } from 'pusher-js';
import { gsap } from "gsap";

interface NotificationsForm {
  service: FormControl<string | null>;
  sort: FormControl<string | null>;
  status: FormControl<string | null>;
}

@Component({
  selector: 'app-office-notifications',
  templateUrl: './office-notifications.component.html',
  styleUrls: ['./office-notifications.component.scss']
})
export class OfficeNotificationsComponent implements OnInit, OnDestroy, AfterViewInit {
  routeData$: Subscription | undefined;

  user$!: Subscription;

  pusherObservable$!: Subscription;
  pusherChannel$!: PresenceChannel;

  notificationsForm: FormGroup;
  notificationsForm$: Subscription | undefined;
  notificationsFormIsSubmitted = false;

  notifications: Notification[] = [];
  notificationsTotal = 10;

  notificationsDropdownServices: Dropdown[] = getDropdown('notificationServices');
  notificationsDropdownSort: Dropdown[] = getDropdown('notificationSort');
  notificationsDropdownStatuses: Dropdown[] = getDropdown('notificationStatuses');

  limit = 10;
  page = 1;

  offers: Offer[] = [];
  offerDetailMap: OfferDetailMap | undefined;

  modalOfferToggle = false;

  hasContract = false;
  hasContractSigned = false;

  constructor(
    private el:ElementRef,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private notificationsService: NotificationsService,
    private helpService: HelpService,
    private offersService: OffersService,
    private pusherService: PusherService,
    private userService: UserService
  ) {
    this.notificationsForm = this.formBuilder.group<NotificationsForm>({
      service: this.formBuilder.control('', [Validators.required]),
      sort: this.formBuilder.control('', [Validators.required]),
      status: this.formBuilder.control('', [Validators.required])
    });
  }

  ngOnInit(): void {
    this.routeData$ = this.route.data.pipe(map((data: any) => data.data)).subscribe({
      next: (routeData: [NotificationList, OfferList]) => {
        const [notificationList, offers] = routeData;

        this.offers = offers.items;

        this.notifications = notificationList.items;
        this.notificationsTotal = notificationList.totalItems;
      },
      error: (error: any) => console.error(error)
    });

    this.notificationsForm$ = this.notificationsForm.valueChanges.subscribe({
      next: () => this.getNotifications(),
      error: (error: any) => console.error(error)
    });

    this.pusherObservable$ = this.pusherService.pusherObservable$.subscribe({
      next: () => {
        this.pusherChannel$ = this.pusherService.pusherChannel.bind(
          this.pusherService.eventList['notification-created'],
          (notification: Notification) => {
            this.pusherService.getNotify(notification.description);

            this.limit = 10;
            this.page = 1;

            this.getNotifications();
          }
        );
      },
      error: (error: any) => console.error(error)
    });

    this.user$ = this.userService.currentUser.subscribe({
      next: (user: User) => {
        this.hasContract = !!user.contract;

        if (this.hasContract) {
          this.hasContractSigned = !!user.contract.signed_at;
        }
      },
      error: (error: any) => console.error(error)
    });
  }

  ngAfterViewInit() {
    const children = this.el.nativeElement.querySelectorAll('.notifications .notification, .notifications > app-list-pagination');
    componentInitAnimation(children, true);
  }

  ngOnDestroy(): void {
    [this.routeData$, this.notificationsForm$, this.user$].forEach($ => $?.unsubscribe());
    [this.pusherChannel$].forEach($ => $?.unbind());
  }

  onSetForm(dropdown: Dropdown, formControl: string): void {
    const control = this.notificationsForm.get(formControl);

    if (dropdown) {
      control?.setValue(dropdown.value);
    } else {
      control?.reset();
    }
  }

  onSelectLimit(limit: number): void {
    this.limit = limit;
    this.page = 1;

    this.getNotifications();
  }

  onSelectPage(page: number | string): void {
    this.page = Number(page);

    this.getNotifications();
  }

  getNotifications(): void {
    let params: any = {
      limit: this.limit,
      page: this.page
    };

    if (this.notificationsForm.get('service')?.valid) {
      params = {
        ...params,
        service: this.notificationsForm.get('service')?.value
      };
    }

    if (this.notificationsForm.get('sort')?.valid) {
      params = {
        ...params,
        order: this.notificationsForm.get('sort')?.value
      };
    }

    if (this.notificationsForm.get('status')?.valid) {
      params = {
        ...params,
        marker: this.notificationsForm.get('status')?.value
      };
    }

    this.notificationsFormIsSubmitted = true;

    this.notificationsService.getAll(params).subscribe({
      next: (notificationList: NotificationList) => {
        this.notifications = notificationList.items;
        this.notificationsTotal = notificationList.totalItems;

        if (!this.notifications.length && !this.notificationsTotal) {
          this.limit = 10;
          this.page = 1;
        }

        this.notificationsFormIsSubmitted = false;
      },
      error: () => (this.notificationsFormIsSubmitted = false)
    });
  }

  onOfferTransfer(book: Book): void {
    this.offersService.getByBookId(book.id).subscribe({
      next: (offerDetailMap: OfferDetailMap) => {
        this.offerDetailMap = offerDetailMap;

        this.modalOfferToggle = true;
      },
      error: (error: any) => console.error(error)
    });
  }

  onShowModalHelp(): void {
    this.helpService.setShowHelpModal({ show: true, title: 'Есть вопрос?' });
  }
}

const componentInitAnimation = (targets: string | object | null, immediateRender?: boolean) => gsap
  .fromTo(targets, {
    autoAlpha: 0,
    y: () => {
      return gsap.utils.random(-50, 0);
    },
  }, {
    autoAlpha: 1,
    y: 0,
    ease: "elastic.out",
    stagger: {
      each: 0.1
    },
    immediateRender,
  });
