/** @format */

import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared';
import { OfficeHomeComponent } from './office-home.component';
import { OfficeHomeRoutingModule } from './office-home-routing.module';

@NgModule({
  imports: [SharedModule, OfficeHomeRoutingModule],
  declarations: [OfficeHomeComponent]
})
export class OfficeHomeModule {}
