/** @format */

import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import { first } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { WizardService } from '../../core';

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss']
})
export class SuccessComponent implements OnInit, OnDestroy {
  @Output() resume = new EventEmitter<any>();

  wizardSubscription$: Subscription | undefined;

  constructor(private wizardService: WizardService) {}

  ngOnInit(): void {
    this.wizardSubscription$ = this.wizardService.wizard$.pipe(first()).subscribe({
      next: () => this.wizardService.onDeleteWizard(),
      error: (error: any) => console.error(error)
    });
  }

  ngOnDestroy(): void {
    this.wizardSubscription$?.unsubscribe();
  }
}
