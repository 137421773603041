/** @format */

import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { UserAttachment } from '../models';
import { map } from 'rxjs/operators';

@Injectable()
export class AttachmentService {
  constructor(private apiService: ApiService) {}

  getAttachments(): Observable<UserAttachment[]> {
    return this.apiService.get('/v1/attachments').pipe(map((data: any) => data.data));
  }

  getAttachment(id: number): Observable<any> {
    return this.apiService.get('/v1/attachments/' + id, undefined, {
      responseType: 'arraybuffer'
    });
  }
}
