<!-- @format -->

<div class="modal box grow flex">
  <div class="grow inner d-flex fd-column position-r bg-color-neutral_white rounded-md p-6">
    <div class="close d-flex ai-center jc-center cursor-p p-1" (click)="closed.emit()">
      <app-svg-icon
        [appIcon]="'cross--yellow'"
        [appWidth]="14"
        [appHeight]="14"
        [appViewBox]="'0 0 14 14'"
      ></app-svg-icon>
    </div>
    <div class="content mb-5">
      <h3 class="mb-3">Необходимо подтверждение</h3>
      <p class="line-height-3 mb-0">
        Мы выслали на ваш адрес электронной почты {{ user.email }} письмо
        с приложениями к основному договору по выбранным видам дистрибуции
        и специальной ссылкой для подписи.
      </p>
      <p class="line-height-3 mt-1 mb-0">
        После перехода по ссылке в письме, приложения будут считаться
        подписанными простой электронной подписью, и будут действовать до тех пор,
        пока не будут расторгнуты по желанию сторон.
      </p>
    </div>
    <div class="d-flex ai-center">
      <!--<button class="btn btn-style-2 btn-inline rounded-md sm d-flex ai-center p-1 mr-3">
        <app-svg-icon
          [appIcon]="'email-gray'"
          [appWidth]="20"
          [appHeight]="16"
          [appViewBox]="'0 0 20 16'"
        ></app-svg-icon>
        <span class="h7 ml-1">Выслать письмо повторно</span>
      </button>-->
      <button
        class="btn btn-style-1 btn-inline rounded-md sm d-flex ai-center p-1"
        (click)="closed.emit()"
      >
        <app-svg-icon
          [appIcon]="'black-check'"
          [appWidth]="16"
          [appHeight]="13"
          [appViewBox]="'0 0 16 13'"
        ></app-svg-icon>
        <span class="h7 ml-1">Понятно</span>
      </button>
    </div>
  </div>
</div>