<!-- @format -->

<ng-container *ngIf="user$ | async as user">
	<div class="box d-flex ai-start jc-start rounded-sm position-r p-3">
		<div class="d-flex ai-start jc-start mr-3">
			<app-svg-icon
				class="d-block"
				appIcon="status-await"
				appWidth="40"
				appHeight="40"
				appViewBox="0 0 24 25"
			></app-svg-icon>
		</div>
		<div class="d-block">
			<div class="d-flex ai-center jc-start mb-1">
				<span class=" h4 text-bold mr-5">
					Ожидание подписи Договора
				</span>
				<time class=" h7">
					{{ user.contract.created_at | dayjs: "format":"HH:mm" }} /
					{{ user.contract.created_at | dayjs: "format":"DD.MM.YYYY" }}
				</time>
			</div>
			<p class=" line-height-3 mb-0">
				Вы передали необходимые сведения для заключения Договора о сотрудничестве.
				Документ с заполненными реквизитами сторон мы направили
				на указанный при регистрации адрес электронной почты
				<a
					class="d-inline-block  text-d-underline"
					[href]="'mailto:' + user.contract.email_for_send"
				>
					{{ user.contract.email_for_send }}
				</a>.
				Пожалуйста, ознакомьютесь с прилигаемым к письму документом и выразите его принятие
				перейдя по ссылке "Подписать договор" в тексте письма.
			</p>
			<div class="d-flex ai-center jc-start mb-h" *ngIf="book">
				<span class=" mr-1">Книга:</span>
				<a class="d-flex ai-center jc-start" [routerLink]="['/office/books', book.id]">
					<app-svg-icon
						class="d-block rounded-sm overflow-h mr-1"
						appIcon="book-cover"
						appWidth="23"
						appHeight="32"
						appViewBox="0 0 116 160"
					></app-svg-icon>
					<span class="">{{ book.title }}</span>
				</a>
			</div>
		</div>
	</div>
</ng-container>
