/** @format */

import { Inject, Injectable } from '@angular/core';
import { UserService } from './user.service';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { LocalStorageService } from './local-storage.service';
import { SnackbarService } from './snackbar.service';
import Pusher from 'pusher-js';
import * as PusherTypes from 'pusher-js';
import { filter, first, tap } from 'rxjs/operators';
import { Configuration, User } from '../models';
import { APP_CONFIG } from './configuration.service';

@Injectable()
export class PusherService {
  user$!: Subscription;
  user!: User;

  pusher$ = new BehaviorSubject<any>({} as any);
  pusherObservable$!: Observable<any>;
  pusherChannel!: PusherTypes.PresenceChannel;
  pusherSound = new Audio();

  eventList: any = {
    ['notification-created']: 'Illuminate\\Notifications\\Events\\BroadcastNotificationCreated'
  };

  constructor(
    @Inject(APP_CONFIG)
    private configuration: Configuration,
    private userService: UserService,
    private localStorageService: LocalStorageService,
    private snackbarService: SnackbarService
  ) {}

  onInitPusher(): void {
    this.user$ = this.userService.currentUser
      .pipe(
        filter((user: User) => !!Object.keys(user).length && user.cabinetAvailable),
        tap((user: User) => (this.user = user)),
        first()
      )
      .subscribe({
        next: (user: User) => {
          this.pusher$.next(
            new Pusher(this.configuration.pusherAppKey, {
              cluster: 'ap2',
              authEndpoint: this.configuration.pusherAppOrigin + '/auth',
              auth: {
                headers: {
                  Accept: 'application/json',
                  Authorization:
                    'Bearer ' + (this.localStorageService.getItem('rugramOfficeUser') || user.token)
                }
              }
            })
          );
        },
        error: (error: any) => console.error(error)
      });

    this.pusherObservable$ = this.pusher$.pipe(
      filter((pusher: any) => !!Object.keys(pusher).length),
      tap(() => {
        this.pusherChannel = this.pusher$.getValue().subscribe('private-users.' + this.user.id);

        this.pusherSound.src = '../../../assets/audio/clearly-602.mp3';
        this.pusherSound.load();
      })
    );
  }

  onDestroyPusher(): void {
    [this.user$, this.pusher$].forEach($ => $?.unsubscribe());
  }

  getNotify(message: string): void {
    this.snackbarService.info(message);

    /**
     * Avoid Uncaught (in promise) DOMException: play() failed because the user didn't interact with the document first
     */
    try {
      this.pusherSound.play().then(() => console.debug('Sound played'));
    } catch (error: any) {
      console.debug(error);
    }
  }
}
