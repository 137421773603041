/** @format */

import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CookieService, LocalStorageService } from '../services';

@Injectable()
export class HttpTokenInterceptor implements HttpInterceptor {
  constructor(
    private localStorageService: LocalStorageService,
    private cookieService: CookieService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let headersConfig: any = {
      // Unnecessary
      // ['Accept']: 'application/json'
    };

    // prettier-ignore
    const cookieWelcomeWizardUser = this.cookieService.getItem('rugramWelcomeWizardUser');

    if (cookieWelcomeWizardUser) {
      headersConfig = {
        ...headersConfig,
        ['Authorization']: 'Bearer '.concat(JSON.parse(cookieWelcomeWizardUser).token)
      };
    }

    // prettier-ignore
    const officeToken = this.localStorageService.getItem('rugramOfficeUser');

    if (officeToken) {
      headersConfig = {
        ...headersConfig,
        ['Authorization']: 'Bearer '.concat(officeToken)
      };
    }

    let request: any = {};

    if (req.url.includes('/login')) {
      request = req.clone({
        setHeaders: headersConfig,
        withCredentials: true
      });
    } else {
      request = req.clone({
        setHeaders: headersConfig
      });
    }

    return next.handle(request);
  }
}
