/** @format */

import { AccessMessageMap } from './access-message.model';

export interface AudiobookOrder {
  id: number; // Уникальный идентификатор услуги
  user_id: number; // Идентификатор пользователя заказывающего услугу
  book_id: number; // Идентификатор книги для которой заказывается услуга
  user_file_link: string | null; // Ссылка на загруженный файл
  user_file_id: string | null;
  file_type: string | null; // Тип файла выбранный пользователем
  isbn: string | null; // ISBN книги
  royalty: number | null; // Роялти
  agreement: boolean; // Согласен ли пользователь с условиями сервиса
  reader_name: string;
  session: string; // Необязательное поле
  write_date: string; // Дата публикации книги
  back_office_id: number; // Уникальный идентификатор дистрибуции в бэкофисе
  back_office_status: string; // Статус дистрибуции в бэкофисе
  created_at: string; // Дата создания услуги
  updated_at: string;
}

export const AudiobookAccessMessageMap: AccessMessageMap = {
  book_copyright_not_transferred: {
    title: 'Подключение услуги в данный момент невозможно',
    text: `В данный момент Вам необходимо подписать предложение о бумажной дистрибуции. Как только оферта будет
      подписана, вам придет уведомление, а услуга создания аудио-книги станет доступной.`,
    icon: 'status-fail',
    iconWidth: 40,
    iconHeight: 40
  },
  no_design_layout: {
    title: 'Подключение услуги в данный момент невозможно',
    text: `В данный момент мы работаем над созданием дизайн-макета созданной вами книги. Как только дизайн-макет будет
      готов, вам придет уведомление, а услуга создания аудио-книги станет доступной.`,
    icon: 'status-await',
    iconWidth: 40,
    iconHeight: 40
  },
  service_in_production: {
    title: 'Услуга уже подключена',
    text: `К этой книге уже подключена услуга создания аудио-книги и в данный момент идет ее исполнение.`,
    icon: 'status-success',
    iconWidth: 40,
    iconHeight: 40
  },
  audiobook_copyright_has_been_transferred: {
    title: 'Подключение услуги в данный момент невозможно',
    text: `На данный вид дистрибуции переданы права`,
    icon: 'status-fail',
    iconWidth: 40,
    iconHeight: 40
  }
};
