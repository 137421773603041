/** @format */

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OfficeSettingsComponent } from './office-settings.component';
import { OfficeSettingsEditComponent } from './edit/edit.component';
import { OfficeSettingsPasswordComponent } from './password/password.component';
import { OfficeSettingsResumeComponent } from './resume/resume.component';
import { OfficeSettingsResumeResolver } from './resume/resume.resolver';
import { OfficeSettingsTemplatesComponent } from './templates/templates.component';

const routes: Routes = [
  {
    path: '',
    component: OfficeSettingsComponent,
    children: [
      {
        path: '',
        component: OfficeSettingsResumeComponent,
        resolve: {
          data: OfficeSettingsResumeResolver
        }
      },
      {
        path: 'edit',
        component: OfficeSettingsEditComponent,
        data: {
          breadcrumb: {
            label: 'Редактирование профиля',
            routerLink: '/office/settings/edit'
          }
        }
      },
      {
        path: 'password',
        component: OfficeSettingsPasswordComponent,
        data: {
          breadcrumb: {
            label: 'Смена пароля',
            routerLink: '/office/settings/password'
          }
        }
      },
      {
        path: 'templates',
        component: OfficeSettingsTemplatesComponent,
        data: {
          breadcrumb: {
            label: 'Шаблоны документов',
            routerLink: '/office/settings/templates'
          }
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OfficeSettingsRoutingModule {}
