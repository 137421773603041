/** @format */

import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-services-terms-payment',
  templateUrl: './services-terms-payment.component.html',
  styleUrls: ['./services-terms-payment.component.scss']
})
export class ServicesTermsPaymentComponent implements OnInit {
  @Output() closed = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {}
}
