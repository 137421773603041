<!-- @format -->

<ng-container>
	<div class="toggle" (click)="onClick($event)">
		<ng-content select="[slot=toggle]"></ng-content>
	</div>
	<div class="target" *ngIf="toggle" [@collapseAnimation]="{value: toggle}">
		<ng-content select="[slot=target]"></ng-content>
	</div>
</ng-container>
