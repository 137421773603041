<!-- @format -->

<div class="box bg-color-neutral_white rounded-md bordered">
	<h1 class="mb-5">Сброс пароля</h1>
	<form [formGroup]="resetForm" (ngSubmit)="onSubmitForm()">
		<fieldset [disabled]="isSubmitting">
			<fieldset class="input d-flex fd-row jc-between ai-center mb-1">
				<label class="d-block h5" for="email">Ваш e-mail:</label>
				<input
					id="email"
					formControlName="email"
					autocomplete="email"
				/>
			</fieldset>
			<a class="extra d-block h7 text-d-underline my-5" [routerLink]="'/auth/login'">
				Вернуться
			</a>
			<button
				class="extra btn btn-style-1 btn-inline lg text-semibold rounded-md px-5"
				[disabled]="resetForm.invalid"
			>
				Продолжить
			</button>
		</fieldset>
	</form>
</div>
