/** @format */

import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared';
import { OfficeSettingsComponent } from './office-settings.component';
import { OfficeSettingsRoutingModule } from './office-settings-routing.module';
import { OfficeSettingsEditComponent } from './edit/edit.component';
import { OfficeSettingsResumeComponent } from './resume/resume.component';
import { OfficeSettingsPasswordComponent } from './password/password.component';
import { DpDatePickerModule } from 'ng2-date-picker';
import { OfficeSettingsTemplatesComponent } from './templates/templates.component';

@NgModule({
  imports: [SharedModule, OfficeSettingsRoutingModule, DpDatePickerModule],
  declarations: [
    OfficeSettingsComponent,
    OfficeSettingsEditComponent,
    OfficeSettingsResumeComponent,
    OfficeSettingsPasswordComponent,
    OfficeSettingsTemplatesComponent
  ]
})
export class OfficeSettingsModule {}
