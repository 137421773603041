/** @format */

import { Injectable } from '@angular/core';
import { ServiceReceipt, ServiceReceiptRow } from '../../../../core';

@Injectable({
  providedIn: 'root'
})
export class ServicesReceiptService {
  constructor() {}

  setRow(data: ServiceReceipt, row: ServiceReceiptRow): ServiceReceipt {
    const index = data.receipt_row?.find(item => item.title === row.title);

    if (index) {
      data.receipt_row = data.receipt_row?.map(item => {
        return item.title === row.title ? row : item;
      });
    } else {
      data.receipt_row?.push(row);
    }

    return { ...data };
  }
}
