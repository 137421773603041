<!-- @format -->

<div class="modal box grow flex">
  <div class="grow inner d-flex fd-column position-r bg-color-neutral_white rounded-md p-6">
	<div class="close d-flex ai-center jc-center cursor-p p-1" (click)="closed.emit()">
		<app-svg-icon
			[appIcon]="'cross--yellow'"
			[appWidth]="14"
			[appHeight]="14"
			[appViewBox]="'0 0 14 14'"
		></app-svg-icon>
	</div>
	<div class="d-flex ai-start">
		<app-svg-icon
			class="mr-3"
			[appIcon]="'status-fail'"
			[appWidth]="32"
			[appHeight]="32"
			[appViewBox]="'0 0 24 25'"
		></app-svg-icon>
		<div>
			<h3 class="mb-1">Ошибка! Заказ не был оплачен.</h3>
			<p class="mb-0">
				{{ message || "Повторите попытку через несколько минут. " +
        "Если не получится, обратитесь за разъяснениями в банк, выпустивший карту или попробуйте оплатить другой картой." }}
			</p>
		</div>
	</div>
</div>
</div>
