/** @format */

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  Book,
  KeysOfferInitialMessageMap,
  Offer,
  OfferInitialMessage,
  OfferInitialMessageMap,
  OffersService
} from '../../../../core';

@Component({
  selector: 'app-offer-initial, [appOfferInitial]',
  templateUrl: './offer-initial.component.html',
  styleUrls: ['./offer-initial.component.scss']
})
export class OfferInitialComponent implements OnInit {
  @Input()
  set appOffer(offer: Offer) {
    this.offer = offer;

    this.isSigned = [
      'audio_book_copyright_status',
      'book_copyright_status',
      'ebook_copyright_status'
    ].some((key: string) => (offer as any)[key] === 4);
  }

  @Input()
  set appMessageMap(key: KeysOfferInitialMessageMap) {
    this.messageMap = OfferInitialMessageMap[key] || OfferInitialMessageMap.default;
  }

  @Input()
  set appShowBook(showBook: boolean) {
    this.showBook = showBook;
  }

  @Output() transfer = new EventEmitter<Book>();

  offer: Offer | undefined;

  showBook = true;

  messageMap: OfferInitialMessage | undefined = OfferInitialMessageMap.default;

  isSigned = false;

  constructor(private offersService: OffersService) {}

  ngOnInit(): void {}

  onClose(offer: Offer): void {
    this.offersService.postOfferHide(offer.id).subscribe({
      next: () => (this.offer = undefined),
      error: (error: any) => console.error(error)
    });
  }
}
