/** @format */

import {Component, OnDestroy, OnInit} from '@angular/core';
import {HelpService, ModalHelpModel, UserService} from '../../../core';
import {BehaviorSubject, combineLatest, Subscription} from "rxjs";

@Component({
  selector: 'app-button-help',
  templateUrl: './button-help.component.html',
  styleUrls: ['./button-help.component.scss']
})
export class ButtonHelpComponent implements OnInit, OnDestroy {
  public user$!: Subscription;
  loggedIn = false;

  showHelpModal$!: Subscription;
  showHelpModal = false;

  constructor(
    private userService: UserService,
    private helpService: HelpService
  ) {}

  ngOnInit(): void {
    this.user$ = combineLatest([
      this.userService.currentUser,
      this.userService.currentWelcomeWizardUser
    ]).subscribe(([rugramOfficeUser, rugramWelcomeWizardUser]) => {
      if (rugramOfficeUser && rugramOfficeUser.hasOwnProperty('id')) {
        this.loggedIn = true;
      }
      else this.loggedIn = !!(rugramWelcomeWizardUser && rugramWelcomeWizardUser.hasOwnProperty('id'));
    });

    this.showHelpModal$ = this.helpService.showModalHelp.subscribe(
      (stateModalHelp: ModalHelpModel) => {
        this.showHelpModal = stateModalHelp.show;
      }
    );
  }

  ngOnDestroy(): void {
    [
      this.user$,
      this.showHelpModal$
    ].forEach($ => $?.unsubscribe());
  }

  onShowModalHelp(): void {
    this.helpService.setShowHelpModal({ show: true, title: 'Есть вопрос?' });
  }
}
