/** @format */

import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { Dropdown } from '../../../../core';
import { fadeAnimation, rotateAnimation } from '../../../../app-animations';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { filter } from 'rxjs/operators';
import { PlatformService } from '../../../../core';
import { DOCUMENT } from '@angular/common';

interface InputForm {
  label: FormControl<string | null>;
}

@Component({
  selector: 'app-dropdown-input',
  templateUrl: './dropdown-input.component.html',
  styleUrls: ['./dropdown-input.component.scss'],
  animations: [fadeAnimation, rotateAnimation]
})
export class DropdownInputComponent implements OnInit, OnDestroy {
  @ViewChild('inputElement') inputElement!: ElementRef;

  @Input()
  set appData(data: Dropdown[]) {
    this.data = data;
  }

  @Input()
  set appRequired(required: boolean) {
    this.required = required;

    if (this.required) {
      this.inputForm.get('label')?.setValidators([Validators.required]);
    }
  }

  @Input()
  set appPlaceholder(placeholder: string) {
    this.placeholder = placeholder;
  }

  @Input()
  set appElectionId(electionId: number | undefined) {
    if (electionId !== undefined) {
      const index = this.data.findIndex((dropdown: Dropdown) => {
        return dropdown.id === electionId;
      });

      this.toggleHovered = index;

      this.inputForm.get('label')?.setValue(this.data[index].label);
    }
  }

  @Input()
  set appClassList(classList: string[]) {
    this.classList = classList;
  }

  @Output() initialized = new EventEmitter<{ [key: string]: any }>();

  @Output() selected = new EventEmitter<any>();

  click$!: Subscription | undefined;
  scroll$!: Subscription | undefined;

  inputForm: FormGroup;
  data!: Dropdown[];

  required: boolean | undefined;
  placeholder: string | undefined;
  classList: string[] = [];

  toggleDropdown = false;
  toggleHovered = -1;

  toggleHeight = '0';
  toggleWidth = '0';

  dropdownTop = '0';
  dropdownLeft = '0';

  constructor(
    @Inject(DOCUMENT)
    private document: Document,
    private platformService: PlatformService,
    private el: ElementRef,
    private formBuilder: FormBuilder
  ) {
    this.inputForm = this.formBuilder.group<InputForm>({
      label: this.formBuilder.control('')
    });
  }

  ngOnInit(): void {
    this.initialized.emit({
      onReset: this.onReset.bind(this)
    });

    this.click$ = fromEvent(this.platformService.getWindow(), 'click').subscribe({
      next: (event?: Event) => {
        if (this.inputElement.nativeElement.contains(event?.target)) {
          const { top, left, height, width } =
            this.inputElement.nativeElement.getBoundingClientRect();

          this.toggleHeight = height + 'px';
          this.toggleWidth = width + 'px';

          this.dropdownTop = top + 'px';
          this.dropdownLeft = left + 'px';

          this.toggleDropdown = !this.toggleDropdown;
        } else {
          this.toggleDropdown = false;
        }
      },
      error: (error: any) => console.error(error)
    });

    /* https://material.angular.io/components/select/overview */

    this.scroll$ = fromEvent(this.platformService.getWindow(), 'scroll')
      .pipe(filter(() => this.toggleDropdown))
      .subscribe({
        next: () => (this.toggleDropdown = false),
        error: (error: any) => console.error(error)
      });
  }

  ngOnDestroy(): void {
    [this.click$, this.scroll$].forEach($ => $?.unsubscribe());
  }

  onReset(): void {
    this.inputForm.reset();

    this.toggleHovered = -1;
    this.toggleDropdown = false;

    this.inputElement.nativeElement.blur();
  }

  onSelect(li: Dropdown | undefined): void {
    // @ts-ignore
    this.inputForm.get('label').setValue(li ? li.dropdown() : '');

    this.selected.emit(li);

    this.toggleHovered = li ? this.data.findIndex(d => d.label === li.label) : -1;
    this.toggleDropdown = false;

    this.inputElement.nativeElement.blur();
  }

  onKeyup(event: KeyboardEvent): void {
    if (this.toggleDropdown) {
      event.preventDefault();

      const inner: HTMLElement = this.el.nativeElement.querySelector('.dropdown-inner');
      // @ts-ignore
      const ul: HTMLElement = inner.querySelector('ul');
      // @ts-ignore
      const li: HTMLElement = ul.querySelector('li');

      const getScrollHeight = (n: number) => li.offsetHeight * (this.inputForm.valid ? n + 1 : n);

      switch (event.key) {
        case 'ArrowDown':
          if (this.toggleHovered < this.data.length - 1) {
            this.toggleHovered++;

            const scrollHeight = getScrollHeight(this.toggleHovered + 1);

            if (scrollHeight > ul.offsetHeight) {
              const scrollHeightDifference = scrollHeight - ul.offsetHeight;

              if (scrollHeightDifference > ul.scrollTop) {
                ul.scrollTop = scrollHeightDifference;
              }
            }
          }

          break;
        case 'ArrowUp':
          if (this.toggleHovered) {
            this.toggleHovered--;

            const scrollHeight = getScrollHeight(this.toggleHovered);

            if (scrollHeight < ul.scrollTop) {
              ul.scrollTop = scrollHeight;
            }
          }

          break;
        case 'Enter':
          this.onSelect(this.data[this.toggleHovered]);

          break;
      }
    }
  }
}
