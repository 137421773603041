<!-- @format -->

<section class="box bg-color-neutral_light-gray-2">
	<header class="header-bar position-r d-flex jc-start py-2 px-6">
		<a [routerLink]="['/office']">
			<img class="d-block" src="assets/logo.png" alt="RUGRAM" />
		</a>
	</header>
	<div class="view">
		<router-outlet></router-outlet>
	</div>
</section>
