/** @format */

import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Book, BookConnected, BookDetail, BookList, StatisticsData } from '../models';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BooksService {
  constructor(private apiService: ApiService) {}

  create(body: any): Observable<Book> {
    return this.apiService.post('/v1/books/create', body).pipe(map((data: any) => data.data));
  }

  update(id: number, body: any): Observable<Book> {
    return this.apiService.post('/v1/books/' + id, body).pipe(map((data: any) => data.data));
  }

  delete(id: number): Observable<any> {
    return this.apiService
      .post('/v1/books/' + id + '/delete', {})
      .pipe(map((data: any) => data.data));
  }

  getAll(params?: any): Observable<BookList> {
    return this.apiService.get('/v1/books', params).pipe(map((data: any) => data.data));
  }

  getById(id: number): Observable<BookDetail> {
    return this.apiService.get(`/v1/books/${id}`).pipe(map((data: any) => data.data));
  }

  getServices(id: number, params?: any): Observable<BookConnected> {
    return this.apiService
      .get('/v1/books/' + id + '/connectedServices', params)
      .pipe(map((data: any) => data.data));
  }

  getStatistics(params?: any): Observable<StatisticsData> {
    return this.apiService.get('/v1/statistic/sale-of-books', params, { observe: 'response' }).pipe(
      switchMap((response: any) => {
        const data: StatisticsData = {
          ...response.body.data,
          status: response.status
        };

        return of({ data });
      }),
      map((data: any) => data.data)
    );
  }

  createDistribution(body: any): Observable<any> {
    return this.apiService.post('/v1/distribution/books', body).pipe(map((data: any) => data.data));
  }
}
