<!-- @format -->

<div class="box bg-color-neutral_white rounded-md bordered">
	<h1 class="mb-5">Давайте познакомимся?</h1>
	<form [formGroup]="registrationForm" (ngSubmit)="onSubmitForm()">
		<fieldset [disabled]="isSubmitting">
			<fieldset class="input d-flex fd-row jc-between ai-center mb-1">
				<label class="d-block h5" for="name">Ваше имя:</label>
				<input
					id="name"
					formControlName="name"
					autocomplete="given-name"
				/>
			</fieldset>
			<fieldset class="input d-flex fd-row jc-between ai-center mb-1">
				<label class="d-block h5" for="surname">Фамилия:</label>
				<input
					id="surname"
					formControlName="surname"
					autocomplete="family-name"
				/>
			</fieldset>
			<fieldset class="input d-flex fd-row jc-between ai-center mb-1">
				<label class="d-block h5" for="email">Ваш e-mail:</label>
				<input
					id="email"
					formControlName="email"
					autocomplete="email"
				/>
			</fieldset>
			<fieldset
				class="input i-end d-flex fd-row jc-between ai-center mb-1"
				*ngIf="{value: false} as isVisible"
			>
				<label class="d-block h5" for="password">Пароль:</label>
				<input
					id="password"
					[type]="isVisible.value ? 'text' : 'password'"
					formControlName="password"
					placeholder="От 6 символов"
					autocomplete="new-password"
				/>
				<app-svg-icon
					class="password"
					[appIcon]="isVisible.value ? 'eye-off' : 'eye'"
					[appWidth]="24"
					[appHeight]="24"
					[appViewBox]="'0 0 24 24'"
					(click)="isVisible.value = !isVisible.value"
				></app-svg-icon>
			</fieldset>
			<fieldset
				class="input i-end d-flex fd-row jc-between ai-center mb-1"
				*ngIf="{value: false} as isVisible"
			>
				<label class="d-block h5" for="password_confirmation">
					Подтверждение пароля:
				</label>
				<input
					id="password_confirmation"
					[type]="isVisible.value ? 'text' : 'password'"
					formControlName="password_confirmation"
					autocomplete="new-password"
				/>
				<app-svg-icon
					class="password"
					[appIcon]="isVisible.value ? 'eye-off' : 'eye'"
					[appWidth]="24"
					[appHeight]="24"
					[appViewBox]="'0 0 24 24'"
					(click)="isVisible.value = !isVisible.value"
				></app-svg-icon>
			</fieldset>
      <div class="d-flex gap-1 jc-between ai-center my-5">
        <a class="extra w-full d-block h7 text-d-underline whitespace-nowrap" [routerLink]="'/auth/login'">
          У меня уже есть учетная запись
        </a>
        <a class="d-block h7 text-d-underline" [routerLink]="'/'">
          Вернуться
        </a>
      </div>
			<div class="d-flex fd-row jc-between ai-center mb-1">
				<button
					class="extra btn btn-style-1 btn-inline lg text-semibold rounded-md px-9"
					[disabled]="registrationForm.invalid"
					data-cy="next"
				>
					Далее
				</button>
				<small *ngIf="registrationForm.dirty && registrationForm.invalid" class="m-0 ml-1 as-center text-color-log-print_main-red">* 
					кнопка не активна, т.к. одно или несколько полей формы не заполнены или содержат недопустимые значения</small>
			</div>
		</fieldset>
	</form>
</div>
