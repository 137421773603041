/** @format */

import { NgModule } from '@angular/core';
import { OfficeComponent } from './office.component';
import { OfficeRoutingModule } from './office-routing.module';
import { OfficeBalanceModule } from './balance/office-balance.module';
import { OfficeHelpModule } from './help/office-help.module';
import { OfficeHomeModule } from './home/office-home.module';
import { OfficeNotificationsModule } from './notifications/office-notifications.module';
import { OfficeServiceModule } from './service/office-service.module';
import { OfficeSettingsModule } from './settings/office-settings.module';
import { SharedModule } from '../shared';

@NgModule({
  imports: [
    OfficeRoutingModule,
    OfficeBalanceModule,
    OfficeHelpModule,
    OfficeHomeModule,
    OfficeNotificationsModule,
    OfficeServiceModule,
    OfficeSettingsModule,
    SharedModule
  ],
  declarations: [OfficeComponent],
  exports: [],
  providers: []
})
export class OfficeModule {}
