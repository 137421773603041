import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NotationService {
  tryJson(payload: any): any {
    try {
      return JSON.parse(payload);
    }
    catch {}
    return payload;
  }
}
