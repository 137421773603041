/** @format */

import { animate, sequence, state, style, transition, trigger } from '@angular/animations';

export const fadeAnimation = trigger('fadeAnimation', [
  transition(
    ':enter',
    [style({ opacity: 0 }), animate('{{duration}}s {{delay}}s ease-out', style({ opacity: 1 }))],
    {
      params: {
        duration: 0.2,
        delay: 0
      }
    }
  ),
  transition(
    ':leave',
    [style({ opacity: 1 }), animate('{{duration}}s {{delay}}s ease-in', style({ opacity: 0 }))],
    {
      params: {
        duration: 0.2,
        delay: 0
      }
    }
  )
]);

export const collapseAnimation = trigger('collapseAnimation', [
  transition(
    ':enter',
    [
      style({ height: 0, opacity: 0 }),
      sequence([
        animate('{{duration}}s ease-out', style({ height: '{{height}}' })),
        animate('{{duration}}s ease-out', style({ opacity: 1 }))
      ])
    ],
    {
      params: {
        height: '*',
        duration: 0.2
      }
    }
  ),
  transition(
    ':leave',
    [
      style({ height: '{{height}}', opacity: 1 }),
      sequence([
        animate('{{duration}}s ease-in', style({ opacity: 0 })),
        animate('{{duration}}s ease-in', style({ height: 0 }))
      ])
    ],
    {
      params: {
        height: '*',
        duration: 0.2
      }
    }
  )
]);

export const rotateAnimation = trigger('rotateAnimation', [
  state('true', style({ transform: 'rotate({{to}})' }), { params: { to: 180 } }),
  state('false', style({ transform: 'rotate({{from}})' }), { params: { from: 0 } }),
  transition('true => false', animate('{{duration}}s {{delay}}s ease-out'), {
    params: {
      delay: 0,
      duration: 0.2
    }
  }),
  transition('false => true', animate('{{duration}}s {{delay}}s ease-in'), {
    params: {
      delay: 0,
      duration: 0.2
    }
  })
]);

export const slideAnimation = trigger('slideAnimation', [
  state('any', style({ transform: 'translateY({{translate}})', transition: 'all 0.5s' }), {
    params: { translate: 0 }
  })
]);
