import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ngGxTextAnimateDirective } from './';

@NgModule({
  declarations: [
    ngGxTextAnimateDirective,
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    ngGxTextAnimateDirective,
  ],
})
export class ngGxTextAnimateModule {}
