/** @format */

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Contract, ContractService } from '../../../core';

@Injectable({
  providedIn: 'any'
})
export class OfficeServiceContractResolverService {
  constructor(private contractService: ContractService) {}

  resolve(): Observable<Contract[]> {
    return this.contractService.getAll();
  }
}
