<!-- @format -->

<ng-container>
	<app-collapse
		class="box rounded-md overflow-h bg-color-neutral_white w-full"
		[class.new]="!notification.read_at"
		(collapsed)="onCollapse($event)"
		[appToggle]="toggleCollapse"
	>
		<div [ngClass]="notificationGrid" class="toggle d-flex ai-center p-3" slot="toggle">
			<app-svg-icon
				class="arrow d-block cursor-p"
				[@rotateAnimation]="{
					value: toggleCollapse,
					params: {from: 0 + 'deg', to: 90 + 'deg'}
				}"
				appIcon="arrow-down-purple"
				appWidth="8"
				appHeight="16"
				appViewBox="0 0 8 16"
			></app-svg-icon>
			<div class="title d-flex ai-center jc-start text-semibold cursor-p">
				{{ notification.title }}
			</div>
			<div class="type d-flex ai-center jc-start">
				<ng-container *ngIf="notification.service && notificationServiceMap as service">
					<app-svg-icon
						class="d-block"
						[appIcon]="service.icon"
						[appWidth]="service.iconWidth"
						[appHeight]="service.iconHeight"
						[appViewBox]="'0 0' + ' ' + service.iconWidth + ' ' + service.iconHeight"
					></app-svg-icon>
					<span class="d-block text-color-log-print_dark-gray ml-1 truncate">
						{{ service.label }}
					</span>
				</ng-container>
			</div>
			<div class="book d-flex ai-center jc-start" [ngClass]="notificationGrid">
				<ng-container *ngIf="notification.book">
					<a
						class="d-flex ai-center jc-start overflow-h text-nowrap"
						[ngClass]="{'opacity-h pointer-n': [1, 2].includes(notification.book.status)}"
						[routerLink]="['/office/books', notification.book.id]"
						data-ignore-collapse="true"
					>
						<figure class="cover rounded-sm overflow-h w-full m-0">
							<img
								class="d-block w-full h-full"
								*ngIf="notification.book.image; else bookPlaceholder"
								[src]="notification.book.image"
								[alt]="notification.book.title"
								data-ignore-collapse="true"
							/>
						</figure>
						<span
							class="link-to-detail d-block ml-1 truncate"
							data-ignore-collapse="true"
						>
							{{ notification.book.title }}
						</span>
					</a>
				</ng-container>
			</div>
			<time class="time text-color-neutral_gray-1 ml-1 truncate">
				{{ notification.created_at | dayjs: "format":"HH:mm / DD.MM.YYYY" }}
			</time>
			<div
				class="status-icon d-flex jc-end"
				*ngIf="notificationStatusMap[notification.marker] as status"
			>
				<app-svg-icon
					class="d-block"
					[appIcon]="(notification.type.includes('Contract') && hasContractSigned) ?
						'status-success' :
						status.icon"
					[appWidth]="24"
					[appHeight]="25"
					[appViewBox]="'0 0 24 25'"
				></app-svg-icon>
			</div>
		</div>
		<div class="target" slot="target">
			<div class="target-inner d-flex fd-column position-r" [ngSwitch]="true">
				<ng-container *ngSwitchDefault>
					<p class="line-height-2 text-color-log-print_dark-gray h6 mb-3">
						{{ notification.content.text }}
					</p>
					<div class="d-flex jc-start ai-center">
						<!-- Связаться с менеджером -->
						<button
							*ngIf="serviceTypeButton.includes('callManager')"
							class="btn btn-style-2 btn-inline px-3 rounded-md mr-3 line-height-5"
							(click)="callManager.emit()"
						>
							<app-svg-icon
								class="mr-1"
								appIcon="chat"
								appWidth="21"
								appHeight="20"
								appViewBox="0 0 21 20"
							></app-svg-icon>
							<span>Связаться с менеджером</span>
						</button>
						<!-- Подписать договор -->
						<a
							*ngIf="serviceTypeButton.includes('signContract')"
							routerLink="/office/services/contract"
						>
							<button
								class="btn btn-inline px-3 rounded-md mr-3 line-height-5"
								[class]="hasContract ? 'btn-style-2' : 'btn-style-1'"
								[disabled]="hasContract"
							>
								<app-svg-icon
									class="mr-1"
									appIcon="contract-black"
									appWidth="21"
									appHeight="20"
									appViewBox="0 0 21 20"
								></app-svg-icon>
								<span>Заполнить договор</span>
							</button>
						</a>
						<!-- Передать права -->
						<button
							*ngIf="serviceTypeButton.includes('transferRights')"
							class="btn btn-style-1 btn-inline px-3 rounded-md mr-3 line-height-5"
							(click)="offerTransfer.emit(notification.book)"
						>
							<app-svg-icon
								class="mr-1"
								appIcon="contract-black"
								appWidth="21"
								appHeight="20"
								appViewBox="0 0 21 20"
							></app-svg-icon>
							<span>Показать предложение</span>
						</button>
						<!-- Отправить письмо еще раз -->
						<button
							*ngIf="serviceTypeButton.includes('resendEmail')"
							class="btn btn-style-1 btn-inline px-3 rounded-md mr-3 line-height-5"
							(click)="onOfferResend(notification.book)"
							[disabled]="offerResendIsSubmit"
						>
							<app-svg-icon
								class="mr-1"
								appIcon="email-black"
								appWidth="20"
								appHeight="16"
								appViewBox="0 0 20 16"
							></app-svg-icon>
							<span>Отправить письмо еще раз</span>
						</button>
						<!-- Отправить письмо еще раз. Контракт -->
						<button
							*ngIf="serviceTypeButton.includes('resendEmailContract')"
							class="btn btn-style-1 btn-inline px-3 rounded-md mr-3 line-height-5"
							(click)="onContractResend()"
							[disabled]="contractResendIsSubmit"
						>
							<app-svg-icon
								class="mr-1"
								appIcon="email-black"
								appWidth="20"
								appHeight="16"
								appViewBox="0 0 20 16"
							></app-svg-icon>
							<span>Отправить письмо еще раз</span>
						</button>
						<!-- Перейти к услуге -->
						<a
							*ngIf="serviceTypeButton.includes('goToService')"
							[routerLink]="['/office/books', notification.book?.id, 'services']"
						>
							<button class="btn btn-style-1 btn-inline px-3 rounded-md mr-3 line-height-5">
								<span>Перейти к услуге</span>
								<app-svg-icon
									class="ml-1"
									appIcon="arrow-right-black"
									appWidth="15"
									appHeight="15"
									appViewBox="0 0 15 15"
								></app-svg-icon>
							</button>
						</a>
						<!-- Смотреть статистику -->
						<a
							*ngIf="serviceTypeButton.includes('viewStatistics')"
							[routerLink]="['/office/books', notification.book?.id, 'statistics']"
						>
							<button class="btn btn-style-1 btn-inline px-3 rounded-md mr-3 line-height-5">
								<span>Смотреть статистику</span>
								<app-svg-icon
									class="ml-1"
									appIcon="arrow-right-black"
									appWidth="15"
									appHeight="15"
									appViewBox="0 0 15 15"
								></app-svg-icon>
							</button>
						</a>
						<!-- Оплатить заказ -->
						<a
							*ngIf="serviceTypeButton.includes('payForOrder')"
							[routerLink]="['/office/services', serviceType[notification.service]]"
							[queryParams]="{
								orderId: notification.content.order_id,
								status: notification.service === 'marketing' ? 'signed' : 'pay'
							}"
						>
							<button class="btn btn-style-1 btn-inline px-3 rounded-md mr-3 line-height-5">
								<span>Оплатить заказ</span>
								<app-svg-icon
									class="ml-1"
									appIcon="arrow-right-black"
									appWidth="15"
									appHeight="15"
									appViewBox="0 0 15 15"
								></app-svg-icon>
							</button>
						</a>
						<!-- Подключить услугу -->
						<a
							*ngIf="serviceTypeButton.includes('activateService')"
							[routerLink]="['/office/services', serviceType[notification.service]]"
							[queryParams]="{bookId: notification.book?.id}"
						>
							<button class="btn btn-style-1 btn-inline px-3 rounded-md mr-3 line-height-5">
								<span>Подключить услугу</span>
								<app-svg-icon
									class="ml-1"
									appIcon="arrow-right-black"
									appWidth="15"
									appHeight="15"
									appViewBox="0 0 15 15"
								></app-svg-icon>
							</button>
						</a>
					</div>
				</ng-container>
			</div>
		</div>
	</app-collapse>
	<ng-template #bookPlaceholder>
		<app-svg-icon
			appIcon="book-cover"
			appWidth="100%"
			appHeight="100%"
			appViewBox="0 0 116 160"
		></app-svg-icon>
	</ng-template>
</ng-container>
