<!-- @format -->

<section class="box">
	<ul
		class="offers d-flex fd-column mx-0 mb-0 p-0"
		*ngIf="offers.length && !(!hasContract || (hasContract && !hasContractSigned))"
	>
		<li
			*ngFor="let offer of offers"
			class="d-flex"
			appOfferInitial
			[appOffer]="offer"
			(transfer)="onOfferTransfer($event)"
		></li>
	</ul>
	<form [formGroup]="notificationsForm" class="rounded-md bordered p-1 mb-h">
		<fieldset
			class="sort d-flex ai-center jc-between w-full"
			[disabled]="notificationsFormIsSubmitted"
		>
			<span class="d-block">Сортировать:</span>
			<fieldset class="d-flex flex-1">
				<app-dropdown-input
					class="w-full"
					[appPlaceholder]="'Все услуги'"
					(selected)="onSetForm($event, 'service')"
					[appData]="notificationsDropdownServices"
					[appClassList]="['sm']"
				></app-dropdown-input>
			</fieldset>
			<fieldset class="d-flex flex-1">
				<app-dropdown-input
					class="w-full"
					[appPlaceholder]="'Сортировка'"
					(selected)="onSetForm($event, 'sort')"
					[appData]="notificationsDropdownSort"
					[appClassList]="['sm']"
				></app-dropdown-input>
			</fieldset>
			<fieldset class="d-flex flex-1">
				<app-dropdown-input
					class="w-full"
					[appPlaceholder]="'Все статусы'"
					(selected)="onSetForm($event, 'status')"
					[appData]="notificationsDropdownStatuses"
					[appClassList]="['sm']"
				></app-dropdown-input>
			</fieldset>
		</fieldset>
	</form>
	<div
		class="notifications d-flex fd-column"
		*ngIf="notifications.length; else notificationsEmpty"
		[class.pointer-n]="notificationsFormIsSubmitted"
	>
		<ul class="d-flex fd-column m-0 p-0" [class.opacity-h]="notificationsFormIsSubmitted">
			<li
				class="notification d-flex my-h"
				*ngFor="let notification of notifications"
				appListNotification
				[appNotification]="notification"
				(callManager)="onShowModalHelp()"
				(offerTransfer)="onOfferTransfer($event)"
			></li>
		</ul>
		<app-list-pagination
			class="notifications-pagination d-block mt-3"
			*ngIf="notificationsTotal > limit"
			[appPage]="page"
			[appLimit]="limit"
			[appTotal]="notificationsTotal"
			(selectPage)="onSelectPage($event)"
			(selectLimit)="onSelectLimit($event)"
		></app-list-pagination>
	</div>
	<app-modal *ngIf="modalOfferToggle" (closed)="modalOfferToggle = false">
		<app-offer-sign
			*ngIf="offerDetailMap"
			[appOfferMap]="offerDetailMap"
			(closed)="modalOfferToggle = false"
		></app-offer-sign>
	</app-modal>
	<ng-template #notificationsEmpty>
		<div class="notifications-empty flex rounded-md bordered p-3 mt-1">
			<app-svg-icon
				class="d-block mr-3"
				appIcon="emoji-sad"
				appWidth="40"
				appHeight="40"
				appViewBox="0 0 40 40"
			></app-svg-icon>
			<div class="d-flex fd-column">
				<span class="d-block text-semibold h5 mb-h">Не найдено уведомлений</span>
				<p class="d-block m-0">Попробуйте изменить параметры сортировки.</p>
			</div>
		</div>
	</ng-template>
</section>
