<!-- @format -->

<div class="modal box grow flex">
  <div class="grow inner relative rounded-md bg-color-neutral_white m-3 p-6">
    <div class="absolute top-0 right-0 cursor-p p-1" (click)="closed.emit()">
      <app-svg-icon
        [appIcon]="'cross--yellow'"
        [appWidth]="14"
        [appHeight]="14"
        [appViewBox]="'0 0 14 14'"
      ></app-svg-icon>
    </div>
    <ng-container [ngSwitch]="true">
      <ng-container *ngSwitchCase="!isCompleted">
        <h3 class="mb-1">Обратная связь в формате «вопрос — ответ»</h3>
        <p class="mb-3 text-gray-500 h7 text-light">
          Пожалуйста, учитывайте, что обращения рассматриваются специалистами отвечающими
          за техническую сторону процесса и маркетинг - они не смогут ответить за специалистов
          отвечающих за творческую составляющую.
          Если вы уже начали работать с вашим ведущим редактором, именно к нему вам
          необходимо обращаться по вопросам связанным с подготовкой и выпуском изданий.
        </p>
        <form [formGroup]="managerForm">
          <fieldset [disabled]="isSubmitted">
            <fieldset class="input d-flex mb-2">
              <label class="d-block text-color-neutral_gray-1 h7 mb-1" for="email">
                Контактный E-mail:
              </label>
              <input *ngIf="managerForm.get('email')" id="email" formControlName="email" />
            </fieldset>
            <fieldset class="textarea d-flex mb-2">
              <label class="d-block text-color-neutral_gray-1 h7 mb-1" for="message">
                Ваш вопрос:
              </label>
              <textarea id="message" formControlName="message" rows="8"></textarea>
            </fieldset>
            <fieldset class="input d-flex mb-2">
              <label class="d-block text-color-neutral_gray-1 h7 mb-1" for="ua">
                К обращению будут добавлены сведения о браузере:
              </label>
              <input id="ua" type="text" [value]="ua.uaVersion" readonly class="!bg-gray-50 !text-gray-500">
            </fieldset>
            <div class="d-flex mt-3" [class.jc-center]="isSubmitted">
              <app-loader class="d-inline-block" [appIsLoading]="isSubmitted">
                <button
                  [disabled]="managerForm.invalid"
                  (click)="onSubmitForm()"
                  class="btn btn-style-1 btn-inline rounded-md d-flex ai-center"
                  slot="content"
                >
                  <app-svg-icon
                    class="mr-1"
                    appWidth="20"
                    appHeight="19"
                    appViewBox="0 0 20 19"
                    appIcon="chat-black"
                  ></app-svg-icon>
                  <span>Отправить</span>
                </button>
              </app-loader>
            </div>
          </fieldset>
        </form>
      </ng-container>
      <ng-container *ngSwitchCase="isCompleted">
        <div class="d-flex ai-start">
          <div class="d-flex ai-center mr-1">
            <app-svg-icon
              appIcon="bg-green-check"
              appWidth="32"
              appHeight="33"
              appViewBox="0 0 50 51"
            ></app-svg-icon>
          </div>
          <div class="d-flex fd-column mt-h">
            <h3 class="text-bold mb-1">Обращение отправлено!</h3>
            <p class="mb-0 text-color-log-print_text">
              В рабочее время ваше обращение будет рассмотрено, ответ будет выслан на контактный E-mail.
            </p>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>