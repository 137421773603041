/** @format */

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OfficeNotificationsComponent } from './office-notifications.component';
import { OfficeNotificationsResolver } from './office-notifications.resolver';

const routes: Routes = [
  {
    path: '',
    component: OfficeNotificationsComponent,
    resolve: {
      data: OfficeNotificationsResolver
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OfficeNotificationsRoutingModule {}
