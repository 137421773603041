<!-- @format -->

<aside class="box d-flex fd-column" *ngIf="user$ | async as user">
	<div class="container-sticky">
		<div class="logo d-flex fd-column ai-center">
			<a [routerLink]="['/office']">
				<img class="d-block" src="assets/logo.png" alt="RUGRAM" />
			</a>
		</div>
		<nav class="nav d-flex fd-column ai-start">
			<ul class="flex flex-col">
				<li class="d-flex ai-center w-full">
					<a
						class="d-flex ai-center"
						[routerLink]="['/office']"
						[routerLinkActive]="['active']"
						[routerLinkActiveOptions]="{exact: true}"
					>
						<app-svg-icon
							class="icon stroke d-flex jc-start"
							[appIcon]="'staff'"
							[appWidth]="16"
							[appHeight]="16"
							[appViewBox]="'0 0 16 16'"
						></app-svg-icon>
						<span class="text-color-log-print_text">Мой кабинет</span>
					</a>
				</li>
				<li class="d-flex ai-center w-full">
					<a
						class="d-flex ai-center"
						[routerLink]="['/office/notifications']"
						[routerLinkActive]="['active']"
					>
						<app-svg-icon
							class="icon stroke d-flex jc-start"
							[appIcon]="'settings'"
							[appWidth]="18"
							[appHeight]="19"
							[appViewBox]="'0 0 18 19'"
						></app-svg-icon>
						<span class="text-color-log-print_text">Уведомления</span>
					</a>
				</li>
				<li class="d-flex ai-center w-full">
					<a
						class="d-flex ai-center"
						[routerLink]="['/office/books']"
						[routerLinkActive]="['active']"
					>
						<app-svg-icon
							class="icon stroke d-flex jc-start"
							[appIcon]="'book'"
							[appWidth]="20"
							[appHeight]="19"
							[appViewBox]="'0 0 20 19'"
						></app-svg-icon>
						<span class="text-color-log-print_text">Книги</span>
					</a>
				</li>
				<li class="d-flex ai-center w-full">
					<a
						class="d-flex ai-center"
						[routerLink]="['/office/services']"
						[routerLinkActive]="['active']"
					>
						<app-svg-icon
							class="icon stroke d-flex jc-start"
							[appIcon]="'brush'"
							[appWidth]="18"
							[appHeight]="18"
							[appViewBox]="'0 0 18 18'"
						></app-svg-icon>
						<span class="text-color-log-print_text">Услуги</span>
					</a>
				</li>
				<li class="d-flex ai-center w-full">
					<a
						class="d-flex ai-center"
						[routerLink]="['/office/balance']"
						[routerLinkActive]="['active']"
					>
						<app-svg-icon
							class="icon fill d-flex jc-start"
							[appIcon]="'balance-wallet-2'"
							[appWidth]="19"
							[appHeight]="16"
							[appViewBox]="'0 0 19 16'"
						></app-svg-icon>
						<span class="text-color-log-print_text">Баланс</span>
					</a>
				</li>
				<li class="d-flex ai-center w-full">
					<a
						class="d-flex ai-center"
						[routerLink]="['/office/help']"
						[routerLinkActive]="['active']"
					>
						<app-svg-icon
							class="icon stroke d-flex jc-start"
							[appIcon]="'question'"
							[appWidth]="20"
							[appHeight]="21"
							[appViewBox]="'0 0 20 21'"
						></app-svg-icon>
						<span class="text-color-log-print_text">Помощь</span>
					</a>
				</li>
				<li class="d-flex ai-center w-full">
					<a
						class="d-flex ai-center"
						[routerLink]="['/office/posts']"
						[routerLinkActive]="['active']"
					>
						<app-svg-icon
							class="icon stroke d-flex jc-start"
							[appIcon]="'contract-yellow'"
							[appWidth]="20"
							[appHeight]="21"
							[appViewBox]="'0 0 20 21'"
						></app-svg-icon>
						<span class="text-color-log-print_text">Публикации</span>
					</a>
				</li>
			</ul>
		</nav>
		<div class="profile d-flex jc-start my-1 mb-3">
			<a
				class="grow d-flex jc-center ai-center mx-auto mr-3"
				[routerLink]="['/office/settings']"
				[routerLinkActive]="['active']"
			>
				<div class="photo d-flex ai-center jc-center overflow-h">
					<img
						class="photo-avatar d-block w-full h-full"
						[src]="user.avatar"
						[alt]="user.name + ' ' + user.surname"
						*ngIf="user.avatar; else noAvatar"
					/>
					<ng-template #noAvatar>
						<app-svg-icon
							class="photo-icon"
							[appIcon]="'photo'"
							[appWidth]="32"
							[appHeight]="34"
							[appViewBox]="'0 0 32 34'"
						></app-svg-icon>
					</ng-template>
				</div>
				<span class="username text-color-log-print_text text-nowrap">
					{{ user.name + " " + user.surname }}
				</span>
			</a>
		</div>
		<nav class="nav d-flex fd-column ai-start">
			<ul class="m-0">
				<li class="d-flex ai-center w-full">
					<a
						class="d-flex logout ai-center text-color-log-print_text cursor-p"
						[routerLink]="null"
						(click)="onLogout()"
					>
						<app-svg-icon
							class="icon fill"
							[appIcon]="'logout'"
							[appWidth]="20"
							[appHeight]="16"
							[appViewBox]="'0 0 20 16'"
						></app-svg-icon>
						<span class="line-height-2 text-color-log-print_text">Выйти</span>
					</a>
				</li>

				<li class="d-flex ai-center w-full mt-2">
					<app-left-bar-advertising-litcovers></app-left-bar-advertising-litcovers>
				</li>
			</ul>
		</nav>
	</div>
</aside>
