/** @format */

import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { HelperService, PlatformService, SnackbarService, User, UserService } from '../../core';
import { Observable, Subscription, timer } from 'rxjs';
import { first, map, mergeMap, take } from 'rxjs/operators';
import intlTelInput, { CountryData } from 'intl-tel-input';

interface ConfirmForm {
  password: FormControl<string | null>;
  password_confirmation: FormControl<string | null>;
}

interface PhoneForm {
  phone: FormControl<string | null>;
}

interface SmsForm {
  sms: FormControl<string | null>;
}

@Component({
  selector: 'app-auth-import',
  templateUrl: './auth-import.component.html',
  styleUrls: ['./auth-import.component.scss']
})
export class AuthImportComponent implements OnInit, AfterViewInit, OnDestroy {
  routeQuery$!: Subscription;
  routeQueryParams!: Params;

  confirmForm: FormGroup;
  confirmIsSubmitting = false;

  phoneForm: FormGroup;
  phoneIsSubmitting = false;
  phoneFormIsSending = false;

  smsForm: FormGroup;
  smsToken!: string;

  count = 60;
  countdown$: Observable<number> | undefined;

  progressState = 'init'; /** init | sms */

  internationalPhoneInput: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private helperService: HelperService,
    private formBuilder: FormBuilder,
    private snackbarService: SnackbarService,
    private elementRef: ElementRef,
    private platformService: PlatformService
  ) {
    this.confirmForm = this.formBuilder.group<ConfirmForm>(
      {
        password: this.formBuilder.control('', [Validators.required, Validators.minLength(6)]),
        password_confirmation: this.formBuilder.control('', [
          Validators.required,
          Validators.minLength(6)
        ])
      },
      {
        validators: [
          this.helperService.getCustomValidation('isSame', {
            left: 'password',
            right: 'password_confirmation'
          })
        ]
      }
    );

    this.phoneForm = this.formBuilder.group<PhoneForm>({
      phone: this.formBuilder.control('', [Validators.required])
    });

    this.smsForm = this.formBuilder.group<SmsForm>({
      sms: this.formBuilder.control('', [Validators.required])
    });
  }

  ngOnInit(): void {
    this.routeQuery$ = this.route.queryParams.subscribe({
      next: (queryParams: Params) => {
        this.routeQueryParams = queryParams;

        if (!('token' in this.routeQueryParams)) {
          this.router.navigateByUrl('/auth/login').then(() => console.debug('Route changed'));
        }
      },
      error: (error: any) => console.error(error)
    });
  }

  ngAfterViewInit(): void {
    this.onInitInternationalPhoneInput();
  }

  ngOnDestroy(): void {
    this.routeQuery$?.unsubscribe();
  }

  onInitInternationalPhoneInput(): void {
    if (this.platformService.isBrowser()) {
      const input: Element = this.elementRef.nativeElement.querySelector('#phone');

      if (input) {
        this.helperService
          .getInternationalPhoneInputConfiguration()
          .pipe(first())
          .subscribe({
            next: (internationalPhoneInputConfiguration: intlTelInput.Options) => {
              // prettier-ignore
              this.internationalPhoneInput = intlTelInput(input, internationalPhoneInputConfiguration);
            },
            error: (error: any) => console.error(error)
          });
      }
    }
  }

  onSubmitConfirmForm(): void {
    if (this.helperService.getFormValidation(this.confirmForm)) {
      this.confirmIsSubmitting = true;

      this.userService
        .postResetImportedAuthor({
          ...this.confirmForm.value,
          token: this.routeQueryParams.token
        })
        .subscribe({
          next: (user: User) => {
            this.userService.setAuthorization(user);

            this.router
              .navigate(['/office'])
              .then(() => this.snackbarService.success('Пароль успешно сохранен', 4000));
          },
          error: (error: any) => {
            this.confirmIsSubmitting = false;

            if (error.data?.verification_token) {
              this.progressState = 'sms';

              this.smsToken = error.data.verification_token;

              const internationalPhoneInputTimeout = setTimeout(() => {
                this.onInitInternationalPhoneInput();

                clearTimeout(internationalPhoneInputTimeout);
              });
            }
          }
        });
    }
  }

  onSubmitPhoneForm(): void {
    if (this.helperService.getFormValidation(this.phoneForm)) {
      const countryData: CountryData = this.internationalPhoneInput.getSelectedCountryData();

      this.phoneIsSubmitting = true;

      this.userService
        .postSMS({
          phone: countryData.dialCode.concat(this.phoneForm.value.phone),
          token: this.smsToken
        })
        .subscribe({
          next: () => {
            this.phoneFormIsSending = true;
            this.snackbarService.success('SMS отправлено');

            this.countdown$ = timer(0, 1000).pipe(
              map(i => this.count - i),
              take(this.count + 1)
            );

            this.phoneIsSubmitting = false;
          },
          error: () => (this.phoneIsSubmitting = false)
        });
    }
  }

  onSubmitSMSForm(): void {
    if (this.helperService.getFormValidation(this.phoneForm)) {
      const countryData: CountryData = this.internationalPhoneInput.getSelectedCountryData();

      this.phoneIsSubmitting = true;

      this.userService
        .postVerification({
          phone: countryData.dialCode.concat(this.phoneForm.value.phone),
          country_code: countryData.iso2,
          token: this.smsToken,
          code: this.smsForm.get('sms')?.value
        })
        .pipe(
          mergeMap(() =>
            /** повторная отправка нужна чтоб на бэке отработало с 200 статусом и тогда токен умрет, а пользователь сможет логиниться */
            this.userService.postResetImportedAuthor({
              ...this.confirmForm.value,
              token: this.routeQueryParams.token
            })
          ),
          mergeMap((user: User) => {
            return this.userService.postLogin({
              email: user.email,
              password: this.confirmForm.get('password')?.value
            });
          })
        )
        .subscribe({
          next: () => {
            this.router
              .navigate(['/office'])
              .then(() => this.snackbarService.success('Телефон подтвержден', 4000));
          },
          error: () => (this.phoneIsSubmitting = false)
        });
    }
  }
}
