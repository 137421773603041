<!-- @format -->

<div class="modal box grow flex">
  <div class="grow inner rounded-md overflow-h m-3">
    <div class="d-flex fd-column position-r bg-color-neutral_white px-6 pt-6 pb-3">
      <div class="close d-flex ai-center jc-center cursor-p p-1" (click)="closed.emit()">
        <app-svg-icon
          [appIcon]="'cross--yellow'"
          [appWidth]="14"
          [appHeight]="14"
          [appViewBox]="'0 0 14 14'"
        ></app-svg-icon>
      </div>
      <h3 class="mb-1">Ознакомьтесь, пожалуйста, с нашим коммерческим предложением.</h3>
      <span class="mb-1">
        Rugram предлагает для вашего литературного произведения <strong>«{{ bookTitle }}»</strong> следующие условия сотрудничества:
      </span>
      <form [formGroup]="offerForm">
        <fieldset [disabled]="offerIsSubmitted">
          <div
            *ngIf="offerMap?.book_distribution?.id"
            class="d-flex fd-column bg-color-neutral_light-gray-2 rounded-sm p-3 my-2 mb-1"
          >
            <div class="d-flex ai-center mb-1">
              <app-svg-icon
                [appIcon]="'service-print-for-self'"
                [appWidth]="24"
                [appHeight]="24"
                [appViewBox]="'0 0 17 21'"
              ></app-svg-icon>
              <span class="text-semibold text-color-log-print_violet-dark-links ml-1">
                Бумажная дистрибуция
              </span>
            </div>
            <span class="d-block line-height-3 mb-1">
              Мы берём на себя все расходы, связанные с изданием и запуском в продажу
              бумажной версии произведения по более чем 50 каналам дистрибуции.
            </span>
            <!--					<div class="d-flex ai-center mb-1">
              <span class="text-semibold mr-1">Параметры печати:</span>
              <span>
                {{ offerMap?.book_distribution?.bookFormat }},
                {{ offerMap?.book_distribution?.printType }},
                {{ offerMap?.book_distribution?.coverType }}
              </span>
            </div>-->
            <div class="d-inline line-height-3 mb-1" *ngIf="offerMap?.book_distribution">
              <span class="text-semibold text-nowrap mr-1">Вы получаете роялти:</span>
              <div class="d-inline">
                <!-- prettier-ignore -->
                <span *ngIf="!!offerMap?.book_distribution?.first_royalty_value && !!offerMap?.book_distribution?.first_royalty_count_start">
                  {{ offerMap?.book_distribution?.first_royalty_value + '% после первых ' + offerMap?.book_distribution?.first_royalty_count_start + ' продаж'}}
                </span>
                <!-- prettier-ignore -->
                <span *ngIf="!!offerMap?.book_distribution?.second_royalty_value && !!offerMap?.book_distribution?.second_royalty_count_start">
                  {{ ', ' + offerMap?.book_distribution?.second_royalty_value + '% после ' + offerMap?.book_distribution?.second_royalty_count_start + ' продаж'}}
                </span>
                <!-- prettier-ignore -->
                <span *ngIf="!!offerMap?.book_distribution?.third_royalty_value && !!offerMap?.book_distribution?.third_royalty_count_start">
                  {{ ', ' + offerMap?.book_distribution?.third_royalty_value + '% после ' + offerMap?.book_distribution?.third_royalty_count_start + ' продаж'}}
                </span>
              </div>
            </div>
            <div class="d-inline line-height-3 mb-1" *ngIf="offerMap?.book_distribution">
              <span class="text-semibold text-nowrap mr-1">Дополнительно:</span>
              повышенный процент с продаж в магазине <span class="text-semibold">rugram-shop.ru</span>
            </div>
            <div class="d-flex ai-center">
              <span class="text-semibold mr-1">Отчетность:</span>
              <span>1 раз в квартал, в разрезе всех площадок партнёров.</span>
            </div>
            <div
              *ngIf="offerMap?.offer?.book_copyright_status === 2"
              class="d-flex ai-center jc-between mt-3"
            >
              <div class="signed-success d-flex ai-center rounded-sm p-1">
                <app-svg-icon
                  [appIcon]="'green-check'"
                  [appWidth]="16"
                  [appHeight]="13"
                  [appViewBox]="'0 0 10 9'"
                ></app-svg-icon>
                <span class="ml-1">Права переданы</span>
              </div>
            </div>
            <div
              *ngIf="offerMap?.offer?.book_copyright_status === 4"
              class="d-flex ai-center jc-between mt-3"
            >
              <div class="signed-await d-flex ai-center rounded-sm p-1">
                <app-svg-icon
                  [appIcon]="'await'"
                  [appWidth]="12"
                  [appHeight]="16"
                  [appViewBox]="'0 0 12 16'"
                ></app-svg-icon>
                <span class="ml-1">Ожидает подписания</span>
              </div>
              <!--<a class="text-color-log-print_main-violet text-d-underline cursor-p">
                Выслать письмо повторно
              </a>-->
            </div>
          </div>
          <div
            *ngIf="offerMap?.offer?.book_copyright_status === 1"
            class="d-flex ai-center jc-between"
          >
            <fieldset class="checkbox ml-3">
              <input
                id="book_distribution"
                type="checkbox"
                formControlName="book_distribution"
              />
              <label for="book_distribution" class="text-color-log-print_dark-gray">
                Соглашаюсь передать права на создание и распространение бумажной книги
              </label>
            </fieldset>
          </div>
          <div class="d-flex ai-start bg-color-log-print_yellow-25 text-amber-800 rounded-sm m-0 my-2 p-1">
            <app-svg-icon
              appIcon="attention-round-yellow"
              appWidth="40"
              appHeight="40"
              appViewBox="0 0 40 40"
            ></app-svg-icon>
            <span class="line-height-2 px-2">
                Согласие на издание и распространение бумажной версии произведения
                является обязательным условием для продолжения сотрудничества с Rugram.
                Соглашение на иные виды дистрибуции вы можете принять позже.
              </span>
          </div>
          <div
            *ngIf="offerMap?.ebook_distribution?.id"
            [ngClass]="{
              'opacity-h pointer-n': bookHasDistribution && offerForm.invalid
            }"
            class="d-flex fd-column bg-color-neutral_light-gray-2 rounded-sm p-3 my-2 mb-1"
          >
            <div class="d-flex ai-center mb-1">
              <app-svg-icon
                [appIcon]="'service-ebook'"
                [appWidth]="24"
                [appHeight]="24"
                [appViewBox]="'0 0 17 21'"
              ></app-svg-icon>
              <span class="text-semibold text-color-log-print_violet-dark-links ml-1">
                Дистрибуция электронной книги
              </span>
            </div>
            <span class="d-block line-height-3 mb-1">
              Мы берём на себя все расходы, связанные с изданием и запуском в продажу
              электронной версии произведения на площадке электронной дистрибуции i-gram.
            </span>
            <div class="d-flex ai-center mb-1" *ngIf="offerMap?.ebook_distribution">
              <span class="text-semibold mr-1">Вы получаете роялти:</span>
              <span>{{ offerMap?.ebook_distribution?.royalty }}% с каждой продажи.</span>
            </div>
            <div class="d-flex ai-center">
              <span class="text-semibold mr-1">Отчетность:</span>
              <span>1 раз в квартал, в разрезе всех площадок партнёров.</span>
            </div>
            <div
              *ngIf="offerMap?.offer?.ebook_copyright_status === 2"
              class="d-flex ai-center jc-between mt-3"
            >
              <div class="signed-success d-flex ai-center rounded-sm p-1">
                <app-svg-icon
                  [appIcon]="'green-check'"
                  [appWidth]="16"
                  [appHeight]="13"
                  [appViewBox]="'0 0 10 9'"
                ></app-svg-icon>
                <span class="ml-1">Права переданы</span>
              </div>
            </div>
            <div
              *ngIf="offerMap?.offer?.ebook_copyright_status === 4"
              class="d-flex ai-center jc-between mt-3"
            >
              <div class="signed-await d-flex ai-center rounded-sm p-1">
                <app-svg-icon
                  [appIcon]="'await'"
                  [appWidth]="12"
                  [appHeight]="16"
                  [appViewBox]="'0 0 12 16'"
                ></app-svg-icon>
                <span class="ml-1">Ожидает подписания</span>
              </div>
              <!--<a class="text-color-log-print_main-violet text-d-underline cursor-p">
                Выслать письмо повторно
              </a>-->
            </div>
          </div>
          <fieldset
            *ngIf="offerMap?.offer?.ebook_copyright_status === 1"
            class="checkbox ml-3"
            [disabled]="bookHasDistribution && offerForm.invalid"
          >
            <input
              id="ebook_distribution"
              type="checkbox"
              formControlName="ebook_distribution"
            />
            <label for="ebook_distribution" class="text-color-log-print_dark-gray">
              Соглашаюсь передать права на создание и распространение электронной книги
            </label>
          </fieldset>
          <div
            *ngIf="offerMap?.audio_distribution?.id"
            [ngClass]="{
              'opacity-h pointer-n': bookHasDistribution && offerForm.invalid
            }"
            class="d-flex fd-column bg-color-neutral_light-gray-2 rounded-sm p-3 my-2 mb-1"
          >
            <div class="d-flex ai-center mb-1">
              <app-svg-icon
                [appIcon]="'service-audio-book'"
                [appWidth]="24"
                [appHeight]="24"
                [appViewBox]="'0 0 21 21'"
              ></app-svg-icon>
              <span class="text-semibold text-color-log-print_violet-dark-links ml-1">
                Дистрибуция аудио-книги
              </span>
            </div>
            <span class="d-block line-height-3 mb-1">
              Мы берём на себя все расходы, связанные с изданием и запуском в продажу
              аудио-версии произведения на крупнейших площадках дистрибуции электронного контента.
            </span>
            <div class="d-flex ai-center mb-1">
              <span class="text-semibold mr-1">Вы получаете роялти:</span>
              <span>{{ offerMap?.audio_distribution?.royalty }}% с каждой продажи.</span>
            </div>
            <div class="d-flex ai-center">
              <span class="text-semibold mr-1">Отчетность:</span>
              <span>1 раз в квартал, в разрезе всех площадок партнёров.</span>
            </div>
            <div
              *ngIf="offerMap?.offer?.audio_book_copyright_status === 2"
              class="d-flex ai-center jc-between mt-3"
            >
              <div class="signed-success d-flex ai-center rounded-sm p-1">
                <app-svg-icon
                  [appIcon]="'green-check'"
                  [appWidth]="16"
                  [appHeight]="13"
                  [appViewBox]="'0 0 10 9'"
                ></app-svg-icon>
                <span class="ml-1">Права переданы</span>
              </div>
            </div>
            <div
              *ngIf="offerMap?.offer?.audio_book_copyright_status === 4"
              class="d-flex ai-center jc-between mt-3"
            >
              <div class="signed-await d-flex ai-center rounded-sm p-1">
                <app-svg-icon
                  [appIcon]="'await'"
                  [appWidth]="12"
                  [appHeight]="16"
                  [appViewBox]="'0 0 12 16'"
                ></app-svg-icon>
                <span class="ml-1">Ожидает подписания</span>
              </div>
              <!--<a class="text-color-log-print_main-violet text-d-underline cursor-p">
                Выслать письмо повторно
              </a>-->
            </div>
          </div>
          <fieldset
            *ngIf="offerMap?.offer?.audio_book_copyright_status === 1"
            class="checkbox ml-3"
            [disabled]="bookHasDistribution && offerForm.invalid"
          >
            <input
              id="audio_distribution"
              type="checkbox"
              formControlName="audio_distribution"
            />
            <label for="audio_distribution" class="text-color-log-print_dark-gray">
              Соглашаюсь передать права на создание и распространение аудио-книги
            </label>
          </fieldset>
          <div *ngIf="offerMap?.offer as offer" class="mx-3 mt-3 text-color-neutral_gray-1">
            * предложение действительно до {{ offer.available_till | dayjs: "format":"DD.MM.YYYY" }}
          </div>
        </fieldset>
      </form>
    </div>
    <div *ngIf="formHasControls" class="bg-color-log-print_main-yellow d-flex jc-end p-5">
      <!--		<a class="d-flex ai-center" [routerLink]="''">-->
      <!--			<app-svg-icon-->
      <!--				[appIcon]="'download-black'"-->
      <!--				[appWidth]="16"-->
      <!--				[appHeight]="16"-->
      <!--				[appViewBox]="'0 0 20 21'"-->
      <!--			></app-svg-icon>-->
      <!--			<span class="text-d-underline line-height-3 text-color-neutral_dark ml-1">-->
      <!--				Скачать полный текст «Оферты»-->
      <!--			</span>-->
      <!--		</a>-->
      <div class="d-flex ai-start mr-2">
        <app-svg-icon
          class="mr-1"
          appIcon="attention-round-black"
          appWidth="22"
          appHeight="22"
          appViewBox="0 0 22 22"
        ></app-svg-icon>
        <div class="h6 text-color-log-print_text line-height-3">
          Приложения к основному договору по выбранным видам дистрибуции
          будут отправлены на ваш адрес электронной почты вместе со специальной ссылкой
          для подписи.
        </div>
      </div>
      <app-tooltip [appClassList]="['offers-sign-btn']">
        <button
          slot="toggle"
          class="btn btn-style-4 btn-inline rounded-md d-flex ai-center px-3 py-1"
          [disabled]="offerForm.invalid || offerIsSubmitted"
          (click)="onSign()"
        >
          <app-svg-icon
            [appIcon]="'yellow-check'"
            [appWidth]="20"
            [appHeight]="20"
            [appViewBox]="'0 0 20 20'"
          ></app-svg-icon>
          <span class="h5 ml-1">Продолжить</span>
        </button>
        <div slot="target">
          <span class="d-block">
            Согласие на издание и распространение бумажной версии произведения
            является обязательным условием для продолжения сотрудничества с Rugram.
            Соглашение на иные виды дистрибуции вы можете принять позже.
          </span>
        </div>
      </app-tooltip>
    </div>
  </div>
</div>
