/** @format */

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService, Book } from 'src/app/core';
import {
  DistributionIsSigned,
  DistributionListServices,
  DistributionOrder,
  DistributionPayment,
  KeysAccessMessageMap
} from '..';

@Injectable({
  providedIn: 'root'
})
export class DistributionService {
  constructor(private apiService: ApiService) {}

  getBooks(): Observable<Book[]> {
    return this.apiService.get('/v1/marketing/books').pipe(
      map((data: any) => data.data),
      map(data => {
        interface BooksData {
          book: Book;
          flag: KeysAccessMessageMap;
          availability: boolean;
        }

        return data.map((booksData: BooksData) => {
          return {
            ...booksData,
            ...booksData.book
          };
        });
      })
    );
  }

  getListService(): Observable<DistributionListServices> {
    return this.apiService.get('/v1/marketing/list').pipe(map((data: any) => data.data));
  }

  createOrder(body: any): Observable<DistributionOrder> {
    return this.apiService.post('/v1/marketing', body).pipe(map((data: any) => data.data));
  }

  getCalculate(orderId: number): Observable<DistributionOrder> {
    return this.apiService
      .get(`/v1/marketing/${orderId}/check`)
      .pipe(map((data: any) => data.data));
  }

  postComplete(orderId: number, body: any): Observable<DistributionOrder> {
    return this.apiService
      .post(`/v1/marketing/${orderId}/complete`, body)
      .pipe(map((data: any) => data.data));
  }

  getPaymentLinks(orderId: number): Observable<DistributionPayment[]> {
    return this.apiService
      .get(`/v1/marketing/${orderId}/paymentLinks`)
      .pipe(map((data: any) => data.data));
  }

  getResend(orderId: number): Observable<DistributionOrder> {
    return this.apiService
      .get(`/v1/marketing/${orderId}/resend`)
      .pipe(map((data: any) => data.data));
  }

  getIsSigned(orderId: number): Observable<DistributionIsSigned> {
    return this.apiService
      .get(`/v1/marketing/${orderId}/isSigned`)
      .pipe(map((data: any) => data.data));
  }

  postIndividual(body: any): Observable<DistributionOrder> {
    return this.apiService
      .post(`/v1/marketing/individual`, body)
      .pipe(map((data: any) => data.data));
  }
}
