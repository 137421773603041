/** @format */

import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  Contract,
  ContractField,
  ContractService,
  FileService,
  HelperService,
  SnackbarService,
  User,
  UserService
} from '../../../core';
import { filter, first, map, tap } from 'rxjs/operators';
import {Observable, Subscription} from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

interface ContractForm {
  contractId: FormControl<string | null>;
}

@Component({
  selector: 'app-office-services-contract',
  templateUrl: './contract.component.html',
  styleUrls: ['./contract.component.scss']
})
export class OfficeServiceContractComponent implements OnInit, OnDestroy {
  routeData$!: Subscription;

  user$: Observable<User> | undefined;

  progressMap = ['active', 'initial', 'initial'];
  progressState = 'initial';
  progressHistory: any = {};

  contractList: Contract[] = [];
  contractSelected: Contract | undefined;
  contractFilled: any;

  contractForm: FormGroup;
  contractFormIsSubmitting = false;

  applicationForm!: FormGroup;
  applicationFormIsSubmitting = false;

  contractResendIsSubmitting = false;

  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private helperService: HelperService,
    private contractService: ContractService,
    private userService: UserService,
    private fileService: FileService,
    private snackbarService: SnackbarService
  ) {
    this.contractForm = this.formBuilder.group<ContractForm>({
      contractId: this.formBuilder.control('', [Validators.required])
    });
  }

  universalFieldMask(fieldName: string) {
    switch (fieldName) {
      case 'snils':
        return '000-000-000 00';
    }
    return '';
  }

  ngOnInit(): void {
    this.routeData$ = this.route.data.pipe(map((data: any) => data.data)).subscribe({
      next: (contractList: Contract[]) => (this.contractList = contractList),
      error: (error: any) => console.error(error)
    });
    this.user$ = this.userService.currentUser;
    this.contractSelected = this.contractList.find((contract: Contract) => {
      return contract.id === this.contractForm.value.contractId;
    });
  }

  ngOnDestroy(): void {
    [this.routeData$].forEach($ => $?.unsubscribe());
  }

  setProgressHistory(state: string): void {
    this.progressHistory = {
      ...this.progressHistory,
      [state]: (() => {
        switch (state) {
          case 'initial': {
            return {
              contractForm: this.contractForm.value
            };
          }
          case 'application': {
            return {
              applicationForm: this.applicationForm.value
            };
          }
          default: {
            return {};
          }
        }
      })()
    };
  }

  onSubmitContractForm(): void {
    if (this.helperService.getFormValidation(this.contractForm)) {
      this.setProgressHistory('initial');

      this.contractSelected = this.contractList.find((contract: Contract) => {
        return contract.id === this.contractForm.value.contractId;
      });

      this.applicationForm = this.formBuilder.group({});

      /**
       * Dynamically create application form
       */

      this.contractSelected?.fields.forEach((contractField: ContractField) => {
        this.applicationForm?.addControl(
          contractField.key,
          new FormControl(contractField.value, Validators.required)
        );
      });

      /**
       * Set value if has previous fill
       */

      if ('application' in this.progressHistory) {
        this.applicationForm.patchValue(this.progressHistory.application.applicationForm);
      }

      this.progressMap = ['done', 'active', 'initial'];
      this.progressState = 'application';
    }
  }

  onSubmitApplicationForm(): void {
    if (this.helperService.getFormValidation(this.applicationForm)) {
      this.setProgressHistory('application');

      this.contractService
        .postContract({
          contract_id: this.contractSelected?.id,
          fields: this.applicationForm.value
        })
        /**
         * Use getAuthorization to fill user contract in app memory
         */

        .pipe(tap(() => this.userService.getAuthorization()))
        .subscribe({
          next: (contractFilled: any) => {
            this.contractFilled = contractFilled;

            this.progressMap = ['done', 'done', 'active'];
            this.progressState = 'done';
          },
          error: (error: any) => console.error(error)
        });
    }
  }

  onReturnToInitial(): void {
    this.setProgressHistory('application');

    /**
     * Set value if has previous fill
     */

    if ('initial' in this.progressHistory) {
      this.contractForm.patchValue(this.progressHistory.initial.contractForm);
    }

    this.progressMap = ['active', 'initial', 'initial'];
    this.progressState = 'initial';
  }

  onDownloadContract(): void {
    const {id, file_name} = this.contractFilled.contract;

    this.contractService.getContract(id).subscribe({
      next: (fileBinary: any) => this.fileService.getDownloadFile(fileBinary, file_name),
      error: (error: any) => console.error(error)
    });
  }

  onContractResend(): void {
    this.userService.currentUser
      .pipe(
        filter((user: User) => !!user.contract.id),
        first()
      )
      .subscribe({
        next: (user: User) => {
          this.contractResendIsSubmitting = true;

          this.contractService.postContractResend(user.contract.id).subscribe({
            next: () => {
              this.contractResendIsSubmitting = false;

              this.snackbarService.success('Мы отправили письмо вам на почту', 4000);
            },
            error: () => (this.contractResendIsSubmitting = false)
          });
        },
        error: (error: any) => console.error(error)
      });
  }
}
