<!-- @format -->

<div class="box w-full d-flex justify-center items-center rounded-sm" @showIn>
	<app-tooltip [appClassList]="['left']">
		<div slot="toggle">
			<a
				class="inner link-to-detail d-flex jc-center"
				[href]="href"
				[target]="target"
			>
				<figure class="hover-shine">
					<img
						class="rounded-sm object-cover"
						src="/assets/advertising/litcovers/banner-1.jpg"
						alt="LitCovers"
					/>
				</figure>
			</a>
		</div>
		<div slot="target">
			<span class="d-block">
				Создайте идеальную обложку для вашего бестселлера за считанные минуты
			</span>
		</div>
	</app-tooltip>
</div>
