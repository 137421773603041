/** @format */

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OfficeHomeComponent } from './office-home.component';
import { HomeResolverService } from './office-home.resolver';

const routes: Routes = [
  {
    path: '',
    component: OfficeHomeComponent,
    resolve: {
      data: HomeResolverService
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OfficeHomeRoutingModule {}
