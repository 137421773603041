<!-- @format -->

<div class="box bg-color-neutral_white rounded-md bordered p-7">
	<h1 *ngIf="!phoneFormIsDone" class="mb-5">Ваш номер телефона</h1>
	<h1 *ngIf="phoneFormIsDone" class="mb-5">Подтверждение номера телефона</h1>
  <!-- Phone -->
	<form [formGroup]="phoneForm" autocomplete="off">
		<fieldset
      [disabled]="phoneFormIsSubmitting"
      *ngIf="{
				hasFocus: phoneInternational?.nativeElement === documentActiveElement,
				hasError: !!phoneForm.controls.phone.errors && phoneForm.controls.phone.touched,
				disabled: phoneFormIsSubmitting || phoneFormIsDone
			} as stateFieldPhone"
    >
      <fieldset
				class="input d-flex fd-row jc-between ai-start mb-1"
				[disabled]="stateFieldPhone.disabled"
			>
				<label class="d-block h5" for="phone">Телефон</label>
        <div class="w-full" data-lenis-prevent="true">
          <input
						intlTelInput
            #phoneInternational
            id="phone"
            tabIndex="0"
            formControlName="phone"
						(countryChange)="phoneCountryData = $event.countryData; phoneExampleCountyNumber = $event.examplePhoneNumber"
            (focus)="stateFieldPhone.hasFocus = true"
            (blur)="stateFieldPhone.hasFocus = false"
          />
          <div *ngIf="!stateFieldPhone.disabled" class="h7 text-light text-color-log-print_dark-gray mt-1">
						<span *ngIf="!phoneCountryData">
							Выберите регион
						</span>
						<span *ngIf="phoneCountryData?.name && !stateFieldPhone.hasFocus">
							Ваш номер мобильного телефона в выбранном регионе
						</span>
						<span *ngIf="phoneCountryData && stateFieldPhone.hasFocus">
							Ваш номер в регионе {{ phoneCountryData.name }} в формате {{ phoneExampleCountyNumber }}
						</span>
          </div>
        </div>
			</fieldset>
		</fieldset>
    <div class="submit">
      <button
        class="btn btn-style-1 btn-inline lg text-semibold rounded-md px-9 mt-3"
        [disabled]="phoneForm.invalid"
        *ngIf="!phoneFormIsDone"
        (click)="onSubmitPhoneForm()"
        data-cy="sendSms"
      >
        Подтвердить
      </button>
    </div>
    <div class="submit text-right"
      *ngIf="phoneFormIsDone && !smsFormIsDone"
    >
      <button class="btn btn-link text-color-log-print_main-violet cursor-p"
        (click)="phoneFormIsDone = false"
      >
        Изменить номер телефона
      </button>
    </div>
  </form>
  <!-- Code -->
  <ng-container *ngIf="phoneFormIsDone && !smsFormIsDone">
		<form [formGroup]="smsForm" *ngIf="{ countdown: !!(countdown$ | async), disabled: smsFormIsSubmitting } as state2" autocomplete="off">
      <div class="m-7 p-4" *ngIf="state2.countdown">
        <ng-container [ngTemplateOutlet]="FlashCallNote"></ng-container>
      </div>
      <div class="my-3 mx-5" *ngIf="!state2.countdown">
        <ng-container [ngTemplateOutlet]="timeoutMessage"></ng-container>
      </div>
      <fieldset *ngIf="state2.countdown" [disabled]="state2.disabled">
        <div class="sending text-color-neutral_gray-2 mb-3"
             *ngIf="countdown$ | async as countdown"
        >
          Код станет недействительным через
          {{ countdown }} {{ countdown | plural : "секунду" : "секунды" : "секунд" }}
        </div>
				<fieldset
					class="input d-flex fd-row jc-start ai-center mb-1"
					[class.invalid]="sms.invalid && sms.touched"
					*ngIf="smsForm.get('sms') as sms"
				>
					<label class="d-block h5" for="sms">Код</label>
					<input
						id="sms"
            tabIndex="0"
            class="w-1/2"
						[class.invalid]="sms.invalid && sms.touched"
						formControlName="sms"
            mask="0 0 0 0"
            shownMaskExpression="_ _ _ _"
            [showMaskTyped]="true"
					/>
				</fieldset>
			</fieldset>
      <div class="submit">
        <button
          class="btn btn-style-1 btn-inline lg text-semibold rounded-md px-9 mt-3"
          [disabled]="state2.disabled || (state2.countdown && (!smsForm.dirty || smsForm.get('sms')?.errors))"
          (click)="state2.countdown ? onSubmitSMSForm() : onSubmitPhoneForm()"
          data-cy="codeConfirmation"
        >
          <span *ngIf="state2.countdown">Подтвердить</span>
          <span *ngIf="!state2.countdown">Попробовать снова</span>
        </button>
      </div>
    </form>
	</ng-container>
  <ng-container *ngIf="phoneFormIsDone && smsFormIsDone">
    <div class="m-7 p-4">
      <ng-container [ngTemplateOutlet]="successMessage"></ng-container>
    </div>
  </ng-container>
</div>
<ng-template #FlashCallNote>
  <div class="d-flex fd-column ai-center">
    <img
      class="call-img mb-1"
      src="assets/images/callsend_mobile.png"
      alt="Входящий звонок"
    />
    <div class="bg-color-neutral_light-gray-2 rounded-md bordered p-4">
      <p class="h6">
        В ближайшее время на указанный вами телефонный номер поступит вызов со случайного номера.
        Отвечать не нужно, просто введите <strong>четыре последние цифры номера</strong>,
        с которого поступил вызов - эти цифры и являются кодом.</p>
    </div>
  </div>
</ng-template>
<ng-template #timeoutMessage>
  <div class="bg-color-log-print_main-red text-color-neutral_white rounded-md bordered p-4">
    <p>По какой-то причине вы не ввели проверочный код за отведенное время.
    Убедитесь в правильности ввода своего телефонного номера и попробуйте еще раз.</p>
  </div>
  <div class="bg-color-log-print_cream text-color-neutral_gray-4 rounded-md bordered p-4 mt-2">
    <p>В последние месяцы все чаще проявляется побочный эффект от блокировок звонков
      в соответствии с федеральным законом № 319-ФЗ от 02.07.2021.
      Если по "счастливой случайности" сотовый оператор блокирует вызовы на ваш номер с номеров,
      используемых для отправки одноразовых кодов подтверждения,
      и вызовы не поступают после нескольких попыток, перешлите телефон менеджеру по email или через обратную связь.
      Мы найдем решение!
    </p>
  </div>
</ng-template>
<ng-template #successMessage>
  <div class="bg-color-log-print_success text-color-neutral_white rounded-md bordered p-4">
    <p>Мы убедились, что вы предоставили актуальную контактную информацию.</p>
    <p class="h5 text-color-log-print_violet-dark-links">Добро пожаловать на платформу RUGRAM!</p>
  </div>
</ng-template>
