/** @format */

import { Component, Input, OnInit } from '@angular/core';
import { transition, trigger } from "@angular/animations";
import { scaleIn } from '../animations';

@Component({
  selector: 'app-right-bar-advertising-1',
  templateUrl: './right-bar-advertising-1.component.html',
  styleUrls: ['./right-bar-advertising-1.component.scss'],
  animations: [
    trigger('showIn', [
      transition(':enter', scaleIn)
    ])
  ]
})
export class RightBarAdvertising1Component implements OnInit {
  @Input()
  showDescription = true;

  constructor() {}

  ngOnInit(): void {}
}
