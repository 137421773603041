<!-- @format -->

<section class="box">
	<article class="article bg-color-neutral_white rounded-sm bordered p-7 mb-6"
    ngGxTextAnimate animate="*" animation="scrollDoc" [serial]="1"
  >
		<div class="d-flex ai-center jc-start bg-color-log-print_light-blue p-h mb-4">
			<p class="d-block text-semibold mb-0">Продажа в магазинах</p>
		</div>
		<div class="sale-in-stores-title-1 mb-4">
			Что предлагает <span class="rugram-publisher">Rugram</span>? Прежде всего, - это продажа Вашей книги в книжных магазинах!
		</div>
    <div class="note1 d-flex ai-start jc-start rounded-sm p-3">
      <div class="d-flex ai-start jc-start mr-3">
        <app-svg-icon
          class="d-block"
          appIcon="active-bell"
          appWidth="40"
          appHeight="40"
          appViewBox="0 0 24 25"
        ></app-svg-icon>
      </div>
      <div class="d-block">
        <div class="sale-in-stores-title-2">
          Если вы получили письмо с уведомлением о том, что ваша книга была одобрена
          инвест-комитетом - это означает, что мы готовы взять на себя
          все расходы, связанные с ее изданием и запуском в продажу.
        </div>
      </div>
    </div>
		<hr class="sale-in-stores-hr w-full" />
		<div class="sale-in-stores-title-2 text-semibold mb-4">
      Rugram предлагает:
		</div>
		<ul class="sale-in-stores-ul mb-0">
			<li class="d-block position-r sale-in-stores-paragraph-1 mb-2">
				<p>Основное, что предлагает Rugram авторам - это продажа их литературных произведений
					в лучших книжных магазинах и маркетплейсах по всей России - и мы это умеем:
          не зря в 2023 году к RUGRAM присоединилось более 1000 новых авторов, а совокупные продажи
          превысили 900 000 экземпляров.
        </p>
				<p class="text-semibold">Если инвест-комитет одобрил вашу книгу - это означает, что мы готовы
					взять на себя <span class="text-d-underline">все</span> расходы
					связанные с ее распространением более чем в 50 каналах дистрибуции,
					с которыми у нас заключены договоры, включая допечатную подготовку переданных материалов,
					печать тиража и запуск книжного проекта в продажу на площадках дистрибуции.</p>
			</li>
			<li class="d-block position-r sale-in-stores-paragraph-1 mb-2">
        По умолчанию тираж будет напечатан в книжном формате 60х90/16 (формат соответствует 1/16 доле листа бумаги размером 60x90 см) с полноцветной обложкой в переплете 7БЦ.
        Нюансы вы сможете обсудить с вашим ведущим редактором.
			</li>
			<li class="d-block position-r sale-in-stores-paragraph-1">
				<p><span class="text-semibold">С каждого проданного экземпляра вы будете получать роялти</span> — лицензионное вознаграждение в виде денежной компенсации за использование авторских прав.
					Процент отчислений согласовывается индивидуально для каждого литературного произведения.</p>
				<p>Период начисления роялти - квартал (каждые 3 месяца). После закрытия отчетного периода
					начисленная сумма будет перечислена на ваш счет, указанный в договоре.</p>
			</li>
		</ul>
		<!-- <div class="sale-in-stores-title-2 text-semibold mb-5">Об услугах</div> -->
		<hr class="sale-in-stores-hr w-full" />
		<div class="sale-in-stores-title-3 text-semibold mb-2">Создание дизайн-макета</div>
		<p class="d-block sale-in-stores-paragraph-1 mb-2">
			Вы можете отслеживать ход выполнения этой услуги в разделе «Уведомления». Первым
			этапом будет создание для вашей книги дизайн-макета. Мы полностью берём эту задачу
			на себя. Обратите внимание, что пока дизайн-макет не готов, вы не сможете подключить
			к книге другие услуги. Создание дизайн-макета обычно занимает 2 недели, когда
			все будет готово, вы получите уведомление.
		</p>
		<img
			class="d-block mb-3"
			src="assets/images/sale-in-stores/sale-in-stores-1.png"
			alt="Создание дизайн-макета"
		/>
		<div class="sale-in-stores-title-3 text-semibold my-2">
			Печать тиража и доставка в магазины
		</div>
		<p class="d-block sale-in-stores-paragraph-1 mb-2">
      На странице книги во вкладке "Соглашения" вы можете отслеживать
      параметры активных соглашений, в соответствии с которыми
      мы выполняем издание и дистрибуцию Вашего литературного произведения.
			Как только тираж будет доставлен в магазины, вы получите
			уведомление. С этого моменты ваша книга начнет продаваться.
		</p>
		<div class="sale-in-stores-title-3 text-semibold my-2">
			Запуск книжного проекта
		</div>
		<p class="d-block sale-in-stores-paragraph-1 mb-2">
			Статистику движения на складе можно будет увидеть во вкладке «Статистика». Данные обновляются ежедневно.
		</p>
		<img
			class="d-block mb-2"
			src="assets/images/sale-in-stores/sale-in-stores-3.png"
			alt="Печать тиража и его доставка в магазины"
		/>
    <div class="sale-in-stores-title-3 text-semibold my-2">
      Начисления и выплаты
    </div>
		<p class="d-block sale-in-stores-paragraph-1 mb-2">
			Сведения о начислениях и выплатах роялти отобразятся в разделе "Баланс".<br>
			Поскольку расчет вашего дохода совершается сложным образом, конкретную
			заработанную сумму вы сможете увидеть через 15 дней после закрытия
			очередного квартала, когда отчетность будет полностью сформирована.
		</p>
	</article>
	<footer class="footer bg-color-neutral_white rounded-sm bordered p-7">
		<div class="d-flex fd-column ai-start jc-start">
			<span class="d-block sale-in-stores-title-4 text-bold mb-1">
				Связаться с менеджером
			</span>
			<span class="d-block sale-in-stores-paragraph-1">
				Если у вас остались вопросы, оставьте нам свой e-mail, и мы с вами свяжемся.
			</span>
		</div>
		<button
			(click)="onShowModalHelp()"
			class="btn btn-style-1 btn-inline rounded-md d-flex ai-center"
			slot="content"
		>
			<app-svg-icon
				class="mr-1"
				appWidth="20"
				appHeight="19"
				appViewBox="0 0 20 19"
				appIcon="chat-black"
			></app-svg-icon>
			<span>Задать вопрос</span>
		</button>
	</footer>
</section>
