/** @format */

import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-svg-icon, [appSvgIcon], [appSvg]',
  templateUrl: './svg-icon.component.html'
})
export class SvgIconComponent {
  @Input()
  set appSquare(square: string | number) {
    this.square = square;
  }

  @Input()
  set appViewBox(viewBox: string) {
    this.viewBox = viewBox;
  }

  @Input()
  set appWidth(width: string | number) {
    this.width = width;
  }

  @Input()
  set appHeight(height: string | number) {
    this.height = height;
  }

  @Input()
  set appIcon(icon: string) {
    this.icon = icon;
  }

  square: string | number | undefined;
  viewBox = '0 0 18 18';

  width: string | number = '18px';
  height: string | number = '18px';

  icon: string | undefined;
}
