/** @format */

import { NgModule } from '@angular/core';
import { SharedModule } from '../shared';
import { ErrorRoutingModule } from './error-routing.module';
import { ErrorComponent } from './error.component';
import { ErrorStatusComponent } from './status/error-status.component';

@NgModule({
  imports: [SharedModule, ErrorRoutingModule],
  declarations: [ErrorComponent, ErrorStatusComponent]
})
export class ErrorModule {}
