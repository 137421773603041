<!-- @format -->

<section class="box p-5 bg-color-neutral_white rounded-sm bordered">
	<h1 class="h3 text-bold">Смена пароля</h1>
	<hr class="w-full my-3" />
	<form [formGroup]="passwordForm">
		<fieldset [disabled]="isSubmitted">
			<fieldset
				class="input i-end d-flex fd-row ai-center mb-3"
				*ngIf="{value: false} as isVisible"
			>
				<label class="d-block text-color-log-print_dark-gray h5" for="password">
					Текущий пароль:
				</label>
				<input
					id="password"
					[type]="isVisible.value ? 'text' : 'password'"
					formControlName="password"
				/>
				<app-svg-icon
					class="password"
					[appIcon]="isVisible.value ? 'eye-off' : 'eye'"
					[appWidth]="24"
					[appHeight]="24"
					[appViewBox]="'0 0 24 24'"
					(click)="isVisible.value = !isVisible.value"
				></app-svg-icon>
			</fieldset>
			<fieldset
				class="input i-end d-flex fd-row ai-center mb-3"
				*ngIf="{value: false} as isVisible"
			>
				<label class="d-block text-color-log-print_dark-gray h5" for="new_password">
					Новый пароль:
				</label>
				<input
					[type]="isVisible.value ? 'text' : 'password'"
					id="new_password"
					formControlName="new_password"
				/>
				<app-svg-icon
					class="password"
					[appIcon]="isVisible.value ? 'eye-off' : 'eye'"
					[appWidth]="24"
					[appHeight]="24"
					[appViewBox]="'0 0 24 24'"
					(click)="isVisible.value = !isVisible.value"
				></app-svg-icon>
			</fieldset>
			<fieldset
				class="input i-end d-flex fd-row ai-center mb-2"
				*ngIf="{value: false} as isVisible"
			>
				<label
					class="d-block text-color-log-print_dark-gray h5"
					for="new_password_confirmation"
				>
					Повторите пароль:&nbsp;
				</label>
				<input
					[type]="isVisible.value ? 'text' : 'password'"
					[class.ng-invalid]="passwordForm.errors?.match === false"
					id="new_password_confirmation"
					formControlName="new_password_confirmation"
				/>
				<app-svg-icon
					class="password"
					[appIcon]="isVisible.value ? 'eye-off' : 'eye'"
					[appWidth]="24"
					[appHeight]="24"
					[appViewBox]="'0 0 24 24'"
					(click)="isVisible.value = !isVisible.value"
				></app-svg-icon>
			</fieldset>
			<p class="text-color-neutral_gray-2 mb-6 h7">
				Пароль должен содержать минимум 6 символов.
			</p>
			<button
				[disabled]="passwordForm.invalid"
				(click)="onSubmit()"
				class="btn btn-style-1 btn-inline lg d-flex ai-center rounded-md mb-3"
			>
				<app-svg-icon
					class="mr-1"
					appIcon="lock-black"
					appWidth="17"
					appHeight="20"
					appViewBox="0 0 17 20"
				></app-svg-icon>
				<span>Сменить пароль</span>
			</button>
		</fieldset>
	</form>
</section>
