/** @format */

import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  ElementRef, HostListener, Inject,
  NgZone,
  OnChanges, OnDestroy,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { APP_CONFIG, Configuration } from "../../../core";
import { Event } from "@angular/router";

@Component({
  selector: 'app-office-help-social',
  templateUrl: './social.component.html',
  styleUrls: ['./social.component.scss']
})
export class OfficeHelpSocialComponent implements OnInit, OnDestroy, AfterViewInit {
  constructor(
    @Inject(APP_CONFIG) private configuration: Configuration,
    private ngZone: NgZone,
    private el:ElementRef,
  ) {
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.ngZone.runOutsideAngular(() => {
        this.animationInit();
        this.afterAnimationInit();
      });
    }, 500)
  }

  ngOnDestroy() {
    this.ngZone.runOutsideAngular(() => {
      this.animationDestroy();
    });
  }

  animationInit(): void
  {
    ScrollTrigger.killAll();
  }

  animationDestroy(): void
  {
    ScrollTrigger.killAll();
  }

  afterAnimationInit(): void
  {
    this.initialiseGSAPScrollTriggerPinning();
  }

  initialiseGSAPScrollTriggerPinning(): void
  {
    const viewport = getViewport();

    // const bg = this.el.nativeElement.querySelector('.box');
    // gsap
    //   .timeline({
    //     repeat: -1,
    //     repeatDelay: 10,
    //   })
    //   .to(bg, {
    //     backgroundPosition: "0% .1%",
    //     duration: 10,
    //     ease: "elastic"
    //   })
    //   .to(bg, {
    //     backgroundPosition: "0% 0%",
    //     delay: 10,
    //     duration: 10,
    //     ease: "elastic"
    //   })

    const container = this.el.nativeElement;
    const sections = gsap.utils.toArray('.section', container);

    let isEven = true;
    sections.forEach((section: any, i) => {
      const panels = gsap.utils.toArray(".panel", section);
      const tl = gsap.timeline({
        id: 's' + i,
        scrollTrigger: {
          trigger: section,
          pin: true,
          pinSpacing: true,
          start: 'top 10%',
          // start: section.offsetTop,
          // end: () => "+=" + (section.offsetWidth),
          scrub: .5,
          snap: {
            // snapTo: (value) => {
            //   return Math.floor(value / (1 / (panels.length + 1))) * (1 / (panels.length + 1));
            // },
            snapTo: 'labels',
            duration: { min: 0.5, max: 40 },
            delay: 1,
            ease: "sine.inOut"
          },
          end: () => {
            isEven = !isEven;
            return '+=' + (8000 * (panels.length ? panels.length : 1));
          },
          preventOverlaps: true,
        }
      });
      if (panels.length) {
        tl.addLabel('').to(panels, {
          delay: 1000,
          duration: 1000 * panels.length,
          x: -1 * section.offsetWidth * (panels.length - 1),
          ease: 'none',
        });
      }
      tl.addLabel('').to(section, {
        opacity: 0,
        delay: 1000,
        duration: 1000,
        ease: 'expo.in',
      });
    });
  }
}

const getViewport = () => {
  const clientWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
  const clientHeight = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
  var screenWidth = window.screen.width;
  var screenHeight = window.screen.height;
  const dpr = parseFloat(window.devicePixelRatio.toFixed(2));
  const zoom = 1 / parseFloat(getComputedStyle(document.documentElement).getPropertyValue('--zoom')) ?? 1.0;
  return {clientWidth, clientHeight, screenWidth, screenHeight, dpr, zoom};
}
