/** @format */

import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { PrintForSelfCalculate } from "../../office/service/core";

@Component({
  selector: 'app-error-code',
  templateUrl: './error-status.component.html'
})
export class ErrorStatusComponent implements OnInit, OnDestroy {
  routeParams$: Subscription | undefined;

  routerQueryParams$!: Subscription;

  statusCode: number | undefined;
  statusCodeMap: number[][] = [
    [100, 199],
    [200, 299],
    [300, 399],
    [400, 499],
    [500, 599]
  ];

  message: string | undefined;
  messageMap: string[] = [
    'Информационное сообщение',
    'Успешно',
    'Перенаправление',
    'Ошибка клиента',
    'Ошибка сервера'
  ];

  constructor(
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.routeParams$ = this.route.params.pipe(map((params: Params) => params.status)).subscribe({
      next: (status: string) => {
        const statusCode = Number(status);
        const message = this.getMessageMap(statusCode);

        if (!statusCode || !message) {
          this.router.navigate([[], 500]).then(() => console.debug('Route was changed'));
        }

        this.statusCode = statusCode;
        this.message = message;
      },
      error: (error: any) => console.error(error)
    });

    this.routerQueryParams$ = this.route.queryParams.subscribe({
      next: (queryParams: Params) => {
        if (queryParams?.message) {
          this.message = queryParams.message;
        }
      },
      error: (error: any) => console.error(error)
    });
  }

  ngOnDestroy(): void {
    [
      this.routeParams$,
      this.routerQueryParams$,
    ].forEach($ => $?.unsubscribe());
  }

  getMessageMap(status: number): string | undefined {
    const i = this.statusCodeMap.findIndex(([min, max]) => min <= status === status < max);

    return this.messageMap[i];
  }
}
