/** @format */

import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Wizard, WizardService } from './core';
import { skipWhile, take} from 'rxjs/operators';
import { Subscription } from 'rxjs';
import {PlatformService} from "../core";

enum ComponentState {
  unknown,
  ready,
  busy,
  editForm,
  resume,
  success
}

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit, OnDestroy, AfterViewInit {
  wizardSubscription$: Subscription | undefined;

  State = ComponentState;
  state: ComponentState = ComponentState.unknown;

  constructor(
    private platformService: PlatformService,
    private wizardService: WizardService
  ) {}

  ngOnInit(): void {
    this.wizardSubscription$ = this.wizardService.wizard$
      // .pipe(debounceTime(1000), first())
      .pipe(
        skipWhile((wizard: Wizard) => !Object.keys(wizard).length),
        take(1)
      )
      .subscribe({
        next: (wizard: Wizard) => {
          if ('uploadForm' in wizard || 'editForm' in wizard) {
            setTimeout(() => {
              this.wizardService.onSetWizard('recover', true);
            })
            this.onStateChange(ComponentState.editForm);
          }
          else this.onStateChange(ComponentState.resume);
        },
        error: (error: any) => console.error(error)
      });

    this.wizardService.onInitWizard();
  }

  ngOnDestroy(): void {
    this.wizardSubscription$?.unsubscribe();

    this.wizardService.onDestroyWizard();
  }

  ngAfterViewInit() {}

  onStateChange(newState: ComponentState, withScroll?: boolean): void {
    this.state = newState;
    if (withScroll) this.platformService.getWindow().scroll({top: 0, left: 0, behavior: 'smooth'});
  }

  onUpdateWizard(payload: any): void {
    const { formName, formValue } = payload;
    const getState = (name: string): ComponentState => {
      switch (name) {
        case 'uploadForm':
          return ComponentState.editForm;
        case 'editForm':
          return ComponentState.resume;
        default:
          return ComponentState.resume;
      }
    }
    if (formName) {
      this.wizardService.onSetWizard(formName, formValue).subscribe({
        next: () => (this.onStateChange(getState(formName))),
        error: (error: any) => console.error(error)
      });
    }
  }

  onCancelWizard(payload: any): void {
    const { resume } = this.wizardService.wizard$.getValue();
    this.wizardService.onSetWizard('resume', resume).subscribe({
      next: () => (this.onStateChange(ComponentState.resume)),
      error: (error: any) => console.error(error)
    });
  }
}
