<!-- @format -->

<section class="box">
	<ng-container [ngSwitch]="true">
		<ng-container *ngSwitchCase="!state">
			<div class="d-flex ai-center jc-between bg-color-neutral_white rounded-md bordered p-7 mb-5">
				<h1 class="mb-0">Добавить произведение?</h1>
				<button
						class="btn btn-style-2 btn-inline lg d-flex ai-center"
						(click)="state = 'terms'"
						data-cy="addBook"
				>
					<app-svg-icon
							class="mr-1"
							appIcon="add-book"
							appWidth="20"
							appHeight="20"
							appViewBox="0 0 20 20"
					></app-svg-icon>
					Добавить
				</button>
			</div>
			<div class="content bg-color-neutral_white rounded-md bordered p-7 mb-5">
				<div class="d-flex fd-row jc-between ai-center mb-1 w-full">
					<div class="title text-color-log-print_dark-gray h6">Вы загрузили:</div>
					<button class="btn btn-style-gray btn-outline d-flex ai-center"
							(click)="onBooklistRefresh()"
							title="Обновить список и статусы в этом браузере"
					>
						Обновить
					</button>
				</div>
				<ul class="w-full p-0 mt-0 ml-0 mb-3">
					<li *ngIf="!resume.length" class="d-flex ai-center rounded-sm mt-3">
						<div class="h7 text-color-neutral_gray-1">
							Пока тут ничего нет. Если вы уже загружали произведения, нажмите кнопку "Обновить" (список).
						</div>
					</li>
					<li *ngFor="let book of resume" class="row d-flex ai-center jc-between py-3">
						<ng-container *ngIf="!book.editForm.status">
							<div class="d-flex ai-center jc-start">
								<app-svg-icon
										class="rounded-sm"
										appIcon="add-book-placeholder"
										appWidth="45"
										appHeight="61"
										appViewBox="0 0 61 84"
								></app-svg-icon>
								<div class="name text-semibold text-color-log-print_text h6 mb-0 px-1">
									{{ book.editForm.name }}
									<span class="ml-1" [class]="bookStatusMap[book.editForm.status].class">
										{{ bookStatusMap[book.editForm.status].label ?? '' }}
									</span>
								</div>
							</div>
							<div class="d-flex ai-center jc-end">
								<button class="btn btn-style-gray btn-outline rounded-md mr-1 d-flex ai-center"
										(click)="onSubmitDraft(book)"
								>
									<app-svg-icon
											class="mr-1"
											appIcon="plus-round-red"
											appWidth="20"
											appHeight="20"
											appViewBox="0 0 32 32"
									></app-svg-icon>
									Отправить
								</button>
								<app-svg-icon
										(touchstart)="hover.value = !hover.value"
										(mouseenter)="hover.value = true"
										(mouseleave)="hover.value = false"
										(click)="onEditDraft(book)"
										class="mr-1 cursor-p"
										[appIcon]="hover.value ? 'edit-round-yellow-hover' : 'edit-round-yellow'"
										appWidth="32"
										appHeight="32"
										appViewBox="0 0 32 32"
										*ngIf="{value: false} as hover"
								></app-svg-icon>
								<app-svg-icon
										(touchstart)="hover.value = !hover.value"
										(mouseenter)="hover.value = true"
										(mouseleave)="hover.value = false"
										(click)="onRemoveDraft(book)"
										class="cursor-p"
										[appIcon]="hover.value ? 'cross-round-red-hover' : 'cross-round-red'"
										appWidth="32"
										appHeight="32"
										appViewBox="0 0 32 32"
										*ngIf="{value: false} as hover"
								></app-svg-icon>
							</div>
						</ng-container>
						<ng-container *ngIf="book.editForm.status">
							<div class="d-flex ai-center jc-start">
								<app-svg-icon
										class="rounded-sm"
										appIcon="book-cover"
										appWidth="45"
										appHeight="61"
										appViewBox="0 0 116 160"
								></app-svg-icon>
								<p class="name text-semibold text-color-log-print_text h6 mb-0 px-1">
									{{ book.editForm.name }}
								</p>
							</div>
							<div class="d-flex ai-center jc-end text-nowrap" [class]="bookStatusMap[book.editForm.status]?.class">
								<app-svg-icon
										(touchstart)="hover.value = !hover.value"
										(mouseenter)="hover.value = true"
										(mouseleave)="hover.value = false"
										class="mr-1"
										[appIcon]="bookStatusMap[book.editForm.status]?.icon"
										appWidth="32"
										appHeight="32"
										appViewBox="0 0 24 25"
										*ngIf="{value: false} as hover"
								></app-svg-icon>
								{{ bookStatusMap[book.editForm.status]?.label ?? '' }}
							</div>
						</ng-container>
					</li>
				</ul>
				<div class="mt-1 ml-1 as-center text-color-neutral_gray-2 h7 text-light"
					 [hidden]="!resume.length"
				>
          <div>* операции редактирования и удаления доступны для черновиков и
            недоступны для произведений ожидающих или получивших оценку инвестиционного комитета</div>
          <div class="mt-1">** если у вас есть одобренные заявки, вам уже доступна расширенная версия личного кабинета.
            Завершите текущую сессию и начните новую, чтобы продолжить.</div>
				</div>
			</div>
		</ng-container>
		<ng-container *ngSwitchCase="state === 'terms'">
			<div class="bg-color-neutral_white rounded-md bordered p-7">
				<h1 class="mb-2">Добавление произведения</h1>
				<ng-container *ngTemplateOutlet="terms"></ng-container>
				<div class="buttons-responsive d-flex jc-between mt-5">
					<button
							class="btn btn-style-2 btn-inline lg text-semibold rounded-md d-flex ai-center"
              (click)="onShowModalHelp()"
					>
						<app-svg-icon
								class="mr-1"
								appWidth="20"
								appHeight="19"
								appViewBox="0 0 20 19"
								appIcon="chat"
						></app-svg-icon>
						Сначала связаться с менеджером
					</button>
					<button
							(click)="add.emit()"
							class="btn btn-style-1 btn-inline lg text-semibold rounded-md ml-1"
							data-cy="uploadFile"
					>
						Загрузить рукопись
					</button>
				</div>
				<div class="mt-5 text-color-log-print_main-violet cursor-p"
					 (click)="state = ''">
					Вернуться
				</div>
			</div>
		</ng-container>
	</ng-container>
	<ng-template #terms>
		<div class="h5 rounded-md bg-color-neutral_light-gray-2 line-height-3 p-3">
			Rugram гарантирует, что ваша рукопись не попадет в третьи руки и не будет размещена
			в открытом доступе.
			<a
					class="text-color-log-print_main-violet cursor-p"
					href="assets/download/privacy_policy.docx"
					download
			>
				Скачать
				<span class="text-bold text-d-underline">«Соглашение о&nbsp;неразглашении»</span>
			</a>
		</div>
	</ng-template>
</section>
