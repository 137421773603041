/** @format */

import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { BalanceService, BalanceTransaction } from '../../core';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class OfficeBalanceResolver {
  constructor(private balanceService: BalanceService, private router: Router) {}

  resolve(): Observable<BalanceTransaction> {
    return this.balanceService
      .getAll({
        limit: 10,
        page: 1
      })
      .pipe(
        catchError((error: any) => {
          this.router
            .navigateByUrl('/error/' + error.status)
            .then(() => console.debug('Route changed'));

          return throwError(() => new Error('OfficeBalanceResolver error'));
        })
      );
  }
}
