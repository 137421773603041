<!-- @format -->

<div class="box d-flex ai-center jc-between">
	<div class="limit d-flex ai-center jc-start">
		<span class="d-block text-color-log-print_dark-gray text-nowrap mr-1">
			Выводить по:
		</span>
		<ul class="d-flex p-0 m-0" *ngIf="limit as limitActive">
			<ng-container *ngFor="let limit of limitList">
				<li
					*ngIf="limit < total"
					class="text-semibold bg-color-neutral_white rounded-sm py-1 px-2"
					[class.active]="limit === limitActive"
					(click)="selectLimit.emit(limit)"
				>
					{{ limit }}
				</li>
			</ng-container>
		</ul>
	</div>
	<div class="page d-flex ai-center jc-end">
		<ul class="d-flex p-0 m-0" *ngIf="page as pageActive">
			<li
				class="text-semibold rounded-sm py-1 px-2"
				[class.active]="page === pageActive"
				[class.pointer-n]="page === '...'"
				*ngFor="let page of pageList"
				(click)="selectPage.emit(page)"
			>
				{{ page }}
			</li>
		</ul>
	</div>
</div>
