/** @format */

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NoAuthGuard } from './auth/no-auth-guard.service';
import { SoftAuthGuard } from './auth/soft-auth-guard.service';
import { WelcomeGuard } from './core';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./landing/landing.module').then(m => m.LandingModule),
    // canActivate: [SoftAuthGuard]
    data: { animation: 'landingPage' }
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
    canActivate: [NoAuthGuard]
  },
  {
    path: 'office',
    loadChildren: () => import('./office/office.module').then(m => m.OfficeModule)
  },
  {
    path: 'welcome',
    loadChildren: () => import('./welcome2/welcome.module').then(m => m.WelcomeModule),
    canActivate: [WelcomeGuard]
  },
  {
    path: 'about-office',
    loadChildren: () => import('./about-office/about-office.module').then(m => m.AboutOfficeModule)
  },
  {
    path: 'error',
    loadChildren: () => import('./error/error.module').then(m => m.ErrorModule)
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: '/error/404'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
