/** @format */

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { BalanceTransaction } from '../models';
import { map } from 'rxjs/operators';

@Injectable()
export class BalanceService {
  constructor(private apiService: ApiService) {}

  getAll(params?: any): Observable<BalanceTransaction> {
    return this.apiService.get('/v1/balance', params).pipe(map((data: any) => data.data));
  }
}
