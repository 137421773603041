/** @format */

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {
  ModalHelpModel
} from '../../core';

@Injectable()
export class HelpService {
  showModalHelp = new BehaviorSubject<ModalHelpModel>({ show: false });

  constructor() {}

  setShowHelpModal(state: ModalHelpModel): void {
    this.showModalHelp.next(state);
  }
}
