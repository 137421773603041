/** @format */

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { forkJoin, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DeliveryPickup, DeliveryService, PrintForSelfService } from '../core';
import { Book } from '../../../core';

@Injectable({
  providedIn: 'any'
})
export class OfficeServicePrintForSelfResolverService {
  constructor(
    private printForSelfService: PrintForSelfService,
    private deliveryService: DeliveryService,
    private router: Router
  ) {}

  // resolve(): Observable<[Book[], DeliveryPickup[]]> {
  //   return forkJoin([
  //     this.printForSelfService.getBooks(),
  //     this.deliveryService.getDeliveryPickup()
  //   ]).pipe(
  //     catchError((error: any) => {
  //       this.router
  //         .navigateByUrl('/error/' + error.status)
  //         .then(() => console.debug('Router changed'));
  //
  //       return throwError(() => new Error('OfficeServicePrintForSelfResolverService error'));
  //     })
  //   );
  // }
  resolve(): Observable<Book[]> {
    return this.printForSelfService.getBooks()
      .pipe(
        catchError((error: any) => {
          this.router
            .navigateByUrl('/error/' + error.status)
            .then(() => console.debug('Router changed'));

          return throwError(() => new Error('OfficeServicePrintForSelfResolverService error'));
        })
      );
  }
}
