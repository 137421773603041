/** @format */

import { Component, Input, OnInit } from '@angular/core';
import { transition, trigger } from "@angular/animations";
import { scaleIn } from '../animations';

@Component({
  selector: 'app-right-bar-doc-templates',
  templateUrl: './right-bar-doc-templates.component.html',
  styleUrls: ['./right-bar-doc-templates.component.scss'],
  animations: [
    trigger('showIn', [
      transition(':enter', scaleIn)
    ])
  ]
})
export class RightBarDocTemplatesComponent implements OnInit {
  @Input()
  showDescription = true;

  constructor() {}

  ngOnInit(): void {}
}
