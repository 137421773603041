<!-- @format -->

<div class="box">
	<section class="p-5 bg-color-neutral_white rounded-sm bordered">
		<h1 class="h3 text-bold mb-0">Редактирование профиля</h1>
		<hr class="w-full my-3" />
		<form [formGroup]="editForm">
			<fieldset [disabled]="isSubmitted">
				<fieldset class="input d-flex fd-row ai-center mb-3">
					<label class="d-block text-color-log-print_dark-gray h5" for="name">Имя:</label>
					<input id="name" formControlName="name" />
				</fieldset>
				<fieldset class="input d-flex fd-row ai-center mb-3">
					<label class="d-block text-color-log-print_dark-gray h5" for="surname">
						Фамилия:
					</label>
					<input id="surname" formControlName="surname" />
				</fieldset>
				<app-tooltip [appClassList]="['settings-phone']">
					<fieldset
						slot="toggle"
						[disabled]="true"
						class="input d-flex fd-row ai-center mb-3"
					>
						<label class="d-block text-color-log-print_dark-gray h5" for="email">
							E-mail:
						</label>
						<input id="email" formControlName="email" />
					</fieldset>
					<div slot="target">
						<span class="d-block">
							Для изменения адреса электронной почты обратитесь к менеджеру
						</span>
					</div>
				</app-tooltip>
				<app-tooltip [appClassList]="['settings-phone']">
					<fieldset
						slot="toggle"
						[disabled]="true"
						class="input d-flex fd-row ai-center mb-3"
					>
						<label class="d-block text-color-log-print_dark-gray h5" for="phone">
							Телефон:
						</label>
						<input
							intlTelInput
							id="phone"
							formControlName="phone"
						/>
					</fieldset>
					<div slot="target">
						<span class="d-block">
							Для изменения номера телефона обратитесь к менеджеру
						</span>
					</div>
				</app-tooltip>
				<div class="d-flex jc-start mb-3">
					<button
						[disabled]="editForm.invalid"
						(click)="onSubmit()"
						class="btn btn-style-1 btn-inline lg rounded-md d-flex ai-center"
					>
						<app-svg-icon
							class="mr-1"
							appIcon="black-pen"
							appWidth="18"
							appHeight="18"
							appViewBox="0 0 18 18"
						></app-svg-icon>
						<span>Сохранить</span>
					</button>
				</div>
			</fieldset>
		</form>
	</section>
</div>
