<!-- @format -->

<div class="box">
	<div class="toggle" (mouseenter)="onHover(true)" (mouseleave)="onHover(false)">
		<ng-content select="[slot=toggle]"></ng-content>
	</div>
	<div
		class="target"
		*ngIf="toggle"
		[ngClass]="classList"
		[style.--toggle-height]="toggleHeight"
		[style.--toggle-width]="toggleWidth"
		[style.--tooltip-top]="tooltipTop"
		[style.--tooltip-left]="tooltipLeft"
		[@enterLeaveTrigger]
	>
		<ng-content select="[slot=target]"></ng-content>
	</div>
</div>
