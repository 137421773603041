<!-- @format -->

<div class="box w-full py-3">
	<app-svg-icon
    *ngIf="balance.type !== 'directum_royalty'"
		class="icon d-block"
		[appIcon]="balance.amount > 0 ? 'balance-income' : 'balance-withdraw'"
		appWidth="40"
		appHeight="40"
		appViewBox="0 0 40 40"
	></app-svg-icon>
	<div class="title d-flex ai-center h4">
		<ng-container [ngSwitch]="balance.type">
			<span *ngSwitchCase="'directum_royalty'" class="text-bold text-[#E51E20]">
        Начисление роялти
      </span>
			<span *ngSwitchCase="'directum_transaction'" class="text-semibold text-green-600">
				Пополнение баланса
			</span>
			<span *ngSwitchDefault class="text-semibold text-slate-500">Оплата услуги</span>
		</ng-container>
	</div>
  <div class="info"
       *ngIf="{value: balance.additional?.books || []} as books"
  >
    <p class="text-slate-500 h6 text-light mt-h mb-0"
      *ngIf="balance.comment && balance.type !== 'directum_transaction'"
    >
      <ng-container [ngSwitch]="balance.type && books.value.length > 0">
        <ng-container *ngSwitchCase="true">
          <ng-container *ngIf="books.value.length === 1">
            <span class="text-semibold">{{ balance.comment }}:</span> «{{ books.value[0].title }}»
          </ng-container>
          <ng-container *ngIf="books.value.length > 1">
            <span class="text-semibold">{{ balance.comment }}:</span>
          </ng-container>
        </ng-container>
        <ng-container *ngSwitchDefault>
          <span class="text-semibold">{{ balance.comment }}</span>
        </ng-container>
      </ng-container>
    </p>
    <ul class="!list-disc pl-0 my-0"
      *ngIf="balance.type === 'directum_royalty' && books.value.length > 1"
    >
      <li class="text-slate-500 h7 text-light mt-h mb-0"
        *ngFor="let book of balance.additional?.books"
      >
        {{ book.title }}
      </li>
    </ul>
  </div>
	<time class="time d-flex ai-center text-slate-400">
		{{ balance.created_at | dayjs: "format":"HH:mm" }} /
		{{ balance.created_at | dayjs: "format":"DD.MM.YYYY" }}
	</time>
	<div class="amount d-flex ai-center jc-end text-nowrap text-weight-600"
     [class.income]="balance.amount > 0"
  >
    <ng-container [ngSwitch]="balance.type">
			<span *ngSwitchCase="'directum_royalty'" class="h5 font-extralight text-slate-500">
        {{
        ["-", ""][balance.amount < 0 ? 0 : 1] +
        (balance.amount | mask: "separator.2" | replace: "\\.":",")
        }} &#x20bd;
      </span>
      <span *ngSwitchCase="'directum_transaction'" class="h4 text-green-600">
        {{
          ["-", "+"][balance.amount < 0 ? 0 : 1] +
          (balance.amount | mask: "separator.2" | replace: "\\.":",")
        }} &#x20bd;
			</span>
      <span *ngSwitchDefault class="h4 text-amber-600">
  			{{
          ["-", "+"][balance.amount < 0 ? 0 : 1] +
          (balance.amount | mask: "separator.2" | replace: "\\.":",")
        }} &#x20bd;
      </span>
    </ng-container>
	</div>
</div>
