<!-- @format -->

<section class="box d-flex fd-column ai-center jc-start">
	<app-snackbar></app-snackbar>
	<div class="content py-7">
		<header class="d-flex fd-column ai-center jc-start mb-5">
			<a [routerLink]="['/']" class="mb-3">
				<img class="logo d-block" src="assets/logo.png" alt="RUGRAM" />
			</a>
			<h3 class="text-bold text-center">
				Технологическое издательство нового типа.
			</h3>
		</header>
		<main [@routeAnimations]="getRouteAnimationData()">
			<router-outlet></router-outlet>
		</main>
	</div>
</section>
