<!-- @format -->

<section class="box d-flex jc-between">
	<div class="content-left d-flex fd-column">
		<form [formGroup]="avatarForm" *ngIf="user$ | async as user">
			<fieldset class="input" [disabled]="isSubmitted">
				<label
					class="avatar d-flex ai-center jc-center overflow-h cursor-p"
					[class.uploaded]="user.avatar && !isSubmitted"
					for="avatar"
				>
					<input
						id="avatar"
						type="file"
						formControlName="avatar"
						(change)="onChangeAvatar($event)"
						[accept]="mimeTypes.join(',')"
						hidden
					/>
					<app-loader
						class="d-flex ai-center jc-center w-full h-full"
						[appIsLoading]="isSubmitted"
					>
						<app-svg-icon
							*ngIf="!user.avatar"
							appIcon="photo-red"
							appWidth="40"
							appHeight="40"
							appViewBox="0 0 40 40"
							slot="content"
						></app-svg-icon>
						<div class="avatar-image w-full h-full" *ngIf="user.avatar" slot="content">
							<img
								class="d-block w-full h-full"
								[src]="user.avatar"
								[alt]="user.name + ' ' + user.surname"
							/>
							<app-svg-icon
								appIcon="photo-black"
								appWidth="40"
								appHeight="32"
								appViewBox="0 0 40 32"
							></app-svg-icon>
						</div>
					</app-loader>
				</label>
			</fieldset>
		</form>
		<div class="d-flex fd-column jc-start ai-center p-h">
			<div class="d-flex ai-center cursor-p h7 mb-h" (click)="onLogout()">
				<app-svg-icon
					class="mr-1"
					appIcon="logout-text"
					appWidth="20"
					appHeight="20"
					appViewBox="0 0 20 20"
				></app-svg-icon>
				Выйти
			</div>
			<!--			<div-->
			<!--				class="d-flex ai-center mt-1 cursor-p h7 text-color-log-print_main-red"-->
			<!--				(click)="onRemoveAccount()"-->
			<!--			>-->
			<!--				<app-svg-icon-->
			<!--					class="mr-1"-->
			<!--					appIcon="basket-red"-->
			<!--					appWidth="20"-->
			<!--					appHeight="20"-->
			<!--					appViewBox="0 0 20 20"-->
			<!--				></app-svg-icon>-->
			<!--				Удалить аккаунт-->
			<!--			</div>-->
		</div>
	</div>
	<div class="content" [@routeAnimations]="getRouteAnimationData()">
		<router-outlet></router-outlet>
	</div>
	<div class="advertising d-flex fd-column">
    <app-right-bar-doc-templates class="d-block"></app-right-bar-doc-templates>
		<app-right-bar-advertising-2 class="d-block"></app-right-bar-advertising-2>
		<app-right-bar-advertising-3 class="d-block"></app-right-bar-advertising-3>
	</div>
</section>
