/** @format */
import { Book } from './book.model';

export interface Offer {
  id: number; // Идентификатор офера
  book_copyright_status: number; // Статус офера для книги
  ebook_copyright_status: number; // Статус офера для электронной книги
  audio_book_copyright_status: number; // Статус офера для аудио книги
  available_till: string; // Дата действительности офера
  created_at: string; // Дата создания офера
  updated_at: string; // Дата обновления офера
  book: Book;
}

export interface OfferDetail {
  id: number; // Уникальный идентификатор дистрибуции
  user_id: number; // Уникальный идентификатор пользователя создавшего дистрибуцию
  book_id: number; // Уникальный идентификатор книги по которой проводится дистрибуция
  user_file_uid: string; // UID файла
  user_file: string; // Ссылка на файл
  back_office_id: number; // Уникальный идентификатор дистрибуции в бэкофисе
  back_office_status: string; // Статус дистрибуции в бэкофисе
  created_at: string; // Дата создания книги
  updated_at: string;
  book: Book;
  royalty?: number;
  first_royalty_value?: number;
  first_royalty_count_start?: number;
  second_royalty_value?: number;
  second_royalty_count_start?: number;
  third_royalty_value?: number;
  third_royalty_count_start?: number;
  bookFormat?: string;
  coverType?: string;
  printType?: string;
}

export interface OfferDetailMap {
  book_distribution: OfferDetail;
  ebook_distribution: OfferDetail;
  audio_distribution: OfferDetail;
  offer: Offer;
}

export interface OfferList {
  items: Offer[];
  totalItems: number; // Общее количество элементов
}

export interface OfferInitialMessage {
  title: string;
  message: string;
}

export type KeysOfferInitialMessageMap = 'default' | 'ebook' | 'audio_book';

type OfferInitialMessageMap = {
  [key in KeysOfferInitialMessageMap]?: OfferInitialMessage;
};

export const OfferInitialMessageMap: OfferInitialMessageMap = {
  default: {
    title: 'Поздравляем! Мы готовы инвестировать в вашу книгу',
    message: `Издательство Rugram готово инвестировать в вашу книгу и взять на себя заботы о публикации. Мы подготовили для вас индивидуальное коммерческое предложение. Надеемся на плодотворное сотрудничество!`
  },
  ebook: {
    title: 'Rugram готов взять на себя создание электронной книги',
    message: `Издательство Rugram готово инвестировать в вашу книгу и взять на себя заботы о публикации. Мы подготовили для вас индивидуальное коммерческое предложение. Надеемся на плодотворное сотрудничество!`
  },
  audio_book: {
    title: 'Rugram готов взять на себя создание аудиокниги',
    message: `Издательство Rugram готово инвестировать в вашу книгу и взять на себя заботы о публикации. Мы подготовили для вас индивидуальное коммерческое предложение. Надеемся на плодотворное сотрудничество!`
  }
};
