<!-- @format -->

<ng-container *ngIf="user$ | async as user">
<section class="box d-flex jc-between ai-start">
	<div class="contract bg-color-neutral_white rounded-sm bordered p-3">
		<h3 class="d-block mb-1">Договор о передаче товаров для распространения (купли-продажи)</h3>
		<app-services-progress
			class="d-block my-3 mx-1"
			[appMap]="progressMap"
		></app-services-progress>
		<ng-container [ngSwitch]="progressState">
      <!--specification-->
      <ng-container *ngSwitchCase="'specification'">
        <span class="d-block mx-1">Шаг 1: Заполнение спецификации</span>
        <div class="flex gap-1 mt-2">
          <div class="w-1/2 d-flex ai-start bg-color-neutral_light-gray-2 rounded-sm p-1">
            <app-svg-icon
              class="d-block mx-2"
              appIcon="active-bell"
              appWidth="32"
              appHeight="32"
              appViewBox="0 0 24 24"
            ></app-svg-icon>
            <div class="leading-4">
              <h1 class="h7 text-semibold">До подписания договор не является действующим</h1>
              <div class="h7 text-light">
                Вы можете пройти шаги, чтобы просто ознакомиться с предложением
                для дальнейшего предметного обсуждения с менеджером.
              </div>
            </div>
          </div>
          <div class="w-1/2 d-flex ai-start bg-color-neutral_light-gray-2 rounded-sm p-1">
            <app-svg-icon
              class="d-block mx-2"
              appIcon="active-bell"
              appWidth="32"
              appHeight="32"
              appViewBox="0 0 24 24"
            ></app-svg-icon>
            <div class="leading-4">
              <h1 class="h7 text-semibold">Неподписанные договоры можно удалять</h1>
              <div class="h7 text-light">
                Сформированные договоры отображаются в вашем профиле.
                Черновики договоров могут быть удалены по вашему усмотрению.
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex ai-start bg-color-log-print_yellow-25 rounded-sm p-3 my-3">
          <app-svg-icon
            class="d-block mr-3"
            appIcon="attention-round-yellow"
            appWidth="40"
            appHeight="40"
            appViewBox="0 0 40 40"
          ></app-svg-icon>
          <div class="h6 text-color-log-print_text leading-5">
            <p><span class="text-semibold">При оценке стоимости единицы товара закладывайте расходы Продавца, связанные с продажей</span>,
              включая стоимость тары, упаковки и доставки товара Покупателю.</p>
            <div>Товары предназначены для использования в налогооблагаемых операциях, в т.ч. для перепродажи.</div>
          </div>
        </div>
        <form [formGroup]="specificationForm">
          <table class="table-auto">
            <thead>
            <tr>
              <th>Наименование</th>
              <th style="width: 20%">Цена за единицу, руб.</th>
              <th style="width: 20%">Количество, шт.</th>
              <th style="width: 1%"></th>
            </tr>
            </thead>
            <tbody formArrayName="items">
            <tr [formGroupName]="i" *ngFor="let item of getSpecificationFormControls(); let i = index ; let last = last">
              <td>
                <fieldset class="input">
                  <input class="" type="text" id="newAttributeName" formControlName="name" />
                </fieldset>
              </td>
              <td>
                <fieldset class="input">
                  <input class="text-right" type="number" min="0" id="newAttributePrice" formControlName="price" />
                </fieldset>
              </td>
              <td>
                <fieldset class="input">
                  <input class="text-right" type="number" min="0" id="newAttributeQuantity" formControlName="quantity"/>
                </fieldset>
              </td>
              <td>
                <button
                  *ngIf="last"
                  class="bg-gray-200 w-[2rem] h-[2rem] rounded-full ml-1"
                  type="button"
                  (click)="onSpecificationFormItemAdd()"
                  title="Добавить строку">+</button>
                <button
                  *ngIf="!last"
                  class="bg-rose-200 w-[2rem] h-[2rem] rounded-full ml-1"
                  (click)="onSpecificationFormItemRemove(i)"
                  title="Удалить строку">-</button>
              </td>
            </tr>
            </tbody>
          </table>
        </form>
      </ng-container>
      <!--contract-->
      <ng-container *ngSwitchCase="'contract'">
        <span class="d-block mx-1">Шаг 2: Выбор типа договора</span>
        <div class="d-flex ai-start bg-color-log-print_yellow-25 rounded-sm p-3 my-3">
          <app-svg-icon
            class="d-block mr-3"
            appIcon="attention-round-yellow"
            appWidth="40"
            appHeight="40"
            appViewBox="0 0 40 40"
          ></app-svg-icon>
          <div class="h6 text-color-log-print_text line-height-3">
            Обратите внимание, что по условиям договора Продавец самостоятельно рассчитывает
            и перечисляет в бюджет суммы налогов, обязанность по уплате которых возникает у Продавца
            в связи с получением от Покупателя денежных средств в счёт оплаты проданного товара.
          </div>
        </div>
        <form [formGroup]="contractForm">
          <fieldset [disabled]="contractFormIsSubmitting">
            <ul
              class="contract-list d-flex jc-between pl-0 m-0"
              *ngIf="contractForm.get('contractId') as contractId"
            >
              <li
                class="d-flex w-full"
                *ngFor="let contract of contractList; let i = index"
              >
                <fieldset
                  class="position-r rounded-md w-full"
                  *ngIf="{value: contractId.value === contract.id} as active"
                  [class.active]="active.value"
                >
                  <input
                    [id]="'contract-id-' + i"
                    type="radio"
                    formControlName="contractId"
                    [value]="contract.id"
                    hidden
                  />
                  <label [for]="'contract-id-' + i">
										<span class="d-block cursor-p py-5 px-3">
											{{ i + 1 }}. {{ contract.name }}
										</span>
                  </label>
                  <app-svg-icon
                    class="d-block active-icon"
                    *ngIf="active.value"
                    appIcon="success-round-green"
                    appWidth="23"
                    appHeight="24"
                    appViewBox="0 0 23 24"
                  ></app-svg-icon>
                </fieldset>
              </li>
            </ul>
          </fieldset>
        </form>
      </ng-container>
      <!--application-->
			<ng-container *ngSwitchCase="'application'">
        <span class="d-block mx-1">Шаг 3: Заполнение реквизитов Продавца</span>
				<div class="d-flex ai-start bg-color-log-print_yellow-25 rounded-sm p-3 my-3">
					<app-svg-icon
						class="d-block mr-3"
						appIcon="attention-round-yellow"
						appWidth="40"
						appHeight="40"
						appViewBox="0 0 40 40"
					></app-svg-icon>
					<div class="h6 text-color-log-print_text line-height-3">
						<div>
							Пожалуйста, заполните <span class="text-weight-600">все поля формы</span>
							для договора <span class="text-weight-600">"{{ contractSelected?.name }}"</span>.
							При отсутствии данных поставьте прочерк.
						</div>
					</div>
				</div>
				<form [formGroup]="applicationForm">
					<fieldset
						[disabled]="applicationFormIsSubmitting"
						*ngIf="contractSelected && contractSelected.fields"
					>
						<fieldset
							class="input d-flex jc-start ai-start"
							[class.mb-1]="!l"
							*ngFor="let field of contractSelected.fields; let l = last"
							[disabled]="field.is_disabled"
						>
							<label class="d-block text-color-neutral_gray-1 h7 mb-1" [for]="field.key">
								{{ field.title }}:
							</label>
							<input
								[id]="field.key"
								[formControlName]="field.key"
								[mask]="universalFieldMask(field.key)"
								[dropSpecialCharacters]="false"
								appInputTrimWhitespace
							/>
						</fieldset>
					</fieldset>
				</form>
			</ng-container>
      <!--submit-->
			<ng-container *ngSwitchCase="'submit'">
				<div class="d-flex ai-center jc-start bg-color-neutral_light-gray-2 p-3">
					<div class="mr-3">
						<app-svg-icon
							class="d-block"
							appIcon="status-await"
							appWidth="40"
							appHeight="40"
							appViewBox="0 0 24 25"
						></app-svg-icon>
					</div>
					<div class="d-block h4">
            Формируется договор...
					</div>
				</div>
			</ng-container>
			<ng-container *ngSwitchCase="'error'">
				<div class="d-flex ai-center jc-start bg-[#ffecef] p-3">
					<div class="mr-3">
						<app-svg-icon
							class="d-block"
							appIcon="status-fail"
							appWidth="40"
							appHeight="40"
							appViewBox="0 0 24 25"
						></app-svg-icon>
					</div>
					<div class="d-block h4">
            Ошибка! Пожалуйста, обратитесь к менеджеру.
					</div>
				</div>
			</ng-container>
      <!--done-->
			<ng-container *ngSwitchCase="'done'">
				<div class="d-flex ai-start jc-start bg-color-neutral_light-gray-2 p-3">
					<div class="d-flex ai-start jc-start mr-3">
						<app-svg-icon
							class="d-block"
							appIcon="email-green"
							appWidth="40"
							appHeight="40"
							appViewBox="0 0 40 40"
						></app-svg-icon>
					</div>
					<div class="d-block">
						<h1 class="d-block h3 text-bold mb-1">
              Договор сформирован и ожидает подписания
						</h1>
						<p class="line-height-3 text-semibold">
              Копия договора отправлена вложением на адрес электронной почты указанный вами при регистрации.
            </p>
            <p class="line-height-3">
              Договор вступит в силу и станет обязательным для сторон после подписания.
              Если вы готовы подписать договор, перейдите по ссылке в письме.
						</p>
						<!--<button
							class="d-flex ai-center btn btn-style-1 btn-inline px-3 rounded-md mr-3 line-height-5 mt-5"
							(click)="onContractResend()"
							[disabled]="contractResendIsSubmitting"
						>
							<app-svg-icon
								class="mr-1"
								appIcon="email-black"
								appWidth="20"
								appHeight="16"
								appViewBox="0 0 20 16"
							></app-svg-icon>
							<span>Выслать письмо еще раз</span>
						</button>-->
            <button
              class="d-flex ai-center btn btn-style-2 btn-inline btn-sm rounded-md px-3 mt-3"
              (click)="onDownloadContract()"
            >
              <app-svg-icon
                class="mr-1"
                appIcon="download-black"
                appWidth="20"
                appHeight="21"
                appViewBox="0 0 20 21"
              ></app-svg-icon>
              <span>Скачать договор</span>
            </button>
					</div>
				</div>
        <div class="d-flex ai-start bg-color-neutral_light-gray-2 rounded-sm p-1 mt-2">
          <app-svg-icon
            class="d-block ml-2 mr-2"
            appIcon="active-bell"
            appWidth="40"
            appHeight="40"
            appViewBox="0 0 24 24"
          ></app-svg-icon>
          <div class="leading-4">
            <h1 class="h7 text-semibold">Все сформированные договоры отображаются в вашем профиле</h1>
            <div class="h7 text-light">
              Сформированные договоры также видны менеджеру. Вы можете ссылаться на названия файлов в профиле при обмене информацией.
            </div>
          </div>
        </div>
      </ng-container>
		</ng-container>
	</div>
	<ng-container [ngSwitch]="progressState">
		<div class="content" *ngSwitchCase="'specification'">
			<div class="d-flex ai-center jc-end">
				<button
					class="btn btn-style-1 btn-inline rounded-md lg d-flex ai-center px-3"
					[disabled]="specificationForm.invalid"
					(click)="onSubmitSpecificationForm()"
				>
					<app-svg-icon
						class="mr-1"
						appIcon="contract-black"
						appWidth="19"
						appHeight="20"
						appViewBox="0 0 19 20"
					></app-svg-icon>
					<span>Продолжить</span>
				</button>
			</div>
		</div>
    <div class="content" *ngSwitchCase="'contract'">
      <div class="d-flex ai-center jc-between">
        <button
          class="btn btn-style-2 btn-inline rounded-md lg d-flex ai-center px-3"
          (click)="onReturnToInitial('contract')"
        >
          <app-svg-icon
            class="mr-1"
            appIcon="arrow-left-black"
            appWidth="15"
            appHeight="15"
            appViewBox="0 0 15 15"
          ></app-svg-icon>
          <span>Назад</span>
        </button>
        <button
          class="btn btn-style-1 btn-inline rounded-md lg d-flex ai-center px-3"
          [disabled]="contractForm.invalid"
          (click)="onSubmitContractForm()"
        >
          <span>Продолжить</span>
          <app-svg-icon
            class="ml-1"
            appIcon="arrow-right-black"
            appWidth="15"
            appHeight="15"
            appViewBox="0 0 15 14"
          ></app-svg-icon>
        </button>
      </div>
    </div>
		<div class="content" *ngSwitchCase="'application'">
			<div class="d-flex ai-center jc-between">
				<button
					class="btn btn-style-2 btn-inline rounded-md lg d-flex ai-center px-3"
					(click)="onReturnToInitial('application')"
				>
					<app-svg-icon
						class="mr-1"
						appIcon="arrow-left-black"
						appWidth="15"
						appHeight="15"
						appViewBox="0 0 15 15"
					></app-svg-icon>
					<span>Назад</span>
				</button>
				<button
					class="btn btn-style-1 btn-inline rounded-md lg d-flex ai-center px-3"
					[disabled]="applicationForm.invalid"
					(click)="onSubmitApplicationForm()"
				>
					<app-svg-icon
						class="mr-1"
						appIcon="contract-black"
						appWidth="19"
						appHeight="20"
						appViewBox="0 0 19 20"
					></app-svg-icon>
					<span>Продолжить</span>
				</button>
			</div>
		</div>
		<div class="content" *ngSwitchCase="'done'">
		</div>
	</ng-container>
	<div class="advertising d-flex fd-column">
		<div class="doc-templates mb-2" [ngClass]="templatesRla.isActive ? 'd-none' : 'd-block'">
			<div class="d-flex fd-column rounded-sm bg-color-log-print_yellow-25 bordered p-3">
				<div class="h4 text-bold line-height-1 mb-h">Шаблоны документов</div>
				<p class="h7">
					Перейдите в раздел, чтобы скачать шаблоны договоров, приложений и соглашений,
					используемых в сервисе Rugram.
				</p>
				<a
						[routerLink]="'/office/settings/templates'"
						[routerLinkActive]="['active']"
						#templatesRla="routerLinkActive"
						class="btn btn-style-1 btn-inline rounded-md d-flex ai-center jc-center"
				>
					<span>Перейти</span>
					<app-svg-icon
							class="ml-1"
							appIcon="arrow-right-black"
							appWidth="15"
							appHeight="15"
							appViewBox="0 0 15 15"
					></app-svg-icon>
				</a>
			</div>
		</div>
	</div>
</section>
</ng-container>
