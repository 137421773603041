/** @format */

import { Component, Input, OnInit } from '@angular/core';
import { Book } from '../../../../core';

@Component({
  selector: 'app-card-book, [appCardBook]',
  templateUrl: './card-book.component.html',
  styleUrls: ['./card-book.component.scss']
})
export class CardBookComponent implements OnInit {
  @Input()
  set appBook(book: Book | undefined) {
    this.book = book;
  }

  book: Book | undefined;

  constructor() {}

  ngOnInit(): void {}
}
