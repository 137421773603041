/** @format */

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-loader, [appLoader]',
  templateUrl: 'loader.component.html',
  styleUrls: ['loader.component.scss']
})
export class LoaderComponent implements OnInit {
  @Input()
  set appIsLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  isLoading = false;

  constructor() {}

  ngOnInit(): void {}
}
