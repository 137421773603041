/** @format */

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { Contract } from '../models';
import { map } from 'rxjs/operators';

@Injectable()
export class ContractService {
  constructor(private apiService: ApiService) {}

  getAll(params?: any): Observable<Contract[]> {
    return this.apiService.get('/v1/contracts', params).pipe(map((data: any) => data.data));
  }

  getContract(id: number): Observable<any> {
    return this.apiService.get('/v1/contracts/get/' + id, undefined, {
      responseType: 'arraybuffer'
    });
  }

  postContract(body: any): Observable<any> {
    return this.apiService.post('/v1/contracts/fill', body).pipe(map((data: any) => data.data));
  }

  postContractResend(id: number): Observable<any> {
    return this.apiService
      .post('/v1/contracts/' + id + '/resend', {})
      .pipe(map((data: any) => data.data));
  }
}
