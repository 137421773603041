/** @format */

import { Component, HostListener, Inject, NgZone, OnDestroy, OnInit } from '@angular/core';
import { APP_CONFIG, Configuration, PusherService, UserService } from './core';
import { animate, animateChild, group, query, style, transition, trigger } from "@angular/animations";
import { ChildrenOutletContexts, Event } from "@angular/router";
import Lenis, { LenisOptions as LenisOptionsBase } from "@studio-freight/lenis";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Draggable } from "gsap/Draggable";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { CustomEase } from "gsap/CustomEase";

type LenisOptions = LenisOptionsBase & {__experimental__naiveDimensions: any};

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  animations: [
    trigger('routeAnimations', [
      transition('* <=> *', [
        style({ position: 'relative' }),
        query(':enter, :leave', [
          style({
            position: 'absolute',
            width: '100%',
            transform: 'scale(1) translateY(0)'
          })
        ], { optional: true }),
        query(':enter', [
          style({ transform: 'scale(0) translateY(-100%)' })
        ], { optional: true }),
        query(':leave', animateChild(), { optional: true }),
        group([
          query(':leave', [
            animate('600ms ease-in', style({ transform: 'translateY(-100%)' }))
          ], { optional: true }),
          query(':enter', [
            animate('600ms 300ms cubic-bezier(0.35, 0, 0.25, 1)', style({ transform: 'scale(1) translateY(0)' }))
          ], { optional: true }),
          query('@*', animateChild(), { optional: true })
        ]),
      ]),
    ]),
  ]
})
export class AppComponent implements OnInit, OnDestroy {
  constructor(
    @Inject(APP_CONFIG) private configuration: Configuration,
    protected ngZone: NgZone,
    private contexts: ChildrenOutletContexts,
    private userService: UserService,
    private pusherService: PusherService,
  ) {}

  ngOnInit(): void {
    this.userService.getAuthorization();

    this.pusherService.onInitPusher();

    this.ngZone.runOutsideAngular(() => {
      this.initialiseLenisScroll();
      gsap.registerPlugin(ScrollTrigger, Draggable, ScrollToPlugin, CustomEase);
      this.adjustScrollTriggerProxy();
      // ScrollTrigger.defaults({markers: !this.configuration.production});
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.ngZone.runOutsideAngular(() => {
      this.adjustScrollTriggerProxy();
    });
  }

  ngOnDestroy(): void {
    this.pusherService.onDestroyPusher();
  }

  getRouteAnimationData() {
    return this.contexts.getContext('primary')?.route?.snapshot?.data?.['animation'];
  }

  adjustScrollTriggerProxy(): void {
    const zoom = 1 / parseFloat(getComputedStyle(document.documentElement).getPropertyValue('--zoom')) ?? 1.0;
    ScrollTrigger.scrollerProxy(window, {
      scrollTop(value) {
        if (arguments.length && value !== undefined) {
          window.scrollTo(0, value / zoom); // setter
        }
        return window.pageYOffset * zoom; // getter
      },
      getBoundingClientRect() {
        return {
          top: 0,
          left: 0,
          width: window.innerWidth,
          height: window.innerHeight,
        };
      }
    });
    ScrollTrigger.refresh();
  }

  initialiseLenisScroll(): void {
    const lenis = new Lenis({
      smoothWheel: true,
    } as LenisOptions);

    lenis.on('scroll', ScrollTrigger.update);

    gsap.ticker.add((time) => {
      lenis.raf(time * 1000);
    });

    gsap.ticker.lagSmoothing(0);
  };
}
