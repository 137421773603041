/** @format */

import { Injectable } from '@angular/core';
import {
  HttpResponse,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { HeaderService } from '../services';

@Injectable()
export class HttpLoaderInterceptor implements HttpInterceptor {
  private requests: HttpRequest<any>[] = [];

  constructor(private headerService: HeaderService) {}

  removeRequest(req: HttpRequest<any>): void {
    if (this.requests.indexOf(req) >= 0) {
      this.requests.splice(this.requests.indexOf(req), 1);
    }

    this.headerService.loading.next(this.requests.length > 0);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.requests.push(req);
    this.headerService.loading.next(true);

    return new Observable(observer => {
      const subscription = next.handle(req).subscribe({
        next: e => {
          if (e instanceof HttpResponse) {
            this.removeRequest(req);
            observer.next(e);
          }
        },
        error: e => {
          this.removeRequest(req);
          observer.error(e);
        },
        complete: () => {
          this.removeRequest(req);
          observer.complete();
        }
      });

      return () => {
        this.removeRequest(req);
        subscription.unsubscribe();
      };
    });
  }
}
