import {Directive, ElementRef, EventEmitter, HostListener, Input, OnInit, Optional, Output, Self,} from '@angular/core';
import { ControlValueAccessor, NgControl, } from "@angular/forms";
import { first } from "rxjs/operators";
import { CountryData, intlTelInput, IntlTelInputService } from '../../core/services/intl-tel-input-utils.service';
import { HelperService } from "../../core";

export interface PhoneNumberSelectedEvent {
  countryData?: CountryData;
  examplePhoneNumber?: string;
}

@Directive({
  selector: 'input[intlTelInput][formControlName]',
})
export class IntlTelInputDirective implements ControlValueAccessor, OnInit {

  @Input('intlTelInput') intlTelInputOptions = {};
  @Optional() @Input('placeholder') placeholder? = '';

  @Optional() @Output() countryChange = new EventEmitter<PhoneNumberSelectedEvent>();

  private intlTelInput!: intlTelInput.Plugin;
  private currentCountryData!: CountryData;
  private examplePhoneNumber?: string;

  constructor(
    @Optional() @Self() public controlDir: NgControl,
    private el: ElementRef,
    private readonly helperService: HelperService,
    private readonly phoneService: IntlTelInputService,
  ) {
    // controlDir.valueAccessor = this;
  }

  ngOnInit(): void {
    this.helperService.getInternationalPhoneInputConfiguration()
      .pipe(first())
      .subscribe({
        next: (internationalPhoneInputConfiguration: intlTelInput.Options) => {
          const opts = {
            ...internationalPhoneInputConfiguration,
            ...this.intlTelInputOptions,
          };
          this.intlTelInput = intlTelInput(this.el.nativeElement, opts);
          this.controlDir.control!.addValidators(this.phoneService.isValidPhoneFormControl2(this.intlTelInput));
          setTimeout(() => this.handleCountryChange());
        },
        error: (error: any) => console.error(error)
      });
  }

  @HostListener("input")
  onControlInput() {
    this.controlDir.control!.markAsTouched();
    this.handleInput();
  }

  @HostListener("blur")
  onControlBlur() {
    this.handleBlur();
  }

  @HostListener('countrychange')
  onCountryChange() {
    this.handleCountryChange();
  }

  registerOnChange(fn: any): void {
    // this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    // this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {}

  writeValue(rawValue: any): void {
    const modelValue = this._computeModelValue(rawValue);
    if (rawValue !== modelValue) {
      this.controlDir.control!.setValue(modelValue);
    }
    if (this.el.nativeElement) {
      this.el.nativeElement.value = this._computeViewValue(rawValue);
    }
    // setTimeout(() => this.emitChanges());
  }

  propagateChange(modelValue: any): void {
    this.writeValue(modelValue);
  }

  emitCountryData(): void {
    this.el.nativeElement.placeholder = this.examplePhoneNumber;
    this.countryChange.next({
      countryData: this.currentCountryData,
      examplePhoneNumber: this.examplePhoneNumber,
    });
  }

  onTouched = () => {};

  handleInput() {
    const rawValue = this.el.nativeElement.value;
    this.onTouched();
    this.writeValue(rawValue);
  }

  handleBlur() {
    const rawValue = this.el.nativeElement.value;
    this.el.nativeElement.value = this._computeViewValue(rawValue || null);
  }

  handleCountryChange(): void {
    this.currentCountryData = this.intlTelInput.getSelectedCountryData();
    this.examplePhoneNumber = this.phoneService.getRawExampleInternationalNumberForCountry(this.currentCountryData?.iso2)?.formatInternational();
    this.writeValue(this.el.nativeElement.value);
    this.emitCountryData();
  }

  private _computeViewValue(rawValue: any) {
    const viewValue = this.phoneService.getInternational(rawValue?.replace(/[^0-9+]/g, '') ?? '', this.currentCountryData?.iso2);
    return (viewValue || rawValue);
  }

  private _computeModelValue(rawValue: any) {
    return rawValue?.replace(/[^0-9+]/g, '') ?? '';
  }
}
