<!-- @format -->

<div class="box position-r rounded-sm">
  <div class="scroller w-full overflow-h">
    <ul class="items inner d-flex pl-0 my-0" #postListElement>
      <li
        [style]="'--width:' + postWidth + 'px;'"
        [style.background-image]="'url(' + post.image + ')'"
        class="item position-r d-flex jc-start ai-end overflow-h rounded-sm p-3"
        *ngFor="let post of posts"
      >
        <a [routerLink]="'/office/posts/' + post.id">
          <div class="overlay"></div>
          <span class="link-to-detail text-semibold line-height-2 h4 mb-0 w-full">
            {{ post.title }}
          </span>
        </a>
      </li>
    </ul>
  </div>
  <div class="drag-proxy"></div>
  <div class="controls">
		<button (click)="onSlide(-1)" class="p-1 bg-color-log-print_dark-gray cursor-p">
			<app-svg-icon
				appIcon="arrow-left-with-body"
				appWidth="16"
				appHeight="10"
				appViewBox="0 0 16 10"
			></app-svg-icon>
		</button>
		<button (click)="onSlide(1)" class="p-1 bg-color-log-print_dark-gray cursor-p">
			<app-svg-icon
				appIcon="arrow-left-with-body"
				appWidth="16"
				appHeight="10"
				appViewBox="0 0 16 10"
			></app-svg-icon>
		</button>
	</div>
</div>
