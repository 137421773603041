/** @format */

import { NgModule } from '@angular/core';
import { SharedModule } from '../shared';
import { AuthComponent } from './auth.component';
import { AuthLoginComponent } from './login/auth-login.component';
import { AuthConfirmComponent } from './confirm/auth-confirm.component';
import { AuthResetComponent } from './reset/auth-reset.component';
import { AuthRegistrationComponent } from './registration/auth-registration.component';
import { AuthSmsComponent } from './sms/auth-sms.component';
import { AuthRoutingModule } from './auth-routing.module';
import { NoAuthGuard } from './no-auth-guard.service';
import { SoftAuthGuard } from './soft-auth-guard.service';
import { AuthImportComponent } from './import/auth-import.component';

@NgModule({
  imports: [SharedModule, AuthRoutingModule],
  declarations: [
    AuthComponent,
    AuthImportComponent,
    AuthLoginComponent,
    AuthConfirmComponent,
    AuthResetComponent,
    AuthRegistrationComponent,
    AuthSmsComponent
  ],
  providers: [NoAuthGuard, SoftAuthGuard]
})
export class AuthModule {}
