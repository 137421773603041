/** @format */

import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared';
import { OfficeHelpComponent } from './office-help.component';
import { OfficeHelpRoutingModule } from './office-help-routing.module';
import { OfficeHelpFormComponent } from './form/form.component';
import { OfficeHelpSaleInStoresComponent } from './sale-in-stores/sale-in-stores.component';
import { OfficeHelpSocialComponent } from "./social/social.component";

@NgModule({
  imports: [SharedModule, OfficeHelpRoutingModule],
  declarations: [OfficeHelpComponent, OfficeHelpFormComponent, OfficeHelpSaleInStoresComponent, OfficeHelpSocialComponent]
})
export class OfficeHelpModule {}
