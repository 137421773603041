/** @format */

import { Component, Input, OnDestroy, OnInit } from '@angular/core';

type MapState = 'initial' | 'active' | 'done';

@Component({
  selector: 'app-services-progress',
  templateUrl: './services-progress.component.html',
  styleUrls: ['./services-progress.component.scss']
})
export class ServicesProgressComponent implements OnInit, OnDestroy {
  @Input()
  set appMap(map: string[]) {
    this.map = map as MapState[];

    if (this.map.every((state: MapState) => state === 'done')) {
      this.mapProgress = this.map.length;
    } else {
      this.mapProgress = this.map.findIndex((state: MapState) => state === 'active');
    }
  }

  map: MapState[] = [];

  mapProgress = 0;

  constructor() {}

  ngOnInit(): void {}

  ngOnDestroy(): void {}
}
