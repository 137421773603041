/** @format */

import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { Breadcrumb } from '../models';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {
  private currentBreadcrumbsSubject = new BehaviorSubject<Breadcrumb[]>([]);
  // prettier-ignore
  public currentBreadcrumbs = this.currentBreadcrumbsSubject.asObservable().pipe(distinctUntilChanged());

  public loading = new BehaviorSubject(false);

  constructor(private router: Router, private route: ActivatedRoute) {
    this.router.events
      .pipe(
        filter(e => e instanceof NavigationEnd),
        distinctUntilChanged()
      )
      .subscribe({
        next: () => {
          this.currentBreadcrumbsSubject.next([]);
          this.pullBreadCrumbs(this.route.root);
        },
        error: (error: any) => console.error(error)
      });
  }

  pullBreadCrumbs(route: ActivatedRoute): void {
    if (route.routeConfig && route.routeConfig.data) {
      this.updateBreadcrumbs(this.getBreadcrumbs().concat([route.routeConfig.data.breadcrumb]));
    }

    if (route.firstChild) {
      this.pullBreadCrumbs(route.firstChild);
    }
  }

  updateIdBreadcrumb(label: string, routerLink: string): void {
    this.updateBreadcrumbs(
      this.getBreadcrumbs().map(b => ({
        label: b.label === 'Lazy' ? label : b.label,
        routerLink:
          b.routerLink.indexOf(':id') >= 0 ? b.routerLink.replace(':id', routerLink) : b.routerLink
      }))
    );
  }

  updateBreadcrumbs(breadcrumbs: Breadcrumb[]): void {
    this.currentBreadcrumbsSubject.next(breadcrumbs);
  }

  getBreadcrumbs(): Breadcrumb[] {
    return this.currentBreadcrumbsSubject.value;
  }
}
