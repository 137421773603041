/** @format */

import { Component, OnInit } from '@angular/core';
import { HelperService, SnackbarService, User, UserService } from '../../core';
import { ChildrenOutletContexts, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { tap } from 'rxjs/operators';
import { transition, trigger } from "@angular/animations";
import { flipInOut, none } from "../books/detail/detail-animations";

interface AvatarForm {
  avatar: FormControl<string | null>;
}

@Component({
  selector: 'app-office-settings',
  templateUrl: './office-settings.component.html',
  styleUrls: ['./office-settings.component.scss'],
  animations: [
    trigger('routeAnimations', [
      transition('void <=> *', none),
      transition('* <=> *', flipInOut),
    ])
  ]
})
export class OfficeSettingsComponent implements OnInit {
  mimeTypes: string[] = [
    'image/jpe',
    'image/jpg',
    'image/jpeg',
    'image/png',
    'image/svg+xml',
    'image/tiff'
  ];

  avatarForm: FormGroup;

  isSubmitted = false;

  user$: Observable<User> | undefined;

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private router: Router,
    private contexts: ChildrenOutletContexts,
    private helperService: HelperService,
    private snackbarService: SnackbarService
  ) {
    this.avatarForm = this.formBuilder.group<AvatarForm>({
      avatar: this.formBuilder.control(null, [Validators.required])
    });
  }

  ngOnInit(): void {
    this.user$ = this.userService.currentUser;
  }

  onChangeAvatar(event: Event): void {
    if (this.helperService.getFormValidation(this.avatarForm)) {
      // @ts-ignore
      const file: File = (event.target as HTMLInputElement).files[0];

      if (file && this.helperService.getFileValidation(file, this.mimeTypes)) {
        this.isSubmitted = true;

        const formData: FormData = new FormData();

        formData.append('avatar', file);

        this.userService
          .postAvatar(formData)
          .pipe(tap(() => (this.isSubmitted = false)))
          .subscribe({
            next: () => this.snackbarService.success('Аватар успешно изменен'),
            error: (error: any) => console.error(error)
          });
      }
    }
  }

  onLogout(): void {
    this.userService.getLogout({ flag: 'this' }).subscribe({
      next: () => this.router.navigateByUrl('/').then(() => console.debug('Route changed')),
      error: (error: any) => console.error(error)
    });
  }

  onRemoveAccount(): void {
    console.log('onRemoveAccount');
  }

  getRouteAnimationData() {
    return this.contexts.getContext('primary')?.route?.snapshot?.data?.['animation'];
  }
}
