<!-- @format -->

<div class="box position-r d-flex ai-center jc-center w-full h-full">
	<div class="w-full h-full" [class.hidden]="isLoading">
		<ng-content select="[slot=content]"></ng-content>
	</div>
	<div class="loader" *ngIf="isLoading">
		<div class="loader-dot"></div>
		<div class="loader-dot"></div>
		<div class="loader-dot"></div>
		<div class="loader-dot"></div>
	</div>
</div>
