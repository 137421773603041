/** @format */

import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class CookieService {
  constructor(@Inject(DOCUMENT) private document: Document) {}

  getItem(item: string): any {
    const matches = document.cookie.match(
      new RegExp('(?:^|; )' + item.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)')
    );

    return matches ? decodeURIComponent(matches[1]) : undefined;
  }

  setItem(key: string, value: string, options: any): void {
    options = {
      path: '/',
      ...options
    };

    if (options.expires instanceof Date) {
      options.expires = options.expires.toUTCString();
    }

    let updatedCookie = encodeURIComponent(key) + '=' + encodeURIComponent(value);

    // eslint-disable-next-line guard-for-in
    for (const optionKey in options) {
      updatedCookie += '; ' + optionKey;

      const optionValue = options[optionKey];

      if (optionValue !== true) {
        updatedCookie += '=' + optionValue;
      }
    }

    this.document.cookie = updatedCookie;
  }

  removeItem(item: string): void {
    this.setItem(item, '', {
      ['max-age']: -1
    });
  }
}
