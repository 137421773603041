/** @format */

import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Autocomplete, FlagAutocomplete } from '../models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AutocompleteService {
  constructor(private apiService: ApiService) {}

  get(flag: FlagAutocomplete, search: string): Observable<Autocomplete[] | undefined> {
    return this.apiService
      .post(`/v1/ac/${flag}`, { data: search })
      .pipe(map((data: any) => data.data));
  }
}
